/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable eqeqeq */
import { useEffect, useImperativeHandle, useRef, useState } from "react";
import SkyeModal from "../../components/SkyeModal";
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { helpers } from "../../../../utilities/helpers";
import { db } from "../../../..";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Input,
} from "@material-tailwind/react";
import { CircularProgress } from "@mui/material";
import { RemoveRedEye } from "@mui/icons-material";
import PaymentMethodFormView from "../../../portal/account_tabs/PaymentMethodFormView";

type Props = {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  user?: any;
  setUser: (user: any) => void;
  onModalUpdated: (user: any) => void;
};
const UserModal = ({
  showModal,
  setShowModal,
  user,
  setUser,
  onModalUpdated,
}: Props) => {
  const [userData, setUserData] = useState(user ?? {});

  useEffect(() => {
    setUserData(user);
  }, [user]);

  const onInputChanged = (e: any) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const [activeTab, setActiveTab] = useState("account");
  const data = [
    {
      label: "ACCOUNT",
      value: "account",
    },
    {
      label: "PROFILE",
      value: "profile",
    },
    // {
    //   label: "ACCREDITATION",
    //   value: "accreditation",
    // },
    // {
    //   label: "USERS",
    //   value: "users",
    // },
    {
      label: "PAYMENT METHOD",
      value: "payment-method",
    },
  ];

  const [paymentMethods, setPaymentMethods] = useState([] as any[]);
  const [paymentMethodsLoading, setPaymentMethodsLoading] = useState(true);

  const [paymentForm, setPaymentForm] = useState({} as any);
  const [showPaymentForm, setShowPaymentForm] = useState(false);

  const transactModalRef = useRef(null as any);

  const onPaymentFormCancel = () => {
    setShowPaymentForm(false);
    setPaymentForm({});
  };

  const onTransactModalUpdated = (data: any) => {
    setUserData({ ...userData, ...data });
    onModalUpdated(data);
  };

  const fetchMethods = async () => {
    setPaymentMethodsLoading(true);
    let methods: any[] = [];
    //fetch methods from firebase
    const methodsRef = collection(db, "payment_methods");

    var queries: any[] = [where("user_id", "==", userData.id)];

    queries.push(orderBy("created_at", "desc"));

    const q = query(methodsRef, ...queries);

    await getDocs(q).then((snapshot) => {
      snapshot.forEach((doc) => {
        methods.push({ ...doc.data(), id: doc.id });
      });
    });
    setPaymentMethods(methods);
    setPaymentMethodsLoading(false);
  };

  useEffect(() => {
    if (userData.id) {
      fetchMethods();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData.id]);

  const generatePassword = () => {
    const chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const passwordLength = 8;
    var password = "";

    for (var i = 0; i <= passwordLength; i++) {
      const randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber + 1);
    }

    setUserData({ ...userData, password: password });
  };

  const onFormSubmit = async (e: any) => {
    e.preventDefault();

    const loading = toast.loading("Please wait...");

    let formData: any = { ...userData };

    formData.full_name = `${formData.first_name} ${formData.last_name}`;

    //if status is approved, then set to active
    if (formData.status === "approved") {
      formData.status = "active";
    } else {
      formData.status = formData.status ?? "pending";
    }

    //update user.verified
    if (formData.verification_status === "success") {
      formData.verified = true;
    } else {
      formData.verified = false;
    }

    //if email, set it to lowercase
    if (formData.email && formData.email !== "") {
      formData.email = formData?.email?.toLowerCase();
    }

    if (formData.id) {
      //update
      //remove id, created_at
      delete formData.id;
      delete formData.created_at;
      formData.updated_at = Timestamp.now();
      formData.search_keywords = helpers.generateSearchKeywords(
        formData.first_name + " " + formData.last_name
      );

      //add to firebase
      const userRef = doc(db, "users", userData.id);
      await updateDoc(userRef, formData, { merge: true })
        .then(async () => {
          //get the updated data
          const docSnap = await getDoc(userRef);
          if (docSnap.exists()) {
            setUser({ ...docSnap.data(), id: docSnap.id });
            setUserData({ ...docSnap.data(), id: docSnap.id });
            onModalUpdated({ ...docSnap.data(), id: docSnap.id });
            toast.success("Updated successfully");
          } else {
            toast.error("Failed to update");
          }
        })
        .catch((error) => {
          toast.error("Failed to update");
        });
    } else {
      //add
      formData.doc_number = await helpers.getNextDocumentNumber("users");
      formData.created_at = Timestamp.now();
      formData.updated_at = Timestamp.now();
      formData.search_keywords = helpers.generateSearchKeywords(
        formData.first_name + " " + formData.last_name
      );

      const usersRef = collection(db, "users");

      //check if email exists
      const emailQuerySnapshot = await getDocs(
        query(usersRef, where("email", "==", formData.email))
      );
      if (!emailQuerySnapshot.empty) {
        toast.error("Email already exists");
        toast.dismiss(loading);
        return;
      }

      //check if phone exists
      const phoneQuerySnapshot = await getDocs(
        query(usersRef, where("phone", "==", formData.phone))
      );
      if (!phoneQuerySnapshot.empty) {
        toast.error("Phone already exists");
        toast.dismiss(loading);
        return;
      }

      await addDoc(usersRef, formData)
        .then(async (docRef) => {
          //get the added data
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setUser({ ...docSnap.data(), id: docSnap.id });
            setUserData({ ...docSnap.data(), id: docSnap.id });
            onModalUpdated({ ...docSnap.data(), id: docSnap.id });
            toast.success("Added successfully");
          } else {
            toast.error("Failed to add");
          }
        })
        .catch((error) => {
          toast.error("Failed to add");
        });
    }

    toast.dismiss(loading);
    setShowModal(false);
    onModalUpdated(formData);
  };

  return (
    <>
      <SkyeModal
        title={userData.id ? "User Information" : "New User"}
        size="max-w-7xl"
        flex={false}
        showModal={showModal}
        setShowModal={setShowModal}
      >
        <form
          className="space-y-6"
          id="user-edit-form"
          onSubmit={onFormSubmit}
          method="POST"
          encType="multipart/form-data"
          autoComplete="off"
          autoSave="off"
        >
          {/*body*/}
          <div className="relative p-6 flex-auto text-left">
            {/* body */}

            {/* account balance */}
            <div className="mb-2">
              <div className="text-center">
                <label
                  htmlFor="account_balance"
                  className="text-center block mb-2 text-sm w-full font-bold text-gray-900 dark:text-gray-300"
                >
                  Account Balance
                </label>

                <div className="text-center">
                  <div
                    onClick={() => { transactModalRef && transactModalRef.current?.openModal("debit", userData) }}
                    className="cursor-pointer text-green-900 inline-block w-9 h-9 text-2xl border-2 border-red-600 hover:bg-red-600 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-400 font-medium rounded-full text-center items-center"
                  >
                    -
                  </div>
                  <input
                    name="account_balance"
                    value={userData.account_balance || ""}
                    defaultValue={userData.account_balance || ""}
                    readOnly
                    type="number"
                    id="account_balance"
                    className="bg-gray-50 mx-1 inline-block w-72 text-center font-bold text-2xl border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500  p-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="0.0"
                  />

                  <div
                    onClick={() => { transactModalRef && transactModalRef.current?.openModal("credit", userData) }}
                    className="cursor-pointer text-blue-900 inline-block w-9 h-9 text-2xl border border-blue-900 hover:bg-blue-900 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-400 font-medium rounded-full text-center items-center"
                  >
                    +
                  </div>
                </div>
              </div>
            </div>

            <Tabs value={activeTab}>
              <TabsHeader
                placeholder={""}
                className="rounded-none border-b border-blue-gray-100 bg-transparent p-0 md:pr-[500px] overflow-x-auto !flex-none grid grid-cols-2 md:!flex"
                indicatorProps={{
                  className:
                    "bg-transparent border-b-[5px] border-gray-700 shadow-none rounded-none w-auto",
                }}
              >
                {data.map(({ label, value }) => (
                  <Tab
                    placeholder={""}
                    key={value}
                    value={value}
                    onClick={() => {
                      setActiveTab(value);
                    }}
                    className={`font-bold whitespace-nowrap ${
                      activeTab === value ? "text-gray-900" : "text-gray-600"
                    }`}
                  >
                    {label}
                  </Tab>
                ))}
              </TabsHeader>
              <TabsBody className="w-full" placeholder={""}>
                {data.map(({ value }) => (
                  <TabPanel className="w-full !p-0" key={value} value={value}>
                    {(() => {
                      if (value == "account") {
                        return (
                          <>
                            <div className="grid grid-cols-6 gap-6">
                              <div className="grid grid-cols-3 gap-6 col-span-6">
                                <div className="">
                                  <label
                                    htmlFor="first_name"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    First name
                                  </label>
                                  <input
                                    name="first_name"
                                    value={userData.first_name || ""}
                                    onChange={onInputChanged}
                                    type="text"
                                    id="first_name"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Full name"
                                  />
                                </div>

                                <div className="">
                                  <label
                                    htmlFor="middle_name"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Middle Name
                                  </label>
                                  <input
                                    name="middle_name"
                                    value={userData.middle_name || ""}
                                    onChange={onInputChanged}
                                    type="text"
                                    id="middle_name"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Middle name"
                                  />
                                </div>

                                <div className="">
                                  <label
                                    htmlFor="last_name"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Last name
                                  </label>
                                  <input
                                    name="last_name"
                                    value={userData.last_name || ""}
                                    onChange={onInputChanged}
                                    type="text"
                                    id="last_name"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Full name"
                                  />
                                </div>
                              </div>

                              <div className="col-span-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full gap-y-2 gap-x-3">
                                <Input
                                  crossOrigin={"none"}
                                  name="title"
                                  type="text"
                                  label="Title"
                                  className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                                  placeholder="Title"
                                  value={userData.title || ""}
                                  onChange={onInputChanged}
                                />

                                <Input
                                  crossOrigin={"none"}
                                  label="Phone Number"
                                  name="phone"
                                  type="number"
                                  // required
                                  className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                                  placeholder="Phone Number *"
                                  value={userData.phone || ""}
                                  onChange={onInputChanged}
                                />

                                <Input
                                  crossOrigin={"none"}
                                  label="Date of Birth"
                                  name="dob"
                                  type="date"
                                  // required
                                  className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                                  placeholder="Date of Birth *"
                                  value={userData.dob || ""}
                                  onChange={onInputChanged}
                                />
                              </div>

                              <div className="col-span-6 lg:col-span-3">
                                <label
                                  htmlFor="email"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Email address
                                </label>
                                <input
                                  name="email"
                                  value={userData.email || ""}
                                  onChange={onInputChanged}
                                  // required
                                  type="email"
                                  className="bg-gray-50 border border-gray-300 text-gray-500 text-sm font-semibold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  placeholder="Email address"
                                />
                              </div>
                              {/* city */}
                              <div className="col-span-6 lg:col-span-3">
                                <label
                                  htmlFor="city"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  City
                                </label>
                                <input
                                  name="city"
                                  value={userData.city || ""}
                                  onChange={onInputChanged}
                                  type="text"
                                  id="city"
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  placeholder="City"
                                />
                              </div>
                              {/* state */}
                              <div className="col-span-6 lg:col-span-3">
                                <label
                                  htmlFor="state"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  State
                                </label>
                                <input
                                  name="state"
                                  value={userData.state || ""}
                                  onChange={onInputChanged}
                                  type="text"
                                  id="state"
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  placeholder="State"
                                />
                              </div>
                              {/* country */}
                              <div className="col-span-6 lg:col-span-3">
                                <label
                                  htmlFor="country"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Country
                                </label>
                                <input
                                  name="country"
                                  value={userData.country || ""}
                                  onChange={onInputChanged}
                                  type="text"
                                  id="country"
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  placeholder="Country"
                                />
                              </div>

                              <div className="col-span-6 lg:col-span-3">
                                <label
                                  htmlFor="address"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Address
                                </label>
                                <input
                                  name="address"
                                  value={userData.address || ""}
                                  onChange={onInputChanged}
                                  type="text"
                                  id="address"
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  placeholder="Address"
                                />
                              </div>

                              <div className="col-span-6 lg:col-span-3">
                                <label
                                  htmlFor="zip_code"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Zip
                                </label>
                                <input
                                  name="zip_code"
                                  value={userData.zip_code || ""}
                                  onChange={onInputChanged}
                                  type="text"
                                  id="zip_code"
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  placeholder="Zip Code"
                                />
                              </div>

                              {userData.id &&
                                !["admin", "super_admin"].includes(
                                  userData.role
                                ) && (
                                  <div className="col-span-6 grid grid-cols-6 gap-6">
                                    <div className="col-span-6 lg:col-span-3">
                                      <label
                                        htmlFor="password"
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Password
                                      </label>
                                      <div className="grid grid-cols-4 gap-x-2">
                                        <input
                                          name="password"
                                          value={userData.password || ""}
                                          onChange={onInputChanged}
                                          // required
                                          type="text"
                                          id="password"
                                          className="col-span-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                          placeholder="Password"
                                        />
                                        <div className="flex items-center">
                                          <button
                                            type="button"
                                            onClick={generatePassword}
                                            className="py-1 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200"
                                          >
                                            Generate
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                              <div className="col-span-6 flex flex-col gap-y-3">
                                <h1 className="text-xl !font-extralight">
                                  IDENTITY VERIFICATION
                                </h1>
                                <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-y-2 gap-x-3">
                                  <Input
                                    crossOrigin={"none"}
                                    label="Identification Number"
                                    name="society_security"
                                    type="number"
                                    // required
                                    className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                                    placeholder="Social Security #(US Investors Only)*"
                                    value={userData.society_security || ""}
                                    onChange={onInputChanged}
                                  />
                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full gap-y-2 gap-x-3">
                                  <Input
                                    crossOrigin={"none"}
                                    label="Nationality"
                                    name="nationality"
                                    className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                                    value={userData.nationality || ""}
                                    onChange={onInputChanged}
                                  />

                                  <Input
                                    crossOrigin={"none"}
                                    label="Country of Residence"
                                    name="country_of_residence"
                                    type="text"
                                    // required
                                    className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                                    placeholder="Country of Residence *"
                                    value={userData.country_of_residence || ""}
                                    onChange={onInputChanged}
                                  />

                                  <select
                                    name="identification_type"
                                    className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                                    value={userData.identification_type || ""}
                                    onChange={onInputChanged}
                                  >
                                    <option value="passport">Passport</option>
                                    <option value="driver_license">
                                      Driver License
                                    </option>
                                    <option value="government_id">
                                      Government ID
                                    </option>
                                    <option value="national_id">
                                      National ID
                                    </option>
                                  </select>
                                </div>

                                {/* file area */}
                                <div
                                  className="p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400 text-center"
                                  role="alert"
                                >
                                  <span className="font-medium">File!</span>{" "}
                                  {userData.identification_file ? (
                                    <span>
                                      <a
                                        href={userData.identification_file}
                                        target="_blank"
                                        className="text-blue-800 underline"
                                      >
                                        View file
                                      </a>
                                    </span>
                                  ) : (
                                    <span>No file uploaded</span>
                                  )}
                                </div>
                              </div>

                              <div className="col-span-6 lg:col-span-3">
                                <label
                                  htmlFor="status"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Status
                                </label>
                                <select
                                  name="status"
                                  value={userData.status || ""}
                                  onChange={onInputChanged}
                                  id="status"
                                  className="bg-gray-50 border-2 border-gray-300 text-gray-500 text-sm font-semibold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                >
                                  <option value="pending">Pending</option>
                                  <option value="approved">Approved</option>
                                  <option value="active">Active</option>
                                  <option value="inactive">Inactive</option>
                                </select>
                              </div>
                              <div className="col-span-6 lg:col-span-3">
                                <label
                                  htmlFor="verification_status"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Verification Status
                                </label>
                                <select
                                  name="verification_status"
                                  value={userData.verification_status || ""}
                                  onChange={onInputChanged}
                                  id="verification_status"
                                  className="bg-gray-50 border-2 border-gray-300 text-gray-500 text-sm font-semibold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                >
                                  <option value="pending">Pending</option>
                                  <option value="success">Verified</option>
                                  <option value="failed">Failed</option>
                                </select>
                              </div>
                            </div>
                          </>
                        );
                      } else if (value == "profile") {
                        return (
                          <>
                            <div className="w-full space-y-5">
                              <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-y-2 gap-x-3">
                                <div className="w-full flex flex-col gap-y-1">
                                  <label className="text-sm font-bold text-gray-700">
                                    Net Worth
                                  </label>
                                  <select
                                    name="net_worth"
                                    // required
                                    className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                                    value={userData.net_worth || ""}
                                    onChange={onInputChanged}
                                  >
                                    <option value="" disabled>
                                      Net Worth *
                                    </option>
                                    <option value="less_100000">
                                      less than $100,000
                                    </option>
                                    <option value="100001-250000">
                                      $100,001 - $250,000
                                    </option>
                                    <option value="250001-500000">
                                      $250,001 - $500,000
                                    </option>
                                    <option value="500001-1000000">
                                      $500,001 - $1,000,000
                                    </option>
                                    <option value="1000001-5000000">
                                      $1,000,001 - $5,000,000
                                    </option>
                                    <option value="more_5000000">
                                      more than $5,000,000
                                    </option>
                                    <option value="">(not set)</option>
                                  </select>
                                </div>

                                <div className="w-full flex flex-col gap-y-1">
                                  <label className="text-sm font-bold text-gray-700">
                                    Annual Net Income
                                  </label>
                                  <input
                                    name="annual_net_income"
                                    type="text"
                                    className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                                    placeholder="Annual Net Income"
                                    value={userData.annual_net_income || ""}
                                    onChange={onInputChanged}
                                  />
                                </div>
                              </div>

                              <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-y-2 gap-x-3">
                                <div className="w-full flex flex-col gap-y-1">
                                  <label className="text-sm font-bold text-gray-700">
                                    Highest Education
                                  </label>
                                  <select
                                    name="highest_education"
                                    // required
                                    className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                                    value={userData.highest_education || ""}
                                    onChange={onInputChanged}
                                  >
                                    <option value="" disabled>
                                      Highest Education *
                                    </option>
                                    <option value="less_high_school">
                                      Less than High School
                                    </option>
                                    <option value="high_school">
                                      High School Graduate
                                    </option>
                                    <option value="some_college">
                                      Some College
                                    </option>
                                    <option value="associate_degree">
                                      Associate's Degree
                                    </option>
                                    <option value="bachelor_degree">
                                      Bachelor's Degree
                                    </option>
                                    <option value="master_degree">
                                      Master's Degree
                                    </option>
                                    <option value="decorate">Doctorate</option>
                                    <option value="">(not set)</option>
                                  </select>
                                </div>

                                <div className="w-full flex flex-col gap-y-1">
                                  <label className="text-sm font-bold text-gray-700">
                                    Risk Tolerance
                                  </label>
                                  <select
                                    name="risk_tolerance"
                                    // required
                                    className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                                    value={userData.risk_tolerance || ""}
                                    onChange={onInputChanged}
                                  >
                                    <option value="" disabled>
                                      Risk Tolerance *
                                    </option>
                                    <option value="conservative">
                                      Conservative
                                    </option>
                                    <option value="moderate">Moderate</option>
                                    <option value="aggressive">
                                      Aggressive
                                    </option>
                                    <option value="">(not set)</option>
                                  </select>
                                </div>
                              </div>

                              <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-y-2 gap-x-3">
                                <div className="w-full flex flex-col gap-y-1">
                                  <label className="text-sm font-bold text-gray-700">
                                    Investment Experience
                                  </label>
                                  <select
                                    name="investment_experience"
                                    // required
                                    className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                                    value={userData.investment_experience || ""}
                                    onChange={onInputChanged}
                                  >
                                    <option value="" disabled>
                                      Investment Experience *
                                    </option>
                                    <option value="none">None</option>
                                    <option value="limited">Limited</option>
                                    <option value="good">Good</option>
                                    <option value="extensive">Extensive</option>
                                    <option value="">(not set)</option>
                                  </select>
                                </div>

                                <div className="w-full flex flex-col gap-y-1">
                                  <label className="text-sm font-bold text-gray-700">
                                    Age
                                  </label>
                                  <select
                                    name="age"
                                    // required
                                    className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                                    value={userData.age || ""}
                                    onChange={onInputChanged}
                                  >
                                    <option value="" disabled>
                                      Age *
                                    </option>
                                    <option value="under_30">Under 30</option>
                                    <option value="30-39">30 - 39</option>
                                    <option value="40-49">40 - 49</option>
                                    <option value="50-59">50 - 59</option>
                                    <option value="60-69">60 - 69</option>
                                    <option value="70-79">70 - 79</option>
                                    <option value="over_79">Over 79</option>
                                    <option value="">(not set)</option>
                                  </select>
                                </div>
                              </div>

                              <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-y-2 gap-x-3">
                                <div className="w-full flex flex-col gap-y-1">
                                  <label className="text-sm font-bold text-gray-700">
                                    Gender
                                  </label>
                                  <select
                                    name="gender"
                                    // required
                                    className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                                    value={userData.gender || ""}
                                    onChange={onInputChanged}
                                  >
                                    <option value="" disabled>
                                      Gender *
                                    </option>
                                    <option value="male">Male</option>
                                    <option value="Femail">Female</option>
                                    <option value="other">Other</option>
                                    <option value="">(not set)</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      } else if (value == "accreditation") {
                        return null;
                      } else if (value == "users") {
                        return null;
                      } else if (value == "payment-method") {
                        return (
                          <>
                            {showPaymentForm ? (
                              <PaymentMethodFormView
                                form={paymentForm}
                                setForm={setPaymentForm}
                                onCancel={onPaymentFormCancel}
                              />
                            ) : (
                              <>
                                {(() => {
                                  if (paymentMethodsLoading) {
                                    return (
                                      <div className="flex flex-row items-center justify-center">
                                        <CircularProgress className="!text-center" />
                                      </div>
                                    );
                                  } else if (paymentMethods.length == 0) {
                                    return (
                                      <div className="flex flex-col items-center justify-center gap-y-6 mt-5 text-center">
                                        <div className="text-gray-800 text-xl">
                                          There are no payment methods
                                        </div>
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <>
                                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg bg-white p-2 md:p-3">
                                          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                            <thead className="text-xs text-gray-700 bg-gray-50">
                                              <tr>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3"
                                                >
                                                  Method
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3"
                                                >
                                                  Name
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3"
                                                >
                                                  Number
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3"
                                                >
                                                  Expiry
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3"
                                                >
                                                  Email
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3"
                                                >
                                                  Details / Edit
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody className="text-gray-900">
                                              {paymentMethods.map(
                                                (method: any) => (
                                                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                                    <td className="px-6 py-4">
                                                      {helpers.capitalizeEachWord(
                                                        method.payment_method?.replace(
                                                          "_",
                                                          " "
                                                        )
                                                      )}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                      {method.holder_name ||
                                                        method.account_name}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                      {method.card_number ||
                                                        method.account_number}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                      {method.expires}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                      {method.email}
                                                    </td>
                                                    <td className="flex items-center justify-center px-6 py-4 gap-x-3">
                                                      <RemoveRedEye
                                                        onClick={() => {
                                                          setPaymentForm(
                                                            method
                                                          );
                                                          setShowPaymentForm(
                                                            true
                                                          );
                                                        }}
                                                        className="text-gray-500 hover:text-gray-700 cursor-pointer"
                                                      />
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </>
                                    );
                                  }
                                })()}
                              </>
                            )}
                          </>
                        );
                      }
                      return null;
                    })()}
                  </TabPanel>
                ))}
              </TabsBody>
            </Tabs>
          </div>
          {/*footer*/}
          <div className="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">
            <button
              type="submit"
              className="bg-black hover:bg-blue-600 text-white font-semibold py-2 px-20 text-xl rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Save
            </button>
          </div>
        </form>
      </SkyeModal>

      {/* transact modal ref */}
      <TransactModal refx={transactModalRef} onUpdated={onTransactModalUpdated} />
    </>
  );
};

type TransactModalProps = {
  onUpdated: (user: any) => void;
  refx: any;
};
const TransactModal = ({ onUpdated, refx }: TransactModalProps) => {

  useImperativeHandle(refx, () => ({
    openModal: (type: string, userData: any) => {
      openModal(type, userData);
    },
  }));


  const [walletAmountData, setWalletAmountData] = useState({} as any);
  const [user, setUser] = useState({} as any);
  const [showModal, setShowModal] = useState(false);
  const [action, setAction] = useState("credit");

  const openModal = (type: string, userData: any) => {
    setAction(type);
    setUser(userData);
    setShowModal(true);
  };

  const walletAmountInputChanged = (e: any) => {
    setWalletAmountData({ ...walletAmountData, [e.target.name]: e.target.value });
  }

  const onFormSubmit = async (e: any) => {
    e.preventDefault();

    const amount = parseFloat(walletAmountData.amount);
    if (!amount || amount <= 0) {
      toast.error("Amount is required");
      return;
    }

    const note = walletAmountData.note;
    if (!note) {
      toast.error("Comment is required");
      return;
    }

    const currentBalance = parseFloat(user.account_balance || 0);
    
    if (action == "debit" && amount > currentBalance) {
      toast.error("Insufficient balance");
      return;
    }

    const loading = toast.loading("Please wait...");

    try {

    const newBalance = action == "credit" ? currentBalance + amount : currentBalance - amount;

    const formData = {
      account_balance: newBalance,
    };

    // update the user balance
    const userRef = doc(db, "users", user.id);
    await updateDoc(userRef, formData);

    // add transaction
    const transactionData = {
      doc_number: await helpers.getNextDocumentNumber("transactions"),
      user_id: user.id,
      amount: amount,
      note: note,
      type: action,
      balance: newBalance,
      updated_at: serverTimestamp(),
      created_at: serverTimestamp(),
    };
    
    await addDoc(collection(db, "transactions"), transactionData);

    // update the user balance
    const userData = { ...user, account_balance: newBalance };
    setUser(userData);
    //notify the parent component
    onUpdated(userData);

    setWalletAmountData({});

    toast.success("Wallet updated successfully");

    // close the modal
    setShowModal(false);


  } catch (error) {
    console.log("error", error);
    toast.error("Failed to update");
  } finally {
    toast.dismiss(loading);
  }

  };

  return (
    <>
      <SkyeModal
        title={action == "credit" ? "Credit" : "Debit"}
        size="max-w-xl"
        flex={false}
        showModal={showModal}
        setShowModal={setShowModal}
      >
        <form
          className="space-y-6"
          id="user-edit-form"
          onSubmit={onFormSubmit}
          method="POST"
          encType="multipart/form-data"
          autoComplete="off"
          autoSave="off"
        >
          {/*body*/}
          <div className="relative p-6 flex-auto text-left">
            {/* body */}

            <div className="modal-body relative p-4">
              <div className="text-center">
                <label
                  htmlFor="amount"
                  className="block mb-2 text-sm w-full text-center font-bold text-gray-900 dark:text-gray-300"
                >
                  Amount
                </label>
                <input
                  name="amount"
                  value={walletAmountData.amount || ""}
                  onChange={walletAmountInputChanged}
                  type="number"
                  id="amount"
                  required
                  className="bg-gray-50 border block text-center text-xl p-1.5 font-bold border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="0.0"
                />
              </div>
              <div className="text-center mt-2">
                {/* <label htmlFor="amount" className="block mb-1 mt-2 text-sm w-full text-center font-bold text-gray-900 dark:text-gray-300">Comment</label> */}
                <input
                  name="note"
                  value={walletAmountData.note || ""}
                  onChange={walletAmountInputChanged}
                  type="text"
                  id="note"
                  required
                  className="bg-gray-50 border block  text-sm border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Transaction Title"
                />
              </div>
            </div>
            <div className="modal-footer items-center justify-center p-4 rounded-b-md">
              <button
                type="submit"
                className={`w-full px-6 py-3  ${
                  action == "credit" ? "bg-blue-500" : "bg-red-500"
                } text-white font-medium text-sm leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1`}
              >
                {action == "credit" ? "CREDIT" : "DEBIT"}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 inline mr-2"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M5 12l5 5l10 -10" />
                </svg>
              </button>
            </div>
          </div>
        </form>
      </SkyeModal>
    </>
  );
};

export default UserModal;
