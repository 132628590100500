/* eslint-disable react-hooks/exhaustive-deps */
'use client';

import { useState, useEffect } from "react";
import useDocumentTitle from "../../../hooks/userDocumentTitle";
import MainTemplate from "./MainTemplate";
import TypesTable from "../components/TypesTable";
import { db } from "../../..";
import { collection, getDocs, orderBy, query } from "firebase/firestore";



const Types = () => {
    useDocumentTitle('Types');

    const [types, setTypes] = useState([] as any[]);
    const [typesLoading, setTypesLoading] = useState(false);
    const fetchTypes = async () => {
        setTypesLoading(true);
        let types: any[] = [];
        //fetch orders from firebase
        const typesRef = collection(db, 'types');
        const q = query(typesRef, orderBy('created_at', 'desc'));
        await getDocs(q).then((snapshot) => {
            snapshot.forEach((doc) => {
                types.push({ ...doc.data(), id: doc.id });
            });
        });
        setTypes(types);
        setTypesLoading(false);
    };

    useEffect(() => {
        fetchTypes();
    }, []);



    const onTableUpdated = (type: any) => {
        fetchTypes();
    }


    return (
        <>

            <MainTemplate title="Product Types">


               

                {/* <!-- Types --> */}
                <TypesTable types={types} onTableUpdated={onTableUpdated} loading={typesLoading} />

                <br />
                <br />
                <br />
                <br />



            </MainTemplate>

        </>
    );
}

export default Types;