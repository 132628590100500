/* eslint-disable eqeqeq */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import { Outlet, Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import useDocumentTitle from "../../hooks/userDocumentTitle";
import PortalTemplate from "./PortalTemplate";
import SelectAccountType from "./setup_steps/SelectAccountType";
import { Helmet } from 'react-helmet';
import AccreditedInvestor from "./setup_steps/AccreditedInvestor";
import BioDataForm from "./setup_steps/BioDataForm";
import { toast } from "react-toastify";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../..";
import useToken from "../../hooks/useToken";
import { helpers } from "../../utilities/helpers";
import AccreditedStatus from "./setup_steps/AccreditedStatus";
import AppContext from "../../AppContext";



function SetupPage() {
  useDocumentTitle("Setup");

  const context: any = useContext(AppContext);

  const [step, setStep] = useState(parseInt((new URLSearchParams(window.location.search).get("step") || 1).toString()));
  const [form, setForm] = useState((context.user || {}) as any);

  const {token} = useToken();



  const onDone = (stepName = "") => {
    if ((stepName == "bio_data" || step == 3)  && !form.accredited_investor) {
      submit();
      return;
    } else if (stepName == "accredited_status" || step == 4) {
      submit();
      return;
    }


    setStep(step + 1);
    window.history.pushState({}, "", `?step=${step + 1}`);
  }

  const submit = async () => {

    const loading = toast.loading("Please wait...", { position: "top-center" });

    try {

      //upate user
      const userRef = doc(db, "users", token);
      await updateDoc(userRef, {
        ...form,
        full_name: form.first_name + " " + form.last_name,
        search_keywords: helpers.generateSearchKeywords(form.first_name + " " + form.last_name),
        setup_completed: true,
      });

      toast.dismiss(loading);

      toast.success("Account setup successful");

      window.location.href = "/portal";

    } catch (error) {
      console.log(error);
      toast.dismiss(loading);
      toast.error("An error occurred. Please try again");
    }

  }


  return (
   <PortalTemplate className="py-10 flex flex-col items-center justify-center">
    <Helmet>
                <style>{'body { background-color: #F2F3F6; }'}</style>
    </Helmet>
    <>
    <div className="flex flex-row items-center justify-center">
      <img className="w-[250px]" src="https://wealthblock-platform.s3.amazonaws.com/image/8a9cfd7a6f2ef1596f0b381a540c9124.png"/>
    </div>
  
    {(() => {
      switch (step) {
        case 1:
          return <SelectAccountType onDone={onDone} form={form}  setForm={setForm}/>;
        case 2:
          return <AccreditedInvestor onDone={onDone} form={form} setForm={setForm} />;
        case 3:
          return <BioDataForm onDone={onDone} form={form} setForm={setForm} setStep={setStep} />;
        case 4:
          return <AccreditedStatus onDone={onDone} form={form} setForm={setForm} />;
        default:
          return <SelectAccountType onDone={onDone} form={form} setForm={setForm} />;
      }
    })()
    }
    </>

   </PortalTemplate>
  );
}

export default  SetupPage;
