/* eslint-disable eqeqeq */
import { CreditCard, Lock } from "@mui/icons-material";
import {
    Card,
    CardBody,
    Input,
    Typography,
  } from "@material-tailwind/react";


  function formatCardNumber(value: string) {
    const val = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    const matches = val.match(/\d{4,16}/g);
    const match = (matches && matches[0]) || "";
    const parts = [];
   
    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }
   
    if (parts.length) {
      return parts.join(" ");
    } else {
      return value;
    }
  }

  function formatExpires(value: string) {
    return value
      .replace(/[^0-9]/g, "")
      .replace(/^([2-9])$/g, "0$1")
      .replace(/^(1{1})([3-9]{1})$/g, "0$1/$2")
      .replace(/^0{1,}/g, "0")
      .replace(/^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, "$1/$2");
  }


type Props = {
    onCancel: () => void;
    form: any;
    setForm: any;
  };
export default function PaymentMethodFormView({ form, setForm, onCancel}: Props) {

    const onSubmit = (e: any) => {
        e.preventDefault();
      };

    const handleChange = (e: any) => {
        // setForm({ ...form, [e.target.name]: e.target.value });
      };


  return (

    <>
    <form onSubmit={onSubmit} className="relative">
        <div className="bg-white rounded-lg p-4 md:p-8 relative">

            <button type="button" onClick={(e) => {
                e.stopPropagation();
                onCancel();
            }} className="bg-white font-bold shadow px-4 py-1 rounded-lg absolute top-5 right-5 text-red-500">Cancel</button>

            {
                ((() => {
                    if (form.payment_method == 'credit_card') {
                        return (
                            <div className="bg-white p-2 md:p-4 md:px-20 flex flex-col items-center justify-center gap-y-3 md:gap-y-5 border-[2px] rounded-lg border-primary-500 text-primary-500">
                                    <h4 className="text-primary-500">Credit or Debit Card</h4>
                                    <Card placeholder={""} className="w-full max-w-[24rem]">
                                        <CardBody placeholder={""}>
                                      
                                            <div>
                                                    <Typography
                                                    placeholder={""}
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="mb-2 font-medium"
                                                    >
                                                        Your Email
                                                    </Typography>
                                                    <Input
                                                        crossOrigin=""
                                                        name="email"
                                                        type="email"
                                                        value={form.email || ""}
                                                        onChange={handleChange}
                                                        required
                                                        placeholder="name@mail.com"
                                                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                                        labelProps={{
                                                        className: "before:content-none after:content-none",
                                                        }}
                                                    />
                                                    </div>
                                    
                                                    <div className="my-3">
                                                    <Typography
                                                    placeholder={""}
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="mb-2 font-medium "
                                                    >
                                                        Card Details
                                                    </Typography>
                                    
                                                    <Input
                                                        crossOrigin=""
                                                        maxLength={19}
                                                        name="card_number"
                                                        value={formatCardNumber(form.card_number || "")}
                                                        onChange={handleChange}
                                                        required
                                                        icon={
                                                        <CreditCard className="absolute left-0 h-4 w-4 text-blue-gray-300" />
                                                        }
                                                        placeholder="0000 0000 0000 0000"
                                                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                                        labelProps={{
                                                        className: "before:content-none after:content-none",
                                                        }}
                                                    />
                                                    <div className="my-4 flex items-center gap-4">
                                                        <div>
                                                        <Typography
                                                        placeholder={""}
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="mb-2 font-medium"
                                                        >
                                                            Expires
                                                        </Typography>
                                                        <Input
                                                            crossOrigin=""
                                                            maxLength={5}
                                                            name="expires"
                                                            value={formatExpires(form.expires || "")}
                                                            onChange={handleChange}
                                                            required
                                                            containerProps={{ className: "min-w-[72px]" }}
                                                            placeholder="00/00"
                                                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                                            labelProps={{
                                                            className: "before:content-none after:content-none",
                                                            }}
                                                        />
                                                        </div>
                                                        <div>
                                                        <Typography
                                                        placeholder={""}
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="mb-2 font-medium"
                                                        >
                                                            CVC
                                                        </Typography>
                                                        <Input
                                                            crossOrigin=""
                                                            maxLength={4}
                                                            name="cvc"
                                                            required
                                                            value={form.cvc || ""}
                                                            onChange={handleChange}
                                                            containerProps={{ className: "min-w-[72px]" }}
                                                            placeholder="000"
                                                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                                            labelProps={{
                                                            className: "before:content-none after:content-none",
                                                            }}
                                                        />
                                                        </div>
                                                    </div>
                                                    <Typography
                                                    placeholder={""}
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="mb-2 font-medium"
                                                    >
                                                        Holder Name
                                                    </Typography>
                                                    <Input
                                                        crossOrigin=""
                                                        name="holder_name"
                                                        value={form.holder_name || ""}
                                                        onChange={handleChange}
                                                        required
                                                        placeholder=""
                                                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                                        labelProps={{
                                                        className: "before:content-none after:content-none",
                                                        }}
                                                    />
                                                    </div>
                                                    {/* <Button type="submit" className="w-full" placeholder={""} size="lg">Submit</Button> */}
                                                    <Typography
                                                    placeholder={""}
                                                    variant="small"
                                                    color="gray"
                                                    className="mt-2 flex items-center justify-center gap-2 font-medium opacity-60"
                                                    >
                                                    <Lock className="-mt-0.5 h-4 w-4" /> Payments are
                                                    secure and encrypted
                                                    </Typography>
                                              
                                        </CardBody>
                                    </Card>
                            </div>
                        );
                    } else if (form.payment_method == 'bank') {
                        return (
                            <div className="bg-white p-4 md:px-20 flex flex-col items-center justify-center text-center gap-y-4 border-[2px] rounded-lg border-primary-500 text-primary-500">
                                <h4 className="text-primary-500">Bank Account</h4>
                                <Card placeholder={""} className="w-full max-w-[24rem]">
                                    <CardBody placeholder={""}>
                                        <div>
                                        <Typography
                                        placeholder={""}
                                            variant="small"
                                            color="blue-gray"
                                            className="mb-2 font-medium"
                                        >
                                            Your Email
                                        </Typography>
                                        <Input
                                            crossOrigin=""
                                            name="email"
                                            type="email"
                                            value={form.email || ""}
                                            onChange={handleChange}
                                            required
                                            placeholder=""
                                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                            labelProps={{
                                            className: "before:content-none after:content-none",
                                            }}
                                        />
                                
                                        <Typography
                                        placeholder={""}
                                            variant="small"
                                            color="blue-gray"
                                            className="mb-2 font-medium mt-3"
                                        >
                                            Bank Name
                                        </Typography>
                                        <Input
                                            crossOrigin=""
                                            name="bank_name"
                                            value={form.bank_name || ""}
                                            onChange={handleChange}
                                            required
                                            placeholder=""
                                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                            labelProps={{
                                            className: "before:content-none after:content-none",
                                            }}
                                        />

                                        <Typography
                                        placeholder={""}
                                            variant="small"
                                            color="blue-gray"
                                            className="mb-2 font-medium mt-3"
                                        >
                                            Account Number
                                        </Typography>
                                        <Input
                                            crossOrigin=""
                                            type="number"
                                            name="account_number"
                                            value={form.account_number || ""}
                                            onChange={handleChange}
                                            required
                                            placeholder=""
                                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                            labelProps={{
                                            className: "before:content-none after:content-none",
                                            }}
                                        />

                                        <Typography
                                        placeholder={""}
                                            variant="small"
                                            color="blue-gray"
                                            className="mb-2 font-medium mt-3"
                                        >
                                            Routing Number
                                        </Typography>
                                        <Input
                                            crossOrigin=""
                                            name="routing_number"
                                            value={form.routing_number || ""}
                                            onChange={handleChange}
                                            required
                                            placeholder=""
                                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                            labelProps={{
                                            className: "before:content-none after:content-none",
                                            }}
                                        />

                                        <Typography
                                            placeholder={""}
                                            variant="small"
                                            color="blue-gray"
                                            className="mb-2 font-medium mt-3"
                                        >
                                            Account Name
                                        </Typography>
                                        <Input
                                            crossOrigin=""
                                            name="account_name"
                                            value={form.account_name || ""}
                                            onChange={handleChange}
                                            required
                                            placeholder=""
                                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                            labelProps={{
                                            className: "before:content-none after:content-none",
                                            }}
                                        />

                                        {/* <Button type="submit" className="w-full mt-3" placeholder={""} size="lg">Submit</Button> */}
                                        <Typography
                                        placeholder={""}
                                            variant="small"
                                            color="gray"
                                            className="mt-2 flex items-center justify-center gap-2 font-medium opacity-60"
                                        >
                                        <Lock className="-mt-0.5 h-4 w-4" /> Payments are secure and encrypted
                                        </Typography>

                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        );
                    }
                    return null;
                    })())
            }

        </div>

        <br/>
   

    </form>

 

    <br/>
    <br/>
    <br/>
    <br/>
       
    </>
  );
}


