/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react/jsx-no-target-blank */
import useDocumentTitle from "../hooks/userDocumentTitle";
import MainTemplate from "./MainTemplate";

export default function HelpingFarmersGrowPage() {
  useDocumentTitle("Helping Farmers Grow");

  const onSubmit = (e: any) => {
    e.preventDefault();
    console.log("submitted");
  }

  return (
    <>
      <MainTemplate pageType="others">
        
        
      <>
  <div
    data-ix="slide-in-scroll-navigation"
    id="Top"
    className="page-header services wf-section"
  >
    <div className="w-container">
      <div className="div-text _2">
        <h1
          data-w-id="2c980ce5-42f8-fc4a-c9a9-2999079c37ec"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
          className="heading center white-font"
        >
          HELPING&nbsp;FARMERS&nbsp;GROW
        </h1>
        <p className="center-paragraph white">
          Tell us about your goals and we will find a solution for you.
          <br />
        </p>
        <div
          data-w-id="7e06734b-b7f5-22b6-0dd7-a0f543b014ea"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
          className="separated-div-wrapper"
        >
          <div className="separator header" />
          <div className="separator header center" />
          <div className="separator header" />
        </div>
        <p
          data-w-id="8518a68b-7374-e277-749e-8b04f39fb0c6"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
          className="paragraph center white"
        >
          With a nice modern and intuitive interface you can control contents to
          be contained and organised.
        </p>
      </div>
    </div>
  </div>
  <div
    data-autoplay="true"
    data-loop="true"
    data-wf-ignore="true"
    data-poster-url="https://uploads-ssl.webflow.com/57bab4d00c7bdb623822fa70/587217d2e5fbe3505cbedf2e_Currency App-SD-poster-00001.jpg"
    data-video-urls="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e5717f541472a42b458bfe6_Currency%20App-SD-transcode.mp4,https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e5717f541472a42b458bfe6_Currency%20App-SD-transcode.webm"
    className="background-video w-background-video w-background-video-atom"
  >
    <video
      id="92cdf720-883c-8310-e377-8988547daec3-video"
      autoPlay={true}
      loop={true}
      style={{
        backgroundImage:
          'url("https://uploads-ssl.webflow.com/57bab4d00c7bdb623822fa70/587217d2e5fbe3505cbedf2e_Currency App-SD-poster-00001.jpg")'
      }}
      muted={true}
      playsInline={true}
      data-wf-ignore="true"
      data-object-fit="cover"
    >
      <source
        src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e5717f541472a42b458bfe6_Currency%20App-SD-transcode.mp4"
        data-wf-ignore="true"
      />
      <source
        src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e5717f541472a42b458bfe6_Currency%20App-SD-transcode.webm"
        data-wf-ignore="true"
      />
    </video>
  </div>
  <div className="setion-services wf-section">
    <div className="w-container">
      <div data-ix="fade-in-on-load" className="services-div">
        <div className="row-motion services-page w-row">
          <div className="column w-col w-col-4">
            <div className="features-block icon gray">
              <div className="div-service">
                <img
                  src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/6201a8921445a433badae242_Sell.png"
                  width={67}
                  alt="Sell Your Farmland"
                  className="features-icon"
                />
              </div>
              <h1 className="heading features services">
                Sale &amp;&nbsp;Leases
              </h1>
              <p className="paragraph center">
                If you or your landlord are selling land that you would like to
                continue to farm, we may be interested in purchasing it and
                setting up a lease with you.
                <br />
              </p>
            </div>
          </div>
          <div className="column w-col w-col-4">
            <div className="features-block icon gray">
              <div className="div-service">
                <img
                  src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/6201a89263adc16a0a949ead_Expand.png"
                  alt="Fund Farmland Property"
                  className="features-icon center"
                />
              </div>
              <h1 className="heading features services">Get More Acreage</h1>
              <p className="paragraph center">
                Have you identified a property that you’d be interested in
                farming? Tell us about it! This could be an opportunity for us
                to buy and lease it to you.&nbsp;
                <br />
              </p>
            </div>
          </div>
          <div className="column w-col w-col-4">
            <div className="features-block icon gray">
              <div className="div-service">
                <img
                  src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/6201a892a7c4cbe556002600_Raise%20Funds.png"
                  alt="Get Funding for Farming
"
                  className="features-icon"
                />
              </div>
              <h1 className="heading features services">raise funds</h1>
              <p className="paragraph center">
                Do you have a vision or goal for your property? Whether you are
                looking to develop, improve, or even transition a property to
                organic, we may be able to assist!
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="w-container">
    <div className="w-row">
      <div className="w-col w-col-6">
        <div data-ix="fade-in-on-scroll-right" className="left-side-div">
          <div
            data-w-id="020e9cd1-9d86-0604-5de9-03434da9d95e"
            style={{
              opacity: 1,
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d"
            }}
            className="div-text _2 left"
          >
            <h2 className="heading center">Lease BAcks</h2>
            <div className="separated-div-wrapper left">
              <div className="separator header" />
              <div className="separator header center" />
              <div className="separator header" />
            </div>
            <p className="paragraph">
              If leasing the property is ideal for you, we can arrange a long
              term and flexible lease. Even before the property is purchased we
              will establish a leaseback structure that works for you.&nbsp;Our
              goal is to make transactions as fast as possible! With expert
              legal and real estate support, the deal will be closed quickly and
              smoothly. Depending on the circumstances, we may be able to help
              you retain part of the ownership in the property.
              <br />
            </p>
          </div>
        </div>
      </div>
      <div className="w-col w-col-6">
        <img
          src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/627974451da2682918f36be6_Investor%20Funding%20for%20Farmer.jpg"
          loading="lazy"
          alt="Investor Funding for Farmers"
        />
      </div>
    </div>
    <div className="w-row">
      <div className="w-col w-col-6">
        <div data-ix="fade-in-on-scroll-right" className="left-side-div">
          <div
            data-w-id="d7cf3458-864c-304e-424f-27c4b416abce"
            style={{
              opacity: 1,
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d"
            }}
            className="div-text _2 left"
          >
            <img
              src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/620c2bb80eb59698da21974f_2.png"
              loading="lazy"
              alt="Fund farming without a bank"
            />
          </div>
        </div>
      </div>
      <div className="w-col w-col-6">
        <div
          data-w-id="048191f2-f7e6-f04d-1d20-aa70c94b9a58"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
          className="div-text _2 left"
        >
          <h2 className="heading center">EXPAND Operations</h2>
          <div className="separated-div-wrapper left">
            <div className="separator header" />
            <div className="separator header center" />
            <div className="separator header" />
          </div>
          <p className="paragraph">
            Are you looking to expand your acreage?
            <br />
            Have you identified a property you’d like to farm but do not want to
            purchase yourself? If so, we may be interested in buying the
            property and set up a multi-year lease with you. <br />
            <br />
            Or maybe your current landlord is selling the property you are
            farming. We can purchase the farm and help you to continue operating
            those acres.
            <br />
          </p>
        </div>
      </div>
    </div>
    <div className="w-row">
      <div className="w-col w-col-6">
        <div data-ix="fade-in-on-scroll-right" className="left-side-div">
          <div
            data-w-id="d60ab795-e6cb-5dbe-5ecb-f9804920bdab"
            style={{
              opacity: 1,
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d"
            }}
            className="div-text _2 left"
          >
            <h2 className="heading center">Capital for Projects</h2>
            <div className="separated-div-wrapper left">
              <div className="separator header" />
              <div className="separator header center" />
              <div className="separator header" />
            </div>
            <p className="paragraph">
              When purchasing farms, we investigate possibilities to develop and
              improve the land whether it be by removing trees, improving
              drainage or transitioning conventional farms into organic.&nbsp;
              <br />
              <br />
              If you've got a plan to develop, improve or convert to organic but
              need capital to execute, share your plan with us.
              <br />
            </p>
          </div>
        </div>
      </div>
      <div className="w-col w-col-6">
        <img
          src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/620c2d85e0505b7d1e17844b_5.png"
          loading="lazy"
          alt="How to get funding for a farm"
        />
      </div>
    </div>
  </div>
  <div className="blog-post wf-section">
    <div className="container center w-container" />
    <div className="loan-container w-container">
      <h1 className="heading-3 center white mobile">Let Us Help You Grow</h1>
      <p className="center-paragraph white">
        Please tell us more about your identified property and goals.
        <br />‍<br />
      </p>
      <a
        href="https://form.jotform.com/211315389721151"
        target="_blank"
        className="button findrpage w-button"
      >
        GET&nbsp;STARTED&nbsp;HERE
      </a>
    </div>
  </div>
  <div className="page-header newsletter subscribe green wf-section">
    <div className="containe trial w-container">
      <div className="div-text padding">
        <h1
          data-w-id="816f6681-f876-7f13-3fd8-e139897fddc8"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
          className="heading center white-font"
        >
          Subscribe To The FamrFundr Newsletter
        </h1>
        <div
          data-w-id="8450fdba-8efe-c343-7d65-4d70b79c0342"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
          className="separated-div-wrapper"
        >
          <div className="separator header" />
          <div className="separator header center white" />
          <div className="separator header" />
        </div>
        <p
          data-w-id="26037651-a298-1545-dcd1-dc0761f7fdf2"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
          className="paragraph center white"
        >
          A nation that destroys its soils destroys itself. Forests are the
          lungs of our land, purifying the air and giving fresh strength to our
          people.
        </p>
      </div>
      <div className="trial-wrapper w-form">
        <form
          id="email-form"
          name="email-form"
          data-name="Email Form"
          method="get"
          className="w-clearfix"
          aria-label="Email Form"
          onSubmit={onSubmit}
        >
          <input
            type="email"
            className="trial-field w-input"
            maxLength={256}
            name="email-5"
            data-name="Email 5"
            placeholder="Enter your email address"
            id="email-5"
            required
          />
          <input
            type="submit"
            defaultValue="Sign up now!"
            data-wait="Please wait..."
            className="trial-button sec w-button"
          />
        </form>
        <div
          className="success-message w-form-done"
          tabIndex={-1}
          role="region"
          aria-label="Email Form success"
        >
          <div className="text-block-7">
            Thank you! Your submission has been received!
          </div>
        </div>
        <div
          className="error-message w-form-fail"
          tabIndex={-1}
          role="region"
          aria-label="Email Form failure"
        >
          <div className="text-block-8">
            Oops! Something went wrong while submitting the form
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="footer dark-bootom wf-section" />
  <a
    href="#Top"
    data-ix="go-top-on-load"
    className="go-to w-inline-block w--current"
  />
  {/*[if lte IE 9]><![endif]*/}
  {/* <div id="v1s4ruuo73q1710595369424" className="widget-hidden">
    <iframe
      src="about:blank"
      frameBorder={0}
      scrolling="no"
      width="64px"
      height="60px"
      style={{
        outline: "none !important",
        visibility: "visible !important",
        resize: "none !important",
        boxShadow: "none !important",
        overflow: "visible !important",
        background: "none !important",
        opacity: "1 !important",
        filter: "alpha(opacity=100) !important",
        msFilter:
          "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
        MzOpacity: "1 !important",
        KhtmlOpacity: "1 !important",
        top: "auto !important",
        right: "auto !important",
        bottom: "20px !important",
        left: "20px !important",
        position: "fixed !important",
        border: "0 !important",
        minHeight: "60px !important",
        minWidth: "64px !important",
        maxHeight: "60px !important",
        maxWidth: "64px !important",
        padding: "0 !important",
        margin: "0 !important",
        MozTransitionProperty: "none !important",
        WebkitTransitionProperty: "none !important",
        OTransitionProperty: "none !important",
        transitionProperty: "none !important",
        transform: "none !important",
        WebkitTransform: "none !important",
        msTransform: "none !important",
        width: "64px !important",
        height: "60px !important",
        display: "block !important",
        zIndex: "1000001 !important",
        backgroundColor: "transparent !important",
        cursor: "none !important",
        float: "none !important",
        borderRadius: "unset !important",
        pointerEvents: "auto !important",
        clip: "auto !important",
        colorScheme: "light !important"
      }}
      id="jh58tqo2enr1710595369455"
      className=""
      title="chat widget"
    />
    <iframe
      src="about:blank"
      frameBorder={0}
      scrolling="no"
      width="350px"
      height="520px"
      style={{
        outline: "none !important",
        visibility: "visible !important",
        resize: "none !important",
        boxShadow: "none !important",
        overflow: "visible !important",
        background: "none !important",
        opacity: "1 !important",
        filter: "alpha(opacity=100) !important",
        msFilter:
          "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
        MzOpacity: "1 !important",
        KhtmlOpacity: "1 !important",
        top: "auto !important",
        right: "auto !important",
        bottom: "90px !important",
        left: "10px !important",
        position: "fixed !important",
        border: "0 !important",
        minHeight: "520px !important",
        minWidth: "350px !important",
        maxHeight: "520px !important",
        maxWidth: "350px !important",
        padding: "0 !important",
        margin: "0 !important",
        MozTransitionProperty: "none !important",
        WebkitTransitionProperty: "none !important",
        OTransitionProperty: "none !important",
        transitionProperty: "none !important",
        transform: "none !important",
        WebkitTransform: "none !important",
        msTransform: "none !important",
        width: "350px !important",
        height: "520px !important",
        display: "none !important",
        zIndex: "auto !important",
        backgroundColor: "transparent !important",
        cursor: "none !important",
        float: "none !important",
        borderRadius: "5px !important",
        pointerEvents: "auto !important",
        clip: "auto !important",
        colorScheme: "light !important"
      }}
      id="fhf85fb91vj81710595369554"
      className=""
      title="chat widget"
    />
    <iframe
      src="about:blank"
      frameBorder={0}
      scrolling="no"
      width="360px"
      height="145px"
      style={{
        outline: "none !important",
        visibility: "visible !important",
        resize: "none !important",
        boxShadow: "none !important",
        overflow: "visible !important",
        background: "none !important",
        opacity: "1 !important",
        filter: "alpha(opacity=100) !important",
        msFilter:
          "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
        MzOpacity: "1 !important",
        KhtmlOpacity: "1 !important",
        top: "auto !important",
        right: "auto !important",
        bottom: 100,
        left: "20px !important",
        position: "fixed !important",
        border: "0 !important",
        minHeight: "145px !important",
        minWidth: "360px !important",
        maxHeight: "145px !important",
        maxWidth: "360px !important",
        padding: "0 !important",
        margin: "0 !important",
        MozTransitionProperty: "none !important",
        WebkitTransitionProperty: "none !important",
        OTransitionProperty: "none !important",
        transitionProperty: "none !important",
        transform: "none !important",
        WebkitTransform: "none !important",
        msTransform: "none !important",
        width: "360px !important",
        height: "145px !important",
        display: "none !important",
        zIndex: "auto !important",
        backgroundColor: "transparent !important",
        cursor: "none !important",
        float: "none !important",
        borderRadius: "unset !important",
        pointerEvents: "auto !important",
        clip: "auto !important",
        colorScheme: "light !important"
      }}
      id="ugf3dvi8du81710595369486"
      className=""
      title="chat widget"
    />
    <iframe
      src="about:blank"
      frameBorder={0}
      scrolling="no"
      width="146px"
      height="85px"
      style={{
        outline: "none !important",
        visibility: "visible !important",
        resize: "none !important",
        boxShadow: "none !important",
        overflow: "visible !important",
        background: "none !important",
        opacity: "1 !important",
        filter: "alpha(opacity=100) !important",
        msFilter:
          "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
        MzOpacity: "1 !important",
        KhtmlOpacity: "1 !important",
        top: "auto !important",
        right: "auto !important",
        bottom: "82px !important",
        left: "20px !important",
        position: "fixed !important",
        border: "0 !important",
        minHeight: "85px !important",
        minWidth: "146px !important",
        maxHeight: "85px !important",
        maxWidth: "146px !important",
        padding: "0 !important",
        margin: "0px 0 0 0 !important",
        MozTransitionProperty: "none !important",
        WebkitTransitionProperty: "none !important",
        OTransitionProperty: "none !important",
        transitionProperty: "none !important",
        transform: "rotate(0deg) translateZ(0)",
        WebkitTransform: "rotate(0deg) translateZ(0)",
        msTransform: "rotate(0deg) translateZ(0)",
        width: "146px !important",
        height: "85px !important",
        display: "none !important",
        zIndex: "1000002 !important",
        backgroundColor: "transparent !important",
        cursor: "none !important",
        float: "none !important",
        borderRadius: "unset !important",
        pointerEvents: "auto !important",
        clip: "auto !important",
        colorScheme: "light !important",
        MozTransform: "rotate(0deg) translateZ(0)",
        OTransform: "rotate(0deg) translateZ(0)",
        transformOrigin: 0,
        MozTransformOrigin: 0,
        WebkitTransformOrigin: 0,
        OTransformOrigin: 0,
        msTransformOrigin: 0
      }}
      id="pcqnssubcfrg1710595369470"
      className=""
      title="chat widget"
    />
  </div> */}
</>




      </MainTemplate>
    </>
  );
}
