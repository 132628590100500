/* eslint-disable eqeqeq */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import useDocumentTitle from "../../hooks/userDocumentTitle";
import PortalTemplate from "./PortalTemplate";
import { Helmet } from 'react-helmet';
import Currency from "../admin/components/Currency";
import { helpers } from "../../utilities/helpers";
import { useContext, useEffect, useState } from "react";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../..";
import { CircularProgress } from "@mui/material";
import OfferModal from "./OfferModal";
import { FilterList } from "@mui/icons-material";
import AppContext from "../../AppContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";



function OfferingsPage() {
  useDocumentTitle("Offerings");

  const navigate = useNavigate();
  const context: any = useContext(AppContext);

    const [products, setProducts] = useState([] as any[]);
    const [productsLoading, setProductsLoading] = useState(true);
    const fetchProducts = async (name = "", fromDate = "", toDate = "") => {
        setProductsLoading(true);
        let products: any[] = [];
        //fetch products from firebase
        const productsRef = collection(db, 'products');

        var queries: any[] = [];

        queries.push(orderBy('created_at', 'desc'));

        const q = query(productsRef, ...queries);

        await getDocs(q).then((snapshot) => {
            snapshot.forEach((doc) => {
                products.push({ ...doc.data(), id: doc.id });
            });
        });
        setProducts(products);
        setProductsLoading(false);
    }

    const [offerData, setOfferData] = useState({} as any);

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
      fetchProducts();
  }, []);

  const onModalUpdated = (product: any) => {
    fetchProducts();
}


    const visitOffer = (product: any) => {
      window.location.href = "/offering/" + product.id;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const investNow = (product: any) => {
      if (context.user && context.token){
        navigate("/offer-pay/" + product.id);
      } else {
        toast.error("Please login/register to continue");
        navigate("/portal/login?redirect=/offering/" + product.id);
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const openOfferContactModal = (product: any) => {
      setOfferData(product);
      //open modal
      setShowModal(true);
    }


  return (
    <>
      <PortalTemplate className="py-1 flex flex-col items-center justify-center">
        <Helmet>
                    <style>{'body { background-color: #F2F3F6; }'}</style>
        </Helmet>
        <>
        <div className="px-4 text-left w-full">
            <div className="w-full flex flex-row items-center justify-between">
              <h1 className="text-black text-xl md:text-2xl text-left w-full">Farmland Investment Offerings</h1>
              {/* filter button */}
              <div className="relative w-36 text-right group">
              <button className="bg-white shadow px-3 py-2 rounded-lg whitespace-nowrap"><FilterList className="mr-1"/> FILTERS</button>
              <ul className="absolute hidden bg-white shadow-lg rounded-md mt-2 px-0 py-0 group-hover:block min-w-28 md:min-w-32">
                <li className="hover:bg-gray-100 p-3 text-md text-left">All Offers</li>
              </ul>
              </div>
            </div>
            <div className="text-gray-600 mt-2 mb-5 text-center w-full">FarmFundr is a farmer-owned, equity crowdfunding platform focused on specialty crop operations in the United States. We offer fractional farmland ownership opportunities to our members.</div>

          {/* offerings */}
          <div className="space-y-8">
            {
              ((() => {
                if (productsLoading) {
                  return <div className="flex flex-row items-center justify-center"><CircularProgress className="!text-center" /></div>
                } else if (products.length == 0) {
                  return <div className="text-center text-gray-600">No offerings available</div>
                } else {
                  return products.map((product) => (
                    <div key={product.id} className="bg-white rounded-xl p-4 flex flex-col items-center w-full md:flex-row shadow-xl transition duration-300 cursor-pointer hover:shadow-2xl hover:bg-gray-100"
                    onClick={() => visitOffer(product)}
                    >
                        <img src={product.images ? product.images[0] : "https://via.placeholder.com/150"} alt="product"
                        className="w-full md:w-2/3 h-52 object-cover rounded" />
                        <div className="flex flex-col md:ml-4 justify-between w-full">
                          <div className="flex flex-col items-center md:items-start">
                            <h2 className="text-gray-700 text-lg mt-1 mb-0">FarmFundr</h2>
                            <div className="text-gray-600 text-lg my-0">{product.name}</div>
                            {/* excerpt description */}
                            <p className="text-gray-500 mt-2 font-light">{
                              product.description.length > 100 ? product.description.substring(0, 100) + "..." : product.description
                            }</p>
                                {/* location, roi, farm cycle */}
                                <div className="flex items-center justify-center w-full gap-x-4 mt-2">
                                  <div className="flex items-center gap-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                                    </svg>

                                      <div className="text-sm text-gray-500 dark:text-gray-400">
                                        {product?.location}
                                      </div>
                                  </div>
                                  <div className="flex items-center gap-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="m9 14.25 6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0c1.1.128 1.907 1.077 1.907 2.185ZM9.75 9h.008v.008H9.75V9Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm4.125 4.5h.008v.008h-.008V13.5Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                                    </svg>

                                      <div className="text-sm text-gray-500 dark:text-gray-400">
                                        {product?.roi}% ROI
                                      </div>
                                  </div>
                                  <div className="flex items-center gap-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="m9 14.25 6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0c1.1.128 1.907 1.077 1.907 2.185ZM9.75 9h.008v.008H9.75V9Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm4.125 4.5h.008v.008h-.008V13.5Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                                    </svg>

                                      <div className="text-sm text-gray-500 dark:text-gray-400">
                                        {product?.cycle ?? "N/A"}
                                      </div>
                                  </div>
                                </div>
                            <div className="text-2xl text-green-400 font-bold font-montserrat">
                              <Currency />{helpers.money(product.price)}
                              {/* <span className="text-sm text-gray-600 font-light"> or More</span> */}
                            </div>
                          </div>
                          {/* <Button 
                          onClick={(e) =>  {
                            e.stopPropagation();
                            investNow(product);
                          }}
                          disabled={product.status != "publish" || product.quantity == 0 || !product.quantity}
                          placeholder={""} className="mt-2 !text-white !bg-primary-500 py-4 !w-full" color="green">Invest Now</Button> */}
                        </div>
                    </div>
                  ))
                }
              })())
            }
          </div>

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </div>
        </>

      </PortalTemplate>

      <OfferModal offer={offerData} setOffer={setOfferData} showModal={showModal} setShowModal={setShowModal} onModalUpdated={onModalUpdated} />
    </>
  );
}

export default  OfferingsPage;
