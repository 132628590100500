/* eslint-disable eqeqeq */
import { doc, updateDoc } from "firebase/firestore";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { db, storage } from "../../..";
import useToken from "../../../hooks/useToken";
import { helpers } from "../../../utilities/helpers";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import AppContext from "../../../AppContext";
import { Input } from "@material-tailwind/react";

export default function Account() {

    const {token} = useToken();

    const context: any = useContext(AppContext);

    const [form, setForm] = useState((context.user || {}) as any);
    const [identificationFile, setIdentificationFile] = useState(null as any);

    const [interests] = useState([
        {
          id: 1,
          name: "Looking for Options to Diversify my Portfolio",
          value: "looking_for_options_to_diversify_my_portfolio",
        },
        {
          id: 2,
          name: "Seeking Long Term Investments that are Inflation Resistant",
          value: "seeking_long_term_investments_that_are_inflation_resistant",
        },
        { id: 3, name: "Desire to Help Farmers", value: "desire_to_help_farmers" },
        {
          id: 4,
          name: "Looking for Farmland Investments with High Return Possibilities",
          value: "looking_for_farmland_investments_with_high_return_possibilities",
        },
        {
          id: 5,
          name: "I want to Diversify Outside of Traditional Asset Classes",
          value: "i_want_to_diversify_outside_of_traditional_asset_classes",
        },
        { id: 6, name: "Seeking Low Votility", value: "seeking_low_votility" },
        {
          id: 7,
          name: "Interested in Lower Volatility Investments",
          value: "interested_in_lower_volatility_investments",
        },
        { id: 8, name: "Other", value: "other" },
      ]);
      const [countries] = useState([
        "United States",
        "Canada",
        "Afghanistan",
        "Albania",
        "Algeria",
        "American Samoa",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antarctica",
        "Antigua and/or Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia",
        "Bosnia and Herzegovina",
        "Botswana",
        "Bouvet Island",
        "Brazil",
        "British Indian Ocean Territory",
        "Brunei Darussalam",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cambodia",
        "Cameroon",
        "Cape Verde",
        "Cayman Islands",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Christmas Island",
        "Cocos (Keeling) Islands",
        "Colombia",
        "Comoros",
        "Congo",
        "Cook Islands",
        "Costa Rica",
        "Croatia (Hrvatska)",
        "Cuba",
        "Cyprus",
        "Czech Republic",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "East Timor",
        "Ecudaor",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Ethiopia",
        "Falkland Islands (Malvinas)",
        "Faroe Islands",
        "Fiji",
        "Finland",
        "France",
        "France, Metropolitan",
        "French Guiana",
        "French Polynesia",
        "French Southern Territories",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Heard and Mc Donald Islands",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran (Islamic Republic of)",
        "Iraq",
        "Ireland",
        "Israel",
        "Italy",
        "Ivory Coast",
        "Jamaica",
        "Japan",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea, Democratic People's Republic of",
        "Korea, Republic of",
        "Kosovo",
        "Kuwait",
        "Kyrgyzstan",
        "Lao People's Democratic Republic",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libyan Arab Jamahiriya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macau",
        "Macedonia",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Mexico",
        "Micronesia, Federated States of",
        "Moldova, Republic of",
        "Monaco",
        "Mongolia",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "Netherlands Antilles",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Niue",
        "Norfork Island",
        "Northern Mariana Islands",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Pitcairn",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Reunion",
        "Romania",
        "Russian Federation",
        "Rwanda",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Georgia South Sandwich Islands",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "St. Helena",
        "St. Pierre and Miquelon",
        "Sudan",
        "Suriname",
        "Svalbarn and Jan Mayen Islands",
        "Swaziland",
        "Sweden",
        "Switzerland",
        "Syrian Arab Republic",
        "Taiwan",
        "Tajikistan",
        "Tanzania, United Republic of",
        "Thailand",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks and Caicos Islands",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States minor outlying islands",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Vatican City State",
        "Venezuela",
        "Vietnam",
        "Virigan Islands (British)",
        "Virgin Islands (U.S.)",
        "Wallis and Futuna Islands",
        "Western Sahara",
        "Yemen",
        "Yugoslavia",
        "Zaire",
        "Zambia",
        "Zimbabwe",
      ]);
    
      // const [selectedValue, setSelectedValue] = useState([]);
    
      // const onSelect = (selectedList: any, selectedItem: any) => {
      //     setSelectedValue(selectedList);
      // }
    
      // const onRemove = (selectedList: any, removedItem: any) => {
      //     setSelectedValue(selectedList);
      // }

    const handleChange = (e: any) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    }

    const onSubmit = async (e: any) => {
        e.preventDefault();

        const loading = toast.loading("Please wait...", { position: "top-center" });

        //add file
        if (identificationFile) {
            //upload file
            const fileName = form.first_name + "-" + helpers.generateRandomString(10) + "." + identificationFile.name.split(".").pop();
            const fileRef = ref(storage, "files/identifications/" + fileName);
            await uploadBytes(fileRef, identificationFile).then(async (snapshot) => {
                await getDownloadURL(fileRef).then((url) => {
                    form.identification_file = url;
                    form.verification_status = "pending";
                });
            }
            ).catch((error) => {
                toast.error("Failed to upload identification file");
            });
        }

    try {

      //upate user
      const userRef = doc(db, "users", token);
      await updateDoc(userRef, {
        ...form,
        search_keywords: helpers.generateSearchKeywords(form.first_name + " " + form.last_name),
        setup_completed: true,
      });

      toast.dismiss(loading);

      toast.success("Account saved successfully");
      
      //reload page
        window.location.href = "/portal/account?tab=account&saved=true";

    } catch (error) {
      console.log(error);
      toast.dismiss(loading);
      toast.error("An error occurred. Please try again");
    }

    }

    return (
    <>

      {
        (new URLSearchParams(window.location.search)).get("saved") && (
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 my-2 rounded relative" role="alert">
                <strong className="font-bold">Success!</strong>
                <span className="block sm:inline"> Account saved successfully</span>
            </div>
        )
      }
      {
        (context.user.verification_status == "pending") && (
          <div
          className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md"
          role="alert"
        >
          <div className="flex">
            <div className="py-1">
              <svg
                className="fill-current h-6 w-6 text-teal-500 mr-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
              </svg>
            </div>
            <div>
              <p className="font-bold">Verification in Progress</p>
              <p className="text-sm">
                Your account verification is in progress. You will be notified in 24 hours.
              </p>
            </div>
          </div>
        </div>
        
        )
      }
      {
        (context.user.verification_status == "success" || context.user.verification_status == "verified") && (
          <>
          {/* component */}
          <div className="bg-gray-100">
            <div className="bg-white p-6  md:mx-auto">
              <svg
                viewBox="0 0 24 24"
                className="text-green-600 w-16 h-16 mx-auto my-6"
              >
                <path
                  fill="currentColor"
                  d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
                ></path>
              </svg>
              <div className="text-center">
                <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
                  Verified!
                </h3>
                <p className="text-gray-600 my-2 text-center">
                  Your account has been verified successfully.
                </p>
              </div>
            </div>
          </div>
        </>

        )
      }

       <div className="text-gray-600 text-sm my-4">FarmFundr has implemented this verification step to stay legally compliant with KYC/AML (Know Your Customer/Anti-Money Laundering) regulations. This is an additional measure to ensure against accepting fraudulent contributions. All investors must complete the KYC/AML form before making any investments through FarmFundr.</div>


       
  
            {
              (context.user.verification_status != "success" && context.user.verification_status != "verified" && context.user.verification_status != "pending") && (
                <div className="bg-white rounded-lg shadow-2xl py-5 px-10 w-full">
                {/**/}
                <div className="flex flex-col items-center w-full space-y-3">
                  <form
                  className="ng-untouched ng-pristine ng-star-inserted ng-invalid w-full space-y-5"
                  onSubmit={onSubmit}
                >
                  <h1 className="text-xl !font-extralight mt-3">
                    PERSONAL INFORMATION
                  </h1>
    
                  {/* first name, middle name and last name */}
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full gap-y-2 gap-x-3">
                    <Input
                      crossOrigin={""}
                      label="First Name"
                      name="first_name"
                      type="text"
                      required
                      className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                      placeholder="First Name *"
                      value={form.first_name || ""}
                      onChange={handleChange}
                    />
    
                    <Input
                      crossOrigin={""}
                      label="Middle Name"
                      name="middle_name"
                      type="text"
                      className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                      placeholder="Middle Name"
                      value={form.middle_name || ""}
                      onChange={handleChange}
                    />
    
                    <Input
                      crossOrigin={""}
                      label="Last Name"
                      name="last_name"
                      type="text"
                      required
                      className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                      placeholder="Last Name *"
                      value={form.last_name || ""}
                      onChange={handleChange}
                    />
                  </div>
                  {/* title, phone number and date of birth */}
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full gap-y-2 gap-x-3">
                    <Input
                      crossOrigin={""}
                      label="Title"
                      name="title"
                      type="text"
                      className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                      placeholder="Title"
                      value={form.title || ""}
                      onChange={handleChange}
                    />
    
                    <Input
                      crossOrigin={""}
                      label="Phone Number"
                      name="phone"
                      type="number"
                      required
                      className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                      placeholder="Phone Number *"
                      value={form.phone || ""}
                      onChange={handleChange}
                    />
    
                    <Input
                      crossOrigin={""}
                      label="Date of Birth"
                      name="dob"
                      type="date"
                      required
                      className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                      placeholder="Date of Birth *"
                      value={form.dob || ""}
                      onChange={handleChange}
                    />
                  </div>
                  
                  <h1 className="text-xl !font-extralight">Address</h1>
                  <div className="flex flex-col space-y-5">
                    <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-y-2 gap-x-3">
                      <Input
                      crossOrigin={""}
                      label="Street Address"
                        name="address"
                        type="text"
                        required
                        className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                        placeholder="Street Address *"
                        value={form.address || ""}
                        onChange={handleChange}
                      />
    
                      <Input
                      crossOrigin={""}
                      label="Suite / Unit"
                        name="suite"
                        type="text"
                        className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                        placeholder="Suite / Unit"
                        value={form.suite || ""}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-y-2 gap-x-3">
                      <Input
                      crossOrigin={""}
                      label="City"
                        name="city"
                        type="text"
                        required
                        className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                        placeholder="City *"
                        value={form.city || ""}
                        onChange={handleChange}
                      />
    
                      <div className="grid grid-cols-2 w-full gap-y-2 gap-x-3">
                        <Input
                      crossOrigin={""}
                      label="State / Region"
                          name="state"
                          type="text"
                          required
                          className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                          placeholder="State / Region *"
                          value={form.state || ""}
                          onChange={handleChange}
                        />
    
                        <Input
                      crossOrigin={""}
                      label="Zip / Postal Code"
                          name="zip_code"
                          type="text"
                          required
                          className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                          placeholder="Zip / Postal Code *"
                          value={form.zip_code || ""}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-y-2 gap-x-3">
                    <div className="flex flex-col w-full">
                      <label className="font-normal text-xs">Country</label>
                      <select
                        name="country"
                        required
                        className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                        value={form.country || ""}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Country *
                        </option>
                        {
                            countries.map((country) => (
                                <option value={country} key={country}>
                                {country}
                                </option>
                            ))
                        }
                      </select>
                      </div>
                    </div>
                  </div>
    
                  <h1 className="text-xl !font-extralight">
                    PLEASE TELL US MORE ABOUT YOU
                  </h1>
                  <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-y-2 gap-x-3">
                    {/* <Multiselect
                    name="interests"
                    placeholder="What is your Primary Interest in Joining FarmFundr?"
                    options={interests} // Options to display in the dropdown
                    selectedValues={selectedValue} // Preselected value to persist in dropdown
                    onSelect={onSelect} // Function will trigger on select event
                    onRemove={onRemove} // Function will trigger on remove event
                    displayValue="name" // Property name to display in the dropdown options
                    className="!w-full py-0 [&>*]:!py-4"
                    showCheckbox={true}
                    showArrow={true}
                    /> */}
                    <select
                      name="interest"
                      className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                      value={form.interest || ""}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        What is your Primary Interest in Joining FarmFundr?
                      </option>
                      {interests.map((interest) => (
                        <option value={interest.value} key={interest.id}>
                          {interest.name}
                        </option>
                      ))}
                    </select>
    
                    <input
                      name="how_you_find_us"
                      type="text"
                      className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                      placeholder="How did you find out about FarmFundr?"
                      value={form.how_you_find_us || ""}
                      onChange={handleChange}
                    />
                  </div>
    
                  <h1 className="text-xl !font-extralight">
                    IDENTITY VERIFICATION
                  </h1>
                  {
                    (context.user.verified || context.user.verification_status == "success" || context.user.verification_status == "verified") ? (
                      <div
                        className="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 text-center"
                        role="alert"
                      >
                        <span className="font-medium">Verified!</span> Your account has been verified.
                      </div>
                    ) : (
                      <>
                        <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-y-2 gap-x-3">
                          <Input
                            crossOrigin={""}
                            label="Social Security #(US Investors Only)"
                            name="society_security"
                            type="number"
                            required
                            className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                            placeholder="Social Security #(US Investors Only)*"
                            value={form.society_security || ""}
                            onChange={handleChange}
                          />
                          <Input
                            crossOrigin={""}
                            label="Next of Kin"
                            name="next_of_kin"
                            type="text"
                            required
                            className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                            placeholder="Next of Kin"
                            value={form.next_of_kin || ""}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full gap-y-2 gap-x-3">
                        <select
                              name="nationality"
                              required
                              className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                              value={form.nationality || ""}
                              onChange={handleChange}
                            >
                              <option value="" disabled>
                                Nationality *
                              </option>
                              {
                                  countries.map((country) => (
                                      <option value={country} key={country}>
                                      {country}
                                      </option>
                                  ))
                              }
                            </select>
    
                          <Input
                            crossOrigin={""}
                            label="Country of Residence"
                            name="country_of_residence"
                            type="text"
                            required
                            className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                            placeholder="Country of Residence *"
                            value={form.country_of_residence || ""}
                            onChange={handleChange}
                          />
    
                          <select
                            name="identification_type"
                            className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                            value={form.identification_type || ""}
                            onChange={handleChange}>
                              <option value="passport">Passport</option>
                              <option value="driver_license">Driver License</option>
                              <option value="government_id">Government ID</option>
                              <option value="national_id">National ID</option>
                          </select>
                        </div>
    
                        {/* upload area */}
                        {
                          (context.user.verification_status != "success" && context.user.verification_status != "pending") && (
                            <label className="cursor-pointer border border-dashed border-gray-600 rounded-md p-5 text-sm placeholder:text-gray-600 w-full flex flex-col items-center justify-center gap-y-3 text-center">
                              <div className="text-gray-600 font-bold">Upload Identification</div>
                              <input
                                type="file"
                                className="hidden"
                                accept="image/*"
                                multiple
                                name="identification_file"
                                onChange={(e: any) => setIdentificationFile(e.target.files[0])}
                              />
                              {
                                  identificationFile ? (
                                      <span className="text-gray-600 text-xs">{identificationFile.name}</span>
                                  ) : (
                                      <>
                                      <div className="text-primary-500 font-bold">Click to Upload File</div>
                                      <span className="text-gray-600 text-xs">(max file size: 8MB)</span>
                                      </>
                                  )
                              }
                            </label>
                          )
                        }
                        {
                          (context.user.verification_status == "pending") && (
                            <div
                              className="p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400 text-center"
                              role="alert"
                            >
                              <span className="font-medium">Progress!</span> Your identification file has been uploaded. Verification is in progress.
                            </div>
    
                          )
                        }
                      </>
                    )
                  }
    
                  
    
    
                  <div></div>
    
                  <div className="flex flex-row items-center justify-center">
                  <button
                    type="submit"
                    className="bg-primary-500 hover:bg-primary text-white text-sm rounded-md p-3 px-20"
                  >
                    SUBMIT
                  </button>
                  </div>
    
                  </form>
                </div>
                </div>
              )
            }
           

         

        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>

    </>)
    ;
}