/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../assets/images/logo2.png";
import AppContext from "../../../AppContext";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../..";
// import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import useToken from "../../../hooks/useToken";
// import { helpers } from "../../../utilities/helpers";

const MainTemplate = (props: any) => {
  const { title, children } = props;

  // const navigate = useNavigate();

  const context: any = useContext(AppContext);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [isProductsMenuOpen, setIsProductsMenuOpen] = useState(false);

  const { logout } = useToken();

  // const activeTab  = window.location.pathname.split("/")[2] ?? "";

  const logOut = () => {
    logout();
    window.location.href = "/admin/login";
  };

  return (
    <>
      <div className="flex h-[calc(100vh-80px)] lg:h-screen bg-gray-50 dark:bg-gray-900">
        {/* <!-- Desktop sidebar --> */}
        <aside className="z-20 hidden w-64 overflow-y-auto bg-white dark:bg-gray-800 lg:block flex-shrink-0">
          <div className="py-4 text-gray-500 dark:text-gray-400">
            <Link
              className="ml-10 text-lg font-bold text-gray-800 dark:text-gray-200 block"
              to="/admin"
            >
              {/* use logo */}
              <img
                className="h-[40px] ml-2 inline-block"
                src={logo}
                alt="Workflow"
              />
              <span className="ml-2">FarmFundr</span>
            </Link>
            <MainTemplateMenu
              context={context}
              setIsProfileMenuOpen={setIsProfileMenuOpen}
              isProfileMenuOpen={isProfileMenuOpen}
              setIsProductsMenuOpen={setIsProductsMenuOpen}
              isProductsMenuOpen={isProductsMenuOpen}
            />
          </div>
        </aside>
        {/* <!-- Mobile sidebar --> */}
        {/* <!-- Backdrop --> */}
        <div
          className={`fixed ${
            isSidebarOpen ? "" : "hidden"
          } lg:hidden inset-0 z-30 flex items-end bg-black bg-opacity-50 lg:items-center lg:justify-center`}
          onClick={() => setIsSidebarOpen(false)}
        ></div>

        <aside
          className={`fixed inset-y-0 z-50 flex-shrink-0 w-64 mt-16 overflow-y-auto bg-white dark:bg-gray-800 ${
            isSidebarOpen ? "" : "hidden"
          } lg:hidden`}
        >
          <div className="py-4 text-gray-500 dark:text-gray-400">
            <Link
              to={"/admin"}
              className="text-lg font-bold text-gray-800 dark:text-gray-200 block text-center"
            >
              <img className="h-[30px] inline" src={logo} alt="Workflow" />
              <span className="ml-2">FarmFundr</span>
            </Link>
            <MainTemplateMenu
              context={context}
              setIsProfileMenuOpen={setIsProfileMenuOpen}
              isProfileMenuOpen={isProfileMenuOpen}
              setIsProductsMenuOpen={setIsProductsMenuOpen}
              isProductsMenuOpen={isProductsMenuOpen}
            />
          </div>
        </aside>

        <div className="flex flex-col flex-1">
          <header className="z-30 py-4 bg-white shadow-md dark:bg-gray-800">
            <div className="flex flex-row items-center justify-between md:justify-end h-full px-6 text-purple-600 dark:text-purple-300">
              {/* <!-- Mobile hamburger --> */}
              <button
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                className="p-1 -ml-1 mr-5 rounded-md w-auto lg:hidden focus:outline-none focus:shadow-outline-purple text-black hover:bg-gray-300"
                aria-label="Menu"
              >
                <svg
                  className="w-6 h-6"
                  area-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
              <ul className="flex items-center justify-end flex-shrink-0 space-x-6">
                {/* <!-- Profile menu --> */}
                <li className="relative">
                  <button
                    onClick={() => setIsProfileMenuOpen(!isProfileMenuOpen)}
                    className="align-middle rounded-full focus:shadow-outline-purple focus:outline-none"
                    aria-label="Account"
                    aria-haspopup="true"
                  >
                    <img
                      className="object-cover w-8 h-8 rounded-full"
                      src="https://www.w3schools.com/howto/img_avatar.png"
                      alt=""
                      area-hidden="true"
                    />
                  </button>
                  <ul
                    className={`absolute right-0 w-56 p-2 mt-2 space-y-2 text-gray-600 bg-white border border-gray-100 rounded-md shadow-md dark:border-gray-700 dark:text-gray-300 dark:bg-gray-700 ${
                      isProfileMenuOpen ? "" : "hidden"
                    } `}
                    aria-label="submenu"
                  >
                    <li className="flex">
                      <Link
                        className="inline-flex items-center w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200"
                        to="/admin/profile"
                      >
                        <svg
                          className="w-4 h-4 mr-3"
                          area-hidden="true"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
                        </svg>
                        <span>Profile</span>
                      </Link>
                    </li>
                    <li className="flex">
                      <button
                        onClick={logOut}
                        className="inline-flex items-center w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200"
                      >
                        <svg
                          className="w-4 h-4 mr-3"
                          area-hidden="true"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"></path>
                        </svg>
                        <span>Log out</span>
                      </button>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </header>

          <main className="h-full pb-16 overflow-y-auto">
            {/* <!-- Remove everything INSIDE this div to a really blank page --> */}
            <div className="container px-2 lg:px-6 mx-auto grid">
              {title?.length > 0 ? (
                <h2 className="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
                  {title}
                </h2>
              ) : (
                <div className="mt-4"></div>
              )}

              {children}
            </div>
          </main>
        </div>
      </div>

      {/* Bottom navigation */}
      {/* <div className="z-30 fixed bottom-0 left-0 w-full bg-white pb-5 !pt-1 lg:hidden">
            <BottomNavigation
              showLabels
              value={activeTab}
              onChange={(event, newValue) => {
                //navigate
                navigate("/admin/" + newValue);
              }}
            >
              <BottomNavigationAction
                value=""
                label="Home"
                icon={<HomeIcon />}
                className={`z-40 ${
                  activeTab == "" ? "!text-black" : "!text-gray-400"
                }`}
              />
              {
                ["admin", "super_admin", "accounting_manager"].includes(context.user.role) && (
              <BottomNavigationAction
                value="payments"
                label="Invoices"
                icon={<InvoiceIcon />}
                className={`z-40 ${
                  activeTab == "payments" ? "!text-black" : "!text-gray-400"
                }`}
              />
              )
              }
              {
                ["admin", "super_admin", "accounting_manager", "production_manager"].includes(context.user.role) && (
              <BottomNavigationAction
                value="orders"
                label="Bookings"
                icon={<TrackingIcon />}
                className={`z-40 ${
                  activeTab == "orders" ? "!text-black" : "!text-gray-400"
                }`}
              />
                )
              }
              {
                ["admin", "super_admin"].includes(context.user.role) && (
              <BottomNavigationAction
                value="users"
                label="Users"
                icon={<ClientIcon />}
                className={`z-40 ${
                  activeTab == "users" ? "!text-black" : "!text-gray-400"
                }`}
              />
                )
              }
              <BottomNavigationAction
                value="products"
                label="Offerings"
                icon={<ProductIcon />}
                className={`z-40 ${
                  activeTab == "products" ? "!text-black" : "!text-gray-400"
                }`}
              />
            </BottomNavigation>
      </div> */}
    </>
  );
};

type MenuProps = {
  context: any;
  setIsProfileMenuOpen: any;
  isProfileMenuOpen: boolean;
  setIsProductsMenuOpen: any;
  isProductsMenuOpen: boolean;
};
const MainTemplateMenu = ({
  context,
  setIsProfileMenuOpen,
  isProfileMenuOpen,
  setIsProductsMenuOpen,
  isProductsMenuOpen,
}: MenuProps) => {
  const location = useLocation();
  const productSubMenus = [
    "/admin/products",
    "/admin/types",
    "/admin/categories",
    "/admin/amenities",
  ];

  const [ordersCount, setBookingsCount] = useState(0);
  // const [paymentsCount, setPaymentsCount] = useState(0);

  const fetchBookings = async () => {
    //fetch orders from firebase
    const ordersRef = collection(db, "orders");
    var queries = [where("status", "in", ["pending", "processing"])];
    if (context.user.role === "sales_manager") {
      queries.push(where("sales_manager_id", "==", context.user.uid));
    }

    const q = query(ordersRef, ...queries);
    await getDocs(q).then((snapshot) => {
      setBookingsCount(snapshot.docs.length);
    });
  };

  const fetchPayments = async () => {
    //fetch payments from firebase
    // const paymentsRef = collection(db, "payments");
    // var queries = [where("status", "==", "pending")];
    // if (context.user.role === "sales_manager") {
    //   queries.push(where("sales_manager_id", "==", context.user.uid));
    // }
    // const q = query(paymentsRef, ...queries);
    // await getDocs(q).then((snapshot) => {
    //   setPaymentsCount(snapshot.docs.length);
    // });
  };

  useEffect(() => {
    fetchBookings();
    fetchPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ul className="mt-6 pl-0">
        {/* DASHBOARD */}
        <li className="relative px-6 py-3">
          {/* <!-- Active items have the snippet below --> */}
          <span
            className={`absolute inset-y-0 left-0 w-1 bg-black rounded-tr-lg rounded-br-lg ${
              location.pathname === "/admin" ? "" : "hidden"
            }`}
            area-hidden="true"
          ></span>

          {/* <!-- Add this classes to an active anchor (a tag) --> */}
          {/* <!-- text-gray-800 dark:text-gray-100 --> */}
          <Link
            className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
            to="/admin"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon !w-5 !h-5 icon-tabler icon-tabler-dashboard"
              width="48"
              height="48"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#597e8d"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M12 13m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
              <path d="M13.45 11.55l2.05 -2.05" />
              <path d="M6.4 20a9 9 0 1 1 11.2 0z" />
            </svg>
            <span className="ml-4">Dashboard</span>
          </Link>
        </li>
        {/* PRODUCTS */}
        <li className="relative px-6 py-3">
          <span
            className={`absolute inset-y-0 left-0 w-1 bg-black rounded-tr-lg rounded-br-lg ${
              productSubMenus.includes(location.pathname) ? "" : "hidden"
            }`}
            aria-hidden="true"
          ></span>
          <button
            onClick={() => setIsProductsMenuOpen(!isProductsMenuOpen)}
            className="inline-flex items-center justify-between w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
            aria-haspopup="true"
          >
            <span className="inline-flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon !w-5 !h-5 icon-tabler icon-tabler-home-eco"
                width="48"
                height="48"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#597e8d"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M20 11l-8 -8l-9 9h2v7a2 2 0 0 0 2 2h5" />
                <path d="M9 21v-6a2 2 0 0 1 2 -2h2c.325 0 .631 .077 .902 .215" />
                <path d="M16 22s0 -2 3 -4" />
                <path d="M19 21a3 3 0 0 1 0 -6h3v3a3 3 0 0 1 -3 3z" />
              </svg>
              <span className="ml-4">Offerings</span>
            </span>
            <svg
              className="w-4 h-4"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>

          <ul
            className={`p-2 mt-2 space-y-2 overflow-hidden text-sm font-medium text-gray-500 rounded-md shadow-inner bg-gray-50 dark:text-gray-400 dark:bg-gray-900 ${
              isProductsMenuOpen || productSubMenus.includes(location.pathname)
                ? ""
                : "hidden"
            }`}
            aria-label="submenu"
          >
            {/* all products */}
            <li className="relative px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
              <span
                className={`absolute inset-y-0 left-0 w-1 bg-black rounded-tr-lg rounded-br-lg ${
                  location.pathname === "/admin/products" ? "" : "hidden"
                }`}
                aria-hidden="true"
              ></span>
              <Link to="/admin/products" className="w-full">
                All Offers
              </Link>
            </li>
            {/* types */}
            {/* <li className="relative px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                                        <span className={`absolute inset-y-0 left-0 w-1 bg-black rounded-tr-lg rounded-br-lg ${(location.pathname === "/admin/types") ? "" : "hidden"}`} aria-hidden="true"></span>
                                        <Link to="/admin/types" className="w-full" >Types</Link>
                                    </li> */}
            {/* categories */}
            <li className="relative px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
              <span
                className={`absolute inset-y-0 left-0 w-1 bg-black rounded-tr-lg rounded-br-lg ${
                  location.pathname === "/admin/categories" ? "" : "hidden"
                }`}
                aria-hidden="true"
              ></span>
              <Link to="/admin/categories" className="w-full">
                Categories
              </Link>
            </li>
            {/* amenities */}
            {/* <li className="relative px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                                        <span className={`absolute inset-y-0 left-0 w-1 bg-black rounded-tr-lg rounded-br-lg ${(location.pathname === "/admin/amenities") ? "" : "hidden"}`} aria-hidden="true"></span>
                                        <Link to="/admin/amenities" className="w-full" >Amenities</Link>
                                    </li> */}
            {/* attributes */}
            {/* <li className="relative px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
              <span
                className={`absolute inset-y-0 left-0 w-1 bg-black rounded-tr-lg rounded-br-lg ${
                  location.pathname === "/admin/attributes" ? "" : "hidden"
                }`}
                aria-hidden="true"
              ></span>
              <Link to="/admin/attributes" className="w-full">
                Attributes
              </Link>
            </li> */}
          </ul>
        </li>
        {/* ORDERS */}
        {[
          "admin",
          "super_admin",
          "accounting_manager",
          "production_manager",
        ].includes(context.user.role) && (
          <li className="relative px-6 py-3">
            <span
              className={`absolute inset-y-0 left-0 w-1 bg-black rounded-tr-lg rounded-br-lg ${
                location.pathname === "/admin/orders" ? "" : "hidden"
              }`}
              area-hidden="true"
            ></span>
            <Link
              className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
              to="/admin/orders"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon !w-5 !h-5 icon-tabler icon-tabler-shopping-cart"
                width="48"
                height="48"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#597e8d"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M6 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                <path d="M17 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                <path d="M17 17h-11v-14h-2" />
                <path d="M6 5l14 1l-1 7h-13" />
              </svg>
              <span className="ml-4">Bookings</span>{" "}
              <span
                className={`bg-red-500 text-white px-2 py-1 rounded-full text-xs font-bold ml-2 ${
                  ordersCount < 1 && "hidden"
                }`}
              >
                {ordersCount}
              </span>
            </Link>
          </li>
        )}
        {/* POSTS */}
        {[
          "admin",
          "super_admin",
          "accounting_manager",
          "production_manager",
        ].includes(context.user.role) && (
          <li className="relative px-6 py-3">
            <span
              className={`absolute inset-y-0 left-0 w-1 bg-black rounded-tr-lg rounded-br-lg ${
                location.pathname === "/admin/posts" ? "" : "hidden"
              }`}
              area-hidden="true"
            ></span>
            <Link
              className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
              to="/admin/posts"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="#597e8d"
                className="icon !w-5 !h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 0 1-2.25 2.25M16.5 7.5V18a2.25 2.25 0 0 0 2.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 0 0 2.25 2.25h13.5M6 7.5h3v3H6v-3Z"
                />
              </svg>
              <span className="ml-4">Posts</span>{" "}
            </Link>
          </li>
        )}
        {/* PAYMENTS */}
        {/* {["admin", "super_admin", "accounting_manager"].includes(
          context.user.role
        ) && (
          <li className="relative px-6 py-3">
            <span
              className={`absolute inset-y-0 left-0 w-1 bg-black rounded-tr-lg rounded-br-lg ${
                location.pathname === "/admin/payments" ? "" : "hidden"
              }`}
              area-hidden="true"
            ></span>
            <Link
              className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
              to="/admin/payments"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon !w-5 !h-5 icon-tabler icon-tabler-receipt"
                width="48"
                height="48"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#597e8d"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2m4 -14h6m-6 4h6m-2 4h2" />
              </svg>
              <span className="ml-4">Invoices</span>
              <span
                className={`bg-red-500 text-white px-2 py-1 rounded-full text-xs font-bold ml-2 ${
                  paymentsCount < 1 && "hidden"
                }`}
              >
                {paymentsCount}
              </span>
            </Link>
          </li>
        )} */}
        {/* USERS */}
        {["admin", "super_admin"].includes(context.user.role) && (
          <li className="relative px-6 py-3">
            <span
              className={`absolute inset-y-0 left-0 w-1 bg-black rounded-tr-lg rounded-br-lg ${
                location.pathname === "/admin/users" ? "" : "hidden"
              }`}
              area-hidden="true"
            ></span>
            <Link
              className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
              to="/admin/users"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon !w-5 !h-5 icon-tabler icon-tabler-users"
                width="48"
                height="48"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#597e8d"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M9 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
                <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
              </svg>
              <span className="ml-4">Users</span>
            </Link>
          </li>
        )}
        {/* CLIENTS */}
        {/* {["admin", "super_admin"].includes(context.user.role) && (
          <li className="relative px-6 py-3">
            <span
              className={`absolute inset-y-0 left-0 w-1 bg-black rounded-tr-lg rounded-br-lg ${
                location.pathname === "/admin/clients" ? "" : "hidden"
              }`}
              area-hidden="true"
            ></span>
            <Link
              className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
              to="/admin/clients"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#597e8d" className="!w-5 !h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
              </svg>

              <span className="ml-4">Clients</span>
            </Link>
          </li>
        )} */}
        {/* PUSH NOTIFICATION */}
        {/* {["admin", "super_admin"].includes(context.user.role) && (
          <li className="relative px-6 py-3">
            <span
              className={`absolute inset-y-0 left-0 w-1 bg-black rounded-tr-lg rounded-br-lg ${
                location.pathname === "/admin/notifications" ? "" : "hidden"
              }`}
              area-hidden="true"
            ></span>
            <Link
              className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
              to="/admin/notifications"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon !w-5 !h-5 icon-tabler icon-tabler-bell"
                width="48"
                height="48"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#597e8d"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M10 5a2 2 0 1 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" />
                <path d="M9 17v1a3 3 0 0 0 6 0v-1" />
              </svg>
              <span className="ml-4">Push Notifications</span>
            </Link>
          </li>
        )} */}
        {/* SETTINGS */}
        {/* {["admin", "super_admin"].includes(context.user.role) && (
          <li className="relative px-6 py-3">
            <span
              className={`absolute inset-y-0 left-0 w-1 bg-black rounded-tr-lg rounded-br-lg ${
                location.pathname === "/admin/settings" ? "" : "hidden"
              }`}
              area-hidden="true"
            ></span>
            <Link
              className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
              to="/admin/settings"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon !w-5 !h-5 icon-tabler icon-tabler-adjustments-alt"
                width="48"
                height="48"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#597e8d"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M4 8h4v4h-4z" />
                <path d="M6 4l0 4" />
                <path d="M6 12l0 8" />
                <path d="M10 14h4v4h-4z" />
                <path d="M12 4l0 10" />
                <path d="M12 18l0 2" />
                <path d="M16 5h4v4h-4z" />
                <path d="M18 4l0 1" />
                <path d="M18 9l0 11" />
              </svg>
              <span className="ml-4">Settings</span>
            </Link>
          </li>
        )} */}
      </ul>
    </>
  );
};

export default MainTemplate;
