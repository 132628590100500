import logo from '../../../assets/images/logo2.png';
function Loading() {
    return (
        <>
            <div className="fixed left-0 top-0 h-screen w-full flex flex-col justify-center items-center bg-f1 bg-opacity-95">
                <div className="text-9xl font-extrabold text-black tracking-widest">
                    <div role="status">
                        <img src={logo} alt="logo" className="w-16 h-16 animate-spin" />
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Loading;