/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import { Outlet, Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import { useState } from "react";
import useDocumentTitle from "../hooks/userDocumentTitle";

function NoPage() {
  useDocumentTitle("404");
//   const navigate = useNavigate();

  const [showResourceMenu, setShowResourceMenu] = useState(false);

  return (
    <>
      <div className="_404-body">
        <div
          data-collapse="medium"
          data-animation="default"
          data-duration={400}
          data-doc-height={1}
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          className="nav-bar w-nav"
        >
          <div className="container-nav w-container">
            <a href="/" className="brand w-nav-brand" aria-label="home" />
            <nav role="navigation" className="nav-menu w-nav-menu">
              <a
                href="/"
                className="nav-link normal-nav w-nav-link"
                style={{ maxWidth: 940 }}
              >
                HOME
              </a>
              <a
                href="/property"
                className="nav-link normal-nav w-nav-link"
                style={{ maxWidth: 940 }}
              >
                investments
              </a>
              <a
                href="/category/blog"
                className="nav-link normal-nav w-nav-link"
                style={{ maxWidth: 940 }}
              >
                Farm investing blog
              </a>
              <a
                href="/about-us"
                className="nav-link normal-nav w-nav-link"
                style={{ maxWidth: 940 }}
              >
                About
              </a>
              <div
                data-delay={0}
                data-hover="false"
                className="nav-link w-dropdown"
                style={{ maxWidth: 940 }}
              >
                <div
                  className="dropdown-toggle w-dropdown-toggle"
                  id="w-dropdown-toggle-0"
                  aria-controls="w-dropdown-list-0"
                  aria-haspopup="menu"
                  aria-expanded="false"
                  role="button"
                  tabIndex={0}
                  onClick={() => setShowResourceMenu(!showResourceMenu)}
                >
                  <div className="text-block-22">resources</div>
                  <div
                    className="dropdown-arrow w-icon-dropdown-toggle"
                    aria-hidden="true"
                  />
                </div>
                <nav
                  className={`dropdown-list big w-dropdown-list !bg-[#000000ae] ${showResourceMenu && "!block"}`}
                  id="w-dropdown-list-0"
                  aria-labelledby="w-dropdown-toggle-0"
                >
                  <a
                    href="/faqs"
                    className="dropdown-link w-dropdown-link"
                    tabIndex={0}
                  >
                    FAQ
                  </a>
                  <a
                    href="/media"
                    className="dropdown-link w-dropdown-link"
                    tabIndex={0}
                  >
                    media &amp;&nbsp;NEws
                  </a>
                  <a
                    href="/helping-farmers-grow"
                    className="dropdown-link w-dropdown-link"
                    tabIndex={0}
                  >
                    Helping Farmers Grow
                  </a>
                  <a
                    href="/sell-farmland"
                    className="dropdown-link w-dropdown-link"
                    tabIndex={0}
                  >
                    Sell A Farm
                  </a>
                  <a
                    href="/custom-farmland-investments"
                    className="dropdown-link w-dropdown-link"
                    tabIndex={0}
                  >
                    farmfinder - custom investments
                  </a>
                </nav>
              </div>
              <a
                href="/contact-us"
                className="nav-link normal-nav w-nav-link"
                style={{ maxWidth: 940 }}
              >
                Contact
              </a>
              <a
                href="/portal"
                target="_blank"
                className="nav-link normal-nav login w-nav-link"
                style={{ maxWidth: 940 }}
              >
                Login
              </a>
              <a
                href="/portal/register"
                target="_blank"
                className="nav-link normal-nav sign-up w-nav-link"
                style={{ maxWidth: 940 }}
              >
                Access Investments
              </a>
            </nav>
            <div
              className="menu-button w-nav-button"
              style={{ WebkitUserSelect: "text" }}
              aria-label="menu"
              role="button"
              tabIndex={0}
              aria-controls="w-nav-overlay-0"
              aria-haspopup="menu"
              aria-expanded="false"
            >
              <div className="icon w-icon-nav-menu" />
            </div>
          </div>
          <img
            src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e59a0a50e170610f872145d_FF-Logo-White-01.png"
            width={130}
            alt=""
            className="center-div"
          />
          <div
            className="w-nav-overlay"
            data-wf-ignore=""
            id="w-nav-overlay-0"
          />
        </div>
        <div className="section-2 wf-section">
          <div className="_404-container w-container">
            <h1
              data-w-id="f1ff6593-0df5-8cb0-366b-89d4a912d818"
              style={{
                opacity: 1,
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d",
              }}
              className="hero-tittle _404"
            >
              Sorry, the page you are looking for was not found!
            </h1>
            <p
              data-w-id="a7b100fe-a69a-b508-942e-46baf5ea2688"
              style={{
                opacity: 1,
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d",
              }}
              className="hero-paragraph"
            >
              Perhaps you can return back to the home page and see if you can
              find what you are looking for.
            </p>
            <a
              href="/"
              data-w-id="b2cabd76-16f4-186e-6996-e328e965d85a"
              style={{
                opacity: 1,
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d",
              }}
              className="button _404 w-button"
            >
              BACK to HOMEPAGE
            </a>
          </div>
        </div>
        <div className="footer dark-bootom wf-section">
          <div className="div-footer">
            <a href="/" className="w-inline-block">
              <img
                src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e59a0c0a67bd6d170e34c37_FarmFundrLogo-01.png"
                width={197}
                alt="How to invest in farmland"
                className="footer-logo"
              />
            </a>
            <div className="sub-footer">
              <div className="div-social w-clearfix">
                <div className="social-icon wrapper">
                  <a
                    href="http://facebook.com/farmfundr"
                    target="_blank"
                    className="w-inline-block w-clearfix"
                  >
                    <img
                      src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e5717f541472a436e58be50_facebook-logo-1_1.png"
                      alt=""
                      className="social-icon"
                    />
                  </a>
                </div>
                <div className="social-icon wrapper">
                  <a
                    href="https://twitter.com/farmfundr"
                    target="_blank"
                    className="w-inline-block w-clearfix"
                  >
                    <img
                      src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e5717f541472a800058bf2f_twitter.png"
                      width={32}
                      alt=""
                      className="social-icon _2"
                    />
                  </a>
                </div>
                <div className="social-icon wrapper">
                  <a
                    href="https://www.instagram.com/farmfundr/"
                    target="_blank"
                    className="w-inline-block"
                  >
                    <img
                      src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e5dcdf52c687cce86214490_Instagram_InvestinFarming.png"
                      alt=""
                      className="social-icon _3"
                    />
                  </a>
                </div>
                <div className="social-icon wrapper _4">
                  <a
                    href="https://www.pinterest.com/farmfundr/"
                    target="_blank"
                    className="w-inline-block"
                  >
                    <img
                      src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e5717f541472a24af58bfc9_Pinterest.png"
                      height={64}
                      width={60}
                      alt=""
                      className="social-icon _3"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="small-footer">
            <p className="footer-info">
              {" "}
              © FarmFundr,&nbsp;LLC. 2022
              <a href="/licensing" className="link-page" />
              <a
                href="https://webflow.com/templates/designers/dorian-hoxha"
                className="link-page"
              />
            </p>
          </div>
        </div>
        {/*[if lte IE 9]><![endif]*/}
        <div id="tib03q5obcno1707752106839" className="widget-hidden">
          <iframe
            src="about:blank"
            frameBorder={0}
            scrolling="no"
            width="64px"
            height="60px"
            style={{
              outline: "none !important",
              visibility: "visible",
              resize: "none",
              boxShadow: "none !important",
              overflow: "visible !important",
              background: "none !important",
              opacity: "1 !important",
              filter: "alpha(opacity=100) !important",
              msFilter:
                "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
              MozOpacity: "1 !important",
              KhtmlOpacity: "1 !important",
              top: "auto !important",
              right: "auto !important",
              bottom: "20px !important",
              left: "20px !important",
              position: "fixed",
              border: "0 !important",
              minHeight: "60px !important",
              minWidth: "64px !important",
              maxHeight: "60px !important",
              maxWidth: "64px !important",
              padding: "0 !important",
              margin: "0 !important",
              MozTransitionProperty: "none !important",
              WebkitTransitionProperty: "none !important",
              OTransitionProperty: "none !important",
              transitionProperty: "none !important",
              transform: "none !important",
              WebkitTransform: "none !important",
              msTransform: "none !important",
              width: "64px !important",
              height: "60px !important",
              display: "block !important",
              zIndex: "1000001 !important",
              backgroundColor: "transparent !important",
              cursor: "none !important",
              float: "none",
              borderRadius: "unset !important",
              pointerEvents: "auto",
              clip: "auto !important",
              colorScheme: "light !important",
            }}
            id="sqh6da5n2u781707752106857"
            className=""
            title="chat widget"
          />
          <iframe
            src="about:blank"
            frameBorder={0}
            scrolling="no"
            width="350px"
            height="520px"
            style={{
              outline: "none !important",
              visibility: "visible",
              resize: "none",
              boxShadow: "none !important",
              overflow: "visible !important",
              background: "none !important",
              opacity: "1 !important",
              filter: "alpha(opacity=100) !important",
              msFilter:
                "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
              MozOpacity: "1 !important",
              KhtmlOpacity: "1 !important",
              top: "auto !important",
              right: "auto !important",
              bottom: "90px !important",
              left: "10px !important",
              position: "fixed",
              border: "0 !important",
              minHeight: "520px !important",
              minWidth: "350px !important",
              maxHeight: "520px !important",
              maxWidth: "350px !important",
              padding: "0 !important",
              margin: "0 !important",
              MozTransitionProperty: "none !important",
              WebkitTransitionProperty: "none !important",
              OTransitionProperty: "none !important",
              transitionProperty: "none !important",
              transform: "none !important",
              WebkitTransform: "none !important",
              msTransform: "none !important",
              width: "350px !important",
              height: "520px !important",
              display: "none !important",
              zIndex: "auto !important",
              backgroundColor: "transparent !important",
              cursor: "none !important",
              float: "none",
              borderRadius: "5px !important",
              pointerEvents: "auto",
              clip: "auto !important",
              colorScheme: "light !important",
            }}
            id="o4995akcdeqg1707752106877"
            className=""
            title="chat widget"
          />
          <iframe
            src="about:blank"
            frameBorder={0}
            scrolling="no"
            width="360px"
            height="55px"
            style={{
              outline: "none !important",
              visibility: "visible",
              resize: "none",
              boxShadow: "none !important",
              overflow: "visible !important",
              background: "none !important",
              opacity: "1 !important",
              filter: "alpha(opacity=100) !important",
              msFilter:
                "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
              MozOpacity: "1 !important",
              KhtmlOpacity: "1 !important",
              top: "auto !important",
              right: "auto !important",
              bottom: 100,
              left: "20px !important",
              position: "fixed",
              border: "0 !important",
              minHeight: "55px !important",
              minWidth: "360px !important",
              maxHeight: "55px !important",
              maxWidth: "360px !important",
              padding: "0 !important",
              margin: "0 !important",
              MozTransitionProperty: "none !important",
              WebkitTransitionProperty: "none !important",
              OTransitionProperty: "none !important",
              transitionProperty: "none !important",
              transform: "none !important",
              WebkitTransform: "none !important",
              msTransform: "none !important",
              width: "360px !important",
              height: "55px !important",
              display: "none !important",
              zIndex: "auto !important",
              backgroundColor: "transparent !important",
              cursor: "none !important",
              float: "none",
              borderRadius: "unset !important",
              pointerEvents: "auto",
              clip: "auto !important",
              colorScheme: "light !important",
            }}
            id="s4sgt6shh8ko1707752106869"
            className=""
            title="chat widget"
          />
          <iframe
            src="about:blank"
            frameBorder={0}
            scrolling="no"
            width="146px"
            height="85px"
            style={{
              outline: "none !important",
              visibility: "visible",
              resize: "none",
              boxShadow: "none !important",
              overflow: "visible !important",
              background: "none !important",
              opacity: "1 !important",
              filter: "alpha(opacity=100) !important",
              msFilter:
                "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
              MozOpacity: "1 !important",
              KhtmlOpacity: "1 !important",
              top: "auto !important",
              right: "auto !important",
              bottom: "82px !important",
              left: "20px !important",
              position: "fixed",
              border: "0 !important",
              minHeight: "85px !important",
              minWidth: "146px !important",
              maxHeight: "85px !important",
              maxWidth: "146px !important",
              padding: "0 !important",
              margin: "0px 0 0 0 !important",
              MozTransitionProperty: "none !important",
              WebkitTransitionProperty: "none !important",
              OTransitionProperty: "none !important",
              transitionProperty: "none !important",
              transform: "rotate(0deg) translateZ(0)",
              WebkitTransform: "rotate(0deg) translateZ(0)",
              msTransform: "rotate(0deg) translateZ(0)",
              width: "146px !important",
              height: "85px !important",
              display: "none !important",
              zIndex: "1000002 !important",
              backgroundColor: "transparent !important",
              cursor: "none !important",
              float: "none",
              borderRadius: "unset !important",
              pointerEvents: "auto",
              clip: "auto !important",
              colorScheme: "light !important",
              MozTransform: "rotate(0deg) translateZ(0)",
              OTransform: "rotate(0deg) translateZ(0)",
              transformOrigin: 0,
              MozTransformOrigin: 0,
              WebkitTransformOrigin: 0,
              OTransformOrigin: 0,
              msTransformOrigin: 0,
            }}
            id="br8b046me8gg1707752106862"
            className=""
            title="chat widget"
          />
        </div>
      </div>
    </>
  );
}

export default NoPage;
