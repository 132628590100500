import React, { useContext, useState } from "react";

export const SmartLoadingDialogContext = React.createContext({
  loading: false,
  show: () => {},
  dismiss: () => {},
});

export const useSmartLoadingDialog = () => {
  const [loading, setLoading] = useState(false);

  const show = () => {
    setLoading(true);
  };
  const dismiss = () => {
    setLoading(false);
  };

  return {
    loading,
    show,
    dismiss,
  };
};

export const useSmartLoadingDialogContext = () => {
  return useContext(SmartLoadingDialogContext);
};

// smart loading dialog
type Props = {
  loading: boolean;
  children?: React.ReactNode;
};
export const SmartLoadingDialog = ({ loading, children }: Props) => {
  return (
    <>
      <div className={`fixed left-0 top-0 h-screen z-50 bg-[#000000c0] w-full flex items-center justify-center ${!loading && "hidden"}`}>
        <div className="flex flex-col justify-center items-center space-x-1 text-sm text-gray-700 bg-white rounded-xl py-5 px-8">
          <svg
            fill="none"
            className="w-[100px] h-[100px] animate-spin"
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clipRule="evenodd"
              d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
              fill="currentColor"
              fillRule="evenodd"
            />
          </svg>
          <div className="text-lg">Loading ...</div>
        </div>
      </div>
    </>
  );
};
