import React, { useState } from "react";
import { helpers } from "../../../utilities/helpers";
import StatusLabel from "./StatusLabel";
import PostModal from "../pages/modal/PostModal";
import CircularLoader from "./CircularLoader";
import { toast } from "react-toastify";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../..";
import { confirm } from "react-confirm-box";

type Props = {
    posts: any[];
    onTableUpdated: (post: any) => void;
    loading?: boolean;
}
const PostsTable = ({ posts, onTableUpdated, loading = false }: Props) => {

    const [showModal, setShowModal] = useState(false);

    const [post, setPost] = useState({});

    const onModalUpdated = (notification: any) => {
        onTableUpdated(notification);
    }

    const deletePost = async (post: any) => {
        //confirm delete
        const proceed = await confirm(`Are you sure you want to delete ${post.title}?`);

        if (!proceed) return;

        const loading = toast.loading("Please wait...");

        const postRef = doc(db, "posts", post.id);

        await deleteDoc(postRef).then(() => {
            onTableUpdated(null);
            toast.success("Deleted successfully");
        }).catch((error) => {
            toast.error("Failed to delete");
        });

        toast.dismiss(loading);
    }



    return (
        <>
            <div className="text-right mb-5">
                <button onClick={() => { setPost({}); setShowModal(true); }} className="text-white bg-black py-1 px-5 rounded hover:bg-gray-700" type="button" data-modal-toggle="add-modal">
                    <svg xmlns="http://www.w3.org/2000/svg" className="inline icon icon-tabler icon-tabler-plus" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <desc>Download more icon variants from https://tabler-icons.io/i/plus</desc>
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <line x1="12" y1="5" x2="12" y2="19"></line>
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg> New</button>
            </div>
            {/* <!-- New Table --> */}
            <div className="w-full overflow-hidden rounded-lg shadow-xs">
                <div className="w-full overflow-x-auto">
                    <table className="w-full whitespace-no-wrap">
                        <thead>
                            <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                                <th className="px-4 py-3">Post</th>
                                <th className="px-4 py-3">Posted By</th>
                                <th className="px-4 py-3">Status</th>
                                <th className="px-4 py-3">Date</th>
                                <th className="px-4 py-3">Action</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                            {
                                loading && (
                                    <tr className="text-gray-700 dark:text-gray-400">
                                        <td className="px-4 py-3 text-sm" colSpan={5}>
                                            <div className="flex justify-center items-center">
                                                <CircularLoader />
                                                <span>Loading...</span>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }
                            {
                                (posts.length > 0) && posts.map((post) => (
                                    <tr 
                                        className="text-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer"
                                        onClick={() => { setPost(post); setShowModal(true); }}
                                        key={post.id}>
                                        <td className="px-4 py-3">
                                            <div className="flex items-center text-sm">
                                                {/* <!-- Avatar with inset shadow --> */}
                                                <div className="relative hidden w-12 h-12 mr-3 rounded-full lg:block">
                                                    <img className="object-cover w-full h-full" src={helpers.getFirstImage(post.image)} alt="" loading="lazy" />
                                                    <div className="absolute inset-0 shadow-inner" aria-hidden="true"></div>
                                                </div>
                                                <div>
                                                    <p className="font-semibold">{helpers.capitalize(post.title)}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="px-4 py-3 text-sm">
                                            <p className="font-semibold">{post.user.full_name}</p>
                                        </td>
                                        <td className="px-4 py-3 text-xs">
                                            <StatusLabel status={post.status} />
                                        </td>
                                        <td className="px-4 py-3 text-sm">
                                            {post.created_at.toDate().toLocaleDateString()}
                                        </td>
                                        <td className="px-4 py-3 text-sm text-right pt-4 flex items-center justify-end">
                                            <button onClick={() => { setPost(post); setShowModal(true); }} id="row-edit" className="inline bg-black rounded text-white p-2 hover:bg-gray-700 disabled:bg-gray-800" data-modal-toggle="edit-modal">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 icon icon-tabler icon-tabler-pencil" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <desc>Download more icon variants from https://tabler-icons.io/i/pencil</desc>
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4"></path>
                                                    <line x1="13.5" y1="6.5" x2="17.5" y2="10.5"></line>
                                                </svg></button>
                                            <button onClick={(e) => {
                                                e.stopPropagation();
                                                deletePost(post)
                                            }} className="inline text-center bg-red-700 rounded text-white p-2 hover:opacity-80 ml-2">
                                                <svg id="icon" xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 icon icon-tabler icon-tabler-trash" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <desc>Download more icon variants from https://tabler-icons.io/i/trash</desc>
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <line x1="4" y1="7" x2="20" y2="7"></line>
                                                    <line x1="10" y1="11" x2="10" y2="17"></line>
                                                    <line x1="14" y1="11" x2="14" y2="17"></line>
                                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                                                </svg>
                                                <svg id="loading" className="hidden animate-spin h-4 w-4 icon icon-tabler text-white" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                            {
                                (posts.length === 0 && !loading) && (
                                    <tr className="text-gray-700 dark:text-gray-400">
                                        <td className="px-4 py-3 text-sm text-center" colSpan={5}>
                                            <p className="font-semibold">No posts found</p>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
                <div className="grid px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 lg:grid-cols-9 dark:text-gray-400 dark:bg-gray-800">
                    <span className="flex items-center col-span-3">
                        Showing {posts.length} of {posts.length}
                    </span>
                    <span className="col-span-2"></span>
                    {/* <!-- Pagination --> */}
                    {/* <span className="flex col-span-4 mt-2 lg:mt-auto lg:justify-end">
                        <nav aria-label="Table navigation">
                            <ul className="inline-flex items-center">
                                <li>
                                    <button className="px-3 py-1 rounded-md rounded-l-lg focus:outline-none focus:shadow-outline-purple" aria-label="Previous">
                                        <svg aria-hidden="true" className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                                            <path d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" fillRule="evenodd"></path>
                                        </svg>
                                    </button>
                                </li>
                                <li>
                                    <button className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple">
                                        1
                                    </button>
                                </li>
                                <li>
                                    <button className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple">
                                        2
                                    </button>
                                </li>
                                <li>
                                    <button className="px-3 py-1 text-white transition-colors duration-150 bg-purple-600 border border-r-0 border-purple-600 rounded-md focus:outline-none focus:shadow-outline-purple">
                                        3
                                    </button>
                                </li>
                                <li>
                                    <button className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple">
                                        4
                                    </button>
                                </li>
                                <li>
                                    <span className="px-3 py-1">...</span>
                                </li>
                                <li>
                                    <button className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple">
                                        8
                                    </button>
                                </li>
                                <li>
                                    <button className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple">
                                        9
                                    </button>
                                </li>
                                <li>
                                    <button className="px-3 py-1 rounded-md rounded-r-lg focus:outline-none focus:shadow-outline-purple" aria-label="Next">
                                        <svg className="w-4 h-4 fill-current" aria-hidden="true" viewBox="0 0 20 20">
                                            <path d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" fillRule="evenodd"></path>
                                        </svg>
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </span> */}
                </div>
            </div>

            {/* MODAL */}
            <PostModal showModal={showModal} setShowModal={setShowModal} post={post} setPost={setPost} onModalUpdated={onModalUpdated} />
        </>
    );
}


export default PostsTable;