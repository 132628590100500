/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import SkyeModal from "../../components/SkyeModal";
import { Timestamp, addDoc, collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db, storage } from "../../../..";
import { toast } from "react-toastify";
import { helpers } from "../../../../utilities/helpers";
import AppContext from "../../../../AppContext";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import CircularLoader from "../../components/CircularLoader";
import Currency from "../../components/Currency";
import ModalImageComponent from "../../components/components/ModalImage";
import ReactStarsComponent from "../../components/components/ReactStars";

// import ProductEditAttributeBox from "../../components/ProductEditAttributeBox";


type Props = {
    title?: string;
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    product?: any;
    setProduct: (product: any) => void;
    onModalUpdated: (product: any) => void;
}
const ProductModal = ({ showModal, setShowModal, product, setProduct, onModalUpdated }: Props) => {

    const context: any = useContext(AppContext);

    const [productData, setProductData] = useState(product);

    const [categories, setCategories] = useState([] as any[]);
    const [productImages, setProductImages] = useState([] as any[]);
    const [reviews, setReviews] = useState([] as any[]); //reviews


    const [categoriesLoading, setCategoriesLoading] = useState(true);
    const [reviewsLoading, setReviewsLoading] = useState(false);



    const [selectedCategories, setSelectedCategories] = useState((product.categories ?? []) as any[]);

    const [showImage, setShowImage] = useState(false);
    const [image, setImage] = useState(null as any);

    useEffect(() => {
        setProductData(product);
        setSelectedCategories(product.categories ?? []);
        
        //load images to productImages
        if (product.images) {
            let images: any[] = [];
            product.images.forEach((image: any) => {
                images.push({
                    image: image,
                    file: null //not a file
                });
            }
            );
            setProductImages(images);
        } else {
            setProductImages([]);
        }
    }, [product, productData.attributes]);

    const fetchCategories = async () => {
        setCategoriesLoading(true);
        const q = query(collection(db, "categories"));
        const querySnapshot = await getDocs(q);
        let categories: any[] = [];
        querySnapshot.forEach((doc) => {
            categories.push({ id: doc.id, ...doc.data() });
        });
        setCategories(categories);
        setCategoriesLoading(false);
    }
    const fetchReviews = async () => {
        setReviewsLoading(true);
        const q = query(collection(db, "reviews"), where("product_id", "==", product.id));
        const querySnapshot = await getDocs(q);
        let reviews: any[] = [];
        querySnapshot.forEach((doc) => {
            reviews.push({ id: doc.id, ...doc.data() });
        });
        setReviews(reviews);
        setReviewsLoading(false);
    }


    useEffect(() => {
        fetchCategories();
        if (product.id) {
            fetchReviews();
        }
    }, []);


    const inputChange = (e: any) => {
        setProductData({ ...productData, [e.target.name]: e.target.value });
    }
  
    const handleCategoriesChange = (value: any, checked: any) => {
        if (checked) {
            setSelectedCategories([...selectedCategories, value]);
        } else {
            setSelectedCategories(selectedCategories.filter((category: any) => category.id !== value.id));
        }
    }


    const imageChange = (e: any, index: any = null) => {
        let images = [...productImages];
        if (index !== null && e.target.files[0]) {
            images[index].file = e.target.files[0];
        } else if (e.target.files[0]) {
            images.push({
                image: URL.createObjectURL(e.target.files[0]),
                file: e.target.files[0],
            });
        }
        setProductImages(images);
    }

    const deleteImage = (index: number) => {
        if (!([ 'admin', 'super_admin', 'production_manager', 'inventory_manager' ].includes(context.user.role))) {
            toast.error("You are not authorized to perform this action!");
            return;
        }
        
        let images = [...productImages];
        images.splice(index, 1);
        setProductImages(images);
    }

    const viewImage = (index: number) => {
        let images = [...productImages];
        setImage(images[index].image);
        setShowImage(true);
    }



    const onModalClosed = () => {
        //reset
        setProductData({});
        setSelectedCategories([]);
        setProductImages([]);
        setReviews([]);
    }

    const submitForm = async (e: any) => {
        e.preventDefault();

        if (!([ 'admin', 'super_admin', 'production_manager', 'inventory_manager' ].includes(context.user.role))) {
            toast.error("You are not authorized to perform this action!");
            return;
        }

        const loading = toast.loading("Please wait...");

        let formData: any = { ...productData }

        formData.categories = selectedCategories;

        formData.categories_ids = selectedCategories.map(category => category.id);

        formData.rating = formData.rating ?? 0;


        //upload images first and get both met urls and new urls together as array[]
        let images: any[] = [];
        for (let i = 0; i < productImages.length; i++) {
            const image = productImages[i];
            if (image.file && image.file !== null) {
                //upload image
                let file = image.file;
                const imageName = formData.name + "-" + helpers.generateRandomString(10) + "." + file.name.split(".").pop();
                const imageRef = ref(storage, "images/products/" + imageName);
                await uploadBytes(imageRef, file).then(async (snapshot) => {
                    await getDownloadURL(imageRef).then((url) => {
                        images.push(url);
                    });
                }
                ).catch((error) => {
                    toast.error("Failed to upload an image");
                });
            } else {
                images.push(image.image);
            }
        }
        formData.images = images;

        //price to number
        formData.price = parseFloat(formData.price ?? 0);
        formData.regular_price = parseFloat(formData.regular_price ?? 0);
        formData.quantity = parseInt(formData.quantity ?? 0);
        formData.rating = parseInt(formData.rating ?? 0);

        if (productData.id) { //update
            //remove id, created_at
            delete formData.id;
            delete formData.created_at;
            formData.updated_at = Timestamp.now();
            formData.search_keywords = helpers.generateSearchKeywords(formData.name);

            if (formData.user_id) {
                //get user info
                const userRef = doc(db, "users", formData.user_id);
                const userSnap = await getDoc(userRef);
                if (userSnap.exists()) {
                    formData.user = { ...userSnap.data(), id: userSnap.id };
                }
            }

            //add to firebase
            const productRef = doc(db, "products", productData.id);
            await updateDoc(productRef, formData, { merge: true }).then(async () => {
                //get the updated data
                const docSnap = await getDoc(productRef);
                if (docSnap.exists()) {
                    setProduct({ ...docSnap.data(), id: docSnap.id });
                    setProductData({ ...docSnap.data(), id: docSnap.id });
                    onModalUpdated({ ...docSnap.data(), id: docSnap.id });
                    toast.success("Updated successfully");
                }
            }).catch((error) => {
                toast.error("Failed to update");
            });

        } else {
            //create
            formData.doc_number = await helpers.getNextDocumentNumber("products");
            formData.user_id = context.user.id;
            formData.user = context.user;
            formData.created_at = Timestamp.now();
            formData.updated_at = Timestamp.now();
            formData.search_keywords = helpers.generateSearchKeywords(formData.name);
            formData.slug = helpers.getSlug(formData.name);
            formData.status = formData.status ?? "publish";
            formData.type = formData.type ?? "simple";

            const productsRef = collection(db, "products");

            //add timestamp to slug if slug already exists
            const slugQuery = query(productsRef, where("slug", "==", formData.slug));
            const slugQuerySnapshot = await getDocs(slugQuery);
            if (slugQuerySnapshot.size > 0) {
                formData.slug += "-" + Date.now();
            }

            //add to firebase
            await addDoc(productsRef, formData).then(async (docRef) => {
                //get the updated data
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setProduct({ id: docSnap.id, ...docSnap.data() });
                    setProductData({ id: docSnap.id, ...docSnap.data() });
                    onModalUpdated({ id: docSnap.id, ...docSnap.data() });
                    toast.success("Offer created successfully!");
                }
            }).catch((error) => {
                toast.error("Something went wrong!");
            });

        }


        toast.dismiss(loading);
        setShowModal(false);
        //reload the page ---------------- I'M TIRED OF RESOLVING CONTINUOUS RENDERING ISSUE
        window.location.reload();
    }


    return (
        <>
            <SkyeModal title={productData.id ? "Offer" : "New Offer"} size="max-w-6xl" flex={false} showModal={showModal} setShowModal={setShowModal} onModalClosed={onModalClosed}>
                <form autoComplete="off" className="space-y-6" id="productData-edit-form" onSubmit={submitForm} method="POST" encType="multipart/form-data">
                    {/*body*/}
                    <div className="relative p-6 flex-auto text-left">
                        <div className="grid grid-cols-6 gap-6">

                            <div className="col-span-6 grid grid-cols-6 gap-6">
                                {/* PROPERTY SIDE 1 */}
                                <div className="col-span-6 lg:col-span-3">

                                    <div className="">
                                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                            Offer Name:
                                        </label>
                                        <input
                                            name='name'
                                            value={productData.name || ""}
                                            onChange={inputChange}
                                            required
                                            type="text" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                                            placeholder="Offer Name" />
                                    </div>

                                    <div className="my-4">
                                        <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                                            Descripton:
                                        </label>
                                        <textarea
                                            name='description'
                                            value={productData.description || ""}
                                            onChange={inputChange}
                                            rows={2}
                                            required
                                            id="description" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                                            placeholder="Description"></textarea>
                                    </div>

                                    {/* <div className="my-4">
                                        <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                                            Address:
                                        </label>
                                        <input
                                            name='address'
                                            value={productData.address || ""}
                                            onChange={inputChange}
                                            id="address" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                                            placeholder="Address"/>
                                    </div> */}

                                    <div className="my-4">
                                        <div className="grid grid-cols-2 gap-3">
                                            <div>
                                                <label htmlFor="regular_price" className="block text-sm font-medium text-gray-700">
                                                    Regular Price:
                                                </label>
                                                <div className="mt-1 relative rounded-md shadow-sm">
                                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                        <span className="text-gray-500 lg:text-sm font-bold"><Currency/></span>
                                                    </div>
                                                    <input
                                                        name="regular_price"
                                                        value={productData.regular_price || ""}
                                                        onChange={inputChange}
                                                        required
                                                        min={0}
                                                        type="number" id="regular_price" className="bg-gray-50 border p-2.5 pl-6 font-bold border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black" placeholder="0.00" />
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                                                    Sale Price:
                                                </label>
                                                <div className="mt-1 relative rounded-md shadow-sm">
                                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                        <span className="text-gray-500 lg:text-sm font-bold"><Currency/></span>
                                                    </div>
                                                    <input
                                                        name="price"
                                                        value={productData.price || ""}
                                                        onChange={inputChange}
                                                        required
                                                        min={0}
                                                        type="number" id="price" className="bg-gray-50 border p-2.5 pl-6 font-bold border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black" placeholder="0.00" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full mt-4">
                                        <label htmlFor="cycle" className="block text-sm font-medium text-gray-700">
                                            Farm Cycle
                                        </label>
                                        <input
                                            name='cycle'
                                            value={productData.cycle || ""}
                                            onChange={inputChange}
                                            type="text"
                                            placeholder="Cycle" id="cycle" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black" 
                                            />

                                        </div>
                                        <div className="w-full mt-4">
                                        <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                                            Location
                                        </label>
                                        <input
                                            name='location'
                                            value={productData.location || ""}
                                            onChange={inputChange}
                                            type="text"
                                            placeholder="Location" id="location" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black" 
                                            />

                                        </div>
                                        
                                    </div>

                                    {/* <div className="my-3">
                                        <label htmlFor="type" className="block text-sm font-medium text-gray-700">
                                            Offer Type:
                                        </label>
                                        <select
                                            name='type'
                                            value={productData.type || "simple"}
                                            onChange={inputChange}
                                            required
                                            id="type" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black">
                                            <option value="simple">Simple</option>
                                            <option value="variable">Variable</option>
                                        </select>
                                    </div> */}

 
                                </div>


                                {/* PROPERTY SIDE 2 */}
                                <div className="col-span-6 lg:col-span-3">
                                    <div className="shadow px-5 py-4 pt-2">
                                        <label htmlFor="name" className="block text-sm font-bold text-gray-700">
                                            Categories:
                                        </label>

                                        <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 items-center mt-2">
                                            {
                                                categoriesLoading && (<CircularLoader className={"w-full text-center col-span-2"} />)
                                            }
                                            {
                                                categories.map((category, index) => (
                                                    <div key={index} className="form-check form-check-inline inline-block">
                                                        <input
                                                            value={category}
                                                            checked={selectedCategories.some(selected => selected.id === category.id)}
                                                            onChange={(event) => handleCategoriesChange(category, event.target.checked)}
                                                            id={`category-${category.id}`}
                                                            className="form-check-input h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-black checked:border-black focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" />
                                                        <label className="text-sm form-check-label inline text-gray-800 cursor-pointer" htmlFor={`category-${category.id}`}>{category.name}</label>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>

                                    <div className="col-span-6 lg:col-span-3 mt-4 lg:mt-6">
                                        <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                                            Quantity:
                                        </label>
                                        <input
                                            name='quantity'
                                            value={productData.quantity || ""}
                                            onChange={inputChange}
                                            required
                                            min={0}
                                            type="text"
                                            placeholder="Quantity" id="quantity" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black" />

                                    </div>

                                    <div className="col-span-6 lg:col-span-3 mt-4 lg:mt-6">
                                        <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                                            ROI %:
                                        </label>
                                        <input
                                            name='roi'
                                            value={productData.roi || ""}
                                            onChange={inputChange}
                                            required
                                            min={0}
                                            max={100}
                                            // not can be double, float or decimal
                                            step="any"
                                            type="number"
                                            placeholder="ROI" id="roi" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black" />

                                    </div>

                                    <div className="col-span-6 lg:col-span-3 mt-4 lg:mt-4">
                                        <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                                            Status:
                                        </label>
                                        <select
                                            name='status'
                                            value={productData.status || ""}
                                            onChange={inputChange}
                                            required
                                            id="status" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black">
                                            <option value="publish">Publish</option>
                                            <option value="draft">Draft</option>
                                        </select>
                                    </div>

                                </div>

                                {/* divider */}
                                <div className="col-span-6">
                                    <hr className="my-1" />
                                </div>

                                <h1 className="col-span-6 text-lg">Offer images</h1>
                                {/* horizontal rows */}
                                <div className="col-span-6 flex overflow-x-scroll space-x-5">
                                    {/* images */}
                                    {
                                        productImages.map((productImage, index) => {
                                            let image = productImage.image;
                                            if (productImage.file) {
                                                image = URL.createObjectURL(productImage.file);
                                            }
                                            return (
                                                <div key={index} className="flex-shrink-0 w-[200px] h-[200px] relative">
                                                    <img src={image} alt="" className="w-full h-full object-cover rounded-md cursor-pointer" onClick={() => viewImage(index)} />
                                                    {/* delete button at right corner */}
                                                    <button onClick={() => deleteImage(index)} type="button" className="absolute top-1 right-1 w-8 h-8 bg-red-500 rounded-full flex items-center justify-center text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                                                        <span className="sr-only">Delete</span>
                                                        <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                            <path fillRule="evenodd" d="M5.293 5.293a1 1 0 011.414 0L10 8.586l3.293-3.293a1 1 0 111.414 1.414L11.414 10l3.293 3.293a1 1 0 01-1.414 1.414L10 11.414l-3.293 3.293a1 1 0 01-1.414-1.414L8.586 10 5.293 6.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                                        </svg>
                                                    </button>

                                                </div>
                                            );
                                        })
                                    }
                                    {/* picker */}
                                    <div className="flex-shrink-0 w-[200px] h-[200px]">
                                        <label
                                            className="flex justify-center w-full h-full px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                                            <span className="flex items-center space-x-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon w-12 h-12 icon-tabler icon-tabler-camera" width="48" height="48" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#597e8d" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M5 7h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2" />
                                                    <path d="M9 13a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                                                </svg>
                                            </span>
                                            {/* ony image files */}
                                            <input type="file" name="image_file" className="hidden" accept="image/*" onChange={(e: any) => imageChange(e)} />
                                        </label>
                                    </div>
                                </div>

                            </div>





                            {/* REVIEWS */}
                            {
                                (reviewsLoading && product.id) && (<CircularLoader className={"w-full text-center col-span-6"} />)
                            }
                            {
                                (reviews.length > 0) ?
                                    (
                                        <>
                                            {/* divider */}
                                            <div className="col-span-6">
                                                <hr className="my-5" />
                                            </div>


                                            <h1 className="col-span-6 text-xl">Reviews </h1>
                                            {/* horizontal rows */}
                                            <div className="col-span-6 flex overflow-x-auto space-x-5 pb-3">
                                                {
                                                    reviews.map((productReview, index) => (
                                                        <div key={productReview.id} className="flex-shrink-0 w-[300px] max-h-[120px] h-max">
                                                            <div className="flex-col justify-start items-start shadow p-2 rounded-md w-[300px] max-h-[120px] h-max overflow-ellipsis overflow-y-hidden mb-2">
                                                                <div className="bg-white justify-start items-center gap-2.5 inline-flex w-full">
                                                                    <div className="w-[42px] h-[42px] relative">
                                                                        <img className="w-[42px] h-[42px] left-0 top-0 absolute rounded-full" src={helpers.getFirstImage(productReview.user.avatar, "avatar", productReview.user.full_name)} alt="" />
                                                                    </div>
                                                                    <div className="flex-col justify-start items-start gap-2.5 inline-flex">
                                                                        <div className="text-gray-900 text-[16px] font-normal leading-none tracking-wide">{productReview.user.full_name}</div>
                                                                        <div className="justify-start items-start gap-2 inline-flex">
                                                                            <ReactStarsComponent
                                                                                count={5}
                                                                                value={productReview.rating}
                                                                                size={16}
                                                                                activeColor="#ffd700"
                                                                                edit={false}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <span className="text-zinc-500 text-xs font-normal leading-snug tracking-wide overflow-ellipsis overflow-y-ellipsis">
                                                                        {productReview.review}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </>
                                    ) : null
                            }

                        </div>

                    </div>
                    {/*footer*/}
                    {
                                    ([ 'admin', 'super_admin', 'production_manager', 'inventory_manager' ].includes(context.user.role)) && (
                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                        <button type="submit" className="w-full text-white bg-black hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-4 text-center dark:bg-blue-600 dark:hover:bg-gray-700 dark:focus:ring-black">
                            <span id="submit-text">{productData.id ? "UPDATE" : "SUBMIT"}</span>
                        </button>
                    </div>
                        )
                    }
                </form>
            </SkyeModal >

            {/* image modal */}
            {
                showImage ? (
                    <ModalImageComponent
                        medium={image}
                        small={image}
                        large={image}
                        alt={productData.name}
                        onClose={() => setShowImage(false)}
                    />
                ) : null
            } 

        </>
    );
}

export default ProductModal;