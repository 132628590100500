/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react/jsx-no-target-blank */
import useDocumentTitle from "../hooks/userDocumentTitle";
import MainTemplate from "./MainTemplate";

export default function FAQPage() {
  useDocumentTitle("FAQs");

  const faqItemClicked = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    //toggle content (siblings)
    let nextSibling = e.target.parentElement.nextElementSibling;
    //next sibling classes must contain toggle-content
    if (nextSibling.classList.contains("toggle-content")) {
      nextSibling.style.height = nextSibling.style.height === "0px" ? "auto" : "0px";
    }
  }

  return (
    <>
      <MainTemplate pageType="others">
        <>
          <div
            data-collapse="medium"
            data-animation="default"
            data-duration={400}
            data-easing="ease"
            data-easing2="ease"
            role="banner"
            className="scroll-navbar navbar-2 w-hidden-medium w-hidden-small w-hidden-tiny w-nav"
            style={{
              willChange: "transform",
              transform:
                "translate3d(0px, -102%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d",
            }}
          >
            <div className="container-nav w-container">
              <a href="/" className="brand w-nav-brand" aria-label="home">
                <img
                  src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e59a0c0a67bd6d170e34c37_FarmFundrLogo-01.png"
                  width={150}
                  alt="How to invest in farmland"
                />
              </a>
              <nav role="navigation" className="nav-menu w-nav-menu">
                <a
                  href="/property"
                  className="nav-link normal-nav black w-nav-link"
                  style={{ maxWidth: 940 }}
                >
                  Properties
                </a>
                <a
                  href="/category/blog"
                  className="nav-link normal-nav black w-nav-link"
                  style={{ maxWidth: 940 }}
                >
                  FARMLAND INVESTING BLOG
                </a>
                <a
                  href="/about-us"
                  className="nav-link normal-nav black w-nav-link"
                  style={{ maxWidth: 940 }}
                >
                  ABOUT US
                </a>
                <div
                  data-delay={0}
                  data-hover="false"
                  className="nav-link w-dropdown"
                  style={{ maxWidth: 940 }}
                >
                  <div
                    className="dropdown-toggle black w-dropdown-toggle"
                    id="w-dropdown-toggle-0"
                    aria-controls="w-dropdown-list-0"
                    aria-haspopup="menu"
                    aria-expanded="false"
                    role="button"
                    tabIndex={0}
                  >
                    <div className="text-block-22">resources</div>
                    <div
                      className="dropdown-arrow w-icon-dropdown-toggle"
                      aria-hidden="true"
                    />
                  </div>
                  <nav
                    className="dropdown-list big w-dropdown-list"
                    id="w-dropdown-list-0"
                    aria-labelledby="w-dropdown-toggle-0"
                  >
                    <a
                      href="/faqs"
                      aria-current="page"
                      className="dropdown-link w-dropdown-link w--current"
                      tabIndex={0}
                    >
                      FAQ
                    </a>
                    <a
                      href="/media"
                      className="dropdown-link w-dropdown-link"
                      tabIndex={0}
                    >
                      media
                    </a>
                    <a
                      href="/helping-farmers-grow"
                      className="dropdown-link w-dropdown-link"
                      tabIndex={0}
                    >
                      HELPING&nbsp;FARMERS&nbsp;GROW
                    </a>
                    <a
                      href="/sell-farmland"
                      className="dropdown-link w-dropdown-link"
                      tabIndex={0}
                    >
                      sell farmland
                    </a>
                    <a
                      href="/custom-farmland-investments"
                      className="dropdown-link w-dropdown-link"
                      tabIndex={0}
                    >
                      farmfindr - Custom Investments
                    </a>
                  </nav>
                </div>
                <a
                  href="/contact-us"
                  className="nav-link normal-nav black w-nav-link"
                  style={{ maxWidth: 940 }}
                >
                  Contact
                </a>
                <a
                  href="/portal/login"
                  className="nav-link normal-nav login scroll w-nav-link"
                  style={{ maxWidth: 940 }}
                >
                  Login
                </a>
                <a
                  href="/portal/register"
                  className="nav-link normal-nav sign-up w-nav-link"
                  style={{ maxWidth: 940 }}
                >
                  Sign Up
                </a>
              </nav>
              <div
                className="menu-button gray w-nav-button"
                style={{ WebkitUserSelect: "text" }}
                aria-label="menu"
                role="button"
                tabIndex={0}
                aria-controls="w-nav-overlay-0"
                aria-haspopup="menu"
                aria-expanded="false"
              >
                <div className="icon w-icon-nav-menu" />
              </div>
            </div>
            <div
              className="w-nav-overlay"
              data-wf-ignore=""
              id="w-nav-overlay-0"
            />
          </div>
          <div
            data-ix="slide-in-scroll-navigation"
            className="page-header toggle faq"
          >
            <div className="w-container">
              <div
                data-w-id="93336932-70a5-fce4-eb6b-a9647b04ded6"
                className="div-text"
              >
                <h1
                  data-w-id="ee32e2a8-22ac-5a01-0846-8c48edf4dcb9"
                  style={{
                    opacity: 1,
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    transformStyle: "preserve-3d",
                  }}
                  className="heading center white-font"
                >
                  Frequently Asked Questions
                </h1>
                <div
                  data-w-id="96cc8675-4f76-aae5-adc5-e642ababf913"
                  style={{
                    opacity: 1,
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    transformStyle: "preserve-3d",
                  }}
                  className="separated-div-wrapper"
                >
                  <div className="separator header" />
                  <div className="separator header center" />
                  <div className="separator header" />
                </div>
                <p
                  data-w-id="80ca1924-1499-582f-1f20-428ab3f33b3a"
                  style={{
                    opacity: 1,
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    transformStyle: "preserve-3d",
                  }}
                  className="paragraph center white"
                >
                  The opportunity to take part ownership in quality farmland is
                  exciting, but it does raise some questions for first time
                  agricultural investors. <br />
                  Here are the questions we receive most often. If you can't
                  find an answer, don't hesitate to{" "}
                  <a href="/contact-us" className="subtle-link">
                    contact us
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="section white">
            <div className="faq-container w-container">
              <div
                data-w-id="1c5f9c7d-6aad-ff9e-8c76-5df6894a280d"
                style={{
                  opacity: 1,
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  transformStyle: "preserve-3d",
                }}
                className="info-box second wide"
              >
                <p className="box-text">
                  Lorem ipsum dolor sit amet, consectetur adipising elit.
                  Suspendisse varius enim in eros elementum tristique. Duis
                  cursus, mi quis viverra ornare
                </p>
                <div
                  data-ix="show-toggle-content-on-click"
                  data-w-id="1c5f9c7d-6aad-ff9e-8c76-5df6894a2812"
                  className="toggle-wrapper box"
                >
                  <a
                    href="#tap"
                    onClick={faqItemClicked}
                    className="toggle-header w-inline-block w-clearfix"
                  >
                    <h2 className="heading-2">
                      HOW DO I VIEW AVAILABLE&nbsp;investment&nbsp;opportunities?
                    </h2>
                    <div className="toggle-icon" />
                  </a>
                  <div
                    data-ix="toggle-content-initial-appe"
                    style={{ width: 858, height: 0 }}
                    className="toggle-content"
                  >
                    <div className="toggle-space">
                      <p className="paragraph">
                        To get started,{" "}
                        <a href="/portal/register" className="text-primary-500">
                          Register Here
                        </a>
                        . Once your complimentary FarmFundr account is created,
                        you will gain access to our investment portal. Here, you
                        will see all available farmland crowdfunding
                        opportunities in full detail. Find something you like?
                        You can invest right through the portal as well.
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  data-ix="show-toggle-content-on-click"
                  data-w-id="1c5f9c7d-6aad-ff9e-8c76-5df6894a281b"
                  className="toggle-wrapper box"
                >
                  <a
                    href="#tap"
                    onClick={faqItemClicked}
                    className="toggle-header w-inline-block w-clearfix"
                  >
                    <h2 className="heading-2">
                      cAN&nbsp;ANYONE&nbsp;INVEST on Farmfundr?
                    </h2>
                    <div className="toggle-icon" />
                  </a>
                  <div
                    data-ix="toggle-content-initial-appe"
                    style={{ width: 858, height: 0 }}
                    className="toggle-content"
                  >
                    <div className="toggle-space">
                      <p className="paragraph">
                        At the moment, our offerings are available to accredited
                        investors only. However, we are working on creating
                        opportunities for non- accredited investors in the near
                        future. <br />
                        We suggest{" "}
                        <a
                          href="/portal/register"
                          target="_blank"
                          className="text-primary-500"
                        >
                          creating a FarmFundr account,
                        </a>{" "}
                        whatever your status, so that you will be kept up to
                        date as opportunities become available
                        <br />
                        <br />
                        To qualify as an <em>ACCREDITED&nbsp;INVESTOR</em>, you
                        must meet certain thresholds as defined by the
                        Securities and Exchange Commission under rule 501 of
                        Regulation D. <br />‍
                        <br />
                        You must meet{" "}
                        <strong>
                          <em>one</em>
                        </strong>{" "}
                        of the following criteria:
                        <br />
                      </p>
                      <ul
                        role="list"
                        className="content-list-item box w-list-unstyled"
                      >
                        <li className="content-item w-clearfix">
                          <img
                            src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e5717f541472a281958bee3_check.png"
                            alt=""
                            className="image-list-item without-paddings"
                          />
                          <p>
                            Have an annual income per individual of over
                            $200,000 per year (or $300,000 combined with spouse)
                            with the expectation of maintaining that
                            &nbsp;income in the future.
                          </p>
                        </li>
                        <li className="content-item w-clearfix">
                          <img
                            src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e5717f541472a281958bee3_check.png"
                            alt=""
                            className="image-list-item without-paddings"
                          />
                          <p>
                            Have a net worth of more than $1 million
                            (individually or jointly), excluding the value of a
                            primary residence..
                          </p>
                        </li>
                        <li className="content-item w-clearfix">
                          <img
                            src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e5717f541472a281958bee3_check.png"
                            alt=""
                            className="image-list-item without-paddings"
                          />
                          <p>
                            Be a bank, insurance company, registered investment
                            advisor, business development company, or small
                            business investment company.
                          </p>
                        </li>
                        <li className="content-item w-clearfix">
                          <img
                            src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e5717f541472a281958bee3_check.png"
                            alt=""
                            className="image-list-item without-paddings"
                          />
                          <p>
                            Be a general partner, executive officer, director or
                            a related combination thereof for the issuer of a
                            security being offered.
                          </p>
                        </li>
                        <li className="content-item w-clearfix">
                          <img
                            src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e5717f541472a281958bee3_check.png"
                            alt=""
                            className="image-list-item without-paddings"
                          />
                          <p>
                            Be a business in which all the equity owners are
                            accredited investors.
                          </p>
                        </li>
                        <li className="content-item w-clearfix">
                          <img
                            src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e5717f541472a281958bee3_check.png"
                            alt=""
                            className="image-list-item without-paddings"
                          />
                          <p>
                            Be an employee benefit plan, a trust, charitable
                            organization, partnership, or company with total
                            assets in excess of $5 million.
                          </p>
                        </li>
                      </ul>
                      <p className="paragraph left">
                        Please visit the SEC&nbsp;website for the full
                        qualifications for{" "}
                        <a
                          href="https://www.investor.gov/additional-resources/news-alerts/alerts-bulletins/updated-investor-bulletin-accredited-investors"
                          target="_blank"
                        >
                          accredited investors.
                        </a>
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  data-ix="show-toggle-content-on-click"
                  data-w-id="1c5f9c7d-6aad-ff9e-8c76-5df6894a2824"
                  className="toggle-wrapper box"
                />
                <div
                  data-ix="show-toggle-content-on-click"
                  data-w-id="03feb622-e61f-65d2-cfad-c0974f617361"
                  className="toggle-wrapper box"
                >
                  <a
                    href="#tap"
                    onClick={faqItemClicked}
                    className="toggle-header w-inline-block w-clearfix"
                  >
                    <h2 className="heading-2">
                      What kinds of projects will I find on your site?
                    </h2>
                    <div className="toggle-icon" />
                  </a>
                  <div
                    data-ix="toggle-content-initial-appe"
                    style={{ height: 0 }}
                    className="toggle-content"
                  >
                    <div className="toggle-space">
                      <p className="paragraph">
                        Right now, you can expect high quality, high profit
                        potential farmland offerings. In the future, we plan to
                        list a variety of projects involving agriculture, from
                        farmland to agricultural facilities. <br />
                        We recommend signing up for an account so that you can
                        be kept up to date as exciting projects are added. We
                        only list deals that we think offer the opportunity for
                        substantial returns and are good for our investors.{" "}
                        <br />
                        <br />
                        Because of our rigid requirements when selecting a
                        property to offer our investors, there may not always be
                        a live opportunity on the site. By creating a
                        complimentary FarmFundr account, you'll be among the
                        first to know when these opportunities do become
                        available.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  data-ix="show-toggle-content-on-click"
                  data-w-id="a15f2ea2-e3b2-811f-e201-cc5f44d643ae"
                  className="toggle-wrapper box"
                >
                  <a
                    href="#tap"
                    onClick={faqItemClicked}
                    className="toggle-header w-inline-block w-clearfix"
                  >
                    <h2 className="heading-2">
                      How do you select projects/properties?
                    </h2>
                    <div className="toggle-icon" />
                  </a>
                  <div
                    data-ix="toggle-content-initial-appe"
                    style={{ width: 858, height: 0 }}
                    className="toggle-content"
                  >
                    <div className="toggle-space">
                      <p className="paragraph">
                        Led by an experienced fourth-generation farmer, our team
                        is exceptionally diligent in selecting offerings for our
                        platform. We perform due diligence and careful analysis
                        of all offerings before offering them to our investors.
                        We do not list any deals that we would not invest in
                        ourselves, and in most cases, we do.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  data-ix="show-toggle-content-on-click"
                  data-w-id="268472e6-55cf-7734-7b2c-59b6abe74cdd"
                  className="toggle-wrapper box"
                >
                  <a
                    href="#tap"
                    onClick={faqItemClicked}
                    className="toggle-header w-inline-block w-clearfix"
                  >
                    <h2 className="heading-2">
                      Why do you believe it is smart to invest in agriculture?
                    </h2>
                    <div className="toggle-icon" />
                  </a>
                  <div
                    data-ix="toggle-content-initial-appe"
                    style={{ height: 0 }}
                    className="toggle-content"
                  >
                    <div className="toggle-space">
                      <p className="paragraph">
                        There are so many reasons! One of the top reasons for
                        our investors is that allocating some portion of your
                        portfolio to a direct investment in farmland may provide
                        you with a reasonably predictable and stable level of
                        residual income. You are also investing in the most
                        valuable commodity in the world – food! <br />
                        <a href="#">CLICK HERE</a> for more reasons why
                        investing in agriculture is a good idea.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  data-ix="show-toggle-content-on-click"
                  data-w-id="f1619f55-985c-cf16-2960-aa8e7ffd3f79"
                  className="toggle-wrapper box"
                >
                  <a
                    href="#tap"
                    onClick={faqItemClicked}
                    className="toggle-header w-inline-block w-clearfix"
                  >
                    <h2 className="heading-2">
                      What is the minimum amount I can invest?
                    </h2>
                    <div className="toggle-icon" />
                  </a>
                  <div
                    data-ix="toggle-content-initial-appe"
                    style={{ height: 0 }}
                    className="toggle-content"
                  >
                    <div className="toggle-space">
                      <p className="paragraph">
                        A minimum investment is stipulated for each project,
                        which will generally range from $10,000 to $100,000,
                        depending on the property.
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  data-ix="show-toggle-content-on-click"
                  data-w-id="3d649838-9064-3fc2-b49d-9c5f8ebc3d4c"
                  className="toggle-wrapper box"
                >
                  <a
                    href="#tap"
                    onClick={faqItemClicked}
                    className="toggle-header w-inline-block w-clearfix"
                  >
                    <h2 className="heading-2">
                      Are these investments considered to be risky?
                    </h2>
                    <div className="toggle-icon" />
                  </a>
                  <div
                    data-ix="toggle-content-initial-appe"
                    style={{ height: 0 }}
                    className="toggle-content"
                  >
                    <div className="toggle-space">
                      <p className="paragraph">
                        You should view these as risky investments,
                        &nbsp;riskier than an investment in a stock market index
                        fund, for example. The same is true for any{" "}
                        <em>direct investment</em> in real estate. You could
                        lose some or all of your money in any of these
                        investments.
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  data-ix="show-toggle-content-on-click"
                  data-w-id="d4172528-797d-5433-2202-63ca49ee1570"
                  className="toggle-wrapper box"
                >
                  <a
                    href="#tap"
                    onClick={faqItemClicked}
                    className="toggle-header w-inline-block w-clearfix"
                  >
                    <h2 className="heading-2">
                      If i invest in a project, do I become personally liable
                      for anything?
                    </h2>
                    <div className="toggle-icon" />
                  </a>
                  <div
                    data-ix="toggle-content-initial-appe"
                    style={{ height: 0 }}
                    className="toggle-content"
                  >
                    <div className="toggle-space">
                      <p className="paragraph">
                        No, not at all. You are personally liable only to make
                        your investment. We choose our legal structures to
                        protect investors from personal liability.
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  data-ix="show-toggle-content-on-click"
                  data-w-id="0a01da37-128d-89d3-37e6-752c5711b99d"
                  className="toggle-wrapper box"
                >
                  <a
                    href="#tap"
                    onClick={faqItemClicked}
                    className="toggle-header w-inline-block w-clearfix"
                  >
                    <h2 className="heading-2">
                      what is the investment process?
                    </h2>
                    <div className="toggle-icon" />
                  </a>
                  <div
                    data-ix="toggle-content-initial-appe"
                    style={{ height: 0 }}
                    className="toggle-content"
                  >
                    <div className="toggle-space">
                      <p className="paragraph">
                        After signing up for a FarmFundr Account, you'll gain
                        access to review our active projects. <br />
                        When you decide to invest, click “Invest” and you will
                        be taken through the process, step by step. <br />
                        In general, we'll have you go through the following
                        steps right in our easy to use portal:
                        <br />
                      </p>
                      <ol role="list" className="content-list-item box">
                        <li className="content-item">
                          <p>• Provide your contact information</p>
                        </li>
                        <li className="content-item">
                          <p>• Review the investment disclosure document</p>
                        </li>
                        <li className="content-item">
                          <p>
                            • Accreditation Verification. Upload documents to
                            prove you qualify.
                          </p>
                        </li>
                        <li className="content-item">
                          <p>• Sign the Investment Agreement</p>
                        </li>
                        <li className="content-item">
                          <p>
                            • Transfer funds (via wire, check, ACH or using your
                            IRA&nbsp;through RocketDollar)
                          </p>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div
                  data-ix="show-toggle-content-on-click"
                  data-w-id="2602cda1-617b-c428-325f-393ab66a4c51"
                  className="toggle-wrapper box"
                >
                  <a
                    href="#tap"
                    onClick={faqItemClicked}
                    className="toggle-header w-inline-block w-clearfix"
                  >
                    <h2 className="heading-2">
                      What is included in my investment
                    </h2>
                    <div className="toggle-icon" />
                  </a>
                  <div
                    data-ix="toggle-content-initial-appe"
                    style={{ height: 0 }}
                    className="toggle-content"
                  >
                    <div className="toggle-space">
                      <p className="paragraph">
                        An LLC is formed for each property listed on FarmFundr.
                        As an investor, you own a share in that LLC. <br />
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  data-ix="show-toggle-content-on-click"
                  data-w-id="6fd60806-e0a6-17dd-3f4b-26454b51787c"
                  className="toggle-wrapper box"
                >
                  <a
                    href="#tap"
                    onClick={faqItemClicked}
                    className="toggle-header w-inline-block w-clearfix"
                  >
                    <h2 className="heading-2">
                      HOW&nbsp;DO&nbsp;I&nbsp;MAKE&nbsp;MONEY?
                    </h2>
                    <div className="toggle-icon" />
                  </a>
                  <div
                    data-ix="toggle-content-initial-appe"
                    style={{ height: 0 }}
                    className="toggle-content"
                  >
                    <div className="toggle-space">
                      <p className="paragraph">
                        At FarmFundr, we do things a little differently so that
                        our investors can realize as much profit from their farm
                        operation as possible. While each offer is structured a
                        bit differently (see individual offerings for full
                        disclosures), generally our model is that you will
                        receive annual returns from the sales of your crop's
                        harvest in addition to the appreciation from ownership
                        of the land.
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  data-ix="show-toggle-content-on-click"
                  data-w-id="a7e3298c-afca-335e-b44f-b44fc4f99692"
                  className="toggle-wrapper box"
                >
                  <a
                    href="#tap"
                    onClick={faqItemClicked}
                    className="toggle-header w-inline-block w-clearfix"
                  >
                    <h2 className="heading-2">
                      How am I affected if farmfundr has business issues?
                    </h2>
                    <div className="toggle-icon" />
                  </a>
                  <div
                    data-ix="toggle-content-initial-appe"
                    style={{ height: 0 }}
                    className="toggle-content"
                  >
                    <div className="toggle-space">
                      <p className="paragraph">
                        Since your investment results in the ownership of a
                        share of an LLC setup for the farmland entity, you will
                        retain your legal ownership, regardless of any business
                        issues FarmFundr, LLC. may have. Our business model was
                        created with our investors's security and best interest
                        top of mind.
                        <br />
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  data-ix="show-toggle-content-on-click"
                  data-w-id="781e4546-7c74-7b6c-6c22-2fd978e0ca15"
                  className="toggle-wrapper box"
                >
                  <a
                    href="#tap"
                    onClick={faqItemClicked}
                    className="toggle-header w-inline-block w-clearfix"
                  >
                    <h2 className="heading-2">
                      What Type of tax form will I receive?
                    </h2>
                    <div className="toggle-icon" />
                  </a>
                  <div
                    data-ix="toggle-content-initial-appe"
                    style={{ height: 0 }}
                    className="toggle-content"
                  >
                    <div className="toggle-space">
                      <p className="paragraph">
                        You will receive a K-1 tax form. It will provide you
                        with detailed information on your share of the
                        partnership's taxable income. Partnerships are generally
                        not subject to federal or state income tax, but instead
                        issue a K-1 to each investor to report his or her share
                        of the partnership’s income, gains, losses, deductions
                        and credits. The K-1s are provided to investors on an
                        annual basis so that each investor can include K-1
                        amounts on his or her tax return.
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  data-ix="show-toggle-content-on-click"
                  data-w-id="0caf0bad-ea3e-a2ad-6924-fbf0f8238030"
                  className="toggle-wrapper box"
                >
                  <a
                    href="#tap"
                    onClick={faqItemClicked}
                    className="toggle-header w-inline-block w-clearfix"
                  >
                    <h2 className="heading-2">CAn I Invest with AN IRA?</h2>
                    <div className="toggle-icon" />
                  </a>
                  <div
                    data-ix="toggle-content-initial-appe"
                    style={{ width: 858, height: 0 }}
                    className="toggle-content"
                  >
                    <div className="toggle-space">
                      <p className="paragraph">
                        Through our investment portal, you can invest with a
                        your IRA&nbsp;with AltoIRA.
                        <br />
                        <br />
                        We are also able to help facilitate a transaction with
                        most IRA&nbsp;custodians. Simply{" "}
                        <a href="mailto:info@farmfundr.com?subject=IRA%20Investment%20Question">
                          contact us
                        </a>{" "}
                        to let us know who your custodian is and what amount
                        you'd like to invest and we'll explain the processes.
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="featured-blog-container w-container">
              <h1 className="heading gray center">
                FEATURED&nbsp;BLOG&nbsp;POSTS
              </h1>
              <div className="w-dyn-list">
                <div role="list" className="w-clearfix w-dyn-items w-row">
                  <div
                    role="listitem"
                    className="dynamic-item w-dyn-item w-col w-col-4"
                  >
                    <a
                      href="#"
                    //   href="/blog/useful-tips-for-becoming-a-successful-agriculture-investor"
                      className="featured-wrapper-3 w-inline-block"
                    >
                      <div className="div-block-5">
                        <div
                          style={{
                            backgroundImage:
                              'url("https://assets-global.website-files.com/5e5717f541472a2c2b58bd84/63efe7c6cb3df45d8d1573d4_Blog%20Image%20-%202022%20(1).png")',
                          }}
                          className="featured-image"
                        />
                      </div>
                      <div className="feafured-text">
                        <div className="featured-title">
                          Useful Tips for Becoming a Successful Agriculture
                          Investor
                        </div>
                        <div className="featured-description">
                          Investing in agriculture can provide long-term
                          profits, and with the right approach, you can become a
                          successful agriculture investor.{" "}
                        </div>
                        <div className="featured-details">
                          <div className="w-clearfix">
                            <img
                              alt=""
                              src=""
                              className="author-image w-dyn-bind-empty"
                            />
                            <div className="author lite w-dyn-bind-empty" />
                            <div className="thumbnail date">
                              February 6, 2023
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div
                    role="listitem"
                    className="dynamic-item w-dyn-item w-col w-col-4"
                  >
                    <a
                      href="#"
                    //   href="/blog/questions-you-should-ask-yourself-before-purchasing-farmland"
                      className="featured-wrapper-3 w-inline-block"
                    >
                      <div className="div-block-5">
                        <div
                          style={{
                            backgroundImage:
                              'url("https://assets-global.website-files.com/5e5717f541472a2c2b58bd84/63efd44026ac7b0d241a099d_Blog%20Image%20-%202022.png")',
                          }}
                          className="featured-image"
                        />
                      </div>
                      <div className="feafured-text">
                        <div className="featured-title">
                          Questions You Should Ask Yourself Before Purchasing
                          Farmland
                        </div>
                        <div className="featured-description">
                          The idea of owning farmland appeals to many investors,
                          however, those who lack experience in farmland will
                          want to ask themselves some key questions before
                          purchasing a farm.
                        </div>
                        <div className="featured-details">
                          <div className="w-clearfix">
                            <img
                              alt=""
                              src=""
                              className="author-image w-dyn-bind-empty"
                            />
                            <div className="author lite w-dyn-bind-empty" />
                            <div className="thumbnail date">
                              January 9, 2023
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div
                    role="listitem"
                    className="dynamic-item w-dyn-item w-col w-col-4"
                  >
                    <a
                        href="#"
                    //   href="/blog/why-invest-in-farmland-instead-of-stocks"
                      className="featured-wrapper-3 w-inline-block"
                    >
                      <div className="div-block-5">
                        <div
                          style={{
                            backgroundImage:
                              'url("https://assets-global.website-files.com/5e5717f541472a2c2b58bd84/632b6388f89abb5591f367ec_Blog%20Image%20-%202022%20(6).png")',
                          }}
                          className="featured-image"
                        />
                      </div>
                      <div className="feafured-text">
                        <div className="featured-title">
                          Why Invest in Farmland Instead of Stocks?
                        </div>
                        <div className="featured-description">
                          While many investors choose to focus their money on
                          the stock market, there’s much to be said for
                          considering farmland as a dependable way to build up
                          your portfolio.
                        </div>
                        <div className="featured-details">
                          <div className="w-clearfix">
                            <img
                              alt=""
                              src=""
                              className="author-image w-dyn-bind-empty"
                            />
                            <div className="author lite w-dyn-bind-empty" />
                            <div className="thumbnail date">
                              September 19, 2022
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer dark-bootom" />
          {/* <div id="gv5eb6p306m1710282997293" className="widget-hidden">
            <iframe
              src="about:blank"
              frameBorder={0}
              scrolling="no"
              width="64px"
              height="60px"
              style={{
                outline: "none !important",
                visibility: "visible !important",
                resize: "none !important",
                boxShadow: "none !important",
                overflow: "visible !important",
                background: "none !important",
                opacity: "1 !important",
                filter: "alpha(opacity=100) !important",
                msFilter:
                  "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
                MzOpacity: "1 !important",
                KhtmlOpacity: "1 !important",
                top: "auto !important",
                right: "auto !important",
                bottom: "20px !important",
                left: "20px !important",
                position: "fixed !important",
                border: "0 !important",
                minHeight: "60px !important",
                minWidth: "64px !important",
                maxHeight: "60px !important",
                maxWidth: "64px !important",
                padding: "0 !important",
                margin: "0 !important",
                MozTransitionProperty: "none !important",
                WebkitTransitionProperty: "none !important",
                OTransitionProperty: "none !important",
                transitionProperty: "none !important",
                transform: "none !important",
                WebkitTransform: "none !important",
                msTransform: "none !important",
                width: "64px !important",
                height: "60px !important",
                display: "block !important",
                zIndex: "1000001 !important",
                backgroundColor: "transparent !important",
                cursor: "none !important",
                float: "none !important",
                borderRadius: "unset !important",
                pointerEvents: "auto !important",
                clip: "auto !important",
                colorScheme: "light !important",
              }}
              id="xj5qqkvb2ae1710282997336"
              className=""
              title="chat widget"
            />
            <iframe
              src="about:blank"
              frameBorder={0}
              scrolling="no"
              width="350px"
              height="520px"
              style={{
                outline: "none !important",
                visibility: "visible !important",
                resize: "none !important",
                boxShadow: "none !important",
                overflow: "visible !important",
                background: "none !important",
                opacity: "1 !important",
                filter: "alpha(opacity=100) !important",
                msFilter:
                  "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
                MzOpacity: "1 !important",
                KhtmlOpacity: "1 !important",
                top: "auto !important",
                right: "auto !important",
                bottom: "90px !important",
                left: "10px !important",
                position: "fixed !important",
                border: "0 !important",
                minHeight: "520px !important",
                minWidth: "350px !important",
                maxHeight: "520px !important",
                maxWidth: "350px !important",
                padding: "0 !important",
                margin: "0 !important",
                MozTransitionProperty: "none !important",
                WebkitTransitionProperty: "none !important",
                OTransitionProperty: "none !important",
                transitionProperty: "none !important",
                transform: "none !important",
                WebkitTransform: "none !important",
                msTransform: "none !important",
                width: "350px !important",
                height: "520px !important",
                display: "none !important",
                zIndex: "auto !important",
                backgroundColor: "transparent !important",
                cursor: "none !important",
                float: "none !important",
                borderRadius: "5px !important",
                pointerEvents: "auto !important",
                clip: "auto !important",
                colorScheme: "light !important",
              }}
              id="lqdrkpqrsa1710282997451"
              className=""
              title="chat widget"
            />
            <iframe
              src="about:blank"
              frameBorder={0}
              scrolling="no"
              width="360px"
              height="145px"
              style={{
                outline: "none !important",
                visibility: "visible !important",
                resize: "none !important",
                boxShadow: "none !important",
                overflow: "visible !important",
                background: "none !important",
                opacity: "1 !important",
                filter: "alpha(opacity=100) !important",
                msFilter:
                  "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
                MzOpacity: "1 !important",
                KhtmlOpacity: "1 !important",
                top: "auto !important",
                right: "auto !important",
                bottom: 100,
                left: "20px !important",
                position: "fixed !important",
                border: "0 !important",
                minHeight: "145px !important",
                minWidth: "360px !important",
                maxHeight: "145px !important",
                maxWidth: "360px !important",
                padding: "0 !important",
                margin: "0 !important",
                MozTransitionProperty: "none !important",
                WebkitTransitionProperty: "none !important",
                OTransitionProperty: "none !important",
                transitionProperty: "none !important",
                transform: "none !important",
                WebkitTransform: "none !important",
                msTransform: "none !important",
                width: "360px !important",
                height: "145px !important",
                display: "none !important",
                zIndex: "auto !important",
                backgroundColor: "transparent !important",
                cursor: "none !important",
                float: "none !important",
                borderRadius: "unset !important",
                pointerEvents: "auto !important",
                clip: "auto !important",
                colorScheme: "light !important",
              }}
              id="wnqhcmpo93k1710282997439"
              className=""
              title="chat widget"
            />
            <iframe
              src="about:blank"
              frameBorder={0}
              scrolling="no"
              width="146px"
              height="85px"
              style={{
                outline: "none !important",
                visibility: "visible !important",
                resize: "none !important",
                boxShadow: "none !important",
                overflow: "visible !important",
                background: "none !important",
                opacity: "1 !important",
                filter: "alpha(opacity=100) !important",
                msFilter:
                  "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
                MzOpacity: "1 !important",
                KhtmlOpacity: "1 !important",
                top: "auto !important",
                right: "auto !important",
                bottom: "82px !important",
                left: "20px !important",
                position: "fixed !important",
                border: "0 !important",
                minHeight: "85px !important",
                minWidth: "146px !important",
                maxHeight: "85px !important",
                maxWidth: "146px !important",
                padding: "0 !important",
                margin: "0px 0 0 0 !important",
                MozTransitionProperty: "none !important",
                WebkitTransitionProperty: "none !important",
                OTransitionProperty: "none !important",
                transitionProperty: "none !important",
                transform: "rotate(0deg) translateZ(0)",
                WebkitTransform: "rotate(0deg) translateZ(0)",
                msTransform: "rotate(0deg) translateZ(0)",
                width: "146px !important",
                height: "85px !important",
                display: "none !important",
                zIndex: "1000002 !important",
                backgroundColor: "transparent !important",
                cursor: "none !important",
                float: "none !important",
                borderRadius: "unset !important",
                pointerEvents: "auto !important",
                clip: "auto !important",
                colorScheme: "light !important",
                MozTransform: "rotate(0deg) translateZ(0)",
                OTransform: "rotate(0deg) translateZ(0)",
                transformOrigin: 0,
                MozTransformOrigin: 0,
                WebkitTransformOrigin: 0,
                OTransformOrigin: 0,
                msTransformOrigin: 0,
              }}
              id="espo3jeqbf91710282997354"
              className=""
              title="chat widget"
            />
          </div> */}
        </>
      </MainTemplate>
    </>
   
  );
}
