/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react/jsx-no-target-blank */
import useDocumentTitle from "../hooks/userDocumentTitle";
import {
  addDoc,
  collection,
  // deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  // getDocs,
  // orderBy,
  // query,
  serverTimestamp,
  where,
  // updateDoc,
  // where,
} from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "..";
import MainTemplate from "./MainTemplate";
import AppContext from "../AppContext";
import { toast } from "react-toastify";
import { helpers } from "../utilities/helpers";
import PaymentMethodForm from "./portal/account_tabs/PaymentMethodForm";
// import { CircularProgress } from "@mui/material";
import { Button, Textarea } from "@material-tailwind/react";
// import { Delete, Edit } from "@mui/icons-material";
import Currency from "../components/Currency";

export default function OfferPaymentPage() {
  useDocumentTitle("Payment");

  const navigate = useNavigate();

  //get id from url
  const { id } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const quantity = parseInt(queryParams.get("quantity") || "1");

  const [product, setProduct] = useState({} as any);
  const [productLoading, setProductLoading] = useState(true);
  const [error, setError] = useState(null as any);

  const [offerDataForm, setOfferDataForm] = useState({} as any);
  const onInputChanged = (e: any) => {
    setOfferDataForm({ ...offerDataForm, [e.target.name]: e.target.value });
  };

  //fetch product from firebase
  const fetchProduct = async () => {
    setProductLoading(true);
    try {
      // fetch product from firebase
      const productRef = doc(db, "products", id!);
      const docSnap = await getDoc(productRef);
      if (docSnap.exists()) {
        setProduct({ ...docSnap.data(), id: docSnap.id });
      }
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      setProductLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchProduct();
    } else {
      setError("Product not found");
      setProductLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const context: any = useContext(AppContext);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [methods, setMethods] = useState([] as any[]);
  // const [methodsLoading, setMethodsLoading] = useState(true);

  const [methodForm, setMethodForm] = useState({} as any);
  const [showForm, setShowForm] = useState(true);
  const [showPreview, setShowPreview] = useState(false);

  // const fetchMethods = async () => {
  //   setMethodsLoading(true);
  //   let methods: any[] = [];
  //   //fetch methods from firebase
  //   const methodsRef = collection(db, "payment_methods");

  //   var queries: any[] = [where("user_id", "==", context.user.id)];

  //   queries.push(orderBy("created_at", "desc"));

  //   const q = query(methodsRef, ...queries);

  //   await getDocs(q).then((snapshot) => {
  //     snapshot.forEach((doc) => {
  //       methods.push({ ...doc.data(), id: doc.id });
  //     });
  //   });
  //   setShowForm(methods.length == 0);
  //   setMethods(methods);
  //   setMethodsLoading(false);
  // };

  // useEffect(() => {
  //   if (context.user) {
  //     fetchMethods();
  //   } else {
  //     setMethods([]);
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [context.user]);

  const onFormSubmit = async () => {
    if (
      methodForm.payment_method == null ||
      methodForm.payment_method == "" ||
      methodForm.payment_method == undefined ||
      !methodForm.payment_method
    ) {
      toast.error("Payment method is required");
      return;
    }

    setShowForm(false);
    setShowPreview(true);

    // const loading = toast.loading("Loading...");

    // try {
    //   const user: any = context.user;
    //   //every possible search item
    //   const searchItems = [user.first_name + " " + user.last_name];

    //   var data: any = null;

    //   if (methodForm.id) {
    //     //update payment method

    //     data = {
    //       ...methodForm,
    //       search_keywords: helpers.generateSearchKeywords(
    //         searchItems.map((s) => s.toLowerCase()).join(" ")
    //       ),
    //       updated_at: serverTimestamp(),
    //     };

    //     const ref = doc(db, "payment_methods", methodForm.id);

    //     await updateDoc(ref, data);

    //     toast.success("Payment method updated successfully");

    //     //close form
    //     setShowForm(false);
    //     fetchMethods();
    //   } else {
    //     //create payment method
    //     const docNumber = await helpers.getNextDocumentNumber(
    //       "payment_methods"
    //     );

    //     data = {
    //       ...methodForm,
    //       doc_number: docNumber,
    //       user_id: user.id || null,
    //       user: user,
    //       search_keywords: helpers.generateSearchKeywords(
    //         searchItems.map((s) => s.toLowerCase()).join(" ")
    //       ),
    //       updated_at: serverTimestamp(),
    //       created_at: serverTimestamp(),
    //     };

    //     console.log(data);

    //     await addDoc(collection(db, "payment_methods"), data);

    //     toast.success("Payment method added successfully");

    //     //close form
    //     setShowForm(false);
    //     fetchMethods();
    //   }
    // } catch (error: any) {
    //   toast.error(error.message || "An error occurred");
    //   return;
    // } finally {
    //   toast.dismiss(loading);
    // }
  };

  const onFormCancel = () => {
    setShowForm(false);
    setMethodForm({});
  };

  // const onDelete = async (id: string) => {
  //   const confirm = window.confirm(
  //     "Are you sure you want to delete this payment method?"
  //   );
  //   if (!confirm) {
  //     return;
  //   }

  //   const loading = toast.loading("Loading...");
  //   try {
  //     const ref = doc(db, "payment_methods", id);

  //     await deleteDoc(ref);

  //     toast.success("Payment method deleted successfully");
  //     // fetchMethods();
  //   } catch (error: any) {
  //     toast.error(error.message || "An error occurred");
  //     return;
  //   } finally {
  //     toast.dismiss(loading);
  //   }
  // };

  const placeOrder = async () => {
    const loading = toast.loading("Placing order...");

    try {
      const user: any = context.user;

      //every possible search item
      const searchItems = [user.first_name + " " + user.last_name];

      try {
        // if payment method not exists save it
        var paymentMethods;
        if (methodForm.payment_method == "bank") {
          // where account_number and routing number
          // const q = query(
          //   collection(db, "payment_methods"),
          //   where("account_number", "==", methodForm.account_number),
          //   where("routing_number", "==", methodForm.routing_number)
          // );
          // paymentMethods = await getDocs(q);
        } else {
          // where card number
          const q = query(
            collection(db, "payment_methods"),
            where("card_number", "==", methodForm.card_number)
          );
          paymentMethods = await getDocs(q);
        }

        if (paymentMethods?.size == 0) {
          //create payment method
          const docNumber = await helpers.getNextDocumentNumber(
            "payment_methods"
          );

          var data: any = {
            ...methodForm,
            doc_number: docNumber,
            user_id: user.id || null,
            user: user,
            search_keywords: helpers.generateSearchKeywords(
              searchItems.map((s) => s.toLowerCase()).join(" ")
            ),
            updated_at: serverTimestamp(),
            created_at: serverTimestamp(),
          };

          await addDoc(collection(db, "payment_methods"), data);
        }
      } catch (error: any) {
        console.log(error);
        // do nothing
      }

      //create order
      const orderDocNumber = await helpers.getNextDocumentNumber("orders");

      var order: any = {
        doc_number: orderDocNumber,
        type: "offer",
        product_id: product.id || null,
        product: {
          ...product,
          quantity: quantity,
          message: offerDataForm.message || null,
        },
        message: offerDataForm.message || null,
        total: (product.price || 0) * quantity,
        user_id: user.id || null,
        user: user,
        status: "pending",
        payment_status: "pending",
        search_keywords: helpers.generateSearchKeywords(
          searchItems.map((s) => s.toLowerCase()).join(" ")
        ),
        payment: {
          ...methodForm,
        },
        updated_at: serverTimestamp(),
        created_at: serverTimestamp(),
      };
      await addDoc(collection(db, "orders"), order);
    } catch (error: any) {
      toast.error(error.message || "An error occurred");
      toast.dismiss(loading);
      return;
    }

    toast.dismiss(loading);

    toast.success("Order placed successfully");

    navigate("/portal/portfolio");
  };

  return (
    <>
      <MainTemplate pageType="others">
        <>
          <div className="w-container" />

          {/* top nav bar background cover */}
          <div className="!bg-gray-900 py-10 md:!py-12"></div>

          {(() => {
            if (productLoading) {
              return (
                <section className="py-8 bg-white md:py-16 dark:bg-gray-900 antialiased">
                  <div className="max-w-screen-xl px-4 mx-auto 2xl:px-0">
                    <div className="flex items-center justify-center h-96">
                      <svg
                        className="w-8 h-8 text-primary-600 animate-spin"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx={12}
                          cy={12}
                          r={10}
                          stroke="currentColor"
                          strokeWidth={4}
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V2.5"
                        />
                      </svg>
                    </div>
                  </div>
                </section>
              );
            } else if (error) {
              return (
                <section className="py-8 bg-white md:py-16 dark:bg-gray-900 antialiased">
                  <div className="max-w-screen-xl px-4 mx-auto 2xl:px-0">
                    <div className="flex items-center justify-center h-96">
                      <p className="text-gray-500 text-xl dark:text-gray-400">
                        {typeof error === "object"
                          ? error?.message || "An error occurred"
                          : error || "An error occurred"}
                      </p>
                    </div>
                  </div>
                </section>
              );
            } else if (!product || !product.id) {
              return (
                <section className="py-8 bg-white md:py-16 dark:bg-gray-900 antialiased">
                  <div className="max-w-screen-xl px-4 mx-auto 2xl:px-0">
                    <div className="flex items-center justify-center h-96">
                      <p className="text-gray-500 text-xl dark:text-gray-400">
                        Investment not found
                      </p>
                    </div>
                  </div>
                </section>
              );
            }

            return (
              <>
              {/* back button nav */}
              <div className="max-w-screen-xl px-4 py-2 mx-auto 2xl:px-0">
                <div className="flex items-center justify-start">
                  <button
                    onClick={() => {
                      if (showPreview) {
                        setShowForm(true);
                        setShowPreview(false);
                        return;
                      }
                      navigate(-1)
                    }}
                    className="flex items-center gap-x-2 text-black bg-white border border-gray-200 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-100 px-3 py-2 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M10 19l-7-7m0 0l7-7m-7 7h18"
                      />
                    </svg>
                    <span>Back</span>
                  </button>
                </div>
              </div>
              
                <section className="py-8 bg-white md:py-16 dark:bg-gray-900 antialiased">
                  {/* PRODUCT PAYING FOR */}
                  <div className="max-w-screen-xl px-4 mx-auto 2xl:px-0">
                    <div className="flex flex-col items-center justify-center gap-y-2 text-center">
                      <h1 className="text-2xl font-semibold text-gray-800 dark:text-gray-100">
                        <span className="text-primary-500">
                          "{product.name}"
                        </span>
                      </h1>
                      <h1 className="text-2xl my-0 font-semibold text-gray-800 dark:text-gray-100">
                        <Currency />
                        <span className="text-black">
                          {helpers.money(product.price * quantity)}
                        </span>
                      </h1>
                      <p className="text-gray-500 dark:text-gray-400">
                        Pay for this investment
                      </p>
                    </div>
                  </div>
                  {/* END OF PRODUCT PAYING FOR */}

                  {showForm ? (
                    <PaymentMethodForm
                      onFormSubmit={onFormSubmit}
                      form={methodForm}
                      setForm={setMethodForm}
                      onCancel={onFormCancel}
                      showCancelBtn={methods.length > 0}
                      submitBtnText="Continue"
                      showBank={true}
                      showCard={false}
                    />
                  ) : (
                    <>
                      {(() => {
                        if (showPreview) {
                          return (
                            <>
                              {/*  payment method form preview */}
                              {methodForm.payment_method == "bank" ? (
                                <div className="flex flex-col items-center justify-center gap-y-2 text-start border p-4 mx-4 sm:mx-20 md:mx-36 lg:mx-56">
                                  {/* instruction */}
                                  <div className="flex flex-row items-center justify-center px-5 w-full">
                                    <div className="text-gray-800 text-center">
                                      Please transfer the total amount of{" "}
                                      <b>
                                        <Currency />
                                      {helpers.money(product.price * quantity)}{" "}
                                      </b>
                                      to the account below
                                    </div>
                                  </div>
                                  {/* payment method */}
                                  <div className="flex flex-row items-center justify-between gap-x-2 w-full">
                                    <div className="text-gray-500">
                                      Payment Method:
                                    </div>
                                    <div className="text-gray-800">
                                      {helpers.capitalizeEachWord(
                                        methodForm.payment_method?.replace(
                                          "_",
                                          " "
                                        )
                                      )}
                                    </div>
                                  </div>
                                  {/* holder name */}
                                  <div className="flex flex-row items-center justify-between gap-x-2 w-full">
                                    <div className="text-gray-500">Name:</div>
                                    <div className="text-gray-800">
                                      {/* {methodForm.holder_name || methodForm.account_name} */}
                                      SHAWN A GLENNEY
                                    </div>
                                  </div>
                                  {/* card number / account number */}
                                  <div className="flex flex-row items-center justify-between gap-x-2 w-full">
                                    <div className="text-gray-500">
                                      {methodForm.payment_method == "bank"
                                        ? "Account Number:"
                                        : "Card Number:"}
                                    </div>
                                    <div className="text-gray-800">
                                      {/* {methodForm.card_number || methodForm.account_number} */}
                                      7178807207
                                    </div>
                                  </div>
                                  {/* routing number */}
                                  {methodForm.payment_method == "bank" && (
                                    <div className="flex flex-row items-center justify-between gap-x-2 w-full">
                                      <div className="text-gray-500">
                                        Routing Number:
                                      </div>
                                      <div className="text-gray-800">
                                        {/* {methodForm.routing_number} */}
                                        256074974
                                      </div>
                                    </div>
                                  )}
                                  {/* bank name */}
                                  {methodForm.payment_method == "bank" && (
                                    <div className="flex flex-row items-center justify-between gap-x-2 w-full">
                                      <div className="text-gray-500">
                                        Bank Name:
                                      </div>
                                      <div className="text-gray-800">
                                        {/* {methodForm.bank_name} */}
                                        Navy Federal Credit Union
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div className="flex flex-col items-center justify-center gap-y-2 text-start border p-4 mx-4 sm:mx-20 md:mx-36 lg:mx-56">
                                  {/* payment method */}
                                  <div className="flex flex-row items-center justify-between gap-x-2 w-full">
                                    <div className="text-gray-500">
                                      Payment Method:
                                    </div>
                                    <div className="text-gray-800">
                                      {helpers.capitalizeEachWord(
                                        methodForm.payment_method?.replace(
                                          "_",
                                          " "
                                        )
                                      )}
                                    </div>
                                  </div>
                                  {/* holder name */}
                                  <div className="flex flex-row items-center justify-between gap-x-2 w-full">
                                    <div className="text-gray-500">Name:</div>
                                    <div className="text-gray-800">
                                      {methodForm.holder_name ||
                                        methodForm.account_name}
                                    </div>
                                  </div>
                                  {/* card number / account number */}
                                  <div className="flex flex-row items-center justify-between gap-x-2 w-full">
                                    <div className="text-gray-500">
                                      {methodForm.payment_method == "bank"
                                        ? "Account Number:"
                                        : "Card Number:"}
                                    </div>
                                    <div className="text-gray-800">
                                      {methodForm.card_number ||
                                        methodForm.account_number}
                                    </div>
                                  </div>
                                  {/* email */}
                                  <div className="flex flex-row items-center justify-between gap-x-2 w-full">
                                    <div className="text-gray-500">Email:</div>
                                    <div className="text-gray-800">
                                      {methodForm.email}
                                    </div>
                                  </div>
                                  {/* routing number */}
                                  {methodForm.payment_method == "bank" && (
                                    <div className="flex flex-row items-center justify-between gap-x-2 w-full">
                                      <div className="text-gray-500">
                                        Routing Number:
                                      </div>
                                      <div className="text-gray-800">
                                        {methodForm.routing_number}
                                      </div>
                                    </div>
                                  )}
                                  {/* bank name */}
                                  {methodForm.payment_method == "bank" && (
                                    <div className="flex flex-row items-center justify-between gap-x-2 w-full">
                                      <div className="text-gray-500">
                                        Bank Name:
                                      </div>
                                      <div className="text-gray-800">
                                        {methodForm.bank_name}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}

                              {/* add note */}
                              <div className="flex flex-col items-center justify-center gap-y-4 text-center mt-4 mx-4 sm:mx-20 md:mx-36 lg:mx-56">
                                <Textarea
                                  name="message"
                                  value={offerDataForm.message || ""}
                                  onChange={onInputChanged}
                                  id="message"
                                  className="bg-gray-50 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                  label="Add a note (optional)"
                                  required
                                />
                                {/* submit button */}
                                <Button
                                  onClick={placeOrder}
                                  placeholder={""}
                                  size={"lg"}
                                  className="!text-white !bg-primary-500 !w-full"
                                  color="green"
                                >
                                  PLACE ORDER
                                </Button>
                              </div>
                            </>
                          );
                        }
                        // else if (methodsLoading) {
                        //   return (
                        //     <div className="flex flex-row items-center justify-center">
                        //       <CircularProgress className="!text-center" />
                        //     </div>
                        //   );
                        // } else if (methods.length == 0) {
                        //   return (
                        //     <div className="flex flex-col items-center justify-center gap-y-6 mt-5 text-center">
                        //       <div className="text-gray-800 text-xl">
                        //         There are no payment methods
                        //       </div>
                        //       <Button
                        //         onClick={() => {
                        //           setMethodForm({});
                        //           setShowForm(true);
                        //         }}
                        //         placeholder={""}
                        //         className="ml-4 !text-white !bg-primary-500 py-3"
                        //         color="green"
                        //       >
                        //         ADD NEW PAYMENT METHOD
                        //       </Button>
                        //     </div>
                        //   );
                        // } else {
                        //   return (
                        //     <>
                        //       <div className="relative overflow-x-auto shadow-md sm:rounded-lg bg-white p-2 md:p-3">
                        //         <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        //           <thead className="text-xs text-gray-700 bg-gray-50">
                        //             <tr>
                        //               <th scope="col" className="px-6 py-3">
                        //                 Method
                        //               </th>
                        //               <th scope="col" className="px-6 py-3">
                        //                 Name
                        //               </th>
                        //               <th scope="col" className="px-6 py-3">
                        //                 Number
                        //               </th>
                        //               <th scope="col" className="px-6 py-3">
                        //                 Expiry
                        //               </th>
                        //               <th scope="col" className="px-6 py-3">
                        //                 Email
                        //               </th>
                        //               <th scope="col" className="px-6 py-3">
                        //                 Details / Edit
                        //               </th>
                        //             </tr>
                        //           </thead>
                        //           <tbody className="text-gray-900">
                        //             {methods.map((method: any) => (
                        //               <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        //                 <td className="px-6 py-4">
                        //                   {helpers.capitalizeEachWord(
                        //                     method.payment_method?.replace(
                        //                       "_",
                        //                       " "
                        //                     )
                        //                   )}
                        //                 </td>
                        //                 <td className="px-6 py-4">
                        //                   {method.holder_name ||
                        //                     method.account_name}
                        //                 </td>
                        //                 <td className="px-6 py-4">
                        //                   {method.card_number ||
                        //                     method.account_number}
                        //                 </td>
                        //                 <td className="px-6 py-4">
                        //                   {method.expires}
                        //                 </td>
                        //                 <td className="px-6 py-4">
                        //                   {method.email}
                        //                 </td>
                        //                 <td className="flex items-center px-6 py-4 gap-x-3">
                        //                   <Edit
                        //                     onClick={() => {
                        //                       setMethodForm(method);
                        //                       setShowForm(true);
                        //                     }}
                        //                     className="text-gray-500 hover:text-gray-700 cursor-pointer"
                        //                   />
                        //                   <Delete
                        //                     onClick={() => onDelete(method.id)}
                        //                     className="text-red-500 hover:text-gray-700 cursor-pointer"
                        //                   />
                        //                 </td>
                        //               </tr>
                        //             ))}
                        //           </tbody>
                        //         </table>
                        //       </div>

                        //       <div className="flex flex-col items-center justify-center gap-y-6 mt-5 text-center">
                        //         <Button
                        //           onClick={() => {
                        //             setMethodForm({});
                        //             setShowForm(true);
                        //           }}
                        //           placeholder={""}
                        //           className="ml-4 !text-white !bg-primary-500 py-3"
                        //           color="green"
                        //         >
                        //           ADD NEW PAYMENT METHOD
                        //         </Button>
                        //       </div>
                        //     </>
                        //   );
                        // }
                      })()}
                    </>
                  )}
                </section>
              </>
            );
          })()}
        </>
      </MainTemplate>
    </>
  );
}
