
import SkyeModal from "../../../admin/components/SkyeModal";

type Props = {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
};
const PortalWithdrawModal = ({ showModal, setShowModal }: Props) => {

  const onFormSubmit = async (e: any) => {
    e.preventDefault();
  };

  return (
    <>
      <SkyeModal
        title={"Withdraw"}
        size="max-w-2xl mt-20"
        flex={false}
        showModal={showModal}
        setShowModal={() => {
          setShowModal(false);
        }}
      >
        <form
          className="space-y-6 text-left"
          id="order-edit-form"
          onSubmit={onFormSubmit}
          method="POST"
          encType="multipart/form-data"
        >
          {/*body*/}
          <div className="relative p-6 flex-auto">
            {/* body */}

            {/* sorry message */}
            <div className="text-center w-full">
              <p className="text-lg text-slate-500 w-full text-center">
              Your withdrawal will be available once your account reflects a positive return on investment (ROI).
              </p>
            </div>
          </div>
          {/*footer*/}
          {/* <div className="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  type="submit"
                  className="bg-black hover:bg-black text-white font-semibold py-2 px-20 text-xl rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2"
                >
                  SUBMIT
                </button>
          </div> */}
        </form>
      </SkyeModal>
    </>
  );
};

export default PortalWithdrawModal;
