import React from 'react';
import './App.css';
import AppContext from './AppContext';
import useAuth from './hooks/useAuth';
import { SmartLoadingDialog, SmartLoadingDialogContext, useSmartLoadingDialog } from './components/SmartLoadingDialog';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ProtectedLayout from './ProtectedLayout';
import NoPage from './pages/NoPage';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from 'react-toastify';
import RegisterPage from './pages/portal/RegisterPage';
import LoginPage from './pages/portal/LoginPage';
import ForgotPasswordPage from './pages/portal/ForgotPasswordPage';
import InvestmentsPage from './pages/InvestmentsPage';
import CustomFarmlandInvestments from './pages/CustomFarmlandInvestments';
import BlogPage from './pages/BlogPage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import FAQPage from './pages/FAQPage';
import MediaPage from './pages/MediaPage';
import HelpingFarmersGrowPage from './pages/HelpingFarmersGrowPage';
import SellFarmlandPage from './pages/SellFarmlandPage';
import SetupPage from './pages/portal/SetupPage';
import OfferingsPage from './pages/portal/OfferingsPage';
import AdminProtectedLayout from './pages/admin/AdminProtectedLayout';
import Home from './pages/admin/pages/Home';
import Products from './pages/admin/pages/Products';
import Types from './pages/admin/pages/Types';
import Categories from './pages/admin/pages/Categories';
import Amenities from './pages/admin/pages/Amenities';
import Attributes from './pages/admin/pages/Attributes';
import BackOrders from './pages/admin/pages/BackOrders';
import Orders from './pages/admin/pages/Orders';
import Posts from './pages/admin/pages/Posts';
import Users from './pages/admin/pages/Users';
import Clients from './pages/admin/pages/Clients';
import Settings from './pages/admin/pages/Settings';
import PushNotifications from './pages/admin/pages/PushNotifications';
import Profile from './pages/admin/pages/Profile';
import PaymentsPage from './pages/admin/pages/Payments';
import Login from './pages/admin/pages/Login';
import DocumentsPage from './pages/portal/DocumentsPage';
import AccountPage from './pages/portal/AccountPage';
import PortfolioPage from './pages/portal/PortfolioPage';
import ChangePasswordPage from './pages/portal/ChangePassword';
import OfferPage from './pages/OfferPage';
import OfferPaymentPage from './pages/OfferPaymentPage';

function App() {
  const { user, token, login, logout, loading, tokenLogin, tokenLogout } = useAuth();
  const {show, dismiss, loading: smartDialogLoading} = useSmartLoadingDialog();


   return (
    <AppContext.Provider value={{ user, token, login, logout, loading, tokenLogin, tokenLogout }}>
      <SmartLoadingDialogContext.Provider value={{show, dismiss, loading: smartDialogLoading}}>
      <BrowserRouter>
        <Routes>
          {/* AUTHENTICATION */}
          <Route index element={<HomePage />} />
          <Route path="/portal/login" element={<LoginPage />} />
          <Route path="/portal/register" element={<RegisterPage />} />
          <Route path="/portal/forgot-password" element={<ForgotPasswordPage />} />

          {/* ADMIN AUTHENTICATION */}
          <Route path="/admin/login" element={<Login />} />

          {/* GENERAL PAGES */}
          <Route path="/property" element={<InvestmentsPage />} />
          <Route path="/property" element={<InvestmentsPage />} />
          <Route path="/custom-farmland-investments" element={<CustomFarmlandInvestments />} />
          <Route path="/category/blog" element={<BlogPage />} />
          <Route path="/about-us" element={<AboutPage />} />
          <Route path="/contact-us" element={<ContactPage />} />
          <Route path="/faqs" element={<FAQPage />} />
          <Route path="/media" element={<MediaPage />} />
          <Route path="/helping-farmers-grow" element={<HelpingFarmersGrowPage />} />
          <Route path="/sell-farmland" element={<SellFarmlandPage />} />
          <Route path="/offering">
            <Route path=":id" element={<OfferPage />} />
          </Route>
          <Route path="/offer-pay">
            <Route path=":id" element={<OfferPaymentPage />} />
          </Route>

          {/* PROTECTED PORTAL */}
          <Route path="/portal" element={<ProtectedLayout />}>
            <Route index element={<OfferingsPage />} />
            <Route path="home" element={<OfferingsPage />} />
            <Route path="setup" element={<SetupPage />} />
            <Route path="offerings" element={<OfferingsPage />} />
            <Route path="portfolio" element={<PortfolioPage />} />
            <Route path="account" element={<AccountPage />} />
            <Route path="documents" element={<DocumentsPage />} />
            <Route path="change-password" element={<ChangePasswordPage />} />
          </Route>

          {/* PROTECTED ADMIN */}
          <Route path="/admin" element={<AdminProtectedLayout />}>
            <Route index element={<Home />} />
            <Route path="home" element={<Home />} />
            <Route path="products" element={<Products />} />
            <Route path="types" element={<Types />} />
            <Route path="categories" element={<Categories />} />
            <Route path="amenities" element={<Amenities />} />
            <Route path="attributes" element={<Attributes />} />
            <Route path="backorders" element={<BackOrders />} />
            <Route path="orders" element={<Orders />} />
            <Route path="payments" element={<PaymentsPage />} />
            <Route path="posts" element={<Posts />} />
            <Route path="users" element={<Users />} />
            <Route path="clients" element={<Clients />} />
            <Route path="settings" element={<Settings />} />
            <Route path="notifications" element={<PushNotifications />} />
            <Route path="profile" element={<Profile />} />
            <Route path="*" element={<NoPage />} />
          </Route>

          <Route path="*" element={<NoPage />} />
        </Routes>
        <ToastContainer />
        <SmartLoadingDialog loading={smartDialogLoading} />
      </BrowserRouter>
      </SmartLoadingDialogContext.Provider>
    </AppContext.Provider>
  );
}

export default App;
