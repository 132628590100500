/* eslint-disable eqeqeq */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import useDocumentTitle from "../../hooks/userDocumentTitle";
import PortalTemplate from "./PortalTemplate";
import { Helmet } from 'react-helmet';
import React, { useContext } from "react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  IconButton,
} from "@material-tailwind/react";
import { ManageSearch, PhotoCamera } from "@mui/icons-material";
import AppContext from "../../AppContext";
import Account from "./account_tabs/Account";
import Profile from "./account_tabs/Profile";
import Accrediation from "./account_tabs/Accreditation";
import Users from "./account_tabs/Users";
import { doc, updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { db, storage } from "../..";
import useToken from "../../hooks/useToken";
import { helpers } from "../../utilities/helpers";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import Withdrawal from "./account_tabs/Withdrawal";



export default function AccountPage() {
  useDocumentTitle("Account");

  const {token} = useToken();

  const context: any = useContext(AppContext);
  

  const [activeTab, setActiveTab] = React.useState(new URLSearchParams(window.location.search).get("tab") || "account");
  const data = [
    {
      label: "ACCOUNT",
      value: "account",
      body: <Account />,
    },
    {
      label: "PROFILE",
      value: "profile",
      body: <Profile />,
    },
    {
      label: "ACCREDITATION",
      value: "accreditation",
      body: <Accrediation />,
    },
    {
      label: "USERS",
      value: "users",
      body: <Users />,
    },
    {
      label: "WITHDRAWAL",
      value: "withdrawal",
      body: <Withdrawal />,
    },
    // {
    //   label: "PAYMENT METHOD",
    //   value: "payment-method",
    //   body: <PaymentMethod />,
    // },
  ];


  const onProfilePictureChange = async (e: any) => {
    
    const loading = toast.loading("Uploading Profile Picture...");

    try {

      var picUrl: any = null;

      const file = e.target.files[0];
      const fileName = context.user.first_name + "-" + helpers.generateRandomString(10) + "." + file.name.split(".").pop();
      const fileRef = ref(storage, "profile/pictures/" + fileName);
      await uploadBytes(fileRef, file).then(async (snapshot) => {
        await getDownloadURL(fileRef).then((url) => {
          picUrl = url;
        });
      }).catch((error) => {
          toast.error("Failed to upload identification file");
      });

      //update profile picture
      const userRef = doc(db, "users", token);
      await updateDoc(userRef, {
        profile_picture: picUrl
      });

      context.login({...context.user, profile_picture: picUrl});

      toast.success("Profile picture uploaded successfully");

    } catch (error) {
      toast.error("Failed to upload profile picture");
    } finally {
      toast.dismiss(loading);
    }
    
  

  }


  return (
   <PortalTemplate className="md:py-6 flex flex-col items-center justify-center">
    <Helmet>
                <style>{'body { background-color: #F2F3F6; }'}</style>
    </Helmet>
    <>
    <div className="w-full px-4 md:px-32">
    <div className="flex flex-row items-center justify-between w-full gap-x-3 mt-3">
      <h1 className="text-black text-xl md:text-2xl text-left w-auto my-0">My Account</h1>
      {/* account balance */}
      <div className="bg-white rounded-lg shadow py-1 px-4 flex flex-col items-start">
        <div className="text-black text-lg font-bold"><span className="font-light text-gray-600">Balance:</span> ${helpers.money(context.user.account_balance || 0)}</div>
      </div>
    </div>

      <div className="bg-white rounded-lg shadow p-4 my-8 flex flex-row items-center justify-start gap-x-5 relative">
        {/* camera */}
        <label className="w-20 md:w-24 h-20 md:h-24 p-2 shadow-lg flex flex-col items-center justify-center gap-y-1 rounded-full cursor-pointer bg-cover bg-center bg-no-repeat" style={{backgroundImage: `url(${context.user.profile_picture})`}}>
          {
            !(context.user.profile_picture) && (
            <>
              <PhotoCamera className="!text-5xl text-gray-400" />
              <div className="text-gray-500 text-sm">Upload</div>
            </>
            )
          }
          <input type="file" accept="image/*" className="hidden" onChange={onProfilePictureChange} />
        </label>
        {/* name and number */}
        <div className="flex flex-col items-start gap-y-1">
          <div className="text-lg font-bold flex flex-row gap-x-2 items-center">
              {context.user.first_name} {context.user.last_name}
              { 
                (context.user.verified || context.user.verification_status == "success" || context.user.verification_status == "verified") && (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="green" className="w-6 h-6 inline-block">
                    <path fillRule="evenodd" d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307Zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
                  </svg>

                )
              }
          </div>
          <div className="text-sm text-gray-500">4006205333</div>
        </div>
        <IconButton placeholder={""} color="white" className="!shadow-lg !rounded-full !text-primary-500 !absolute md:!relative !right-1 !top-1" onClick={() => {}}>
          <ManageSearch className="!text-2xl" />
        </IconButton>
      </div>


      <Tabs value={activeTab}>
        <TabsHeader
        placeholder={""}
          className="rounded-none border-b border-blue-gray-100 bg-transparent p-0 md:pr-[500px] overflow-x-auto z-0"
          indicatorProps={{
            className:
              "bg-transparent border-b-[5px] border-gray-700 shadow-none rounded-none w-auto z-0",
          }}
        >
          {data.map(({ label, value }) => (
            <Tab
            placeholder={""}
              key={value}
              value={value}
              onClick={() => {
                setActiveTab(value);
                window.history.pushState({}, "", `?tab=${value}`);
              }}
              className={`font-bold whitespace-nowrap ${activeTab === value ? "text-gray-900" : "text-gray-600"}`}
            >
              {label}
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody className="w-full" placeholder={""}>
          {data.map(({ value, body }) => (
            <TabPanel className="w-full !p-0" key={value} value={value}>
                {body}
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
    </div>
    </>

   </PortalTemplate>
  );
}


