/* eslint-disable react-hooks/exhaustive-deps */
'use client';

import { useState, useEffect } from "react";
import useDocumentTitle from "../../../hooks/userDocumentTitle";
import MainTemplate from "./MainTemplate";
import NotificationsTable from "../components/NotificationsTable";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../../..";



const PushNotifications = () => {
    useDocumentTitle('Push Notifications');

    const [notifications, setNotifications] = useState([] as any[]);
    const [notificationsLoading, setNotificationsLoading] = useState(false);
    const fetchNotifications = async () => {
        setNotificationsLoading(true);
        let notifications: any[] = [];
        //fetch orders from firebase
        const notificationsRef = collection(db, 'notifications');
        const q = query(notificationsRef, orderBy('created_at', 'desc'));
        await getDocs(q).then((snapshot) => {
            snapshot.forEach((doc) => {
                notifications.push({ ...doc.data(), id: doc.id });
            });
        });
        setNotifications(notifications);
        setNotificationsLoading(false);
    };

    useEffect(() => {
        fetchNotifications();
    }, []);


    const onTableUpdated = (notification: any) => {
        fetchNotifications();
    }


    return (
        <>

            <MainTemplate title="Push Notifications">


               

                {/* <!-- Notifications --> */}
                <NotificationsTable notifications={notifications} onTableUpdated={onTableUpdated} loading={notificationsLoading} />

                <br />
                <br />
                <br />
                <br />



            </MainTemplate>

        </>
    );
}

export default PushNotifications;