/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, IconButton } from "@material-tailwind/react";
import { useContext, useState } from "react";
import HelpIcon from "@mui/icons-material/Help";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonIcon from "@mui/icons-material/Person";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AppContext from "../../AppContext";
import { Link, useLocation } from "react-router-dom";
import useToken from "../../hooks/useToken";
import { AccountBox, Folder, House, Logout, Poll } from "@mui/icons-material";
import ContactModal from "./ContactModal";

/* eslint-disable eqeqeq */
type Props = {
  children: React.ReactNode;
  pageType?: string;
  className?: string;
};
export default function PortalTemplate({ children, pageType, className }: Props) {
  const [showScrollHeader, setShowScrollHeader] = useState(false);

  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const [showContactModal, setShowContactModal] = useState(false);

  const context: any = useContext(AppContext);

  const location = useLocation();

  const { logout } = useToken();

  //scroll event
  window.onscroll = () => {
    if (window.scrollY > 50) {
      setShowScrollHeader(true);
    } else {
      setShowScrollHeader(false);
    }
  };

  const logOut = () => {
    logout();
    window.location.href = "/portal/login";
  };

  const contactUs = () => {
    setShowContactModal(true);
  };

  return (
    <>
      {/* header */}
      <header
        className={`${
          showScrollHeader ? "fixed" : "relative"
        } z-40 w-full bg-white text-black flex flex-row items-center justify-between px-1 md:px-5 py-0.5 text-xs font-[500] border-b border-b-[#68686831]`}
      >
        {/* mobile nav button */}
        <IconButton
          size="md"
          placeholder=""
          className="md:hidden shadow-none !text-gray-500"
          color="white"
          onClick={() => setShowMobileMenu(!showMobileMenu)}
        >
          <MenuIcon fontSize="medium" />
        </IconButton>

        {/* logo */}
        <a
          href="/"
          aria-current="page"
          className="brand w-nav-brand w--current"
          aria-label="home"
        >
          <img
            src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e59a0c0a67bd6d170e34c37_FarmFundrLogo-01.png"
            width={140}
            alt="How to invest in farmland"
          />
        </a>

        {/* menus */}
        <ul className="m-0 p-0 hidden md:flex flex-row items-center justify-start gap-x-4 w-[60%] text-sm text-gray-600">
          <li className={`${["/portal/offerings", "/portal"].includes(location.pathname) && "text-black"} active:text-black hover:text-black`}><Link to="/portal/offerings">Offerings</Link></li>
          <li className={`${["/portal/portfolio"].includes(location.pathname) && ""} active:text-black hover:text-black`}><Link to="/portal/portfolio">Portfolio</Link></li>
          <li className={`${["/portal/account"].includes(location.pathname) && ""} active:text-black hover:text-black`}><Link to="/portal/account">My Account</Link></li>
          <li className={`${["/portal/documents"].includes(location.pathname) && ""} active:text-black hover:text-black`}><Link to="/portal/documents">My Documents</Link></li>
        </ul>

        {/* nav */}
        <nav
          role="navigation"
          className="flex flex-row items-center justify-right mt-2 space-x-0 md:space-x-2"
        >
          {/* contact */}
          <IconButton
            size="md"
            placeholder=""
            color="white"
            className="text-gray-500 !shadow-none hidden md:block"
            onClick={contactUs}
          >
            <HelpIcon fontSize="medium" />
          </IconButton>
          {/* notification */}
          <IconButton
            size="md"
            placeholder=""
            color="white"
            className="text-gray-500 !shadow-none"
          >
            <NotificationsIcon fontSize="medium" />
          </IconButton>
          {/* account - but carret next to the right */}
          <Button
            size="md"
            placeholder=""
            color="white"
            className="text-gray-500 !shadow-none !px-1"
            onClick={() => setShowAccountMenu(!showAccountMenu)}
          >
            <div className="flex flex-row items-center w-auto">
              <PersonIcon fontSize="medium" />
              <span className={` ${context.user.setup_completed ? "hidden md:block" : "hidden" } text-gray-700 text-xs font-light normal-case mx-2`}>{context.user.first_name} {context.user.last_name}</span>
              <ExpandMoreIcon fontSize="medium" className="!hidden md:!block" />
            </div>
          </Button>
        </nav>

        {/* account expand */}
        <div className={`${showAccountMenu ? "" : "hidden"} absolute right-0 top-14 bg-white py-5 shadow-md text-gray-600 min-w-[180px] md:min-w-[250px]`}>
          <div className="px-5 py-2 border-b">
            {context.user.email}
          </div>
          <button
            mat-menu-item=""
            className="mat-focus-indicator active mat-menu-item ng-tns-c126-1 mx-5 text-green-800 my-2"
            role="menuitem"
            tabIndex={0}
            aria-disabled="false"
          >
            <CheckCircleIcon
              className="mat-icon notranslate mat-icon-no-color"
              role="img"
              aria-hidden="true"
            />
            <span className="pl-4 text-sm">{context.user.first_name} {context.user.last_name}</span>
          </button>
          <div className="px-7 md:px-16 flex flex-col space-y-2">
            <a href="/portal/account" className="hover:text-primary">
              <span className="">My Account</span>
            </a>
            {/* <a href="#" className="hover:text-primary">
              <span className="">Invite Accounts</span>
            </a> */}
            {/**/}
            <a href="/portal/change-password" className="hover:text-primary">
              <span className="">Change Password</span>
            </a>
            <a href="#"
              onClick={logOut}
             className="hover:text-primary">
              <span className="">Log Out</span>
            </a>
          </div>
          {/**/}
        </div>

        {/* mobile left side menu  */}
        {/* TODO: later */}


      </header>

      {/* mobile menu backdrop */}
      <div
        className={`${
          showMobileMenu ? "block" : "hidden"
        } fixed z-50 inset-0 bg-black bg-opacity-50 top-[55px]`}
      ></div>
      {/* mobile menu */}
      <div
        className={`${
          showMobileMenu ? "block" : "hidden"
        } fixed z-50 top-[55px] left-0 bg-white w-auto px-3 h-full`}
      >
        <div className="flex flex-col items-center justify-start space-y-3 h-full py-5">
          <Link to={"/portal/offerings"} className="text-gray-500 !shadow-none p-3 hover:text-black hover:bg-gray-100 rounded">
            <div className="flex flex-col items-center w-auto">
              <House fontSize="medium" />
              <span className="text-gray-700 text-xs font-light normal-case">
                Offerings
              </span>
            </div>
          </Link>
          <Link to={"/portal/portfolio"} className="text-gray-500 !shadow-none p-3 hover:text-black hover:bg-gray-100 rounded">
            <div className="flex flex-col items-center w-auto">
              <Poll fontSize="medium" />
              <span className="text-gray-700 text-xs font-light normal-case">
                Portfolio
              </span>
            </div>
          </Link>
          <Link to={"/portal/account"} className="text-gray-500 !shadow-none p-3 hover:text-black hover:bg-gray-100 rounded">
            <div className="flex flex-col items-center w-auto">
              <AccountBox fontSize="medium" />
              <span className="text-gray-700 text-xs font-light normal-case">
                Account
              </span>
            </div>
          </Link>
          <Link to={"/portal/documents"} className="text-gray-500 !shadow-none p-3 hover:text-black hover:bg-gray-100 rounded">
            <div className="flex flex-col items-center w-auto">
              <Folder fontSize="medium" />
              <span className="text-gray-700 text-xs font-light normal-case">
                Documets
              </span>
            </div>
          </Link>
          <div className="text-gray-500 !shadow-none p-3 hover:text-black hover:bg-gray-100 rounded" onClick={logOut}>
            <div className="flex flex-col items-center w-auto">
              <Logout fontSize="medium" />
              <span className="text-gray-700 text-xs font-light normal-case">
                Log Out
              </span>
            </div>
          </div>
          <div className="text-gray-500 !shadow-none p-3 hover:text-black hover:bg-gray-100 rounded" onClick={contactUs}>
            <div className="flex flex-col items-center w-auto">
              <Logout fontSize="medium" />
              <span className="text-gray-700 text-xs font-light normal-case">
               Contact Us
              </span>
            </div>
          </div>
        </div>
      </div>
      

      {/* children */}
      <div className={`bg-[#F2F3F6] ${className}`}>{children}</div>




      <ContactModal showModal={showContactModal} setShowModal={setShowContactModal} />
    </>
  );
}
