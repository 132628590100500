/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */

import { Button } from "@material-tailwind/react";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../../AppContext";
import {
  collection,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../..";
import { CircularProgress } from "@mui/material";
import { helpers } from "../../../utilities/helpers";
import PortalWithdrawModal from "./modals/PortalWithdrawModal";
import Currency from "../../../components/Currency";

export default function Withdrawal() {
  const context: any = useContext(AppContext);

  const [withdrawals, setWithdrawals] = useState([] as any[]);
  const [loading, setLoading] = useState(true);

  const [showWithdrawModal, setShowWithdrawModal] = useState(false);

  const fetchWithdrawals = async () => {
    setLoading(true);
    let withdrawals: any[] = [];
    //fetch withdrawals from firebase
    const methodsRef = collection(db, "withdrawals");

    var queries: any[] = [where("user_id", "==", context.user.id)];

    queries.push(orderBy("created_at", "desc"));

    const q = query(methodsRef, ...queries);

    await getDocs(q).then((snapshot) => {
      snapshot.forEach((doc) => {
        withdrawals.push({ ...doc.data(), id: doc.id });
      });
    });
    setWithdrawals(withdrawals);
    setLoading(false);
  };

  useEffect(() => {
    fetchWithdrawals();
  }, []);

  return (
    <>
      {(() => {
        if (loading) {
          return (
            <div className="flex flex-row items-center justify-center">
              <CircularProgress className="!text-center" />
            </div>
          );
        } else if (withdrawals.length == 0) {
          return (
            <div className="flex flex-col items-center justify-center gap-y-6 mt-5 text-center">
              <div className="text-gray-800 text-lg">
                You have not made any withdrawals yet
              </div>
            </div>
          );
        } else {
          return (
            <>
              <div className="bg-white p-4 rounded-lg">
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="p-4">
                          <div className="flex items-center">
                            <input
                              id="checkbox-all-search"
                              type="checkbox"
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                              htmlFor="checkbox-all-search"
                              className="sr-only"
                            >
                              checkbox
                            </label>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Title
                        </th>
                        {/* <th scope="col" className="px-6 py-3">
                  From
                </th>
                <th scope="col" className="px-6 py-3">
                  To
                </th> */}
                        <th scope="col" className="px-6 py-3">
                          Amount
                        </th>
                        {/* <th scope="col" className="px-6 py-3">
                  KYC
                </th>
                <th scope="col" className="px-6 py-3">
                  Accreditation
                </th> */}
                        <th scope="col" className="px-6 py-3">
                          Type
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Status
                        </th>
                        {/* <th scope="col" className="px-6 py-3">
                  Payment Method
                </th> */}
                        {/* <th scope="col" className="px-6 py-3">
                  Cancel
                </th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {(() => {
                        if (loading) {
                          return (
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 w-full">
                              <td
                                colSpan={5}
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap text-center"
                              >
                                <div className="flex items-center justify-center gap-3 !w-full">
                                  <CircularProgress size={20} />
                                  Please wait
                                </div>
                              </td>
                            </tr>
                          );
                        } else if (withdrawals.length == 0) {
                          return (
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 w-full">
                              <td
                                colSpan={5}
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap text-center"
                              >
                                No transactions found
                              </td>
                            </tr>
                          );
                        }

                        return withdrawals.map((withdrawal) => {
                          return (
                            <tr
                              key={withdrawal.id}
                              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                            >
                              <td className="w-4 p-4">
                                <div className="flex items-center">
                                  <input
                                    id="checkbox-table-search-1"
                                    type="checkbox"
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  />
                                  <label
                                    htmlFor="checkbox-table-search-1"
                                    className="sr-only"
                                  >
                                    checkbox
                                  </label>
                                </div>
                              </td>
                              <th
                                scope="row"
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                              >
                                {withdrawal.note}
                              </th>
                              <td className="px-6 py-4 font-bold text-black">
                                <Currency />
                                {helpers.money(withdrawal.amount ?? 0)}
                              </td>
                              <td className="px-6 py-4">
                                <span
                                  className={`rounded-lg px-2 py-1 font-normal text-white ${
                                    withdrawal.type == "credit"
                                      ? "bg-green-500"
                                      : "bg-red-500"
                                  }`}
                                >
                                  {helpers.capitalizeFirstLetter(
                                    withdrawal.type ?? "N/A"
                                  )}
                                </span>
                              </td>
                              <td className="px-6 py-4 text-green-500 font-bold">
                                Success
                              </td>
                              {/* <td className="flex items-center px-6 py-4">
                      <a
                        href="#"
                        className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                      >
                        Edit
                      </a>
                      <a
                        href="#"
                        className="font-medium text-red-600 dark:text-red-500 hover:underline ms-3"
                      >
                        Remove
                      </a>
                    </td> */}
                            </tr>
                          );
                        });
                      })()}
                    </tbody>
                  </table>
                </div>
                {/* pagination */}
                <nav
                  className="flex items-center flex-column flex-wrap md:flex-row justify-between pt-4"
                  aria-label="Table navigation"
                >
                  <span className="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">
                    Showing{" "}
                    <span className="font-semibold text-gray-900 dark:text-white">
                      1-{withdrawals.length}
                    </span>{" "}
                    of{" "}
                    <span className="font-semibold text-gray-900 dark:text-white">
                      {withdrawals.length}
                    </span>
                  </span>
                  <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                    <li>
                      <a
                        href="#"
                        className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      >
                        Previous
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        aria-current="page"
                        className="flex items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                      >
                        1
                      </a>
                    </li>
                    {/* <li>
              <a
                href="#"
                className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              >
                2
              </a>
            </li>
            <li>
              <a
                href="#"
                aria-current="page"
                className="flex items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
              >
                3
              </a>
            </li>
            <li>
              <a
                href="#"
                className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              >
                4
              </a>
            </li>
            <li>
              <a
                href="#"
                className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              >
                5
              </a>
            </li> */}
                    <li>
                      <a
                        href="#"
                        className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      >
                        Next
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </>
          );
        }
      })()}

      <div className="flex flex-col md:flex-row items-center justify-center gap-y-2 gap-x-0 md:gap-x-5 mt-5 text-center w-full">
        <Button
          onClick={() => {
            setShowWithdrawModal(true);
          }}
          placeholder={""}
          className=" !text-white !bg-primary py-3 w-full"
          color="green"
        >
          MAKE A WITHDRAWAL
        </Button>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <PortalWithdrawModal
        showModal={showWithdrawModal}
        setShowModal={setShowWithdrawModal}
      />
    </>
  );
}
