import { useState } from "react";
import LandingHeader from "../components/headers/LandingHeader";
import LandigScrollHeader from "../components/headers/LandingScrollHeader";
import DefaultFooter from "../components/footers/DefaultFooter";
import DefaultHeader from "../components/headers/DefaultHeader";
import DefaultScrollHeader from "../components/headers/DefaultScrollHeader";

/* eslint-disable eqeqeq */
type Props = {
  children: React.ReactNode;
  pageType?: string;
};
export default function MainTemplate({ children, pageType }: Props) {
  const [navLinks, setNavLinks] = useState([
    { title: "PROPERTIES", href: "/property" },
    { title: "FARMLAND INVESTING BLOG", href: "/category/blog" },
    { title: "ABOUT US", href: "/about-us" },
    {
      title: "RESOURCES",
      href: "/resources",
      showSubLinks: false,
      subLinks: [
        { title: "FAQ'S", href: "/faqs" },
        { title: "MEDIA / NEWS", href: "/media" },
        { title: "HELPING FARMERS GROW", href: "/helping-farmers-grow" },
        { title: "SELL FARMLAND", href: "/sell-farmland" },
        {
          title: "FARMFUNDR CUSTOM INVESTMENTS",
          href: "/custom-farmland-investments",
        },
      ],
    },
    { title: "CONTACT", href: "/contact-us" },
    { title: "LOGIN", href: "/portal/login", name: "login" },
    {
      title: "SIGN UP",
      name: "sign-up",
      href: "/portal/register",
    },
  ]);

  const [showScrollHeader, setShowScrollHeader] = useState(false);

  //scroll event
  window.onscroll = () => {
    if (window.scrollY > 50) {
      setShowScrollHeader(true);
    } else {
      setShowScrollHeader(false);
    }
  };


  return (
    <>
      {/* header */}
      {pageType === "landing" ? ( 
      <>
        <LandingHeader navLinks={navLinks} setNavLinks={setNavLinks} className={`${showScrollHeader ? "!hidden" : ""}`} />
        <LandigScrollHeader navLinks={navLinks} setNavLinks={setNavLinks} className={`${showScrollHeader ? "" : "!hidden"}`} />
      </>
      ) : (
        <>
        <DefaultHeader navLinks={navLinks} setNavLinks={setNavLinks} className={`${showScrollHeader ? "!hidden" : ""}`} />
        <DefaultScrollHeader navLinks={navLinks} setNavLinks={setNavLinks} className={`${showScrollHeader ? "" : "!hidden"}`} />
      </>
      )}

      {/* children */}
      {children}
      {/* footer */}
      <DefaultFooter />
    </>
  );
}
