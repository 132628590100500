/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react/jsx-no-target-blank */
import useDocumentTitle from "../hooks/userDocumentTitle";
import MainTemplate from "./MainTemplate";

export default function MediaPage() {
  useDocumentTitle("Media");

  const onSubmit = (e: any) => {
    e.preventDefault();
    console.log("submitted");
  }

  return (
    <>
      <MainTemplate pageType="others">
        
        
      <>
  <div data-ix="slide-in-scroll-navigation" className="page-header toggle faq">
    <div className="w-container">
      <div className="div-text">
        <h1
          data-w-id="0655bdb5-1043-fb11-5f59-726a0bc25214"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
          className="heading center white-font"
        >
          MEdia
        </h1>
        <div
          data-w-id="0655bdb5-1043-fb11-5f59-726a0bc25216"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
          className="separated-div-wrapper"
        >
          <div className="separator header" />
          <div className="separator header center" />
          <div className="separator header" />
        </div>
        <p
          data-w-id="0655bdb5-1043-fb11-5f59-726a0bc2521a"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
          className="paragraph center white"
        >
          The opportunity to take part ownership in quality farmland is
          exciting, but it does raise some questions for first time agricultural
          investors. <br />
          Here are the questions we receive most often. If you can't find an
          answer, don't hesitate to{" "}
          <a href="/contact-us" className="subtle-link">
            contact us
          </a>
        </p>
      </div>
    </div>
  </div>
  <div className="blog-post featured">
    <div className="w-container">
      <div className="div-text">
        <h2 className="heading center">FarmFundr FEatured On</h2>
      </div>
    </div>
    <div className="w-container">
      <div className="columns-2 w-row">
        <div className="column-17 w-col w-col-2">
          <a
            href="https://yieldtalk.com/farmfundr-review/"
            target="_blank"
            className="w-inline-block"
          >
            <img
              src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e741f5aba97007e1244ec3c_1_ACt6Ff0J7wEdExDLd91C7g.png"
              alt=""
              className="image-26 featured"
            />
          </a>
        </div>
        <div className="column-18 w-col w-col-2">
          <a href="https://abc30.com/5445010/" className="w-inline-block">
            <img
              src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e741f5bba9700574444ec3d_kfsn_icon.png"
              alt=""
              className="image-26 featured"
            />
          </a>
        </div>
        <div className="w-col w-col-2">
          <a
            href="http://unbelievable-real-estate-stories.libsyn.com/s2-ep-67-diversifying-with-farmland-through-crowdfunding-with-brandon-silveira"
            className="w-inline-block"
          >
            <img
              src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e741f5935e3637264ada62e_ellieperlman.png"
              alt=""
              className="image-26 featured"
            />
          </a>
        </div>
        <div className="w-col w-col-2">
          <a href="https://mapableusa.com/farmfundr" className="w-inline-block">
            <img
              src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e741f5c23227e93230a300a_mapablelogo4.png"
              alt=""
              className="image-26 featured"
            />
          </a>
        </div>
        <div className="w-col w-col-2">
          <a
            href="https://agfundernews.com/crowdfunding-the-newest-investment-vehicle-in-farmland.html"
            className="w-inline-block"
          >
            <img
              src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e741f5a4367c9f8c5ca3d27_AFNLogo_Black-1-300x135-300x135.png"
              alt=""
              className="image-26 featured"
            />
          </a>
        </div>
        <div className="w-col w-col-2">
          <a
            href="https://joefairless.com/podcast/jf1977-how-to-invest-in-farmland-without-huge-amounts-of-capital-with-brandon-silveira/"
            className="w-inline-block"
          >
            <img
              src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e752101adfe6c25a316e501_joe-fairless-logo-2.png"
              alt=""
              className="image-26 featured"
            />
          </a>
        </div>
      </div>
      <div className="columns-2 w-row">
        <div className="column-17 w-col w-col-3">
          <a
            href="https://profiles.forbes.com/members/business/profile/Brandon-Silveira-Founder-CEO-FarmFundr-LLC/c1071ba5-1cf1-48f1-a395-ed834c2f9672"
            className="w-inline-block"
          >
            <img
              src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/6036ae9a1f2d545f9a4256eb_FBC-Badge-Circle-Blue-2021.png"
              loading="lazy"
              width={143}
              alt="FarmFundr Forbes Council Member"
              className="image-26 featured"
            />
          </a>
        </div>
        <div className="column-18 w-col w-col-3">
          <a
            href="https://www.kevinbupp.com/podcast/273-building-cash-flow-and-wealth-through-inflation-resistant-farmland-investments-with-brandon-silveira-from-farmfundr-com/"
            className="w-inline-block"
          >
            <img
              src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5f08a742c898486096541a2d_real-estate-investing-for-cash-flow-with-kevin-bupp.png"
              width={313}
              alt=""
              className="image-26 featured"
            />
          </a>
        </div>
        <div className="w-col w-col-3">
          <a
            href="#"
            // href="https://www.rocketdollar.com/blog/agricultural-crowdfunding-with-farmfundr"
            className="w-inline-block"
          >
            <img
              src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5f08a7e17c02bc2b91d57bf1_RocketDollar-Logo.png"
              width={143}
              alt=""
              className="image-26 featured"
            />
          </a>
        </div>
        <div className="w-col w-col-3">
          <a
            href="https://mapableusa.com/farmfundr"
            className="w-inline-block"
          />
        </div>
      </div>
      <div className="div-text" />
    </div>
  </div>
  <div>
    <div className="w-container">
      <div className="w-row">
        <div className="w-col w-col-6">
          <div className="contact-wrapper-div in-devices">
            <div className="algin-center">
              <div className="title-contact">INQUIRIES</div>
              <div>
                For media inquiries, speaking opportunities for our CEO, or
                other opportunities, please contact us at <br />
                (559) 380-5492 or use the form below,
              </div>
            </div>
            <div className="top-margin">
              <div className="w-form">
                <form
                  id="wf-form-Media-Form"
                  name="wf-form-Media-Form"
                  data-name="Media Form"
                  method="get"
                  data-wf-page-id="60663b672c7236a4324694c4"
                  data-wf-element-id="c0ad0efb-c7e4-95b4-cd72-38e03981996b"
                  aria-label="Media Form"
                  onSubmit={onSubmit}
                >
                  <input
                    className="text-field-popup w-input"
                    maxLength={256}
                    name="First-Name"
                    data-name="First Name"
                    placeholder="First Name"
                    type="text"
                    id="First-Name"
                    required
                  />
                  <input
                    className="text-field-popup w-input"
                    maxLength={256}
                    name="Last-Name"
                    data-name="Last Name"
                    placeholder="Last Name"
                    type="text"
                    id="Last-Name"
                    required
                  />
                  <input
                    className="text-field-popup w-input"
                    maxLength={256}
                    name="Email"
                    data-name="Email"
                    placeholder="Email address"
                    type="email"
                    id="Email"
                    required
                  />
                  <input
                    className="text-field-popup w-input"
                    maxLength={256}
                    name="Phone"
                    data-name="Phone"
                    placeholder="Phone Number"
                    type="tel"
                    id="Phone"
                    required
                  />
                  <input
                    className="text-field-popup area w-input"
                    maxLength={256}
                    name="Message"
                    data-name="Message"
                    placeholder="Message"
                    type="text"
                    id="Message"
                    required
                  />
                  <div>
                    <div className="algin-center">
                      <input
                        type="submit"
                        data-wait="Please wait..."
                        className="button full w-button"
                        defaultValue="Send Message"
                      />
                    </div>
                  </div>
                </form>
                <div
                  className="success-message w-form-done"
                  tabIndex={-1}
                  role="region"
                  aria-label="Media Form success"
                >
                  <div>
                    Thank you! Your form has been submitted! <br />
                    We will be in touch as soon as possible.
                  </div>
                </div>
                <div
                  className="error-message error-message-3 error-message-4 error-message-5 error-message-6 w-form-fail"
                  tabIndex={-1}
                  role="region"
                  aria-label="Media Form failure"
                >
                  <div>
                    Oops! Something went wrong while submitting the form. Please
                    check all fields. If you're still receiveing issues, please
                    email info@FarmFundr.com
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-col w-col-6">
          <div>
            <div className="media-kit-bloc">
              <h3 className="heading3 white small">
                Media Kit &amp;&nbsp;REsource Library
              </h3>
              <p className="paragraph white">
                We've put together a folder with resources including images,
                press releases, video and more.
              </p>
              <a
                href="https://drive.google.com/open?id=1slz4NiSLWCWHMG1D2cKKvZjIAIHaIvbS"
                data-ix="show-contact-wrapper-on-click"
                data-w-id="629d6bb7-aa78-b3b7-5b62-48b709fee5fd"
                target="_blank"
                className="button outline white center w-button"
              >
                Download
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div className="featured-blog-container w-container">
      <h1 className="heading gray center">FEATURED&nbsp;BLOG&nbsp;POSTS</h1>
      <div className="w-dyn-list">
        <div role="list" className="w-clearfix w-dyn-items w-row">
          <div
            role="listitem"
            className="dynamic-item w-dyn-item w-col w-col-4"
          >
            <a
              href="#"
            //   href="/blog/useful-tips-for-becoming-a-successful-agriculture-investor"
              className="featured-wrapper-3 w-inline-block"
            >
              <div className="div-block-5">
                <div
                  style={{
                    backgroundImage:
                      'url("https://assets-global.website-files.com/5e5717f541472a2c2b58bd84/63efe7c6cb3df45d8d1573d4_Blog%20Image%20-%202022%20(1).png")'
                  }}
                  className="featured-image"
                />
              </div>
              <div className="feafured-text">
                <div className="featured-title">
                  Useful Tips for Becoming a Successful Agriculture Investor
                </div>
                <div className="featured-description">
                  Investing in agriculture can provide long-term profits, and
                  with the right approach, you can become a successful
                  agriculture investor.{" "}
                </div>
                <div className="featured-details">
                  <div className="w-clearfix">
                    <img
                      alt=""
                      src=""
                      className="author-image w-dyn-bind-empty"
                    />
                    <div className="author lite w-dyn-bind-empty" />
                    <div className="thumbnail date">February 6, 2023</div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div
            role="listitem"
            className="dynamic-item w-dyn-item w-col w-col-4"
          >
            <a
              href="#"
            //   href="/blog/questions-you-should-ask-yourself-before-purchasing-farmland"
              className="featured-wrapper-3 w-inline-block"
            >
              <div className="div-block-5">
                <div
                  style={{
                    backgroundImage:
                      'url("https://assets-global.website-files.com/5e5717f541472a2c2b58bd84/63efd44026ac7b0d241a099d_Blog%20Image%20-%202022.png")'
                  }}
                  className="featured-image"
                />
              </div>
              <div className="feafured-text">
                <div className="featured-title">
                  Questions You Should Ask Yourself Before Purchasing Farmland
                </div>
                <div className="featured-description">
                  The idea of owning farmland appeals to many investors,
                  however, those who lack experience in farmland will want to
                  ask themselves some key questions before purchasing a farm.
                </div>
                <div className="featured-details">
                  <div className="w-clearfix">
                    <img
                      alt=""
                      src=""
                      className="author-image w-dyn-bind-empty"
                    />
                    <div className="author lite w-dyn-bind-empty" />
                    <div className="thumbnail date">January 9, 2023</div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div
            role="listitem"
            className="dynamic-item w-dyn-item w-col w-col-4"
          >
            <a
              href="#"
            //   href="/blog/why-invest-in-farmland-instead-of-stocks"
              className="featured-wrapper-3 w-inline-block"
            >
              <div className="div-block-5">
                <div
                  style={{
                    backgroundImage:
                      'url("https://assets-global.website-files.com/5e5717f541472a2c2b58bd84/632b6388f89abb5591f367ec_Blog%20Image%20-%202022%20(6).png")'
                  }}
                  className="featured-image"
                />
              </div>
              <div className="feafured-text">
                <div className="featured-title">
                  Why Invest in Farmland Instead of Stocks?
                </div>
                <div className="featured-description">
                  While many investors choose to focus their money on the stock
                  market, there’s much to be said for considering farmland as a
                  dependable way to build up your portfolio.
                </div>
                <div className="featured-details">
                  <div className="w-clearfix">
                    <img
                      alt=""
                      src=""
                      className="author-image w-dyn-bind-empty"
                    />
                    <div className="author lite w-dyn-bind-empty" />
                    <div className="thumbnail date">September 19, 2022</div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    data-autoplay="true"
    data-loop="true"
    data-wf-ignore="true"
    data-poster-url="https://uploads-ssl.webflow.com/57bab4d00c7bdb623822fa70/587217d2e5fbe3505cbedf2e_Currency App-SD-poster-00001.jpg"
    data-video-urls="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e5717f541472a42b458bfe6_Currency%20App-SD-transcode.mp4,https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e5717f541472a42b458bfe6_Currency%20App-SD-transcode.webm"
    className="background-video w-background-video w-background-video-atom"
  >
    <video
      id="92cdf720-883c-8310-e377-8988547daec3-video"
      autoPlay={true}
      loop={true}
      style={{
        backgroundImage:
          'url("https://uploads-ssl.webflow.com/57bab4d00c7bdb623822fa70/587217d2e5fbe3505cbedf2e_Currency App-SD-poster-00001.jpg")'
      }}
      muted={true}
      playsInline={true}
      data-wf-ignore="true"
      data-object-fit="cover"
    >
      <source
        src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e5717f541472a42b458bfe6_Currency%20App-SD-transcode.mp4"
        data-wf-ignore="true"
      />
      <source
        src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e5717f541472a42b458bfe6_Currency%20App-SD-transcode.webm"
        data-wf-ignore="true"
      />
    </video>
  </div>
  <div className="call-to-action">
    <div className="container-4 w-container">
      <a
        href="https://drive.google.com/open?id=1slz4NiSLWCWHMG1D2cKKvZjIAIHaIvbS"
        data-ix="show-contact-wrapper-on-click"
        data-w-id="39c392e1-68ce-08b9-fb99-df449b2a3186"
        target="_blank"
        className="button outline white w-button"
      >
        Download media kit and other assets here{" "}
      </a>
    </div>
  </div>
  <div className="footer dark-bootom" />
  <a href="#" data-ix="go-top-on-load" className="go-to w-inline-block" />
  {/* <div id="t7qpf1b9k0po1710594851947" className="widget-hidden">
    <iframe
      src="about:blank"
      frameBorder={0}
      scrolling="no"
      width="64px"
      height="60px"
      style={{
        outline: "none !important",
        visibility: "visible !important",
        resize: "none !important",
        boxShadow: "none !important",
        overflow: "visible !important",
        background: "none !important",
        opacity: "1 !important",
        filter: "alpha(opacity=100) !important",
        msFilter:
          "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
        MzOpacity: "1 !important",
        KhtmlOpacity: "1 !important",
        top: "auto !important",
        right: "auto !important",
        bottom: "20px !important",
        left: "20px !important",
        position: "fixed !important",
        border: "0 !important",
        minHeight: "60px !important",
        minWidth: "64px !important",
        maxHeight: "60px !important",
        maxWidth: "64px !important",
        padding: "0 !important",
        margin: "0 !important",
        MozTransitionProperty: "none !important",
        WebkitTransitionProperty: "none !important",
        OTransitionProperty: "none !important",
        transitionProperty: "none !important",
        transform: "none !important",
        WebkitTransform: "none !important",
        msTransform: "none !important",
        width: "64px !important",
        height: "60px !important",
        display: "block !important",
        zIndex: "1000001 !important",
        backgroundColor: "transparent !important",
        cursor: "none !important",
        float: "none !important",
        borderRadius: "unset !important",
        pointerEvents: "auto !important",
        clip: "auto !important",
        colorScheme: "light !important"
      }}
      id="b5fi3bmm987o1710594851967"
      className=""
      title="chat widget"
    />
    <iframe
      src="about:blank"
      frameBorder={0}
      scrolling="no"
      width="350px"
      height="520px"
      style={{
        outline: "none !important",
        visibility: "visible !important",
        resize: "none !important",
        boxShadow: "none !important",
        overflow: "visible !important",
        background: "none !important",
        opacity: "1 !important",
        filter: "alpha(opacity=100) !important",
        msFilter:
          "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
        MzOpacity: "1 !important",
        KhtmlOpacity: "1 !important",
        top: "auto !important",
        right: "auto !important",
        bottom: "90px !important",
        left: "10px !important",
        position: "fixed !important",
        border: "0 !important",
        minHeight: "520px !important",
        minWidth: "350px !important",
        maxHeight: "520px !important",
        maxWidth: "350px !important",
        padding: "0 !important",
        margin: "0 !important",
        MozTransitionProperty: "none !important",
        WebkitTransitionProperty: "none !important",
        OTransitionProperty: "none !important",
        transitionProperty: "none !important",
        transform: "none !important",
        WebkitTransform: "none !important",
        msTransform: "none !important",
        width: "350px !important",
        height: "520px !important",
        display: "none !important",
        zIndex: "auto !important",
        backgroundColor: "transparent !important",
        cursor: "none !important",
        float: "none !important",
        borderRadius: "5px !important",
        pointerEvents: "auto !important",
        clip: "auto !important",
        colorScheme: "light !important"
      }}
      id="m85n9qhaapt81710594852112"
      className=""
      title="chat widget"
    />
    <iframe
      src="about:blank"
      frameBorder={0}
      scrolling="no"
      width="360px"
      height="55px"
      style={{
        outline: "none !important",
        visibility: "visible !important",
        resize: "none !important",
        boxShadow: "none !important",
        overflow: "visible !important",
        background: "none !important",
        opacity: "1 !important",
        filter: "alpha(opacity=100) !important",
        msFilter:
          "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
        MzOpacity: "1 !important",
        KhtmlOpacity: "1 !important",
        top: "auto !important",
        right: "auto !important",
        bottom: 100,
        left: "20px !important",
        position: "fixed !important",
        border: "0 !important",
        minHeight: "55px !important",
        minWidth: "360px !important",
        maxHeight: "55px !important",
        maxWidth: "360px !important",
        padding: "0 !important",
        margin: "0 !important",
        MozTransitionProperty: "none !important",
        WebkitTransitionProperty: "none !important",
        OTransitionProperty: "none !important",
        transitionProperty: "none !important",
        transform: "none !important",
        WebkitTransform: "none !important",
        msTransform: "none !important",
        width: "360px !important",
        height: "55px !important",
        display: "none !important",
        zIndex: "auto !important",
        backgroundColor: "transparent !important",
        cursor: "none !important",
        float: "none !important",
        borderRadius: "unset !important",
        pointerEvents: "auto !important",
        clip: "auto !important",
        colorScheme: "light !important"
      }}
      id="tvip17jc6t981710594852043"
      className=""
      title="chat widget"
    />
    <iframe
      src="about:blank"
      frameBorder={0}
      scrolling="no"
      width="146px"
      height="85px"
      style={{
        outline: "none !important",
        visibility: "visible !important",
        resize: "none !important",
        boxShadow: "none !important",
        overflow: "visible !important",
        background: "none !important",
        opacity: "1 !important",
        filter: "alpha(opacity=100) !important",
        msFilter:
          "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
        MzOpacity: "1 !important",
        KhtmlOpacity: "1 !important",
        top: "auto !important",
        right: "auto !important",
        bottom: "82px !important",
        left: "20px !important",
        position: "fixed !important",
        border: "0 !important",
        minHeight: "85px !important",
        minWidth: "146px !important",
        maxHeight: "85px !important",
        maxWidth: "146px !important",
        padding: "0 !important",
        margin: "0px 0 0 0 !important",
        MozTransitionProperty: "none !important",
        WebkitTransitionProperty: "none !important",
        OTransitionProperty: "none !important",
        transitionProperty: "none !important",
        transform: "rotate(0deg) translateZ(0)",
        WebkitTransform: "rotate(0deg) translateZ(0)",
        msTransform: "rotate(0deg) translateZ(0)",
        width: "146px !important",
        height: "85px !important",
        display: "none !important",
        zIndex: "1000002 !important",
        backgroundColor: "transparent !important",
        cursor: "none !important",
        float: "none !important",
        borderRadius: "unset !important",
        pointerEvents: "auto !important",
        clip: "auto !important",
        colorScheme: "light !important",
        MozTransform: "rotate(0deg) translateZ(0)",
        OTransform: "rotate(0deg) translateZ(0)",
        transformOrigin: 0,
        MozTransformOrigin: 0,
        WebkitTransformOrigin: 0,
        OTransformOrigin: 0,
        msTransformOrigin: 0
      }}
      id="z8ic8403u6u81710594851982"
      className=""
      title="chat widget"
    />
  </div> */}
</>



      </MainTemplate>
    </>
  );
}
