import ModalImage from "react-modal-image";

export default function ModalImageComponent({ medium, small, large, alt, onClose }) {
    return (
        <ModalImage
            medium={medium}
            small={small}
            large={large}
            alt={alt}
            onClose={onClose}
        />
    );
}