/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react/jsx-no-target-blank */
import useDocumentTitle from "../hooks/userDocumentTitle";
import MainTemplate from "./MainTemplate";

export default function SellFarmlandPage() {
  useDocumentTitle("Sell Farmland");

  const onSubmit = (e: any) => {
    e.preventDefault();
    console.log("submitted");
  }

  return (
    <>
      <MainTemplate pageType="others">
        
        
      <>
  <div
    data-ix="slide-in-scroll-navigation"
    className="page-header list cause-1 wf-section"
  >
    <div className="w-container">
      <div className="div-text _2">
        <h1
          data-w-id="394716e7-a41a-d91b-119d-d814fd65a2af"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
          className="heading center white-font"
        >
          SELL&nbsp;OR&nbsp;Rent YOUR&nbsp;FARMLAND
        </h1>
        <div
          data-w-id="394716e7-a41a-d91b-119d-d814fd65a2b1"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
          className="separated-div-wrapper"
        >
          <div className="separator header" />
          <div className="separator header center" />
          <div className="separator header" />
        </div>
        <p
          data-w-id="394716e7-a41a-d91b-119d-d814fd65a2b5"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
          className="paragraph center white"
        >
          With a nice modern and intuitive interface you can control contents to
          be contained and organised.
        </p>
      </div>
    </div>
  </div>
  <div className="blog-post wf-section">
    <div className="w-container">
      <h2 className="heading-3 center">Find Funding or Sell Your Farm</h2>
      <p className="center-paragraph">
        We are interested in talking with you to learn about property you are
        looking to sell or rent out. <br />
        We are only interested in farmland operations in the United States at
        this time.
        <br />‍<br />
        Please tell us more about you and your property below so we can start a
        conversation.
        <br />
      </p>
    </div>
    <div className="w-container">
      <div className="w-row">
        <div className="sell-columb w-col w-col-6">
          <h4 className="sellpageheading">Why sell to us?</h4>
          <p>
            FarmFundr is owned and operated by a third generation farmer. We
            take extreme pride in the properties that we acquire and manage. If
            your property isn't the right fit for us, we also have qualified
            buyers and family farmers ready to make you an offer.
            <br />
          </p>
          <h4 className="sellpageheading">We offer options..</h4>
          <p>
            We can be very flexible when it comes to deal structure and terms.
            Whether you would like a cash offer, income from an owner-finance
            arrangement or to rent out your property to a farmer, we can find an
            arrangement that is best for you. In some cases, we may be able to
            help you retain part ownership in the property or to continue to
            farm it yourself. Not sure what you want quite yet? No problem! Fill
            out the form and let's talk.
            <br />
          </p>
          <h4 className="sellpageheading">What is the process?</h4>
          <p>
            The process is super simple. Complete the form to give us basic
            information about your property. If it's something we are interested
            in, we will setup a call to discuss potential deal structures that
            meet your needs. We never take commissions on the sell of your
            property. &nbsp;No fees and no obligation or hassle, ever!
            <br />
            <br />
          </p>
        </div>
        <div className="w-col w-col-6">
          <div className="contact-wrapper-div in-devices">
            <div className="algin-center">
              <div className="title-contact" />
            </div>
            <h4 className="heading-5">Tell us about your property...</h4>
            <div className="top-margin">
              <div className="w-form">
                <form
                  id="wf-form-Sell-Farmland"
                  name="wf-form-Sell-Farmland"
                  data-name="Sell Farmland"
                  method="get"
                  aria-label="Sell Farmland"
                  onSubmit={onSubmit}
                >
                  <input
                    type="text"
                    className="text-field-popup w-input"
                    maxLength={256}
                    name="First-Name"
                    data-name="First Name"
                    placeholder="First Name"
                    id="First-Name"
                    required
                  />
                  <input
                    type="text"
                    className="text-field-popup w-input"
                    maxLength={256}
                    name="Last-Name"
                    data-name="Last Name"
                    placeholder="Last Name"
                    id="Last-Name"
                    required
                  />
                  <input
                    type="email"
                    className="text-field-popup w-input"
                    maxLength={256}
                    name="Email"
                    data-name="Email"
                    placeholder="Email address"
                    id="Email"
                    required
                  />
                  <input
                    type="tel"
                    className="text-field-popup w-input"
                    maxLength={256}
                    name="Phone"
                    data-name="Phone"
                    placeholder="Phone Number"
                    id="Phone"
                    required
                  />
                  <input
                    type="text"
                    className="text-field-popup w-input"
                    maxLength={256}
                    name="APN-Address"
                    data-name="APN/Address"
                    placeholder="Property APN or Address"
                    id="APN-Address"
                    required
                  />
                  <input
                    type="text"
                    className="text-field-popup w-input"
                    maxLength={256}
                    name="Acres"
                    data-name="Acres"
                    placeholder="How many Acres?"
                    id="Acres"
                    required
                  />
                  <input
                    type="text"
                    className="text-field-popup w-input"
                    maxLength={256}
                    name="What-Crop"
                    data-name="What Crop?"
                    placeholder="What is currently planted on the property?"
                    id="What-Crop"
                    required
                  />
                  <input
                    type="text"
                    className="text-field-popup w-input"
                    maxLength={256}
                    name="Irrigation-District-and-Water"
                    data-name="Irrigation District and Water"
                    placeholder="What is the water district and available water sources?"
                    id="Irrigation-District-and-Water"
                    required
                  />
                  <input
                    type="text"
                    className="text-field-popup area w-input"
                    maxLength={256}
                    name="Message"
                    data-name="Message"
                    placeholder="Pease tell us more about your property & goals"
                    id="Message"
                  />
                  <div>
                    <div className="algin-center">
                      <input
                        type="submit"
                        defaultValue="Send Message"
                        data-wait="Please wait..."
                        className="button full w-button"
                      />
                    </div>
                  </div>
                </form>
                <div
                  className="success-message w-form-done"
                  tabIndex={-1}
                  role="region"
                  aria-label="Sell Farmland success"
                >
                  <div>
                    Thank you! Your form has been submitted! <br />
                    If you have a prospectus, business plan or any other
                    supplemental information, please e-mail it to
                    info@farmfundr.com
                    <br />
                    We will be in touch as soon as possible.
                  </div>
                </div>
                <div
                  className="error-message error-message-3 error-message-4 error-message-5 error-message-6 w-form-fail"
                  tabIndex={-1}
                  role="region"
                  aria-label="Sell Farmland failure"
                >
                  <div>
                    Oops! Something went wrong while submitting the form. Please
                    check all fields. If you're still receiveing issues, please
                    email info@FarmFundr.com
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="loan-container w-container">
      <h1 className="heading-3 center white mobile">
        Are you a Farmer Seeking a Loan?
      </h1>
      <p className="center-paragraph white">
        We are happy to put you in touch with our lending partner. Please fill
        out your information below.
        <br />
      </p>
      <div className="contact-wrapper-div in-devices loan">
        <div className="algin-center">
          <div className="title-contact" />
        </div>
        <div className="top-margin">
          <div className="w-form">
            <form
              id="wf-form-Ag-Loan-Form"
              name="wf-form-Ag-Loan-Form"
              data-name="Ag Loan Form"
              method="get"
              aria-label="Ag Loan Form"
              onSubmit={onSubmit}
            >
              <input
                type="text"
                className="text-field-popup w-input"
                maxLength={256}
                name="First-Name"
                data-name="First Name"
                placeholder="First Name"
                id="First-Name-3"
                required
              />
              <input
                type="text"
                className="text-field-popup w-input"
                maxLength={256}
                name="Last-Name"
                data-name="Last Name"
                placeholder="Last Name"
                id="Last-Name-3"
                required
              />
              <input
                type="email"
                className="text-field-popup w-input"
                maxLength={256}
                name="Email"
                data-name="Email"
                placeholder="Email address"
                id="Email-3"
                required
              />
              <input
                type="tel"
                className="text-field-popup w-input"
                maxLength={256}
                name="Phone-2"
                data-name="Phone 2"
                placeholder="Phone Number"
                id="Phone-2"
                required
              />
              <input
                type="number"
                className="text-field-popup w-input"
                maxLength={256}
                name="Desired-Loan-Amount"
                data-name="Desired Loan Amount"
                placeholder="Desired Loan Amount"
                id="Desired-Loan-Amount"
                required
              />
              <input
                type="number"
                className="text-field-popup w-input"
                maxLength={256}
                name="Credit-Score"
                data-name="Credit Score"
                placeholder="What is your credit score? "
                id="Credit-Score"
                required
              />
              <div>
                <div className="algin-center">
                  <input
                    type="submit"
                    defaultValue="Send Message"
                    data-wait="Please wait..."
                    className="button full w-button"
                  />
                </div>
              </div>
            </form>
            <div
              className="success-message w-form-done"
              tabIndex={-1}
              role="region"
              aria-label="Ag Loan Form success"
            >
              <div>
                Thank you! Your form has been submitted! We will be in touch as
                soon as possible.
              </div>
            </div>
            <div
              className="error-message error-message-3 error-message-4 error-message-5 error-message-6 w-form-fail"
              tabIndex={-1}
              role="region"
              aria-label="Ag Loan Form failure"
            >
              <div>
                Oops! Something went wrong while submitting the form. Please
                check all fields. If you're still receiveing issues, please
                email info@FarmFundr.com
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="footer dark-bootom wf-section" />
  {/*[if lte IE 9]><![endif]*/}
  {/* <div id="dghsjg10hph1710595716471" className="widget-hidden">
    <iframe
      src="about:blank"
      frameBorder={0}
      scrolling="no"
      width="64px"
      height="60px"
      style={{
        outline: "none !important",
        visibility: "visible !important",
        resize: "none !important",
        boxShadow: "none !important",
        overflow: "visible !important",
        background: "none !important",
        opacity: "1 !important",
        filter: "alpha(opacity=100) !important",
        msFilter:
          "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
        MzOpacity: "1 !important",
        KhtmlOpacity: "1 !important",
        top: "auto !important",
        right: "auto !important",
        bottom: "20px !important",
        left: "20px !important",
        position: "fixed !important",
        border: "0 !important",
        minHeight: "60px !important",
        minWidth: "64px !important",
        maxHeight: "60px !important",
        maxWidth: "64px !important",
        padding: "0 !important",
        margin: "0 !important",
        MozTransitionProperty: "none !important",
        WebkitTransitionProperty: "none !important",
        OTransitionProperty: "none !important",
        transitionProperty: "none !important",
        transform: "none !important",
        WebkitTransform: "none !important",
        msTransform: "none !important",
        width: "64px !important",
        height: "60px !important",
        display: "block !important",
        zIndex: "1000001 !important",
        backgroundColor: "transparent !important",
        cursor: "none !important",
        float: "none !important",
        borderRadius: "unset !important",
        pointerEvents: "auto !important",
        clip: "auto !important",
        colorScheme: "light !important"
      }}
      id="t2b41te5jfmo1710595716497"
      className=""
      title="chat widget"
    />
    <iframe
      src="about:blank"
      frameBorder={0}
      scrolling="no"
      width="350px"
      height="520px"
      style={{
        outline: "none !important",
        visibility: "visible !important",
        resize: "none !important",
        boxShadow: "none !important",
        overflow: "visible !important",
        background: "none !important",
        opacity: "1 !important",
        filter: "alpha(opacity=100) !important",
        msFilter:
          "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
        MzOpacity: "1 !important",
        KhtmlOpacity: "1 !important",
        top: "auto !important",
        right: "auto !important",
        bottom: "90px !important",
        left: "10px !important",
        position: "fixed !important",
        border: "0 !important",
        minHeight: "520px !important",
        minWidth: "350px !important",
        maxHeight: "520px !important",
        maxWidth: "350px !important",
        padding: "0 !important",
        margin: "0 !important",
        MozTransitionProperty: "none !important",
        WebkitTransitionProperty: "none !important",
        OTransitionProperty: "none !important",
        transitionProperty: "none !important",
        transform: "none !important",
        WebkitTransform: "none !important",
        msTransform: "none !important",
        width: "350px !important",
        height: "520px !important",
        display: "none !important",
        zIndex: "auto !important",
        backgroundColor: "transparent !important",
        cursor: "none !important",
        float: "none !important",
        borderRadius: "5px !important",
        pointerEvents: "auto !important",
        clip: "auto !important",
        colorScheme: "light !important"
      }}
      id="bmhqc57sk6q1710595716529"
      className=""
      title="chat widget"
    />
    <iframe
      src="about:blank"
      frameBorder={0}
      scrolling="no"
      width="360px"
      height="145px"
      style={{
        outline: "none !important",
        visibility: "visible !important",
        resize: "none !important",
        boxShadow: "none !important",
        overflow: "visible !important",
        background: "none !important",
        opacity: "1 !important",
        filter: "alpha(opacity=100) !important",
        msFilter:
          "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
        MzOpacity: "1 !important",
        KhtmlOpacity: "1 !important",
        top: "auto !important",
        right: "auto !important",
        bottom: 100,
        left: "20px !important",
        position: "fixed !important",
        border: "0 !important",
        minHeight: "145px !important",
        minWidth: "360px !important",
        maxHeight: "145px !important",
        maxWidth: "360px !important",
        padding: "0 !important",
        margin: "0 !important",
        MozTransitionProperty: "none !important",
        WebkitTransitionProperty: "none !important",
        OTransitionProperty: "none !important",
        transitionProperty: "none !important",
        transform: "none !important",
        WebkitTransform: "none !important",
        msTransform: "none !important",
        width: "360px !important",
        height: "145px !important",
        display: "none !important",
        zIndex: "auto !important",
        backgroundColor: "transparent !important",
        cursor: "none !important",
        float: "none !important",
        borderRadius: "unset !important",
        pointerEvents: "auto !important",
        clip: "auto !important",
        colorScheme: "light !important"
      }}
      id="t16csb2e71lo1710595716518"
      className=""
      title="chat widget"
    />
    <iframe
      src="about:blank"
      frameBorder={0}
      scrolling="no"
      width="146px"
      height="85px"
      style={{
        outline: "none !important",
        visibility: "visible !important",
        resize: "none !important",
        boxShadow: "none !important",
        overflow: "visible !important",
        background: "none !important",
        opacity: "1 !important",
        filter: "alpha(opacity=100) !important",
        msFilter:
          "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
        MzOpacity: "1 !important",
        KhtmlOpacity: "1 !important",
        top: "auto !important",
        right: "auto !important",
        bottom: "82px !important",
        left: "20px !important",
        position: "fixed !important",
        border: "0 !important",
        minHeight: "85px !important",
        minWidth: "146px !important",
        maxHeight: "85px !important",
        maxWidth: "146px !important",
        padding: "0 !important",
        margin: "0px 0 0 0 !important",
        MozTransitionProperty: "none !important",
        WebkitTransitionProperty: "none !important",
        OTransitionProperty: "none !important",
        transitionProperty: "none !important",
        transform: "rotate(0deg) translateZ(0)",
        WebkitTransform: "rotate(0deg) translateZ(0)",
        msTransform: "rotate(0deg) translateZ(0)",
        width: "146px !important",
        height: "85px !important",
        display: "none !important",
        zIndex: "1000002 !important",
        backgroundColor: "transparent !important",
        cursor: "none !important",
        float: "none !important",
        borderRadius: "unset !important",
        pointerEvents: "auto !important",
        clip: "auto !important",
        colorScheme: "light !important",
        MozTransform: "rotate(0deg) translateZ(0)",
        OTransform: "rotate(0deg) translateZ(0)",
        transformOrigin: 0,
        MozTransformOrigin: 0,
        WebkitTransformOrigin: 0,
        OTransformOrigin: 0,
        msTransformOrigin: 0
      }}
      id="ki9f7d16e8jg1710595716505"
      className=""
      title="chat widget"
    />
  </div> */}
</>




      </MainTemplate>
    </>
  );
}
