/* eslint-disable eqeqeq */
import { doc, updateDoc } from "firebase/firestore";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { db, storage } from "../../..";
import useToken from "../../../hooks/useToken";
import { helpers } from "../../../utilities/helpers";
import AppContext from "../../../AppContext";
import { Domain, HelpOutline, People, Person } from "@mui/icons-material";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

export default function Accreditation() {

    const {token} = useToken();

    const context: any = useContext(AppContext);

    const [form, setForm] = useState((context.user || {}) as any);

    const [documentYear1, setDocumentYear1] = useState(null as any);
    const [documentYear2, setDocumentYear2] = useState(null as any);
    const [additionalDocument1, setAdditionalDocument1] = useState(null as any);
    const [additionalDocument2, setAdditionalDocument2] = useState(null as any);
    const [professionalDocument, setProfessionalDocument] = useState(null as any);

    const handleChange = (e: any) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    }

    const onSubmit = async (e: any) => {
        e.preventDefault();

        const loading = toast.loading("Please wait...", { position: "top-center" });

        //add documentYear1
        if (documentYear1) {
            //upload file
            const fileName = form.first_name + "-" + helpers.generateRandomString(10) + "." + documentYear1.name.split(".").pop();
            const fileRef = ref(storage, "files/identifications/" + fileName);
            await uploadBytes(fileRef, documentYear1).then(async (snapshot) => {
                await getDownloadURL(fileRef).then((url) => {
                    form.document_year1 = url;
                });
            }
            ).catch((error) => {
                toast.error("Failed to upload document year 1 file");
            });
        }
        //add documentYear2
        if (documentYear2) {
            //upload file
            const fileName = form.first_name + "-" + helpers.generateRandomString(10) + "." + documentYear2.name.split(".").pop();
            const fileRef = ref(storage, "files/identifications/" + fileName);
            await uploadBytes(fileRef, documentYear2).then(async (snapshot) => {
                await getDownloadURL(fileRef).then((url) => {
                    form.document_year2 = url;
                });
            }
            ).catch((error) => {
                toast.error("Failed to upload document year 2 file");
            });
        }
        //add additionalDocument1
        if (additionalDocument1) {
            //upload file
            const fileName = form.first_name + "-" + helpers.generateRandomString(10) + "." + additionalDocument1.name.split(".").pop();
            const fileRef = ref(storage, "files/identifications/" + fileName);
            await uploadBytes(fileRef, additionalDocument1).then(async (snapshot) => {
                await getDownloadURL(fileRef).then((url) => {
                    form.additional_document1 = url;
                });
            }
            ).catch((error) => {
                toast.error("Failed to upload additional document 1 file");
            });
        }
        //add additionalDocument2
        if (additionalDocument2) {
            //upload file
            const fileName = form.first_name + "-" + helpers.generateRandomString(10) + "." + additionalDocument2.name.split(".").pop();
            const fileRef = ref(storage, "files/identifications/" + fileName);
            await uploadBytes(fileRef, additionalDocument2).then(async (snapshot) => {
                await getDownloadURL(fileRef).then((url) => {
                    form.additional_document2 = url;
                });
            }
            ).catch((error) => {
                toast.error("Failed to upload additional document 2 file");
            });
        }
        //add professionalDocument
        if (professionalDocument) {
            //upload file
            const fileName = form.first_name + "-" + helpers.generateRandomString(10) + "." + professionalDocument.name.split(".").pop();
            const fileRef = ref(storage, "files/identifications/" + fileName);
            await uploadBytes(fileRef, professionalDocument).then(async (snapshot) => {
                await getDownloadURL(fileRef).then((url) => {
                    form.professional_document = url;
                });
            }
            ).catch((error) => {
                toast.error("Failed to upload professional document file");
            });
        }

    try {

      //update user
      const userRef = doc(db, "users", token);
      await updateDoc(userRef, {
        ...form,
        search_keywords: helpers.generateSearchKeywords(form.first_name + " " + form.last_name),
        setup_completed: true,
      });

      toast.dismiss(loading);

      toast.success("Accreditation saved successfully");
      
      //reload page
        window.location.href = "/portal/account?tab=accreditation&saved=true";

    } catch (error) {
      console.log(error);
      toast.dismiss(loading);
      toast.error("An error occurred. Please try again");
    }

    }

    return (
    <>

{
        (new URLSearchParams(window.location.search)).get("saved") && (
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 my-2 rounded relative" role="alert">
                <strong className="font-bold">Success!</strong>
                <span className="block sm:inline"> Accreditation saved successfully</span>
            </div>
        )
      }


       <div className="bg-white rounded-lg shadow-2xl py-5 px-10 w-full">
          {/**/}
          <div className="flex flex-col items-center w-full space-y-3">
  
            <form
              className="ng-untouched ng-pristine ng-star-inserted ng-invalid w-full space-y-5"
              onSubmit={onSubmit}
            >
              
              <h1 className="text-xl !font-extralight mt-3 flex flex-row items-center justify-start gap-x-4">
              ACCREDITATION STATUS <HelpOutline fontSize="small" className="!text-gray-600"/>
              </h1>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                    <div 
                      className={`bg-white p-4 flex flex-col items-center text-center gap-y-2 border-[2px] rounded-lg cursor-pointer ${form.accredited_status == 'above_200000' ? "border-primary-500 text-primary-500" : "border-gray-300 text-gray-600"}`}
                      onClick={() => setForm({ ...form, accredited_status: "above_200000" })}
                      >
                        <Person fontSize="large" className="!text-6xl"/>
                        <div className="font-bold text-gray-600">My Individual Income is</div>
                        <div className="font-bold text-primary-500">Above $200,000</div>
                        <div className="text-gray-600">(for each of the last 2 years)</div>
                        <input type="radio" name="accredited_status" value="above_200000" 
                        onChange={handleChange}
                        checked={form.accredited_status == 'above_200000'}
                         className="mr-2 h-6 w-6 accent-primary-500 focus:accent-primary-500"/>
                    </div>
                    <div 
                      className={`bg-white p-4 flex flex-col items-center text-center gap-y-2 border-[2px] rounded-lg cursor-pointer ${form.accredited_status == 'above_300000' ? "border-primary-500 text-primary-500" : "border-gray-300 text-gray-600"}`}
                      onClick={() => setForm({ ...form, accredited_status: "above_300000" })}
                      >
                        <People fontSize="large" className="!text-6xl"/>
                        <div className="font-bold text-gray-600">My Joint Income with spouse is</div>
                        <div className="font-bold text-primary-500">Above $300,000</div>
                        <div className="text-gray-600">(for each of the last 2 years)</div>
                        <input type="radio" name="accredited_status" value="above_300000" 
                        onChange={handleChange}
                        checked={form.accredited_status == 'above_300000'}
                         className="mr-2 h-6 w-6 accent-primary-500 focus:accent-primary-500"/>
                    </div>
                    <div 
                      className={`bg-white p-4 flex flex-col items-center text-center gap-y-2 border-[2px] rounded-lg cursor-pointer ${form.accredited_status == 'above_1m' ? "border-primary-500 text-primary-500" : "border-gray-300 text-gray-600"}`}
                      onClick={() => setForm({ ...form, accredited_status: "above_1m" })}
                      >
                        <Domain fontSize="large" className="!text-6xl"/>
                        <div className="font-bold text-gray-600 text-center">My individual Net Worth or joint with spouse is</div>
                        <div className="font-bold text-primary-500">Above $1M</div>
                        <div className="text-gray-600">(excluding primary residence)</div>
                        <input type="radio" name="accredited_status" value="above_1m" 
                        onChange={handleChange}
                        checked={form.accredited_status == 'above_1m'}
                         className="mr-2 h-6 w-6 accent-primary-500 focus:accent-primary-500"/>
                    </div>
                    <div 
                      className={`bg-white p-4 flex flex-col items-center text-center gap-y-2 border-[2px] rounded-lg cursor-pointer ${form.accredited_status == 'above_5m' ? "border-primary-500 text-primary-500" : "border-gray-300 text-gray-600"}`}
                      onClick={() => setForm({ ...form, accredited_status: "above_5m" })}
                      >
                        <Person fontSize="large" className="!text-6xl"/>
                        <div className="font-bold text-gray-600 text-center">I own Total Investments</div>
                        <div className="font-bold text-primary-500">Above $5M</div>
                        <div className="text-gray-600">(including jointly with spouse)</div>
                        <input type="radio" name="accredited_status" value="above_5m" 
                        onChange={handleChange}
                        checked={form.accredited_status == 'above_5m'}
                         className="mr-2 h-6 w-6 accent-primary-500 focus:accent-primary-500"/>
                    </div>
                    <div 
                      className={`bg-white p-4 flex flex-col items-center text-center gap-y-2 border-[2px] rounded-lg cursor-pointer ${form.accredited_status == 'licensed_individual' ? "border-primary-500 text-primary-500" : "border-gray-300 text-gray-600"}`}
                      onClick={() => setForm({ ...form, accredited_status: "licensed_individual" })}
                      >
                        <Person fontSize="large" className="!text-6xl"/>
                        <div className="font-bold text-gray-600 text-center">I am a licensed individual that holds an active Series 7, Series 65, or Series 82 registration</div>
                        {/* <div className="font-bold text-primary-500">Above $300,000</div> */}
                        {/* <div className="text-gray-600">(for each of the last 2 years)</div> */}
                        <input type="radio" name="accredited_status" value="licensed_individual" 
                        onChange={handleChange}
                        checked={form.accredited_status == 'licensed_individual'}
                         className="mr-2 h-6 w-6 accent-primary-500 focus:accent-primary-500"/>
                    </div>
                    <div 
                      className={`bg-white p-4 flex flex-col items-center text-center gap-y-2 border-[2px] rounded-lg cursor-pointer ${form.accredited_status == 'not_accredited' ? "border-primary-500 text-primary-500" : "border-gray-300 text-gray-600"}`}
                      onClick={() => setForm({ ...form, accredited_status: "not_accredited" })}
                      >
                        <Person fontSize="large" className="!text-6xl"/>
                        <div className="font-bold text-gray-600 text-center">I am not an <br/> Accredited Investor</div>
                        {/* <div className="font-bold text-primary-500">Above $300,000</div> */}
                        {/* <div className="text-gray-600">(for each of the last 2 years)</div> */}
                        <input type="radio" name="accredited_status" value="not_accredited" 
                        onChange={handleChange}
                        checked={form.accredited_status == 'not_accredited'}
                         className="mr-2 h-6 w-6 accent-primary-500 focus:accent-primary-500"/>
                    </div>
                </div>

                { !form.has_professional_letter && (
                <div className="p-2 md:p-4 border-2 border-primary-500 rounded-lg flex flex-col items-start w-full">
                    <div className="text-lg">Verify Individual Gross Income Above $200,000</div>
                    <div className="text-sm">Please provide at least one tax document for each of the two most recent years. Accepted documents include your full tax return, W-2, K-1, or 1099.</div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 w-full mt-2">
                        <label className="cursor-pointer border border-dashed border-gray-600 rounded-md p-5 text-sm placeholder:text-gray-600 w-full flex flex-col items-center justify-center gap-y-3 text-center">
                                <div className="text-gray-600 font-bold">Upload Document for Year 1</div>
                                <input
                                type="file"
                                className="hidden"
                                accept="image/*"
                                multiple
                                name="document_year1"
                                onChange={(e: any) => setDocumentYear1(e.target.files[0])}
                                />
                                {
                                    documentYear1 ? (
                                        <span className="text-gray-600 text-xs">{documentYear1.name}</span>
                                    ) : (
                                        <>
                                        <div className="text-primary-500 font-bold">Click to Upload File</div>
                                        <span className="text-gray-600 text-xs">(max file size: 8MB)</span>
                                        </>
                                    )
                                }
                        </label>
                        <label className="cursor-pointer border border-dashed border-gray-600 rounded-md p-5 text-sm placeholder:text-gray-600 w-full flex flex-col items-center justify-center gap-y-3 text-center">
                                <div className="text-gray-600 font-bold">Upload Document for Year 2</div>
                                <input
                                type="file"
                                className="hidden"
                                accept="image/*"
                                multiple
                                name="document_year2"
                                onChange={(e: any) => setDocumentYear2(e.target.files[0])}
                                />
                                {
                                    documentYear2 ? (
                                        <span className="text-gray-600 text-xs">{documentYear2.name}</span>
                                    ) : (
                                        <>
                                        <div className="text-primary-500 font-bold">Click to Upload File</div>
                                        <span className="text-gray-600 text-xs">(max file size: 8MB)</span>
                                        </>
                                    )
                                }
                        </label>
                        <label className="cursor-pointer border border-dashed border-gray-600 rounded-md p-5 text-sm placeholder:text-gray-600 w-full flex flex-col items-center justify-center gap-y-3 text-center">
                                <div className="text-gray-600 font-bold">Upload Additional File (optional)</div>
                                <input
                                type="file"
                                className="hidden"
                                accept="image/*"
                                multiple
                                name="additional_document1"
                                onChange={(e: any) => setAdditionalDocument1(e.target.files[0])}
                                />
                                {
                                    additionalDocument1 ? (
                                        <span className="text-gray-600 text-xs">{additionalDocument1.name}</span>
                                    ) : (
                                        <>
                                        <div className="text-primary-500 font-bold">Click to Upload File</div>
                                        <span className="text-gray-600 text-xs">(max file size: 8MB)</span>
                                        </>
                                    )
                                }
                        </label>
                        <label className="cursor-pointer border border-dashed border-gray-600 rounded-md p-5 text-sm placeholder:text-gray-600 w-full flex flex-col items-center justify-center gap-y-3 text-center">
                                <div className="text-gray-600 font-bold">Upload Additional File (optional)</div>
                                <input
                                type="file"
                                className="hidden"
                                accept="image/*"
                                multiple
                                name="additional_document2"
                                onChange={(e: any) => setAdditionalDocument2(e.target.files[0])}
                                />
                                {
                                    additionalDocument2 ? (
                                        <span className="text-gray-600 text-xs">{additionalDocument2.name}</span>
                                    ) : (
                                        <>
                                        <div className="text-primary-500 font-bold">Click to Upload File</div>
                                        <span className="text-gray-600 text-xs">(max file size: 8MB)</span>
                                        </>
                                    )
                                }
                        </label>
                    </div>
                </div>
                )}

                { !form.has_professional_letter && ( <div className="text-center">--  OR --</div> ) }
                <label className="flex flex-row items-center justify-center gap-x-2 font-normal">
                    <input type="checkbox" name="has_professional_letter" value="above_200000" 
                        onChange={(e) => setForm({ ...form, has_professional_letter: e.target.checked })}
                        checked={form.has_professional_letter}
                         className="mr-2 h-6 w-6 accent-primary-500 focus:accent-primary-500"/>
                    <div className="">I have a professional letter stating that I am an accredited investor</div>

                </label>

                { form.has_professional_letter && (
                <div className="p-2 md:p-4 border-2 border-primary-500 rounded-lg flex flex-col items-start w-full">
                    <div className="text-lg">Professional Letter</div>
                    <div className="text-xs">Provide an official written communication from a <b>licensed CPA, Attorney, Investment advisor, or Registered broker-dealer,</b> stating that the professional service provider has a reasonable belief that you are an Accredited Investor. The letter must be on the writer’s letterhead, include your name and date (Must be dated within the last 3 months).</div>
                        <label className="cursor-pointer border border-dashed border-gray-600 rounded-md p-5 mt-2 text-sm placeholder:text-gray-600 w-full flex flex-col items-center justify-center gap-y-3 text-center">
                                <div className="text-gray-600 font-bold">Upload Professional Letter</div>
                                <input
                                type="file"
                                className="hidden"
                                accept="image/*"
                                multiple
                                name="professional_document"
                                onChange={(e: any) => setProfessionalDocument(e.target.files[0])}
                                />
                                {
                                    professionalDocument ? (
                                        <span className="text-gray-600 text-xs">{professionalDocument.name}</span>
                                    ) : (
                                        <>
                                        <div className="text-primary-500 font-bold">Click to Upload File</div>
                                        <span className="text-gray-600 text-xs">(max file size: 8MB)</span>
                                        </>
                                    )
                                }
                        </label>
                </div>
                )}
              

    

              <div></div>

              <div className="flex flex-row items-center justify-center">
                <button
                    type="submit"
                    className="bg-primary-500 hover:bg-primary text-white text-sm rounded-md p-3 px-20"
                >
                    UPDATE PROFILE
                </button>
              </div>

            </form>
          </div>
        </div>

        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>

    </>)
    ;
}