/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import { Outlet, Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import useDocumentTitle from "../../hooks/userDocumentTitle";
import useToken from "../../hooks/useToken";
import { useState } from "react";
import { toast } from "react-toastify";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../..";



function LoginPage() {
  useDocumentTitle("Login");

  const { login } = useToken();
  const [formData, setFormData] = useState({} as any);

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
};


  const onSubmit = async (e: any) => {
    e.preventDefault();

    const loading = toast.loading("Please wait...", { position: "top-center" });
    
        const q = query(collection(db, "users"), where("email", "==", formData.email));
        const querySnapshot = await getDocs(q);


        if (querySnapshot.empty) {
          toast.dismiss(loading);
            toast.error("No user found with this email");
            return;
        }

        //get first user
        let user = querySnapshot.docs[0].data();
        user.id = querySnapshot.docs[0].id;
        
        console.log(user.password, formData.password);
        

        //check password
        if (user.password !== formData.password) {
            toast.dismiss(loading);
            toast.error("Incorrect password");
            return;
        }

        //set token
        login(user.id);

        toast.dismiss(loading);

        //redirect to dashboard
        if (user.setup_completed) {
          window.location.href = "/portal";
        } else {
          window.location.href = "/portal/setup";
        }


  }


  return (
    <>



            <div
              _ngcontent-dyt-c349=""
              className="login-page ng-star-inserted h-screen flex px-3 sm:px-0"
              style={{
                backgroundImage:
                  'url("https://wealthblock-platform.s3.amazonaws.com/image/0703b3542e034354155049345d8c1183.jpg")',
                backgroundSize: "cover",
                backgroundPosition: "center",
                minHeight: 819
              }}
            >
              <div _ngcontent-dyt-c349="" className="login-container md:mx-auto my-auto mt-10 md:mt-auto w-full md:w-auto bg-white rounded-lg shadow-2xl pt-10 pb-10 px-10">
                {/**/}
                        <div className="flex flex-col items-center w-full md:min-w-[600px]">
                         
                         
                        <a href="/">
                          <img
                            _ngcontent-dyt-c160=""
                            className="logo ext-website ng-star-inserted h-auto w-[200px]"
                            src="https://wealthblock-platform.s3.amazonaws.com/image/8a9cfd7a6f2ef1596f0b381a540c9124.png"
                          />
                          </a>



                          <form className="ng-untouched ng-pristine ng-star-inserted ng-invalid w-full space-y-10" onSubmit={onSubmit}>
                            <h2 _ngcontent-dyt-c160="" className="text-center font-light text-2xl">Log In</h2>
                        
                           <div className="flex flex-col w-full space-y-6">

                            <input 
                              name="email"
                              type="email"
                              required
                              className="border rounded-md p-3 text-lg"
                              placeholder="Email *"
                              onChange={handleChange}
                            />

                            <input 
                              name="password"
                              type="password"
                              required
                              className="border rounded-md p-3 text-lg"
                              placeholder="Password *"
                              onChange={handleChange}
                            />

                            {/* center sign up button */}
                            <button
                              type="submit"
                              className="bg-primary-500 hover:bg-primary text-white rounded-md p-3 px-20 text-lg mx-auto"
                            >
                              LOG IN
                            </button>

                            {/* center dont' have an account sign up herer */}
                            <div className="text-center">
                              <span className="">Don't have an account? </span>
                              <Link
                                _ngcontent-lqc-c160=""
                                to="/portal/register"
                                className="text-blue-300"
                              >
                                Sign up here.
                              </Link>
                            </div>

                            {/* center forget password */}
                            <div className="text-center">
                              <Link
                                _ngcontent-lqc-c160=""
                                to="/portal/forgot-password"
                                className="text-blue-300"
                              >
                                Forgot password?
                              </Link>
                              </div>


                           </div>
                           

                          </form>
                        


                        </div>
              </div>
            </div>



    </>
  );
}

export default LoginPage;
