/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import { Domain, HelpOutline, People, Person } from "@mui/icons-material";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
// import Multiselect from 'multiselect-react-dropdown';

type Props = {
  onDone: (stepName: string) => void;
  form: any;
  setForm: any;
  setStep?: (step: number) => void;
};
export default function AccreditedStatus({ onDone, form, setForm, setStep }: Props) {

  const onSubmit = (e: any) => {
    e.preventDefault();
    onDone("accredited_status");
  };

  const handleChange = (e: any) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  return (
    <>
      <h3 className="text-[#737f8b] text-center font-sans text-[20px] font-extralight my-10">
        Please share some basic information to continue.
      </h3>

      <div className="px-10 w-full">
        <div className="bg-white rounded-lg shadow-2xl py-5 px-10 w-full">
          {/**/}
          <div className="flex flex-col items-center w-full space-y-3">
            <div className="text-gray-600 text-2xl">
              <PeopleAltIcon
                fontSize="large"
                className="!text-primary-500 mr-2"
              />
              {form.account_type === "entity" ? "Entity" : "Individual"}
            </div>

            {/* <Link
              to="/portal/setup?step=1"
              className="text-gray-600 hover:text-primary-500 text-xs"
            >
              Not the right account type? Click here to go back.
            </Link> */}

            <div className="text-sm w-full text-left flex flex-row space-x-3 items-center text-black">
                    <div className="text-sm text-left flex flex-row items-center text-black whitespace-nowrap">
                        <span className="flex items-center justify-center text-white bg-blue-500 rounded-full p-2 text-xs mr-2 h-6 w-6 text-center">
                        1
                        </span>
                        Basic Info
                    </div>
                    <div className="py-[1px] bg-gray-500 w-full"></div>
                    <div className="text-sm text-left flex flex-row items-center text-black whitespace-nowrap">
                      <span className="flex items-center justify-center text-white bg-primary-500 rounded-full p-2 text-xs mr-2 h-6 w-6 text-center">
                        2
                      </span>
                      Accreditation
                    </div>
            </div>

            <form
              className="ng-untouched ng-pristine ng-star-inserted ng-invalid w-full space-y-5"
              onSubmit={onSubmit}
            >
              <h1 className="text-xl !font-extralight mt-3 flex flex-row items-center justify-start gap-x-4">
              ACCREDITATION STATUS <HelpOutline fontSize="small" className="!text-gray-600"/>
              </h1>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                    <div 
                      className={`bg-white p-4 flex flex-col items-center text-center gap-y-2 border-[2px] rounded-lg cursor-pointer ${form.accredited_status == 'above_200000' ? "border-primary-500 text-primary-500" : "border-gray-300 text-gray-600"}`}
                      onClick={() => setForm({ ...form, accredited_status: "above_200000" })}
                      >
                        <Person fontSize="large" className="!text-6xl"/>
                        <div className="font-bold text-gray-600">My Individual Income is</div>
                        <div className="font-bold text-primary-500">Above $200,000</div>
                        <div className="text-gray-600">(for each of the last 2 years)</div>
                        <input type="radio" name="accredited_status" value="above_200000" 
                        onChange={handleChange}
                        checked={form.accredited_status == 'above_200000'}
                         className="mr-2 h-6 w-6 accent-primary-500 focus:accent-primary-500"/>
                    </div>
                    <div 
                      className={`bg-white p-4 flex flex-col items-center text-center gap-y-2 border-[2px] rounded-lg cursor-pointer ${form.accredited_status == 'above_300000' ? "border-primary-500 text-primary-500" : "border-gray-300 text-gray-600"}`}
                      onClick={() => setForm({ ...form, accredited_status: "above_300000" })}
                      >
                        <People fontSize="large" className="!text-6xl"/>
                        <div className="font-bold text-gray-600">My Joint Income with spouse is</div>
                        <div className="font-bold text-primary-500">Above $300,000</div>
                        <div className="text-gray-600">(for each of the last 2 years)</div>
                        <input type="radio" name="accredited_status" value="above_300000" 
                        onChange={handleChange}
                        checked={form.accredited_status == 'above_300000'}
                         className="mr-2 h-6 w-6 accent-primary-500 focus:accent-primary-500"/>
                    </div>
                    <div 
                      className={`bg-white p-4 flex flex-col items-center text-center gap-y-2 border-[2px] rounded-lg cursor-pointer ${form.accredited_status == 'above_1m' ? "border-primary-500 text-primary-500" : "border-gray-300 text-gray-600"}`}
                      onClick={() => setForm({ ...form, accredited_status: "above_1m" })}
                      >
                        <Domain fontSize="large" className="!text-6xl"/>
                        <div className="font-bold text-gray-600 text-center">My individual Net Worth or joint with spouse is</div>
                        <div className="font-bold text-primary-500">Above $1M</div>
                        <div className="text-gray-600">(excluding primary residence)</div>
                        <input type="radio" name="accredited_status" value="above_1m" 
                        onChange={handleChange}
                        checked={form.accredited_status == 'above_1m'}
                         className="mr-2 h-6 w-6 accent-primary-500 focus:accent-primary-500"/>
                    </div>
                    <div 
                      className={`bg-white p-4 flex flex-col items-center text-center gap-y-2 border-[2px] rounded-lg cursor-pointer ${form.accredited_status == 'above_5m' ? "border-primary-500 text-primary-500" : "border-gray-300 text-gray-600"}`}
                      onClick={() => setForm({ ...form, accredited_status: "above_5m" })}
                      >
                        <Person fontSize="large" className="!text-6xl"/>
                        <div className="font-bold text-gray-600 text-center">I own Total Investments</div>
                        <div className="font-bold text-primary-500">Above $5M</div>
                        <div className="text-gray-600">(including jointly with spouse)</div>
                        <input type="radio" name="accredited_status" value="above_5m" 
                        onChange={handleChange}
                        checked={form.accredited_status == 'above_5m'}
                         className="mr-2 h-6 w-6 accent-primary-500 focus:accent-primary-500"/>
                    </div>
                    <div 
                      className={`bg-white p-4 flex flex-col items-center text-center gap-y-2 border-[2px] rounded-lg cursor-pointer ${form.accredited_status == 'licensed_individual' ? "border-primary-500 text-primary-500" : "border-gray-300 text-gray-600"}`}
                      onClick={() => setForm({ ...form, accredited_status: "licensed_individual" })}
                      >
                        <Person fontSize="large" className="!text-6xl"/>
                        <div className="font-bold text-gray-600 text-center">I am a licensed individual that holds an active Series 7, Series 65, or Series 82 registration</div>
                        {/* <div className="font-bold text-primary-500">Above $300,000</div> */}
                        {/* <div className="text-gray-600">(for each of the last 2 years)</div> */}
                        <input type="radio" name="accredited_status" value="licensed_individual" 
                        onChange={handleChange}
                        checked={form.accredited_status == 'licensed_individual'}
                         className="mr-2 h-6 w-6 accent-primary-500 focus:accent-primary-500"/>
                    </div>
                    <div 
                      className={`bg-white p-4 flex flex-col items-center text-center gap-y-2 border-[2px] rounded-lg cursor-pointer ${form.accredited_status == 'not_accredited' ? "border-primary-500 text-primary-500" : "border-gray-300 text-gray-600"}`}
                      onClick={() => setForm({ ...form, accredited_status: "not_accredited" })}
                      >
                        <Person fontSize="large" className="!text-6xl"/>
                        <div className="font-bold text-gray-600 text-center">I am not an <br/> Accredited Investor</div>
                        {/* <div className="font-bold text-primary-500">Above $300,000</div> */}
                        {/* <div className="text-gray-600">(for each of the last 2 years)</div> */}
                        <input type="radio" name="accredited_status" value="not_accredited" 
                        onChange={handleChange}
                        checked={form.accredited_status == 'not_accredited'}
                         className="mr-2 h-6 w-6 accent-primary-500 focus:accent-primary-500"/>
                    </div>
                </div>

    
              <button
                type="submit"
                className="bg-primary-500 hover:bg-primary text-white text-sm rounded-md p-3 px-20 w-full"
              >
                NEXT
              </button>
              <button
                type="button"
                className="bg-white text-gray-600 hover:text-primary text-sm rounded-md p-3 px-20 w-full"
                onClick={(e) => {
                  e.stopPropagation();
                  setStep && setStep(2);
                  window.history.pushState({}, "", `?step=2`);
                }}
              >
                BACK
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
