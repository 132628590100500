import { Link, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const ErrorHandlerComponent = (props: any) => {
    const { error, errorMessage } = props;

    var errorCode = 401;
    var message = errorMessage ?? "";

    if (error == null) {
        toast.error("Unknown Error!");
        message = "Unknown Error!";
    } else if (error.response) {
        errorCode = error.response.status;
        if (error.response.status === 401 || error.response.status === 403) {
            toast.error("You are not authorized!");
            return <Navigate to="/admin/login" />;
        } else if (error.response.status === 404) {
            message = error.code +  ": 404 Error, " + error.config.url;
        }
    }

    return (
        <>
            <section className="flex items-center h-full p-16 dark:bg-gray-900 dark:text-gray-100">
                <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
                    <div className="max-w-md text-center">
                        <h2 className="mb-8 font-extrabold text-9xl dark:text-gray-600">
                            <span className="sr-only">Error</span>{errorCode}
                        </h2>
                        <p className="text-2xl font-semibold lg:text-3xl">Sorry, we couldn't process your command.</p>
                        <p className="mt-4 mb-8 dark:text-gray- font-bold italic">{message}</p>
                        <p className="mt-4 mb-8 dark:text-gray-400">But dont worry, you can login again, if you still have access.</p>
                        <Link rel="noopener noreferrer" to="/admin/login" className="px-8 py-3 bg-primary-900 text-white font-semibold rounded-full dark:bg-violet-400 dark:text-gray-900">Login Again</Link>
                    </div>
                </div>
            </section>
        </>
    );
};