import { doc, updateDoc } from "firebase/firestore";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { db } from "../../..";
import useToken from "../../../hooks/useToken";
import { helpers } from "../../../utilities/helpers";
import AppContext from "../../../AppContext";

export default function Profile() {

    const {token} = useToken();

    const context: any = useContext(AppContext);

    const [form, setForm] = useState((context.user || {}) as any);

    const handleChange = (e: any) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    }

    const onSubmit = async (e: any) => {
        e.preventDefault();

        const loading = toast.loading("Please wait...", { position: "top-center" });

    try {

      //update user
      const userRef = doc(db, "users", token);
      await updateDoc(userRef, {
        ...form,
        search_keywords: helpers.generateSearchKeywords(form.first_name + " " + form.last_name),
        setup_completed: true,
      });

      toast.dismiss(loading);

      toast.success("Profile saved successfully");
      
      //reload page
      window.location.href = "/portal/account?tab=profile&saved=true";

    } catch (error) {
      console.log(error);
      toast.dismiss(loading);
      toast.error("An error occurred. Please try again");
    }

    }

    return (
    <>
    

    {
        (new URLSearchParams(window.location.search)).get("saved") && (
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 my-2 rounded relative" role="alert">
                <strong className="font-bold">Success!</strong>
                <span className="block sm:inline"> Profile saved successfully</span>
            </div>
        )
      }

       <div className="bg-white rounded-lg shadow-2xl py-5 px-10 w-full">
          {/**/}
          <div className="flex flex-col items-center w-full space-y-3">
  
            <form
              className="ng-untouched ng-pristine ng-star-inserted ng-invalid w-full space-y-5"
              onSubmit={onSubmit}
            >
      
              <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-y-2 gap-x-3">
              <div className="flex flex-col w-full">
                <label className="font-normal text-xs">Net Worth *</label>
                <select
                  name="net_worth"
                  required
                  className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                  value={form.net_worth || ""}
                  onChange={handleChange}>
                    <option value="" disabled>Net Worth *</option>
                    <option value="less_100000">less than $100,000</option>
                    <option value="100001-250000">$100,001 - $250,000</option>
                    <option value="250001-500000">$250,001 - $500,000</option>
                    <option value="500001-1000000">$500,001 - $1,000,000</option>
                    <option value="1000001-5000000">$1,000,001 - $5,000,000</option>
                    <option value="more_5000000">more than $5,000,000</option>
                    <option value="">(not set)</option>
                </select>
                </div>

                <div className="flex flex-col w-full">
                  <label className="font-normal text-xs">Annual Net Income *</label>
                <input
                  name="annual_net_income"
                  type="text"
                  className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                  placeholder="Annual Net Income"
                  value={form.annual_net_income || ""}
                  onChange={handleChange}
                />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-y-2 gap-x-3">
              <div className="flex flex-col w-full">
                <label className="font-normal text-xs">Highest Education *</label>
                <select
                  name="highest_education"
                  required
                  className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                  value={form.highest_education || ""}
                  onChange={handleChange}>
                    <option value="" disabled>Highest Education *</option>
                    <option value="less_high_school">Less than High School</option>
                    <option value="high_school">High School Graduate</option>
                    <option value="some_college">Some College</option>
                    <option value="associate_degree">Associate's Degree</option>
                    <option value="bachelor_degree">Bachelor's Degree</option>
                    <option value="master_degree">Master's Degree</option>
                    <option value="decorate">Doctorate</option>
                    <option value="">(not set)</option>
                </select>
                </div>

                <div className="flex flex-col w-full">
                <label className="font-normal text-xs">Risk Tolerance *</label>
                <select
                  name="risk_tolerance"
                  required
                  className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                  value={form.risk_tolerance || ""}
                  onChange={handleChange}>
                    <option value="" disabled>Risk Tolerance *</option>
                    <option value="conservative">Conservative</option>
                    <option value="moderate">Moderate</option>
                    <option value="aggressive">Aggressive</option>
                    <option value="">(not set)</option>
                </select>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-y-2 gap-x-3">
              <div className="flex flex-col w-full">
                <label className="font-normal text-xs">Investment Experience *</label>
                <select
                  name="investment_experience"
                  required
                  className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                  value={form.investment_experience || ""}
                  onChange={handleChange}>
                    <option value="" disabled>Investment Experience *</option>
                    <option value="none">None</option>
                    <option value="limited">Limited</option>
                    <option value="good">Good</option>
                    <option value="extensive">Extensive</option>
                    <option value="">(not set)</option>
                </select>
                </div>

                <div className="flex flex-col w-full">
                <label className="font-normal text-xs">Age *</label>
                <select
                  name="age"
                  required
                  className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                  value={form.age || ""}
                  onChange={handleChange}>
                    <option value="" disabled>Age *</option>
                    <option value="under_30">Under 30</option>
                    <option value="30-39">30 - 39</option>
                    <option value="40-49">40 - 49</option>
                    <option value="50-59">50 - 59</option>
                    <option value="60-69">60 - 69</option>
                    <option value="70-79">70 - 79</option>
                    <option value="over_79">Over 79</option>
                    <option value="">(not set)</option>
                </select>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-y-2 gap-x-3">
              <div className="flex flex-col w-full">
                <label className="font-normal text-xs">Gender *</label>
                <select
                  name="gender"
                  required
                  className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                  value={form.gender || ""}
                  onChange={handleChange}>
                    <option value="" disabled>Gender *</option>
                    <option value="male">Male</option>
                    <option value="Femail">Female</option>
                    <option value="other">Other</option>
                    <option value="">(not set)</option>
                </select>
                </div>

               
              </div>
              

    

              <div></div>

              <div className="flex flex-row items-center justify-center">
              <button
                type="submit"
                className="bg-primary-500 hover:bg-primary text-white text-sm rounded-md p-3 px-10 md:px-20"
              >
                UPDATE PROFILE
              </button>
              </div>

            </form>
          </div>
        </div>

        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>

    </>)
    ;
}