/* eslint-disable react-hooks/exhaustive-deps */
'use client';

import { useState, useEffect } from "react";
import useDocumentTitle from "../../../hooks/userDocumentTitle";
import MainTemplate from "./MainTemplate";
import AttributesTable from "../components/AttributesTable";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../../..";



const Attributes = () => {
    useDocumentTitle('Attributes');

    const [attributes, setAttributes] = useState([] as any[]);
    const [attributesLoading, setAttributesLoading] = useState(false);
    const fetchAttributes =  async () => {
        setAttributesLoading(true);
        let attributes: any[] = [];
        //fetch orders from firebase, order by date created
        const attributesRef = collection(db, 'attributes');
        const q = query(attributesRef, orderBy('created_at', 'desc'));
        await getDocs(q).then((snapshot) => {
            snapshot.forEach((doc) => {
                attributes.push({ ...doc.data(), id: doc.id });
            });
        });
        setAttributes(attributes);
        setAttributesLoading(false);
    };


    useEffect(() => {
        fetchAttributes();
    }, []);

    const onTableUpdated = (attribute: any) => {
        fetchAttributes();
    }


    return (
        <>

            <MainTemplate title="Attributes">


                {/* <!-- Attributes --> */}
                <AttributesTable attributes={attributes} onTableUpdated={onTableUpdated} loading={attributesLoading} />

                <br />
                <br />
                <br />
                <br />



            </MainTemplate>

        </>
    );
}

export default Attributes;