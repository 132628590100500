/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react/jsx-no-target-blank */
import useDocumentTitle from "../hooks/userDocumentTitle";
import MainTemplate from "./MainTemplate";

export default function BlogPage() {
    useDocumentTitle("Blog");


  return (
    <>
      <MainTemplate pageType="others">
      
      <>
  <div
    data-collapse="medium"
    data-animation="default"
    data-duration={400}
    data-easing="ease"
    data-easing2="ease"
    role="banner"
    className="scroll-navbar navbar-2 w-hidden-medium w-hidden-small w-hidden-tiny w-nav"
    style={{
      willChange: "transform",
      transform:
        "translate3d(0px, -102%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
      transformStyle: "preserve-3d"
    }}
  >
    <div className="container-nav w-container">
      <a href="/" className="brand w-nav-brand" aria-label="home">
        <img
          src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e59a0c0a67bd6d170e34c37_FarmFundrLogo-01.png"
          width={150}
          alt="How to invest in farmland"
        />
      </a>
      <nav role="navigation" className="nav-menu w-nav-menu">
        <a
          href="/property"
          className="nav-link normal-nav black w-nav-link"
          style={{ maxWidth: 940 }}
        >
          Properties
        </a>
        <a
          href="/category/blog"
          aria-current="page"
          className="nav-link normal-nav black w-nav-link w--current"
          style={{ maxWidth: 940 }}
        >
          FARMLAND INVESTING BLOG
        </a>
        <a
          href="/about-us"
          className="nav-link normal-nav black w-nav-link"
          style={{ maxWidth: 940 }}
        >
          ABOUT US
        </a>
        <div
          data-delay={0}
          data-hover="false"
          className="nav-link w-dropdown"
          style={{ maxWidth: 940 }}
        >
          <div
            className="dropdown-toggle black w-dropdown-toggle"
            id="w-dropdown-toggle-0"
            aria-controls="w-dropdown-list-0"
            aria-haspopup="menu"
            aria-expanded="false"
            role="button"
            tabIndex={0}
          >
            <div className="text-block-22">resources</div>
            <div
              className="dropdown-arrow w-icon-dropdown-toggle"
              aria-hidden="true"
            />
          </div>
          <nav
            className="dropdown-list big w-dropdown-list"
            id="w-dropdown-list-0"
            aria-labelledby="w-dropdown-toggle-0"
          >
            <a
              href="/faqs"
              className="dropdown-link w-dropdown-link"
              tabIndex={0}
            >
              FAQ
            </a>
            <a
              href="/media"
              className="dropdown-link w-dropdown-link"
              tabIndex={0}
            >
              media
            </a>
            <a
              href="/helping-farmers-grow"
              className="dropdown-link w-dropdown-link"
              tabIndex={0}
            >
              HELPING&nbsp;FARMERS&nbsp;GROW
            </a>
            <a
              href="/sell-farmland"
              className="dropdown-link w-dropdown-link"
              tabIndex={0}
            >
              sell farmland
            </a>
            <a
              href="/custom-farmland-investments"
              className="dropdown-link w-dropdown-link"
              tabIndex={0}
            >
              farmfindr - Custom Investments
            </a>
          </nav>
        </div>
        <a
          href="/contact-us"
          className="nav-link normal-nav black w-nav-link"
          style={{ maxWidth: 940 }}
        >
          Contact
        </a>
        <a
          href="/portal/login"
          className="nav-link normal-nav login scroll w-nav-link"
          style={{ maxWidth: 940 }}
        >
          Login
        </a>
        <a
          href="/portal/register"
          className="nav-link normal-nav sign-up w-nav-link"
          style={{ maxWidth: 940 }}
        >
          Sign Up
        </a>
      </nav>
      <div
        className="menu-button gray w-nav-button"
        style={{ WebkitUserSelect: "text" }}
        aria-label="menu"
        role="button"
        tabIndex={0}
        aria-controls="w-nav-overlay-0"
        aria-haspopup="menu"
        aria-expanded="false"
      >
        <div className="icon w-icon-nav-menu" />
      </div>
    </div>
    <div className="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0" />
  </div>
  <div
    data-ix="hide-popup-on-initial"
    className="contact-full-wrapper"
    style={{ display: "none" }}
  >
    <div className="popup-div">
      <div className="popup-content-div">
        <div className="contact-wrapper-div in-devices">
          <div className="algin-center">
            <div>
              <div className="sub-tittle top">EVERY DOLLAR COUNTS</div>
              <h2 className="heading-4">Make a Donation Today</h2>
            </div>
          </div>
          <div className="top-margin">
            <div className="w-form">
              <form
                id="email-form"
                name="email-form"
                data-name="Email Form"
                method="get"
                data-wf-page-id="60663b672c7236a1694694b3"
                data-wf-element-id="4a8d00a1-f448-4f36-5c63-33359a84d817"
                aria-label="Email Form"
              >
                <label htmlFor="Amount" className="field-label center">
                  Amount
                </label>
                <input
                  className="text-field-popup w-input"
                  maxLength={256}
                  name="Amount"
                  data-name="Amount"
                  placeholder="$"
                  type="text"
                  id="Amount"
                  required
                />
                <label htmlFor="Name-5" className="field-label center">
                  Your Details
                </label>
                <input
                  className="text-field-popup w-input"
                  maxLength={256}
                  name="Name-4"
                  data-name="Name 4"
                  placeholder="Enter your name"
                  type="text"
                  id="Name-5"
                />
                <input
                  className="text-field-popup w-input"
                  maxLength={256}
                  name="Surname"
                  data-name="Surname"
                  placeholder="Enter your surname"
                  type="email"
                  id="Surname"
                  required
                />
                <input
                  className="text-field-popup w-input"
                  maxLength={256}
                  name="Enter-your-email-address"
                  data-name="Enter your email address"
                  placeholder="Enter your email address"
                  type="text"
                  id="Enter-your-email-address"
                  required
                />
                <input
                  className="text-field-popup w-input"
                  maxLength={256}
                  name="Additional-Information"
                  data-name="Additional Information"
                  placeholder="Additional Information"
                  type="text"
                  id="Additional-Information"
                  required
                />
                <div>
                  <div className="algin-center">
                    <input
                      type="submit"
                      data-wait="Please wait..."
                      className="button w-button"
                      defaultValue="Place donation"
                    />
                  </div>
                </div>
              </form>
              <div
                className="w-form-done"
                tabIndex={-1}
                role="region"
                aria-label="Email Form success"
              >
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div
                className="w-form-fail"
                tabIndex={-1}
                role="region"
                aria-label="Email Form failure"
              >
                <div>Oops! Something went wrong while submitting the form</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      data-w-id="0aa23d50-42fe-dba2-e74c-5c7dbfbeb5e8"
      className="close-button"
    />
  </div>
  <div data-ix="slide-in-scroll-navigation" className="page-header blog-2">
    <div className="w-container">
      <div className="div-text _2">
        <h1
          data-w-id="4eec7733-154e-1548-f5e1-247f470ab3cd"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
          className="heading center white-font"
        >
          Latest Posts
        </h1>
        <div
          data-w-id="4eec7733-154e-1548-f5e1-247f470ab3cf"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
          className="separated-div-wrapper"
        >
          <div className="separator header" />
          <div className="separator header center" />
          <div className="separator header" />
          <p
            data-w-id="4eec7733-154e-1548-f5e1-247f470ab3d3"
            style={{
              opacity: 1,
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d"
            }}
            className="paragraph center white"
          >
            Here, you can find the latest articles{" "}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div className="blog-post blog">
    <div className="w-container">
      <div className="blog-div over">
        <div className="w-dyn-list">
          <div role="list" className="w-clearfix w-dyn-items w-row">
            <div
              role="listitem"
              className="dynamic-item w-dyn-item w-col w-col-4"
            >
              <a
                href="#"
                // href="/blog/useful-tips-for-becoming-a-successful-agriculture-investor"
                className="featured-wrapper w-inline-block"
              >
                <div className="div-block-5">
                  <div
                    style={{
                      backgroundImage:
                        'url("https://assets-global.website-files.com/5e5717f541472a2c2b58bd84/63efe7c6cb3df45d8d1573d4_Blog%20Image%20-%202022%20(1).png")'
                    }}
                    className="featured-image"
                  />
                </div>
                <div className="feafured-text">
                  <div className="featured-title">
                    Useful Tips for Becoming a Successful Agriculture Investor
                  </div>
                  <div className="featured-description">
                    Investing in agriculture can provide long-term profits, and
                    with the right approach, you can become a successful
                    agriculture investor.{" "}
                  </div>
                  <div className="featured-details">
                    <div className="w-clearfix">
                      <img
                        alt=""
                        src=""
                        className="author-image w-dyn-bind-empty"
                      />
                      <div className="author lite w-dyn-bind-empty" />
                      <div className="thumbnail date">February 6, 2023</div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div
              role="listitem"
              className="dynamic-item w-dyn-item w-col w-col-4"
            >
              <a
                href="#"
                // href="/blog/questions-you-should-ask-yourself-before-purchasing-farmland"
                className="featured-wrapper w-inline-block"
              >
                <div className="div-block-5">
                  <div
                    style={{
                      backgroundImage:
                        'url("https://assets-global.website-files.com/5e5717f541472a2c2b58bd84/63efd44026ac7b0d241a099d_Blog%20Image%20-%202022.png")'
                    }}
                    className="featured-image"
                  />
                </div>
                <div className="feafured-text">
                  <div className="featured-title">
                    Questions You Should Ask Yourself Before Purchasing Farmland
                  </div>
                  <div className="featured-description">
                    The idea of owning farmland appeals to many investors,
                    however, those who lack experience in farmland will want to
                    ask themselves some key questions before purchasing a farm.
                  </div>
                  <div className="featured-details">
                    <div className="w-clearfix">
                      <img
                        alt=""
                        src=""
                        className="author-image w-dyn-bind-empty"
                      />
                      <div className="author lite w-dyn-bind-empty" />
                      <div className="thumbnail date">January 9, 2023</div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div
              role="listitem"
              className="dynamic-item w-dyn-item w-col w-col-4"
            >
              <a
                href="#"
                // href="/blog/why-invest-in-farmland-instead-of-stocks"
                className="featured-wrapper w-inline-block"
              >
                <div className="div-block-5">
                  <div
                    style={{
                      backgroundImage:
                        'url("https://assets-global.website-files.com/5e5717f541472a2c2b58bd84/632b6388f89abb5591f367ec_Blog%20Image%20-%202022%20(6).png")'
                    }}
                    className="featured-image"
                  />
                </div>
                <div className="feafured-text">
                  <div className="featured-title">
                    Why Invest in Farmland Instead of Stocks?
                  </div>
                  <div className="featured-description">
                    While many investors choose to focus their money on the
                    stock market, there’s much to be said for considering
                    farmland as a dependable way to build up your portfolio.
                  </div>
                  <div className="featured-details">
                    <div className="w-clearfix">
                      <img
                        alt=""
                        src=""
                        className="author-image w-dyn-bind-empty"
                      />
                      <div className="author lite w-dyn-bind-empty" />
                      <div className="thumbnail date">September 19, 2022</div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div
              role="listitem"
              className="dynamic-item w-dyn-item w-col w-col-4"
            >
              <a
                href="#"
                // href="/blog/the-advantages-and-disadvantages-of-selling-or-renting-out-farmland"
                className="featured-wrapper w-inline-block"
              >
                <div className="div-block-5">
                  <div
                    style={{
                      backgroundImage:
                        'url("https://assets-global.website-files.com/5e5717f541472a2c2b58bd84/62e968c00af872197307c92f_The%20Advantages%20and%20Disadvantages%20of%20Selling%20or%20Renting%20Out%20Farmland%20(3).png")'
                    }}
                    className="featured-image"
                  />
                </div>
                <div className="feafured-text">
                  <div className="featured-title">
                    The Advantages and Disadvantages of Selling or Renting Out
                    Farmland
                  </div>
                  <div className="featured-description">
                    Whether you have recently inherited a family farm or are in
                    the process of getting ready to retire from your
                    agricultural business, you’ll be faced with options to rent
                    or sell.
                  </div>
                  <div className="featured-details">
                    <div className="w-clearfix">
                      <img
                        alt=""
                        src=""
                        className="author-image w-dyn-bind-empty"
                      />
                      <div className="author lite w-dyn-bind-empty" />
                      <div className="thumbnail date">August 22, 2022</div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div
              role="listitem"
              className="dynamic-item w-dyn-item w-col w-col-4"
            >
              <a
                href="#"
                // href="/blog/what-are-the-main-factors-that-affect-farmland-values"
                className="featured-wrapper w-inline-block"
              >
                <div className="div-block-5">
                  <div
                    style={{
                      backgroundImage:
                        'url("https://assets-global.website-files.com/5e5717f541472a2c2b58bd84/62db0bc3cbf74336e6301a12_Blog%20Image%20-%202022%20(4).png")'
                    }}
                    className="featured-image"
                  />
                </div>
                <div className="feafured-text">
                  <div className="featured-title">
                    What are the main factors that affect farmland values?
                  </div>
                  <div className="featured-description">
                    The value of farmland in America is increasing rapidly. It
                    is important for any prospective investors understand the
                    factors that affect farmland values.
                  </div>
                  <div className="featured-details">
                    <div className="w-clearfix">
                      <img
                        alt=""
                        src=""
                        className="author-image w-dyn-bind-empty"
                      />
                      <div className="author lite w-dyn-bind-empty" />
                      <div className="thumbnail date">July 25, 2022</div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div
              role="listitem"
              className="dynamic-item w-dyn-item w-col w-col-4"
            >
              <a
                href="#"
                // href="/blog/how-an-investment-in-agriculture-helps-address-food-insecurity"
                className="featured-wrapper w-inline-block"
              >
                <div className="div-block-5">
                  <div
                    style={{
                      backgroundImage:
                        'url("https://assets-global.website-files.com/5e5717f541472a2c2b58bd84/62cdce7d50c6daf913a3770b_Blog%20Image%20-%202022%20(3).png")'
                    }}
                    className="featured-image"
                  />
                </div>
                <div className="feafured-text">
                  <div className="featured-title">
                    How an investment in agriculture helps address food
                    insecurity
                  </div>
                  <div className="featured-description">
                    A chief issue of concern today is food security. Those with
                    a desire to do something about the problem may feel that
                    they can do their part by investing in farmland.
                  </div>
                  <div className="featured-details">
                    <div className="w-clearfix">
                      <img
                        alt=""
                        src=""
                        className="author-image w-dyn-bind-empty"
                      />
                      <div className="author lite w-dyn-bind-empty" />
                      <div className="thumbnail date">July 18, 2022</div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="featured-blog-container w-container">
      <h1 className="heading gray center">FEATURED&nbsp;BLOG&nbsp;POSTS</h1>
      <div className="w-dyn-list">
        <div role="list" className="w-clearfix w-dyn-items w-row">
          <div
            role="listitem"
            className="dynamic-item w-dyn-item w-col w-col-4"
          >
            <a
              href="#"
            //   href="/blog/useful-tips-for-becoming-a-successful-agriculture-investor"
              className="featured-wrapper-3 w-inline-block"
            >
              <div className="div-block-5">
                <div
                  style={{
                    backgroundImage:
                      'url("https://assets-global.website-files.com/5e5717f541472a2c2b58bd84/63efe7c6cb3df45d8d1573d4_Blog%20Image%20-%202022%20(1).png")'
                  }}
                  className="featured-image"
                />
              </div>
              <div className="feafured-text">
                <div className="featured-title">
                  Useful Tips for Becoming a Successful Agriculture Investor
                </div>
                <div className="featured-description">
                  Investing in agriculture can provide long-term profits, and
                  with the right approach, you can become a successful
                  agriculture investor.{" "}
                </div>
                <div className="featured-details">
                  <div className="w-clearfix">
                    <img
                      alt=""
                      src=""
                      className="author-image w-dyn-bind-empty"
                    />
                    <div className="author lite w-dyn-bind-empty" />
                    <div className="thumbnail date">February 6, 2023</div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div
            role="listitem"
            className="dynamic-item w-dyn-item w-col w-col-4"
          >
            <a
              href="#"
            //   href="/blog/questions-you-should-ask-yourself-before-purchasing-farmland"
              className="featured-wrapper-3 w-inline-block"
            >
              <div className="div-block-5">
                <div
                  style={{
                    backgroundImage:
                      'url("https://assets-global.website-files.com/5e5717f541472a2c2b58bd84/63efd44026ac7b0d241a099d_Blog%20Image%20-%202022.png")'
                  }}
                  className="featured-image"
                />
              </div>
              <div className="feafured-text">
                <div className="featured-title">
                  Questions You Should Ask Yourself Before Purchasing Farmland
                </div>
                <div className="featured-description">
                  The idea of owning farmland appeals to many investors,
                  however, those who lack experience in farmland will want to
                  ask themselves some key questions before purchasing a farm.
                </div>
                <div className="featured-details">
                  <div className="w-clearfix">
                    <img
                      alt=""
                      src=""
                      className="author-image w-dyn-bind-empty"
                    />
                    <div className="author lite w-dyn-bind-empty" />
                    <div className="thumbnail date">January 9, 2023</div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div
            role="listitem"
            className="dynamic-item w-dyn-item w-col w-col-4"
          >
            <a
              href="#"
            //   href="/blog/why-invest-in-farmland-instead-of-stocks"
              className="featured-wrapper-3 w-inline-block"
            >
              <div className="div-block-5">
                <div
                  style={{
                    backgroundImage:
                      'url("https://assets-global.website-files.com/5e5717f541472a2c2b58bd84/632b6388f89abb5591f367ec_Blog%20Image%20-%202022%20(6).png")'
                  }}
                  className="featured-image"
                />
              </div>
              <div className="feafured-text">
                <div className="featured-title">
                  Why Invest in Farmland Instead of Stocks?
                </div>
                <div className="featured-description">
                  While many investors choose to focus their money on the stock
                  market, there’s much to be said for considering farmland as a
                  dependable way to build up your portfolio.
                </div>
                <div className="featured-details">
                  <div className="w-clearfix">
                    <img
                      alt=""
                      src=""
                      className="author-image w-dyn-bind-empty"
                    />
                    <div className="author lite w-dyn-bind-empty" />
                    <div className="thumbnail date">September 19, 2022</div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="call-to-action">
    <div className="w-container">
      <div className="w-row">
        <div className="w-col w-col-9">
          <h2 className="cta-text">
            Start Exploring High Profit Potential Farmland Investments
          </h2>
        </div>
        <div className="w-col w-col-3">
          <a
            href="/portal/login"
            data-ix="show-contact-wrapper-on-click"
            data-w-id="9d9f4086-7586-69f8-c80b-ecaa8fc12354"
            target="_blank"
            className="button outline w-button"
          >
            GET&nbsp;ACCESS Now
          </a>
        </div>
      </div>
    </div>
  </div>
  <div className="footer dark-bootom" />
  <div id="o1fnirt773a1710281533910" className="widget-hidden">
    <iframe
      src="about:blank"
      frameBorder={0}
      scrolling="no"
      width="64px"
      height="60px"
      style={{
        outline: "none !important",
        visibility: "visible",
        resize: "none",
        boxShadow: "none !important",
        overflow: "visible !important",
        background: "none !important",
        opacity: "1 !important",
        filter: "alpha(opacity=100) !important",
        msFilter:
          "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
        MozOpacity: "1 !important",
        KhtmlOpacity: "1 !important",
        top: "auto !important",
        right: "auto !important",
        bottom: "20px !important",
        left: "20px !important",
        position: "fixed",
        border: "0 !important",
        minHeight: "60px !important",
        minWidth: "64px !important",
        maxHeight: "60px !important",
        maxWidth: "64px !important",
        padding: "0 !important",
        margin: "0 !important",
        MozTransitionProperty: "none !important",
        WebkitTransitionProperty: "none !important",
        OTransitionProperty: "none !important",
        transitionProperty: "none !important",
        transform: "none !important",
        WebkitTransform: "none !important",
        msTransform: "none !important",
        width: "64px !important",
        height: "60px !important",
        display: "block !important",
        zIndex: "1000001 !important",
        backgroundColor: "transparent !important",
        cursor: "none !important",
        float: "none",
        borderRadius: "unset !important",
        pointerEvents: "auto",
        clip: "auto !important",
        colorScheme: "light !important"
      }}
      id="yrgrhcqkphoo1710281533944"
      className=""
      title="chat widget"
    />
    <iframe
      src="about:blank"
      frameBorder={0}
      scrolling="no"
      width="350px"
      height="520px"
      style={{
        outline: "none !important",
        visibility: "visible",
        resize: "none",
        boxShadow: "none !important",
        overflow: "visible !important",
        background: "none !important",
        opacity: "1 !important",
        filter: "alpha(opacity=100) !important",
        msFilter:
          "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
        MozOpacity: "1 !important",
        KhtmlOpacity: "1 !important",
        top: "auto !important",
        right: "auto !important",
        bottom: "90px !important",
        left: "10px !important",
        position: "fixed",
        border: "0 !important",
        minHeight: "520px !important",
        minWidth: "350px !important",
        maxHeight: "520px !important",
        maxWidth: "350px !important",
        padding: "0 !important",
        margin: "0 !important",
        MozTransitionProperty: "none !important",
        WebkitTransitionProperty: "none !important",
        OTransitionProperty: "none !important",
        transitionProperty: "none !important",
        transform: "none !important",
        WebkitTransform: "none !important",
        msTransform: "none !important",
        width: "350px !important",
        height: "520px !important",
        display: "none !important",
        zIndex: "auto !important",
        backgroundColor: "transparent !important",
        cursor: "none !important",
        float: "none",
        borderRadius: "5px !important",
        pointerEvents: "auto",
        clip: "auto !important",
        colorScheme: "light !important"
      }}
      id="zavqb6en0151710281534009"
      className=""
      title="chat widget"
    />
    <iframe
      src="about:blank"
      frameBorder={0}
      scrolling="no"
      width="360px"
      height="145px"
      style={{
        outline: "none !important",
        visibility: "visible",
        resize: "none",
        boxShadow: "none !important",
        overflow: "visible !important",
        background: "none !important",
        opacity: "1 !important",
        filter: "alpha(opacity=100) !important",
        msFilter:
          "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
        MozOpacity: "1 !important",
        KhtmlOpacity: "1 !important",
        top: "auto !important",
        right: "auto !important",
        bottom: 100,
        left: "20px !important",
        position: "fixed",
        border: "0 !important",
        minHeight: "145px !important",
        minWidth: "360px !important",
        maxHeight: "145px !important",
        maxWidth: "360px !important",
        padding: "0 !important",
        margin: "0 !important",
        MozTransitionProperty: "none !important",
        WebkitTransitionProperty: "none !important",
        OTransitionProperty: "none !important",
        transitionProperty: "none !important",
        transform: "none !important",
        WebkitTransform: "none !important",
        msTransform: "none !important",
        width: "360px !important",
        height: "145px !important",
        display: "none !important",
        zIndex: "auto !important",
        backgroundColor: "transparent !important",
        cursor: "none !important",
        float: "none",
        borderRadius: "unset !important",
        pointerEvents: "auto",
        clip: "auto !important",
        colorScheme: "light !important"
      }}
      id="nhu3hpb9lnpo1710281533987"
      className=""
      title="chat widget"
    />
    <iframe
      src="about:blank"
      frameBorder={0}
      scrolling="no"
      width="146px"
      height="85px"
      style={{
        outline: "none !important",
        visibility: "visible",
        resize: "none",
        boxShadow: "none !important",
        overflow: "visible !important",
        background: "none !important",
        opacity: "1 !important",
        filter: "alpha(opacity=100) !important",
        msFilter:
          "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
        MozOpacity: "1 !important",
        KhtmlOpacity: "1 !important",
        top: "auto !important",
        right: "auto !important",
        bottom: "82px !important",
        left: "20px !important",
        position: "fixed",
        border: "0 !important",
        minHeight: "85px !important",
        minWidth: "146px !important",
        maxHeight: "85px !important",
        maxWidth: "146px !important",
        padding: "0 !important",
        margin: "0px 0 0 0 !important",
        MozTransitionProperty: "none !important",
        WebkitTransitionProperty: "none !important",
        OTransitionProperty: "none !important",
        transitionProperty: "none !important",
        transform: "rotate(0deg) translateZ(0)",
        WebkitTransform: "rotate(0deg) translateZ(0)",
        msTransform: "rotate(0deg) translateZ(0)",
        width: "146px !important",
        height: "85px !important",
        display: "none !important",
        zIndex: "1000002 !important",
        backgroundColor: "transparent !important",
        cursor: "none !important",
        float: "none",
        borderRadius: "unset !important",
        pointerEvents: "auto",
        clip: "auto !important",
        colorScheme: "light !important",
        MozTransform: "rotate(0deg) translateZ(0)",
        OTransform: "rotate(0deg) translateZ(0)",
        transformOrigin: 0,
        MozTransformOrigin: 0,
        WebkitTransformOrigin: 0,
        OTransformOrigin: 0,
        msTransformOrigin: 0
      }}
      id="k3fn0d8va7mo1710281533965"
      className=""
      title="chat widget"
    />
  </div>
</>


      
      </MainTemplate>
    </>
  );
}
