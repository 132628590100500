/* eslint-disable eqeqeq */
import { useContext, useEffect, useState } from "react";
import { Timestamp, doc, updateDoc } from "firebase/firestore";
import { db } from "../../../..";
import { toast } from "react-toastify";
import AppContext from "../../../../AppContext";
import { helpers } from "../../../../utilities/helpers";
import SkyeModal from "../../../admin/components/SkyeModal";
import Currency from "../../../admin/components/Currency";
import StatusLabel from "../../../admin/components/StatusLabel";
// import StatusLabel from "../../components/StatusLabel";
// import { useNavigate } from "react-router-dom";

type Props = {
  title?: string;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  order?: any;
  setOrder: (order: any) => void;
  onModalUpdated: (order: any) => void;
};
const PortalOrderModal = ({
  showModal,
  setShowModal,
  order,
  setOrder,
  onModalUpdated,
}: Props) => {

  // const navigate = useNavigate();

  const context: any = useContext(AppContext);

  const [orderData, setOrderData] = useState(order ?? {});

  useEffect(() => {
    setOrderData(order);
  }, [order, orderData.notes]);

  // const onInputChanged = (e: any) => {
  //   setOrderData({ ...orderData, [e.target?.name]: e.target?.value });
  // };

  const onFormSubmit = async (e: any) => {
    e.preventDefault();


    //close modal
    setShowModal(false);

    return;

    // eslint-disable-next-line no-unreachable
    const loading = toast.loading("Updating invoice...");

    //remove other fields except status and notes
    let formData: any = {
      status: orderData.status,
      //add who updated the order
      updated_by: {
        id: context.user.id,
        doc_number: context.user.doc_number ?? 0,
        first_name: context.user.first_name,
        last_name: context.user.last_name,
        email: context.user.email,
        phone: context.user.phone,
        role: context.user.role,
      }
    };

    //update updated_at
    formData.updated_at = Timestamp.now();

    //update order
    await updateDoc(doc(db, "orders", orderData.id), formData);


    //close modal
    setShowModal(false);

    //remove loading
    toast.dismiss(loading);

    toast.success("Offer updated successfully!");

    setOrder({});

    onModalUpdated(formData);
  };

  return (
    <>
      <SkyeModal
        title={orderData.id ? `Investment #${orderData.doc_number}` : "Investment"}
        size="max-w-2xl mt-20"
        flex={false}
        showModal={showModal}
        setShowModal={() => {
          setShowModal(false);
          setOrder({});
        }}
      >
        <form
          className="space-y-6 text-left"
          id="order-edit-form"
          onSubmit={onFormSubmit}
          method="POST"
          encType="multipart/form-data"
        >
          {/*body*/}
          <div className="relative p-6 flex-auto">
            {/* body */}

            {/* date */}
            <div className="col-span-6 lg:col-span-3 mb-4">
              <div className="grid grid-cols-2 items-center">
                <div className="block text-sm font-bold text-black">
                  Date:
                </div>
                <div className="font-bold">{orderData?.created_at?.toDate()?.toLocaleDateString()}</div>
              </div>
            </div>

            {/* status */}
            {
              orderData.type != "contact" && (
            <div className="col-span-6 lg:col-span-3 mb-4">
              <div className="grid grid-cols-2 items-center">
                <label
                  htmlFor="status"
                  className="block text-sm font-bold text-black   "
                >
                  Order Status:
                </label>
                <div className="font-bold"><StatusLabel status={orderData.status} /> </div>
              </div>
            </div>
              )
            }
            {/* payment status */}
            
            <div className="col-span-6 lg:col-span-3 mb-4">
              <div className="grid grid-cols-2 items-center">
                <div className="block text-sm font-bold text-black">
                  Payment Status:
                </div>
                <div className="flex items-center">
                  <StatusLabel status={orderData.payment_status}
                  className="px-5"
                   />
                </div>
              </div>
            </div>

            {/* items */}
            {
              orderData.product && (
                <div className="flex flex-col">
                  <div className="-my-2 overflow-x-auto lg:-mx-8">
                    <div className="py-2 align-middle inline-block w-full lg:px-6">
                      <div className="shadow overflow-hidden border-b border-gray-200 lg:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50 dark:bg-gray-700">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
                              >
                                Item
                              </th>
                              {/* <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                                                            Description
                                                        </th> */}
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
                              >
                                Unit
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
                              >
                                Price
                              </th>
                              {/* <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
                              >
                                Total
                              </th> */}
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                            {[orderData.product]?.map((item: any, index: number) => item && (
                              <tr key={index}>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="text-sm text-gray-900 dark:text-gray-100">
                                    {item?.name}
                                  </div>
                                  {item.message && (
                                    <div className="text-xs text-gray-500 dark:text-gray-100 mt-1">
                                      <div className="text-black underline">Message</div>
                                      <div 
                                      dangerouslySetInnerHTML={{
                                        __html: item?.message?.replace(
                                          /(?:\r\n|\r|\n)/g,
                                          "<br>"
                                        ),
                                      }}>
                                      </div>
                                    </div>
                                  )}
                                </td>
                                {/* <td className="px-6 py-4 whitespace-nowrap">
                                                                <div className="text-sm text-gray-900 dark:text-gray-100">{item.description}</div>
                                                            </td> */}
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="text-sm text-gray-900 dark:text-gray-100">
                                    {item.quantity ?? 1}
                                  </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="text-sm text-gray-900 dark:text-gray-100">
                                    <Currency />
                                    {helpers.money(item.price)}
                                  </div>
                                </td>
                                {/* <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">
                                    <Currency />
                                    {helpers.money(item.subtotal)}
                                  </div>
                                </td> */}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }

{
              orderData.type == "contact" && (
                <div className="text-sm text-gray-500 dark:text-gray-100 mt-1 shadow rounded p-4">
                      <div className="text-black">Message</div>
                                      <div 
                                      dangerouslySetInnerHTML={{
                                        __html: orderData?.message?.replace(
                                          /(?:\r\n|\r|\n)/g,
                                          "<br>"
                                        ),
                                      }}>
                    </div>
                </div>
              )
            }

            {/* total table */}
            {
              orderData.type != "contact" && (
                <div className="flex flex-col mt-4">
                  <div className="-my-2 overflow-x-auto lg:-mx-6">
                    <div className="py-2 align-middle inline-block w-full lg:px-6">
                      <div className="shadow overflow-hidden border-b border-gray-200 lg:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                            {/* <tr>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">
                                  Subtotal
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-900 dark:text-gray-100 font-bold text-right">
                                  <Currency />
                                  {helpers.money(orderData.subtotal)}
                                </div>
                              </td>
                            </tr> */}
                            {/* <tr>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">Tax</div>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="text-sm text-gray-900 dark:text-gray-100 font-bold  text-right"><Currency />{orderData.tax}</div>
                                                        </td>
                                                    </tr> */}
                            <tr>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">
                                  Total
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-900 dark:text-gray-100 font-bold  text-right">
                                  <Currency />
                                  {helpers.money(orderData.total)}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }

            {/* Client */}
            {/* <div className="flex flex-col mt-4">
              <label
                htmlFor="customer"
                className="block text-sm font-bold text-black   "
              >
                Info:
              </label>
              <div className="flex flex-col mt-1">
                <div className="-my-2 overflow-x-auto lg:-mx-6">
                  <div className="py-2 align-middle inline-block w-full lg:px-6">
                    <div className="shadow overflow-hidden border-b border-gray-200 lg:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200">
                        <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700 divide-none">
                          <tr>
                            <td className="px-6 py-2 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">
                                Name
                              </div>
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100">
                                {orderData.user?.first_name} {orderData.user?.last_name}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="px-6 py-2 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">
                                Contact
                              </div>
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100">
                                {orderData.user?.phone || ""}
                                {" "}
                                {orderData.user?.email || "N/A"}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* bank transfer info */}
            {
              orderData.payment?.payment_method == "bank" && (orderData.payment_status != "success" && orderData.payment_status != "paid") && (
              <div className="flex flex-col mt-4">
                <label
                  htmlFor="customer"
                  className="block text-sm font-bold text-black   "
                >
                  Bank Transfer:
                </label>
                <div className="flex flex-col mt-1">
                  <div className="-my-2 overflow-x-auto lg:-mx-6">
                    <div className="py-2 align-middle inline-block w-full lg:px-6">
                      <div className="shadow overflow-hidden border-b border-gray-200 lg:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700 divide-none">
                            <tr>
                              <td className="px-2 md:px-6 py-2 whitespace-nowrap">
                                <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">
                                  Bank Name
                                </div>
                              </td>
                              <td className="px-2 md:px-6 py-2 whitespace-nowrap">
                                <div className="text-sm text-gray-900 dark:text-gray-100">
                                  Navy Federal Credit Union
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="px-2 md:px-6 py-2 whitespace-nowrap">
                                <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">
                                  Routing Number
                                </div>
                              </td>
                              <td className="px-2 md:px-6 py-2 whitespace-nowrap">
                                <div className="text-sm text-gray-900 dark:text-gray-100">
                                  256074974
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="px-2 md:px-6 py-2 whitespace-nowrap">
                                <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">
                                  Account Number
                                </div>
                              </td>
                              <td className="px-2 md:px-6 py-2 whitespace-nowrap">
                                <div className="text-sm text-gray-900 dark:text-gray-100">
                                7178807207
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="px-2 md:px-6 py-2 whitespace-nowrap">
                                <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">
                                  Account Name
                                </div>
                              </td>
                              <td className="px-2 md:px-6 py-2 whitespace-nowrap">
                                <div className="text-sm text-gray-900 dark:text-gray-100">
                                SHAWN A GLENNEY
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              )
            }

            
          </div>
          {/*footer*/}
          <div className="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  type="submit"
                  className="bg-black hover:bg-black text-white font-semibold py-2 px-20 text-xl rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2"
                >
                  OK
                </button>
          </div>
        </form>
      </SkyeModal>
    </>
  );
};

export default PortalOrderModal;
