/* eslint-disable eqeqeq */
import { Button } from "@material-tailwind/react";
import { useContext, useEffect, useRef, useState } from "react";
import {
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { helpers } from "../../../utilities/helpers";
import { toast } from "react-toastify";
import AppContext from "../../../AppContext";
import { db } from "../../..";
import { CircularProgress } from "@mui/material";
import StatusLabel from "../../admin/components/StatusLabel";
import PortalOrderModal from "./modals/PortalOrderModal";
import Currency from "../../admin/components/Currency";

export default function Investments() {
  const context: any = useContext(AppContext);

  const orderModalRef = useRef(null as any);

  const [orders, setOrders] = useState([] as any[]);
  const [ordersLoading, setOrdersLoading] = useState(true);

  const fetchOrders = async (
    name = "",
    fromDate = "",
    toDate = "",
    id = ""
  ) => {
    setOrdersLoading(true);
    let orders: any[] = [];
    //fetch orders from firebase
    const ordersRef = collection(db, "orders");

    var queries: any[] = [
        where("user_id", "==", context.user.id),
        where("type", "!=", "contact"),
    ];

    if (name && name.length > 0) {
      const searchWords = helpers.generateSearchKeywords(name);
      queries.push(where("search_keywords", "array-contains-any", searchWords));
    } else if (fromDate && fromDate.length > 0 && toDate && toDate.length > 0) {
      const from = Timestamp.fromDate(new Date(fromDate));
      const to = Timestamp.fromDate(new Date(toDate));
      queries.push(where("created_at", ">=", from));
      queries.push(where("created_at", "<=", to));
    } else if (id && id.length > 0) {
      queries.push(where("doc_number", "==", parseInt(id)));
    }

    queries.push(orderBy("created_at", "desc"));

    const q = query(ordersRef, ...queries);
    await getDocs(q).then((snapshot) => {
      // var totalSales = 0;
      // var totalPending = 0;
      // var totalPaid = 0;

      // var totalSalesAmount = 0.0;
      // var totalPendingAmount = 0.0;
      // var totalPaidAmount = 0.0;

      snapshot.forEach((doc) => {
        const data = doc.data();
        orders.push({ ...data, id: doc.id });

        //calculate totals
        // if (data.payment_status === "paid") {
        //   totalPaid++;
        //   totalPaidAmount += data.total;
        // } else if (data.payment_status === "pending") {
        //   totalPending++;
        //   totalPendingAmount += data.total;
        // }

        // totalSales++;
        // totalSalesAmount += data.total;
      });

      // setTotalSales(totalSales);
      // setTotalPending(totalPending);
      // setTotalPaid(totalPaid);

      // setTotalSalesAmount(totalSalesAmount);
      // setTotalPendingAmount(totalPendingAmount);
      // setTotalPaidAmount(totalPaidAmount);
    });
    setOrders(orders);
    setOrdersLoading(false);

    //get id from url
    const urlParams = new URLSearchParams(window.location.search);
    const getId = urlParams.get("id");

    if (name == "" && fromDate == "" && toDate == "" && id == "" && getId) {
      //if getId exists in orders, then open the modal
      const order = orders.find((o) => o.id == getId);
      if (order) {
        orderModalRef.current?.setOrder(order);
        orderModalRef.current?.setShowModal(true);
      } else {
        const orderLoading = toast.loading("Loading Order...");
        //get the order from firebase
        const orderRef = doc(db, "orders", getId);
        const docSnap = await getDoc(orderRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          orderModalRef.current?.setOrder({ ...data, id: docSnap.id });
          orderModalRef.current?.setShowModal(true);
        }
        toast.dismiss(orderLoading);
      }
    }
  };

  const [showModal, setShowModal] = useState(false);
  const [order, setOrder] = useState({});

  useEffect(() => {
    fetchOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onModalUpdated = (order: any) => {
    fetchOrders();
  };

  return (
    <>
      {(() => {
        if (ordersLoading) {
          return (
            <div className="flex flex-col items-center justify-center gap-y-6 mt-16 text-center">
              <div className="text-gray-800 text-2xl">
                <CircularProgress size={20} /> Loading your portfolio...
              </div>
            </div>
          );
        } else if (orders.length == 0) {
          return (
            <div className="flex flex-col items-center justify-center gap-y-6 mt-16 text-center">
              <div className="text-gray-800 text-2xl">
                Your portfolio is currently empty
              </div>
              <Button
                onClick={() => {
                  window.location.href = "/property";
                }}
                placeholder={"Explore offerings"}
                className="ml-4 !text-white !bg-primary-500 py-4 text-lg"
                color="green"
              >
                START INVESTING NOW
              </Button>
            </div>
          );
        }

        return (
          <>
            <div
              className="w-full overflow-hidden rounded-lg shadow-xs mt-2"
            >
              <div className="w-full overflow-x-auto">
                <table className="container w-full whitespace-no-wrap # flex flex-row flex-no-wrap lg:bg-white rounded-lg overflow-hidden lg:shadow-lg my-5">
                  <thead>
                    {/* hide head for mobile */}
                    <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800 # hidden ?flex flex-col flex-no wrap lg:table-row rounded-l-lg lg:rounded-none mb-2 lg:mb-0">
                      <th className="px-4 py-3">#</th>
                      <th className="px-4 py-3">Investment</th>
                      <th className="px-4 py-3">ROI</th>
                      <th className="px-4 py-3">Status</th>
                      <th className="px-4 py-3">Date</th>
                      <th className="px-4 py-3 text-right">Action</th>
                    </tr>
                  </thead>
                  <tbody className="bg-whitex divide-y dark:divide-gray-700 dark:bg-gray-800 # flex-1 lg:flex-none w-full">
                    {orders.map((order) => (
                        <tr
                          className="bg-white text-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer # flex flex-col flex-nowrap lg:table-row mb-2 lg:mb-0 rounded-xl lg:rounded-none shadow lg:shadow-none hover:shadow-lg lg:hover:shadow-none border min-w-[350px] w-full"
                          onClick={() => {
                            setOrder(order);
                            setShowModal(true);
                          }}
                          key={order.id}
                        >
                          <td className="px-4 py-3 w-full md:w-auto">
                            <div className="flex flex-col md:flex-row w-full items-start md:items-center text-md lg:text-sm">
                              {/* <!-- Avatar with inset shadow --> */}
                              <div className="relative w-full h-20 md:w-8 md:h-8 mr-3 rounded">
                                <img
                                  className="object-cover w-full h-full rounded"
                                  src={helpers.getFirstImage(
                                    order.product?.images[0])}
                                  alt=""
                                  loading="lazy"
                                />
                                <div
                                  className="absolute inset-0 rounded-full shadow-inner"
                                  aria-hidden="true"
                                ></div>
                              </div>
                              <div className="hover:text-pink-800 hover:underline hover:cursor-pointer space-y-2 lg:space-y-0">
                                <p className="font-semibold lg:whitespace-nowrap inline lg:block ml-1 lg:ml-0">
                                #{order.doc_number || order.id} {" "}
                                  {helpers.capitalizeEachWord(
                                    order.product?.name
                                  )}
                                </p>
                                <p className="text-xs text-gray-600 dark:text-gray-400">
                                    {/* display first 3 categories */}
                                  {order.product?.categories
                                    ?.slice(0, 3)
                                    .map((category: any) => (
                                      <span
                                        key={category.id}
                                        className="inline-block bg-gray-200 rounded-full px-2 py-0.5 text-xs font-semibold text-gray-700 mr-2"
                                      >
                                        {category.name}
                                      </span>
                                    ))}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td className="px-4 lg:py-3 text-xs text-left lg:text-left font-bold">
                            <span className="md:hidden font-bold">Investment: </span>
                            <Currency />{helpers.money(order.total)}
                          </td>
                          <td className="px-4 lg:py-3 text-xs text-left lg:text-left font-bold">
                          <span className="md:hidden font-bold">ROI: </span>
                            {
                                order.roi ? (
                                    <>
                                        <Currency />{helpers.money(order.roi ?? 0)}
                                    </>
                                ) : (
                                    <span className="text-gray-500">Pending</span>
                                )
                            }
                            
                          </td>
                          <td className="px-4 lg:py-3 text-xs text-right lg:text-left">
                            <StatusLabel status={order.status} />
                          </td>
                          <td className="px-4 py-3 text-sm text-left">
                            {order?.created_at?.toDate()?.toLocaleDateString()}
                          </td>
                          <td className="px-4 py-3  text-sm text-right pt-0 lg:pt-4 flex items-center justify-end">
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                setOrder(order);
                                setShowModal(true);
                              }}
                              id="row-edit"
                              className="inline bg-black rounded text-white p-2 hover:bg-gray-700 disabled:bg-gray-800"
                              data-modal-toggle="edit-modal"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                              </svg>

                            </button>
                          </td>
                        </tr>
                      ))}
                    {orders.length === 0 && (
                      <tr className="text-gray-700 dark:text-gray-400">
                        <td
                          className="px-4 py-3 text-sm text-center"
                          colSpan={6}
                        >
                          No bookings yet
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="grid px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 lg:grid-cols-9 dark:text-gray-400 dark:bg-gray-800">
                <span className="flex items-center col-span-3">
                  Showing {orders.length} of {orders.length}
                </span>
                <span className="col-span-2"></span>
                {/* <!-- Pagination --> */}
                {/* <span className="flex col-span-4 mt-2 lg:mt-auto lg:justify-end">
                        <nav aria-label="Table navigation">
                            <ul className="inline-flex items-center">
                                <li>
                                    <button className="px-3 py-1 rounded-md rounded-l-lg focus:outline-none focus:shadow-outline-purple" aria-label="Previous">
                                        <svg aria-hidden="true" className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                                            <path d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" fillRule="evenodd"></path>
                                        </svg>
                                    </button>
                                </li>
                                <li>
                                    <button className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple">
                                        1
                                    </button>
                                </li>
                                <li>
                                    <button className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple">
                                        2
                                    </button>
                                </li>
                                <li>
                                    <button className="px-3 py-1 text-white transition-colors duration-150 bg-purple-600 border border-r-0 border-purple-600 rounded-md focus:outline-none focus:shadow-outline-purple">
                                        3
                                    </button>
                                </li>
                                <li>
                                    <button className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple">
                                        4
                                    </button>
                                </li>
                                <li>
                                    <span className="px-3 py-1">...</span>
                                </li>
                                <li>
                                    <button className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple">
                                        8
                                    </button>
                                </li>
                                <li>
                                    <button className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple">
                                        9
                                    </button>
                                </li>
                                <li>
                                    <button className="px-3 py-1 rounded-md rounded-r-lg focus:outline-none focus:shadow-outline-purple" aria-label="Next">
                                        <svg className="w-4 h-4 fill-current" aria-hidden="true" viewBox="0 0 20 20">
                                            <path d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" fillRule="evenodd"></path>
                                        </svg>
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </span> */}
              </div>
            </div>
          </>
        );
      })()}




<PortalOrderModal
        showModal={showModal}
        setShowModal={setShowModal}
        order={order}
        setOrder={setOrder}
        onModalUpdated={onModalUpdated}
      />
    </>
  );
}
