/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import { Outlet, Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import { useState } from "react";
import useDocumentTitle from "../../hooks/userDocumentTitle";
import { Link } from "react-router-dom";
import { Timestamp, addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { toast } from "react-toastify";
import { db } from "../..";
import useToken from "../../hooks/useToken";
import { helpers } from "../../utilities/helpers";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "wealthblock-app": React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      "mat-sidenav-container": React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      "mat-sidenav-content": React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      "mat-card": React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      "wb-login": React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      "mat-form-field": React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      "mat-label": React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      "mat-checkbox": React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      "wb-button": React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      "router-outlet": React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      "wb-login-page": React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      "wb-modal": React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

function RegisterPage() {
  useDocumentTitle("Register");

  const { login } = useToken();
    const [formData, setFormData] = useState({} as any);

    const handleChange = (e: any) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    

    const loading = toast.loading("Please wait...");

    //check if email already exists
    const q = query(collection(db, "users"), where("email", "==", formData.email));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      toast.dismiss(loading);
      return toast.error("Email already exists");
    }

    //register user
    const usersRef = collection(db, "users");
    
    const docRef = await addDoc(usersRef, {
      doc_number: await helpers.getNextDocumentNumber("users"),
      email: formData.email,
      password: formData.password,
      status: "active",
      role: "user",
      created_at: Timestamp.now(),
      updated_at: Timestamp.now(),
    });

    toast.dismiss(loading);
    toast.success("Registration successful");

    //login user
    login(docRef.id);

    //redirect to dashboard
    window.location.href = "/portal/setup";

  }


  return (
    <>



            <div
              _ngcontent-dyt-c349=""
              className="login-page ng-star-inserted h-screen flex px-3 sm:px-0"
              style={{
                backgroundImage:
                  'url("https://wealthblock-platform.s3.amazonaws.com/image/0703b3542e034354155049345d8c1183.jpg")',
                backgroundSize: "cover",
                backgroundPosition: "center",
                minHeight: 819
              }}
            >
              <div _ngcontent-dyt-c349="" className="login-container sm:mx-auto my-auto mt-10 md:mt-auto bg-white rounded-lg shadow-2xl pt-10 pb-16 px-10">
                {/**/}
                        <div className="flex flex-col items-center w-full md:min-w-[600px]">
                         
                         
                        <a href="/">
                          <img
                            _ngcontent-dyt-c160=""
                            className="logo ext-website ng-star-inserted h-auto w-[200px]"
                            src="https://wealthblock-platform.s3.amazonaws.com/image/8a9cfd7a6f2ef1596f0b381a540c9124.png"
                          />
                          </a>



                          <form className="ng-untouched ng-pristine ng-star-inserted ng-invalid w-full space-y-10" onSubmit={onSubmit}>
                            <h2 _ngcontent-dyt-c160="" className="text-center font-light text-2xl">Sign Up</h2>
                        
                           <div className="flex flex-col w-full space-y-6">

                            <input 
                              name="email"
                              type="email"
                              required
                              className="border rounded-md p-3 text-lg"
                              placeholder="Email *"
                              onChange={handleChange}
                            />

                            <input 
                              name="password"
                              type="password"
                              required
                              className="border rounded-md p-3 text-lg"
                              placeholder="Password *"
                              onChange={handleChange}
                            />

                            {/* agree privacy checkbox */}
                            <div className="flex items-center justify-center space-x-3">
                              <input 
                                name="agree"
                                type="checkbox"
                                required
                                className="rounded-md p-3 text-lg"
                              />
                              <span className="mat-checkbox-label">
                                  <span style={{ display: "none" }}>&nbsp;</span> I agree to the{" "}
                                  <a
                                    _ngcontent-lqc-c160=""
                                    target="_blank"
                                    href="/portal/agreements/terms"
                                    className="text-blue-300"
                                  >
                                    Terms of Use
                                  </a>{" "}
                                  &amp;{" "}
                                  <a
                                    _ngcontent-lqc-c160=""
                                    target="_blank"
                                    href="/portal/agreements/privacy"
                                    className="text-blue-300"
                                  >
                                    Privacy Policy
                                  </a>
                                </span>

                            </div>

                            {/* center sign up button */}
                            <button
                              type="submit"
                              className="bg-primary-500 hover:bg-primary text-white rounded-md p-3 px-20 text-lg mx-auto"
                            >
                              SIGN UP
                            </button>

                            {/* center already have an account login */}
                            <div className="text-center">
                              <span className="">Already have an account? </span>
                              <Link
                                _ngcontent-lqc-c160=""
                                to="/portal/login"
                                className="text-blue-300"
                              >
                                Login
                              </Link>
                            </div>


                           </div>
                           

                          </form>
                        


                        </div>
              </div>
            </div>



    </>
  );
}

export default RegisterPage;
