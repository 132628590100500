import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

type Props = {
  onDone: (stepName: string) => void;
  form: any;
  setForm: any;
};
export default function SelectAccountType({ onDone, form, setForm }: Props) {



  return (
    <>
      <h3 className="text-[#737f8b] text-center font-sans text-[20px] font-extralight my-10">
        Before we get started, we need a little bit more information about you.{" "}
        <br />
        <br />
        <span className="ng-star-inserted">
          Please select one of the account types listed below.
        </span>
      </h3>

      <div className="flex flex-col md:flex-row gap-y-16 md:gap-y-0 md:gap-x-5 items-center justify-center px-5 md:px-24 mt-12">
        <div className="group w-full">
          <div className="bg-white text-gray-600 h-full rounded-lg px-8 py-20 flex flex-col gap-y-3 items-center justify-center hover:bg-gray-50 cursor-pointer hover:shadow-lg"
          onClick={() => {
            setForm({ ...form, account_type: "individual" });
            onDone("account_type");
        }}
          >
            <h1 className="rounded-full p-[5px] bg-white shadow text-[#4D8B2B] text-xl text-center font-[600] text-[30px] font mt-[-120px] w-[100px] h-[100px] flex items-center justify-center">
              <PeopleAltIcon className="!text-[60px]" />
            </h1>
            <h2 className="text-[22px] font-medium mt-0">
            Individual
            </h2>
            <p className="mx-auto my-auto leading-[15px] text-center text-gray-600 block w-auto text-sm font-normal">
                Individual or Joint investor managing a single account
                <br />
                <br />
            </p>
          </div>
        </div>

        <div className="group w-full">
          <div className="bg-white text-gray-600 h-full rounded-lg px-8 py-20 flex flex-col gap-y-3 items-center justify-center hover:bg-gray-50 cursor-pointer hover:shadow-lg"
            onClick={() => {
                setForm({ ...form, account_type: "entity" });
                onDone("account_type");
            }}
          >
            <h1 className="rounded-full p-[5px] bg-white shadow text-[#4D8B2B] text-xl text-center font-[600] text-[30px] font mt-[-120px] w-[100px] h-[100px] flex items-center justify-center">
              <AccountBalanceIcon className="!text-[60px]" />
            </h1>
            <h2 className="text-[22px] font-medium mt-0">
            Entity
            </h2>
            <p className="mx-auto my-auto leading-[15px] text-center text-gray-600 block w-auto text-sm font-normal">
            Registered Investment Advisors, Financial Advisors, Family Offices, Trusts, IRAs or those investing on behalf of an entity
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
