/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
export default function DefaultFooter() {
  return (
    <footer className="footer dark-bootom wf-section">
      <div className="div-footer">
        <a href="/" aria-current="page" className="w-inline-block w--current">
          <img
            src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e59a0c0a67bd6d170e34c37_FarmFundrLogo-01.png"
            width={197}
            alt="How to invest in farmland"
            className="footer-logo"
          />
        </a>
        <div className="sub-footer">
          <div className="div-social w-clearfix">
            {/* <div className="social-icon wrapper">
              <a
                href="http://facebook.com/farmfundr"
                target="_blank"
                className="w-inline-block w-clearfix"
              >
                <img
                  src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e5717f541472a436e58be50_facebook-logo-1_1.png"
                  alt=""
                  className="social-icon"
                />
              </a>
            </div> */}
            {/* <div className="social-icon wrapper">
              <a
                href="https://twitter.com/farmfundr"
                target="_blank"
                className="w-inline-block w-clearfix"
              >
                <img
                  src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e5717f541472a800058bf2f_twitter.png"
                  width={32}
                  alt=""
                  className="social-icon _2"
                />
              </a>
            </div> */}
            <div className="social-icon wrapper">
              <a
                href="https://www.instagram.com/farmsfundr/"
                target="_blank"
                className="w-inline-block"
              >
                <img
                  src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e5dcdf52c687cce86214490_Instagram_InvestinFarming.png"
                  alt=""
                  className="social-icon _3"
                />
              </a>
            </div>
            {/* <div className="social-icon wrapper _4">
              <a
                href="https://www.pinterest.com/farmfundr/"
                target="_blank"
                className="w-inline-block"
              >
                <img
                  src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e5717f541472a24af58bfc9_Pinterest.png"
                  height={64}
                  width={60}
                  alt=""
                  className="social-icon _3"
                />
              </a>
            </div> */}
          </div>
        </div>
      </div>
      <div className="small-footer">
        <p className="footer-info">
          {" "}
          © FarmFundr,&nbsp;LLC. 2022
          <a href="/licensing" className="link-page" />
          <a
            href="https://webflow.com/templates/designers/dorian-hoxha"
            className="link-page"
          />
        </p>
      </div>
    </footer>
  );
}
