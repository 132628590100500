import { helpers } from "../../../utilities/helpers";

type Props = {
    status: string;
    className?: string;
    replacements?: { [key: string]: string };
}
const StatusLabel = ({ status, className, replacements }: Props) => {
    //default status bg and text color
    let bgColor = "bg-gray-100";
    let textColor = "text-gray-700";

    //change bg and text color based on status
    switch (status) {
        case "Pending":
        case "pending":
            bgColor = "bg-yellow-100";
            textColor = "text-yellow-700";
            break;
        case "Processing":
        case "processing":
            bgColor = "bg-blue-100";
            textColor = "text-blue-700";
            break;
        case "Completed":
        case "completed":
            bgColor = "bg-green-100";
            textColor = "text-green-700";
            break;
        case "Paid":
        case "paid":
            bgColor = "bg-green-100";
            textColor = "text-green-700";
            break;
        case "Cancelled":
        case "cancelled":
            bgColor = "bg-red-100";
            textColor = "text-red-700";
            break;
        case "Approved":
        case "approved":
            bgColor = "bg-blue-100";
            textColor = "text-black";
            break;
        case "denied":
        case "Denied":
            bgColor = "bg-red-100";
            textColor = "text-red-700";
            break;
        default:
            break;
    }


    var statusText = status;
    if (replacements) {
        for (var key in replacements) {
            statusText = statusText?.replace(key, replacements[key]);
        }
    }

    return (
        <span className={`px-2 py-1 text-center font-semibold leading-tight ${textColor} ${bgColor} rounded-full ${className}`}>
            {(status === "approved") ? "✓ " : ""}
            {helpers.capitalize(statusText)}
            {(status === "completed" || status === "paid") ? " ✓✓" : ""}
        </span>
    );
}

export default StatusLabel;