// import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useContext } from "react";
import useToken from "../../hooks/useToken";
import AppContext from "../../AppContext";
import Loader from "./components/Loader";

function AdminProtectedLayout() {
  const { token } = useToken();
  const { loading, user }: any = useContext(AppContext);

  if (!token) {
    // user is not authenticated
    return <Navigate to="/admin/login" />;
  }

  if (loading) {
    return <Loader />;
  } else {
    if (!user) {
      //user profile cannot be fetch - probabaly authentication error
      // return <h1>No user</h1>;
      return <Navigate to="/admin/login" />;
    } else if (user && !(["admin", "super_admin"].includes(user.role))) {
        return <Navigate to="/admin/login" />;
    }
  }

  return <Outlet />; //to display the inner children routes
}

export default AdminProtectedLayout;
