/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react/jsx-no-target-blank */
import useDocumentTitle from "../hooks/userDocumentTitle";
import MainTemplate from "./MainTemplate";
import OfferContentPage from "./OfferContentPage";

export default function OfferPage() {
  useDocumentTitle("Investment Info");

  return (
    <>
      <MainTemplate pageType="others">
        <>
          <div className="w-container" />
 
    
            {/* top nav bar background cover */}
            <div className="!bg-gray-900 py-10 md:!py-12"></div>
      




          <OfferContentPage />

      
        </>
      </MainTemplate>
    </>
  );
}
