/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from "react";
import PaymentsTable from "../components/PaymentsTable";
import useDocumentTitle from "../../../hooks/userDocumentTitle";
import MainTemplate from "./MainTemplate";
import {
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../..";
import { helpers } from "../../../utilities/helpers";
import Currency from "../components/Currency";
import ExportExcelButton from "../components/Buttons/ExportExcelButton";
import { toast } from "react-toastify";

const Invoices = () => {
  useDocumentTitle("Invoices");

  const paymentModalRef = useRef(null as any);

  //faker products
  const [payments, setInvoices] = useState([] as any[]);
  const [paymentsLoading, setInvoicesLoading] = useState(true);

  const [salesManagers, setSalesManagers] = useState([] as any[]);

  const [totalPaid, setTotalPaid] = useState(0);
  const [totalPending, setTotalPending] = useState(0);
  const [totalProcessing, setTotalProcessing] = useState(0);
  const [totalCancelled, setTotalCancelled] = useState(0);
  const [totalRefunded, setTotalRefunded] = useState(0);

  const [totalPaidAmount, setTotalPaidAmount] = useState(0.0);
  const [totalPendingAmount, setTotalPendingAmount] = useState(0.0);
  const [totalProcessingAmount, setTotalProcessingAmount] = useState(0.0);
  const [totalCancelledAmount, setTotalCancelledAmount] = useState(0.0);
  const [totalRefundedAmount, setTotalRefundedAmount] = useState(0.0);
  const [totalAmount, setTotalAmount] = useState(0.0);

  const fetchInvoices = async (
    name = "",
    fromDate = "",
    toDate = "",
    id = "",
    salesManager = ""
  ) => {
    setInvoicesLoading(true);
    let payments: any[] = [];
    //fetch payments from firebase
    const paymentsRef = collection(db, "payments");

    var queries: any[] = [];

    if (name && name.length > 0) {
      const searchWords = helpers.generateSearchKeywords(name);
      queries.push(where("search_keywords", "array-contains-any", searchWords));
    } else if (fromDate && fromDate.length > 0 && toDate && toDate.length > 0) {
      const from = Timestamp.fromDate(new Date(fromDate));
      const to = Timestamp.fromDate(new Date(toDate));
      queries.push(where("created_at", ">=", from));
      queries.push(where("created_at", "<=", to));
    } else if (id && id.length > 0) {
      queries.push(where("doc_number", "==", parseInt(id)));
    } else if (salesManager && salesManager.length > 0) {
      queries.push(where("sales_manager_id", "==", salesManager));
    }

    queries.push(orderBy("created_at", "desc"));

    const q = query(paymentsRef, ...queries);
    await getDocs(q).then((snapshot) => {
      var totalPaid = 0;
      var totalPending = 0;
      var totalProcessing = 0;
      var totalCancelled = 0;
      var totalRefunded = 0;

      var totalPaidAmount = 0.0;
      var totalPendingAmount = 0.0;
      var totalProcessingAmount = 0.0;
      var totalCancelledAmount = 0.0;
      var totalRefundedAmount = 0.0;
      var totalAmount = 0.0;

      snapshot.forEach((doc) => {
        const data = doc.data();
        payments.push({ 
          ...data, 
          id: doc.id,
         });

        if (data.status === "paid") {
          totalPaid++;
          totalPaidAmount += parseFloat(data.amount);
        } else if (data.status === "pending") {
          totalPending++;
          totalPendingAmount += parseFloat(data.amount);
        } else if (data.status === "processing") {
          totalProcessing++;
          totalProcessingAmount += parseFloat(data.amount);
        } else if (data.status === "cancelled") {
          totalCancelled++;
          totalCancelledAmount += parseFloat(data.amount);
        } else if (data.status === "refunded") {
          totalRefunded++;
          totalRefundedAmount += parseFloat(data.amount);
        }

        totalAmount += parseFloat(data.amount);
      });

      setTotalPaid(totalPaid);
      setTotalPending(totalPending);
      setTotalProcessing(totalProcessing);
      setTotalCancelled(totalCancelled);
      setTotalRefunded(totalRefunded);

      setTotalPaidAmount(totalPaidAmount);
      setTotalPendingAmount(totalPendingAmount);
      setTotalProcessingAmount(totalProcessingAmount);
      setTotalCancelledAmount(totalCancelledAmount);
      setTotalRefundedAmount(totalRefundedAmount);
      setTotalAmount(totalAmount);
    });
    setInvoices(payments);
    setInvoicesLoading(false);

    //get id from url
    const urlParams = new URLSearchParams(window.location.search);
    const getId = urlParams.get("id");

    if (name == "" && fromDate == "" && toDate == "" && id == "" && getId) {
      //if getId exists in payments, then open the modal
      const payment = payments.find((p) => p.id == getId);
      if (payment) {
        paymentModalRef.current?.setPayment(payment);
        paymentModalRef.current?.setShowModal(true);
      } else {
        const paymentLoading = toast.loading("Loading payment...");
        //get the payment from firebase
        const paymentRef = doc(db, "payments", getId);
        const docSnap = await getDoc(paymentRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          paymentModalRef.current?.setPayment({ ...data, id: docSnap.id });
          paymentModalRef.current?.setShowModal(true);
        }
        toast.dismiss(paymentLoading);
    }
  }

  };

  const fetchSalesManagers = async () => {
    const salesManagersRef = collection(db, "users");
    const q = query(salesManagersRef, where("role", "==", "sales_manager"));
    await getDocs(q).then((snapshot) => {
      var salesManagers: any[] = [];
      snapshot.forEach((doc) => {
        const data = doc.data();
        salesManagers.push({ ...data, id: doc.id });
      });
      setSalesManagers(salesManagers);
    });
  }

  const onSearchNameButtonClicked = () => {
    (document.getElementById("from_date") as any).value = "";
    (document.getElementById("to_date") as any).value = "";
    (document.getElementById("search_id") as any).value = "";
    (document.getElementById("sales_manager") as any).value = "";
    const name: any = (document.getElementById("product_name") as any)?.value;
    fetchInvoices(name);
  };

  const onSearchDateButtonClicked = () => {
    (document.getElementById("product_name") as any).value = "";
    (document.getElementById("search_id") as any).value = "";
    (document.getElementById("sales_manager") as any).value = "";
    const fromDate: any = (document.getElementById("from_date") as any)?.value;
    const toDate: any = (document.getElementById("to_date") as any)?.value;
    fetchInvoices("", fromDate, toDate, "");
  };

  const onSearchIDButtonClicked = () => {
    (document.getElementById("product_name") as any).value = "";
    (document.getElementById("from_date") as any).value = "";
    (document.getElementById("to_date") as any).value = "";
    (document.getElementById("sales_manager") as any).value = "";
    const id: any = (document.getElementById("search_id") as any)?.value;
    fetchInvoices("", "", "", id);
  };

  const onSalesManagerChanged = () => {
    (document.getElementById("product_name") as any).value = "";
    (document.getElementById("from_date") as any).value = "";
    (document.getElementById("to_date") as any).value = "";
    (document.getElementById("search_id") as any).value = "";
    const salesManager: any = (document.getElementById("sales_manager") as any)?.value;
    fetchInvoices("", "", "", "", salesManager);
  }

  useEffect(() => {
    fetchInvoices();
    fetchSalesManagers();
  }, []);

  const onTableUpdated = (payment: any) => {
    fetchInvoices();
  };

  return (
    <>
      <MainTemplate title="Invoices">
        {/* <!-- search filter --> */}
        <div className="flex flex-col lg:flex-row items-start lg:items-center justify-start lg:justify-center w-full gap-4">
          <div className="flex flex-row w-full lg:w-auto">
            <input
              type="search"
              id="product_name"
              onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                  onSearchNameButtonClicked();
                }
              }}
              className="block p-2.5 w-full  text-sm text-gray-900 bg-gray-50 rounded-l-lg appearance-none  border border-gray-300 focus:ring-black focus:border-black dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-black"
              placeholder="Search name"
            />
            <button
              onClick={onSearchNameButtonClicked}
              type="button"
              className="p-2.5 text-sm font-medium text-white bg-black rounded-r-lg border border-black hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-black dark:hover:bg-gray-900 dark:focus:ring-blue-800"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
              <span className="sr-only">Search</span>
            </button>
          </div>
          <div className="flex flex-row w-full lg:w-auto">
            <input
              type="search"
              id="search_id"
              onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                  onSearchIDButtonClicked();
                }
              }}
              className="block p-2.5 w-full  text-sm text-gray-900 bg-gray-50 rounded-l-lg appearance-none border border-gray-300 focus:ring-black focus:border-black dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-black"
              placeholder="Search ID"
            />
            <button
              onClick={onSearchIDButtonClicked}
              type="button"
              className="p-2.5 text-sm font-medium text-white bg-black rounded-r-lg border border-black hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-black dark:hover:bg-gray-900 dark:focus:ring-blue-800"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
              <span className="sr-only">Search</span>
            </button>
          </div>
          <div className="flex flex-row w-full lg:w-auto">
            <input
              type="date"
              id="from_date"
              className="block p-2.5 w-full  text-sm text-gray-900 bg-gray-50 rounded-tl-lg appearance-none rounded-bl-lg border border-r-0 border-gray-300 focus:ring-black focus:border-black dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-black"
              placeholder="From"
            />
            <input
              type="date"
              id="to_date"
              className="block p-2.5 w-full  text-sm text-gray-900 bg-gray-50 border appearance-none border-gray-300 focus:ring-black focus:border-black dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-black"
              placeholder="To"
            />
            <button
              onClick={onSearchDateButtonClicked}
              type="button"
              className="p-2.5 text-sm font-medium text-white bg-black rounded-r-lg border border-black hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-black dark:hover:bg-gray-900 dark:focus:ring-blue-800"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
              <span className="sr-only">Search</span>
            </button>
          </div>
          <div className="flex flex-row w-full lg:w-auto">
            <select
              id="sales_manager"
              onChange={onSalesManagerChanged}
              className="block p-2.5 min-w-[180px] w-full  text-sm text-gray-900 bg-gray-50 rounded-l-lg appearance-none border border-gray-300 focus:ring-black focus:border-black dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-black">
              <option value="">Sales Manager</option>
              {
                salesManagers.map((salesManager) => (
                  <option key={salesManager.id} value={salesManager.id}>{salesManager.full_name}</option>
                ))
              }
              </select>
              <button
              type="button"
              onClick={() => {
                //open select dropdown
                var selectElement = document.getElementById("sales_manager") as any;

                if (document.createEvent) {
                  var e = document.createEvent("MouseEvents");
                  e.initEvent("mousedown", true, true);
                  selectElement.dispatchEvent(e);
                } else if (selectElement.fireEvent) {
                  selectElement.fireEvent("onmousedown");
                }
              }}
              className="p-2 text-sm font-medium text-white bg-black rounded-r-lg border border-black">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
              </svg>

            </button>
          </div>
          <div className="hidden lg:flex flex-row w-full lg:w-auto">
            <ExportExcelButton
              excelData={payments.map((payment) => {
                return {
                  ID: payment.doc_number,
                  "Sales Manager": payment.sales_manager?.full_name,
                  Amount: payment.amount,
                  "Outstanding": (() => {
                    var totalPaid = parseFloat(payment.total_paid);
                    var totalAmount = parseFloat(payment.amount);
                    if (totalPaid < totalAmount) {
                      return totalAmount - totalPaid;
                    }
                    return 0;
                  })(),
                  Client: payment.client?.full_name || payment.user?.full_name,
                  "Client Status": payment.is_new_client ? "New" : "Old",
                  Email: payment.client?.email || payment.user?.email,
                  Phone: payment.client?.phone || payment.user?.phone,
                  "Payment Status": payment.status,
                  "Date": payment.created_at.toDate().toLocaleString(),
                };
              })}
              fileName={`Invoices-${new Date().toLocaleDateString()}`}
              className="w-full text-white mr-2 bg-black py-1.5 ml-4 text-lg px-5 rounded hover:bg-blue-600 flex flex-row items-center justify-center"
              data-modal-toggle="add-modal"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="inline h-6 w-6 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                />
              </svg>
              Export
            </ExportExcelButton>
          </div>
        </div>

        {/* stats */}
        {paymentsLoading ? (
          <></>
        ) : (
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-2 mt-2">
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all w-full">
              <div className="bg-white p-4">
                <div className="lg:flex lg:items-start">
                  <div className="text-center lg:mt-0 lg:ml-2 lg:text-left">
                    <h3 className="text-sm leading-6 font-medium text-gray-400">
                      Total Amount ({payments.length})
                    </h3>
                    <p className="text-xl font-bold text-black">
                      <Currency />
                      {helpers.money(totalAmount)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all w-full">
              <div className="bg-white p-4">
                <div className="lg:flex lg:items-start">
                  <div className="text-center lg:mt-0 lg:ml-2 lg:text-left">
                    <h3 className="text-sm leading-6 font-medium text-gray-400">
                      Total Paid ({totalPaid}/{payments.length})
                    </h3>
                    <p className="text-xl font-bold text-black">
                      <Currency />
                      {helpers.money(totalPaidAmount)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all w-full">
              <div className="bg-white p-4">
                <div className="lg:flex lg:items-start">
                  <div className="text-center lg:mt-0 lg:ml-2 lg:text-left">
                    <h3 className="text-sm leading-6 font-medium text-gray-400">
                      Total Pending ({totalPending}/{payments.length})
                    </h3>
                    <p className="text-xl font-bold text-black">
                      <Currency />
                      {helpers.money(totalPendingAmount)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all w-full">
              <div className="bg-white p-4">
                <div className="lg:flex lg:items-start">
                  <div className="text-center lg:mt-0 lg:ml-2 lg:text-left">
                    <h3 className="text-sm leading-6 font-medium text-gray-400">
                      Total Processing ({totalProcessing}/{payments.length})
                    </h3>
                    <p className="text-xl font-bold text-black">
                      <Currency />
                      {helpers.money(totalProcessingAmount)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all w-full">
              <div className="bg-white p-4">
                <div className="lg:flex lg:items-start">
                  <div className="text-center lg:mt-0 lg:ml-2 lg:text-left">
                    <h3 className="text-sm leading-6 font-medium text-gray-400">
                      Total Cancelled ({totalCancelled}/{payments.length})
                    </h3>
                    <p className="text-xl font-bold text-black">
                      <Currency />
                      {helpers.money(totalCancelledAmount)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all w-full">
              <div className="bg-white p-4">
                <div className="lg:flex lg:items-start">
                  <div className="text-center lg:mt-0 lg:ml-2 lg:text-left">
                    <h3 className="text-sm leading-6 font-medium text-gray-400">
                      Total Refunded ({totalRefunded}/{payments.length})
                    </h3>
                    <p className="text-xl font-bold text-black">
                      <Currency />
                      {helpers.money(totalRefundedAmount)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <PaymentsTable
          payments={payments}
          loading={paymentsLoading}
          onTableUpdated={onTableUpdated}
          refx={paymentModalRef}
        />
      </MainTemplate>
    </>
  );
};

export default Invoices;
