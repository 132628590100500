/* eslint-disable eqeqeq */


type Props = {
    navLinks: Array<any>,
    setNavLinks: Function,
    className?: string
    };
export default function LandigScrollHeader({navLinks, setNavLinks, className}: Props) {
  return (
    <>
      <header
        className={`fixed z-40 w-full bg-white text-black hidden md:flex flex-row items-center justify-center px-5 py-2 text-xs font-[500] border-b border-b-[#ffffff31] ${className}`}
      >
        {/* logo */}
        <a
          href="/"
          aria-current="page"
          className="brand w-nav-brand w--current"
          aria-label="home"
        >
          <img
            src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e59a0c0a67bd6d170e34c37_FarmFundrLogo-01.png"
            width={150}
            alt="How to invest in farmland"
          />
        </a>
        {/* nav */}
        <nav
          role="navigation"
          className="flex flex-row items-center justify-center mt-2"
        >
          {navLinks.map((link, index) => {
            if (link.name == "sign-up") {
              return (
                <a
                  key={index}
                  href={link.href}
                  className="py-3 px-4 text-white bg-[#95AFA1] hover:bg-primary rounded-full"
                >
                  {link.title}
                </a>
              );
            } else if (link.subLinks) {
              return (
                <div key={index} className="relative">
                  <div
                    className="py-3 px-4 hover:bg-[#F5F5F5] rounded-full cursor-pointer flex gap-x-2 items-center"
                    onClick={() => {
                      const newLinks = [...navLinks];
                      newLinks[index].showSubLinks =
                        !newLinks[index].showSubLinks;
                      setNavLinks(newLinks);
                    }}
                  >
                    <div className="">{link.title}</div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="w-3 h-3"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m19.5 8.25-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </div>
                  <nav
                    className={`absolute z-30 top-14 left-0 bg-[#000000ce] text-white shadow-lg py-5 ${
                      link.showSubLinks ? "block" : "hidden"
                    }`}
                  >
                    {link.subLinks.map((subLink: any, index: any) => {
                      return (
                        <a
                          key={`sublink-${index}`}
                          href={subLink.href}
                          className="block pl-6 pr-12 py-4 text-gray-200 hover:bg-[#ffffff24] hover:text-white whitespace-nowrap"
                        >
                          {subLink.title}
                        </a>
                      );
                    })}
                  </nav>
                </div>
              );
            }
            return (
              <a
                key={index}
                href={link.href}
                className="py-3 px-4 hover:bg-[#F5F5F5] rounded-full"
              >
                {link.title}
              </a>
            );
          })}
        </nav>
        {/* mobile nav button */}
        <div
          className="md:hidden"
          aria-label="menu"
          role="button"
          tabIndex={0}
          aria-controls="w-nav-overlay-0"
          aria-haspopup="menu"
          aria-expanded="false"
        >
          <div className="icon w-icon-nav-menu" />
        </div>
      </header>
    </>
  );
}
