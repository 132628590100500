/* eslint-disable jsx-a11y/anchor-is-valid */

import { useContext } from "react";
import AppContext from "../../../AppContext";
import { Edit } from "@mui/icons-material";




export default function Users() {

  const context: any = useContext(AppContext);


  return (

    <>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg bg-white p-2 md:p-3">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Username
                </th>
                <th scope="col" className="px-6 py-3">
                  First Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Last Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Phone #
                </th>
                <th scope="col" className="px-6 py-3">
                  Is Active
                </th>
                <th scope="col" className="px-6 py-3">
                  Is Primary
                </th>
                <th scope="col" className="px-6 py-3">
                  Email Verified
                </th>
                <th scope="col" className="px-6 py-3">
                  Notifications
                </th>
                <th scope="col" className="px-6 py-3">
                  Details / Edit
                </th>
              </tr>
            </thead>
            <tbody className="text-gray-900">
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td className="px-6 py-4">{context.user.email}</td>
                <td className="px-6 py-4">{context.user.first_name}</td>
                <td className="px-6 py-4">{context.user.last_name}</td>
                <td className="px-6 py-4">{context.user.phone}</td>
                <td className="px-6 py-4">
                <div className="flex items-center">
                    <input
                      id="checkbox-table-search-1"
                      type="checkbox"
                      checked={context.user.is_active || true}
                      className="w-4 h-4 text-primary bg-gray-100 border-gray-300 rounded focus:ring-primary accent-primary"
                    />
                    <label
                      htmlFor="checkbox-table-search-1"
                      className="sr-only"
                    >
                      checkbox
                    </label>
                  </div>
                </td>
                <td className="px-6 py-4">
                <div className="flex items-center">
                    <input
                      id="checkbox-table-search-1"
                      type="checkbox"
                      checked={context.user.is_primary || true}
                      className="w-4 h-4 text-primary bg-gray-100 border-gray-300 rounded focus:ring-primary accent-primary"
                    />
                    <label
                      htmlFor="checkbox-table-search-1"
                      className="sr-only"
                    >
                      checkbox
                    </label>
                  </div>
                </td>
                <td className="px-6 py-4">
                <div className="flex items-center">
                    <input
                      id="checkbox-table-search-1"
                      type="checkbox"
                      checked={context.user.email_verified}
                      className="w-4 h-4 text-primary bg-gray-100 border-gray-300 rounded focus:ring-primary accent-primary"
                    />
                    <label
                      htmlFor="checkbox-table-search-1"
                      className="sr-only"
                    >
                      checkbox
                    </label>
                  </div>
                </td>
                <td className="px-6 py-4">
                  <a href="#" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Manage</a>
                </td>
                <td className="flex items-center px-6 py-4">
                  <Edit className="text-gray-500 hover:text-gray-700 cursor-pointer"/>
                </td>
              </tr>
            </tbody>
          </table>
        </div>


        <br/>
        {/* <div className="flex flex-row items-center justify-center">
                <button
                    type="button"
                    className="bg-primary-500 hover:bg-primary text-white text-sm font-bold rounded-md p-3 px-7"
                >
                    ADD NEW USER
                </button>
        </div> */}

        <br/>
        <br/>
        <br/>
        <br/>
  
    </>
  );
}


