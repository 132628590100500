/* eslint-disable eqeqeq */
import { useContext, useEffect, useState } from "react";
import SkyeModal from "../../components/SkyeModal";
import Currency from "../../components/Currency";
import { Timestamp, doc, updateDoc } from "firebase/firestore";
import { db } from "../../../..";
import { toast } from "react-toastify";
import AppContext from "../../../../AppContext";
import { helpers } from "../../../../utilities/helpers";
// import StatusLabel from "../../components/StatusLabel";
// import { useNavigate } from "react-router-dom";

type Props = {
  title?: string;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  order?: any;
  setOrder: (order: any) => void;
  onModalUpdated: (order: any) => void;
};
const OrderModal = ({
  showModal,
  setShowModal,
  order,
  setOrder,
  onModalUpdated,
}: Props) => {

  // const navigate = useNavigate();

  const context: any = useContext(AppContext);

  const [orderData, setOrderData] = useState(order ?? {});

  useEffect(() => {
    setOrderData(order);
  }, [order, orderData.notes]);

  const onInputChanged = (e: any) => {
    setOrderData({ ...orderData, [e.target?.name]: e.target?.value });
  };

  const onFormSubmit = async (e: any) => {
    e.preventDefault();

    const loading = toast.loading("Updating invoice...");

    //remove other fields except status and notes
    let formData: any = {
      roi: orderData.roi || 0,
      status: orderData.status,
      payment_status: orderData.payment_status || "pending",
      //add who updated the order
      updated_by: {
        id: context.user.id,
        doc_number: context.user.doc_number ?? 0,
        first_name: context.user?.first_name ?? null,
        last_name: context.user?.last_name ?? null,
        email: context.user?.email ?? null,
        phone: context.user?.phone ?? null,
        role: context.user?.role ?? null,
      }
    };

    //update updated_at
    formData.updated_at = Timestamp.now();

    //update order
    await updateDoc(doc(db, "orders", orderData.id), formData);


    //close modal
    setShowModal(false);

    //remove loading
    toast.dismiss(loading);

    toast.success("Offer updated successfully!");

    setOrder({});

    onModalUpdated(formData);
  };

  return (
    <>
      <SkyeModal
        title={orderData.id ? `Offer #${orderData.doc_number}` : "New Offer"}
        size="max-w-2xl"
        flex={false}
        showModal={showModal}
        setShowModal={() => {
          setShowModal(false);
          setOrder({});
        }}
      >
        <form
          className="space-y-6 text-left"
          id="order-edit-form"
          onSubmit={onFormSubmit}
          method="POST"
          encType="multipart/form-data"
        >
          {/*body*/}
          <div className="relative p-6 flex-auto">
            {/* body */}

            {/* date */}
            <div className="col-span-6 lg:col-span-3 mb-4">
              <div className="grid grid-cols-2 items-center">
                <div className="block text-sm font-bold text-black">
                  Date:
                </div>
                <div className="font-bold">{orderData?.created_at?.toDate()?.toLocaleDateString()}</div>
              </div>
            </div>

            {/* status */}
            {
              orderData.type != "contact" && (
                <>
                  <div className="col-span-6 lg:col-span-3 mb-4">
                    <div className="grid grid-cols-2 items-center">
                      <label
                        htmlFor="status"
                        className="block text-sm font-bold text-black   "
                      >
                        Order Status:
                      </label>
                      <select
                        name="status"
                        value={orderData.status || ""}
                        onChange={onInputChanged}
                        id="status"
                        className="bg-gray-50 border-2 border-gray-300 text-gray-500 text-sm font-semibold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="pending">Pending</option>
                        <option value="approved">Approved</option>
                        <option value="completed">Completed</option>
                        <option value="processing">Processing</option>
                        <option value="cancelled">Cancelled</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-span-6 lg:col-span-3 mb-4">
                    <div className="grid grid-cols-2 items-center">
                      <label
                        htmlFor="status"
                        className="block text-sm font-bold text-black   "
                      >
                        Payment Status:
                      </label>
                      <select
                        name="payment_status"
                        value={orderData.payment_status || ""}
                        onChange={onInputChanged}
                        id="payment_status"
                        className="bg-gray-50 border-2 border-gray-300 text-gray-500 text-sm font-semibold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="pending">Pending</option>
                        <option value="processing">Processing</option>
                        <option value="paid">Paid</option>
                        <option value="cancelled">Cancelled</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-span-6 lg:col-span-3 mb-4">
                    <div className="grid grid-cols-2 items-center">
                      <label
                        htmlFor="status"
                        className="block text-sm font-bold text-black   "
                      >
                        ROI Profit:
                      </label>
                      <input
                        name="roi"
                        value={orderData.roi || ""}
                        onChange={onInputChanged}
                        id="roi"
                        type="number"
                        placeholder="0.0"
                        className="text-center bg-white border-2 border-gray-300 text-black text-lg font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>
                  </div>
                </>
              )
            }
            {/* payment status */}
            {/* <div className="col-span-6 lg:col-span-3 mb-4">
              <div className="grid grid-cols-2 items-center">
                <div className="block text-sm font-bold text-black">
                  Payment Status:
                </div>
                <div className="flex items-center">
                  <StatusLabel status={orderData.payment_status} replacements={{
                    "processing": "Outstanding",
                  }}
                  className="px-5"
                   />
                   { ['admin', 'super_admin', 'accounting_manager'].includes(context.user.role) && (
                   <button type="button" onClick={() => { 
                      if (orderData.payment_id) {
                        navigate(`/admin/payments?id=${orderData.payment_id}`);
                      }
                                 }} id="row-edit" className="flex items-center underline rounded text-gray-700 hover:text-white p-0.5 hover:bg-gray-700 disabled:bg-gray-800 ml-1" data-modal-toggle="edit-modal">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 icon icon-tabler icon-tabler-pencil inline" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <desc>Download more icon variants from https://tabler-icons.io/i/pencil</desc>
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4"></path>
                                                    <line x1="13.5" y1="6.5" x2="17.5" y2="10.5"></line>
                                                </svg>
                                                <span className="hidden sm:inline">Go to Payment</span>
                   </button>
                    )}
                </div>
              </div>
            </div> */}

            {/* items */}
            {
              orderData.product && (
                <div className="flex flex-col">
                  <div className="-my-2 overflow-x-auto lg:-mx-8">
                    <div className="py-2 align-middle inline-block w-full lg:px-6">
                      <div className="shadow overflow-hidden border-b border-gray-200 lg:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50 dark:bg-gray-700">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
                              >
                                Item
                              </th>
                              {/* <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                                                            Description
                                                        </th> */}
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
                              >
                                Unit
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
                              >
                                Price
                              </th>
                              {/* <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
                              >
                                Total
                              </th> */}
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                            {[orderData.product]?.map((item: any, index: number) => item && (
                              <tr key={index}>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="text-sm text-gray-900 dark:text-gray-100">
                                    {item?.name}
                                  </div>
                                  {item.message && (
                                    <div className="text-xs text-gray-500 dark:text-gray-100 mt-1">
                                      <div className="text-black underline">Message</div>
                                      <div 
                                      dangerouslySetInnerHTML={{
                                        __html: item?.message?.replace(
                                          /(?:\r\n|\r|\n)/g,
                                          "<br>"
                                        ),
                                      }}>
                                      </div>
                                    </div>
                                  )}
                                </td>
                                {/* <td className="px-6 py-4 whitespace-nowrap">
                                                                <div className="text-sm text-gray-900 dark:text-gray-100">{item.description}</div>
                                                            </td> */}
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="text-sm text-gray-900 dark:text-gray-100">
                                    {item.quantity ?? 1}
                                  </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="text-sm text-gray-900 dark:text-gray-100">
                                    <Currency />
                                    {helpers.money(item.price)}
                                  </div>
                                </td>
                                {/* <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">
                                    <Currency />
                                    {helpers.money(item.subtotal)}
                                  </div>
                                </td> */}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }

{
              orderData.type == "contact" && (
                <div className="text-sm text-gray-500 dark:text-gray-100 mt-1 shadow rounded p-4">
                      <div className="text-black">Message</div>
                                      <div 
                                      dangerouslySetInnerHTML={{
                                        __html: orderData?.message?.replace(
                                          /(?:\r\n|\r|\n)/g,
                                          "<br>"
                                        ),
                                      }}>
                    </div>
                </div>
              )
            }

            {/* total table */}
            {
              orderData.type != "contact" && (
                <div className="flex flex-col mt-4">
                  <div className="-my-2 overflow-x-auto lg:-mx-6">
                    <div className="py-2 align-middle inline-block w-full lg:px-6">
                      <div className="shadow overflow-hidden border-b border-gray-200 lg:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                            {/* <tr>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">
                                  Subtotal
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-900 dark:text-gray-100 font-bold text-right">
                                  <Currency />
                                  {helpers.money(orderData.subtotal)}
                                </div>
                              </td>
                            </tr> */}
                            {/* <tr>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">Tax</div>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="text-sm text-gray-900 dark:text-gray-100 font-bold  text-right"><Currency />{orderData.tax}</div>
                                                        </td>
                                                    </tr> */}
                            <tr>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">
                                  Total
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-900 dark:text-gray-100 font-bold  text-right">
                                  <Currency />
                                  {helpers.money(orderData.total)}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }

            {/* Client */}
            <div className="flex flex-col mt-4">
              <label
                htmlFor="customer"
                className="block text-sm font-bold text-black   "
              >
                Client:
              </label>
              <div className="flex flex-col mt-1">
                <div className="-my-2 overflow-x-auto lg:-mx-6">
                  <div className="py-2 align-middle inline-block w-full lg:px-6">
                    <div className="shadow overflow-hidden border-b border-gray-200 lg:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200">
                        <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700 divide-none">
                          <tr>
                            <td className="px-6 py-2 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">
                                Name
                              </div>
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100">
                                {orderData.user?.first_name} {orderData.user?.last_name}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="px-6 py-2 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">
                                Contact
                              </div>
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100">
                                {orderData.user?.phone || ""}
                                {" "}
                                {orderData.user?.email || "N/A"}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            
          </div>
          {/*footer*/}
          <div className="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">
          {
              orderData.type != "contact" && (
                <button
                  type="submit"
                  className="bg-black hover:bg-black text-white font-semibold py-2 px-20 text-xl rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2"
                >
                  Update Offer
                </button>
              )
          }
          </div>
        </form>
      </SkyeModal>
    </>
  );
};

export default OrderModal;
