import ReactStars from "react-rating-stars-component";

export default function ReactStarsComponent({ count, value, size, activeColor, edit }) {
    return (
        <ReactStars
            count={count}
            value={value}
            size={size}
            activeColor={activeColor}
            edit={edit}
        />
    );
}