/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import { Outlet, Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import useDocumentTitle from "../../hooks/userDocumentTitle";



function ForgotPasswordPage() {
  useDocumentTitle("Forgot Password");

  const onSubmit = (e: any) => {
    e.preventDefault();
  }


  return (
    <>



            <div
              _ngcontent-dyt-c349=""
              className="login-page ng-star-inserted h-screen flex px-3 sm:px-0"
              style={{
                backgroundImage:
                  'url("https://wealthblock-platform.s3.amazonaws.com/image/0703b3542e034354155049345d8c1183.jpg")',
                backgroundSize: "cover",
                backgroundPosition: "center",
                minHeight: 819
              }}
            >
              <div _ngcontent-dyt-c349="" className="login-container md:mx-auto my-auto mt-10 md:mt-auto bg-white rounded-lg shadow-2xl pt-10 pb-10 px-10">
                {/**/}
                        <div className="flex flex-col items-center w-full md:min-w-[600px]">
                         
                         
                         <a href="/">
                          <img
                            _ngcontent-dyt-c160=""
                            className="logo ext-website ng-star-inserted h-auto w-[200px]"
                            src="https://wealthblock-platform.s3.amazonaws.com/image/8a9cfd7a6f2ef1596f0b381a540c9124.png"
                          />
                          </a>



                          <form className="ng-untouched ng-pristine ng-star-inserted ng-invalid w-full space-y-10" onSubmit={onSubmit}>
                            <h2 _ngcontent-dyt-c160="" className="text-center font-light text-2xl">Forgot Password</h2>
                        
                           <div className="flex flex-col w-full space-y-6">

                            <input 
                              name="email"
                              type="email"
                              required
                              className="border rounded-md p-3 text-lg"
                              placeholder="Email *"
                            />

                            {/* center sign up button */}
                            <button
                              type="submit"
                              className="bg-primary-500 hover:bg-primary text-white rounded-md p-3 px-20 text-sm mx-auto"
                            >
                              RESET PASSWORD
                            </button>

                           </div>
                           

                          </form>
                        


                        </div>
              </div>
            </div>



    </>
  );
}

export default ForgotPasswordPage;
