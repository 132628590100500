/* eslint-disable eqeqeq */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import useDocumentTitle from "../../hooks/userDocumentTitle";
import PortalTemplate from "./PortalTemplate";
import { Helmet } from 'react-helmet';
import React from "react";
import {
  Input,
  Button,
} from "@material-tailwind/react";

import { CreateNewFolder, Reorder } from "@mui/icons-material";



export default function DocumentsPage() {
  useDocumentTitle("Documents");


  return (
   <PortalTemplate className="md:py-6 flex flex-col items-center justify-center">
    <Helmet>
                <style>{'body { background-color: #F2F3F6; }'}</style>
    </Helmet>
    <>
    <div className="w-full px-4 md:px-20">
      {/* heading */}
      <div className="w-full flex flex-col md:flex-row items-start md:items-center md:justify-between">
        <h1 className="text-black text-xl md:text-2xl text-left w-full mb-5">Documents</h1>
        <div className="flex flex-col md:flex-row items-start md:items-center md:justify-end gap-2">
            {/* search input */}
            <Input
              crossOrigin=""
              type="text"
              placeholder="Search"
              label="Search"
              />
            {/* ] date */}
            <Input
              crossOrigin=""
              type="date"
              placeholder="Created Between"
              label="Created Between"
              />
            <div className="flex flex-row items-center gap-x-2">
            {/* add folder button */}
            <Button
                onClick={() => {
                    // setForm({});
                    // setShowForm(true);
                }}
                placeholder={"Add Folder"} className=" !text-white !bg-primary-500 py-2 flex flex-row items-center whitespace-nowrap gap-x-1" color="green">
                  <CreateNewFolder />
                  ADD FOLDER
            </Button>
            {/* sort folder button */}
            <Button
                onClick={() => {
                    // setForm({});
                    // setShowForm(true);
                }}
                placeholder={"Add Folder"} className="py-2 flex flex-row items-center whitespace-nowrap gap-x-1" color="white">
                  <Reorder />
                  ADD FOLDER
            </Button>
          </div>
        </div>
        </div>


        <div className="flex flex-col items-center justify-center gap-y-6 mt-16 text-center">
            <div className="text-gray-800 text-xl md:text-2xl px-10">You currently have no documents</div>
        </div>
    
    </div>
    </>

   </PortalTemplate>
  );
}


