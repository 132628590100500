/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react/jsx-no-target-blank */
import useDocumentTitle from "../hooks/userDocumentTitle";
import MainTemplate from "./MainTemplate";
import { useEffect, useState } from "react";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "..";
import { CircularProgress } from "@mui/material";
import Currency from "../components/Currency";
import { helpers } from "../utilities/helpers";


export default function InvestmentsPage() {
    useDocumentTitle("Investment Properties");


    const [products, setProducts] = useState([] as any[]);
    const [productsLoading, setProductsLoading] = useState(true);
    const fetchProducts = async (name = "", fromDate = "", toDate = "") => {
        setProductsLoading(true);
        let products: any[] = [];
        //fetch products from firebase
        const productsRef = collection(db, 'products');

        var queries: any[] = [];

        queries.push(orderBy('created_at', 'desc'));

        const q = query(productsRef, ...queries);

        await getDocs(q).then((snapshot) => {
            snapshot.forEach((doc) => {
                products.push({ ...doc.data(), id: doc.id });
            });
        });
        setProducts(products);
        setProductsLoading(false);
    }

    useEffect(() => {
      fetchProducts();
  }, []);


  return (
    <>
      <MainTemplate pageType="others">
      
      <>
  <div data-ix="slide-in-scroll-navigation" className="page-header gallery-2">
    <div className="w-container">
      <div className="div-text">
        <h1
          data-w-id="400405cb-2231-b075-becd-fd1f67d873df"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
          className="heading center white-font"
        >
          investment properties
        </h1>
        <div
          data-w-id="ac33cd18-35dc-dfae-faf2-a147208328bb"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
          className="separated-div-wrapper"
        >
          <div className="separator header" />
          <div className="separator header center" />
          <div className="separator header" />
        </div>
        <p
          data-w-id="44eb2ade-1eca-795c-7fbf-fbefe108056e"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
          className="paragraph center white"
        >
          With a nice modern and intuitive interface you can control contents to
          be contained and organised.
        </p>
      </div>
    </div>
  </div>

  <div className="blog-post image" />
  <div className="blog-post video alternative gray !pt-4">
    <div className="container-3 w-container" />
    {
      (() => {
        if (productsLoading) {
          return (
            <div className="flex flex-row items-center justify-center">
              <CircularProgress className="!text-center" />
            </div>
          );
        } else if (products.length == 0) {
          return (
            <div className="w-container">
              <p className="centered-paragraph">
                <strong className="featured-subheading black">
                  No investments available at the moment.
                  <br />
                </strong>
                Please check back later.
                <br />
              </p>
            </div>
          );
        }

        return (
          <>
            <div className="w-dyn-list px-2 md:px-20">
              <div role="list" className="grid grid-cols-1 md:grid-cols-3 md:gap-x-8 gap-y-4 md:gap-y-5">
                {
                  products.map((product: any) => {
                    return (
                      <div
                        onClick={() => window.location.href = `/offering/${product.id}`}
                        role="listitem"
                        className="border border-gray-400 rounded-lg shadow w-full cursor-pointer"
                      >
                        <a
                          href={`/offering/${product.id}`}
                          // target="_blank"
                          className="w-inline-block !w-full"
                        >
                          <img
                            height={250}
                            alt={product.name}
                            src={product.images ? product.images[0] ?? "https://via.placeholder.com/150" : "https://via.placeholder.com/150"}
                            className="image-24 !w-full"
                          />
                        </a>
                        {
                          (product.status != "publish" || product.quantity == 0 || !product.quantity) && (
                            <div
                              style={{
                                backgroundColor: "hsla(40.84507042253521, 92.21%, 54.71%, 1.00)"
                              }}
                              className="category-tag">
                              Closed
                            </div>
                          )
                        }
                        
                        <h1 className="project-name">{product.name}</h1>
                        <h1 className="location">{product.location ?? "Headquarters"}</h1>
                        <h1 className="min-inv-heading">Min. Investment: <Currency />{helpers.money(product.price)}</h1>
                        <div className="flex items-start justify-between px-10">
                          {/* roi */}
                          <div className="flex flex-col">
                            <h1 className="proj-return-heading !text-gray-500 flex items-center gap-x-1">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-gray-500">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m9 14.25 6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0c1.1.128 1.907 1.077 1.907 2.185ZM9.75 9h.008v.008H9.75V9Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm4.125 4.5h.008v.008h-.008V13.5Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                              </svg>
                              ROI
                              </h1>
                            <h1 className="proj-return-heading !text-black">
                             {product.roi ?? 10}%{" "}
                            </h1>
                          </div>
                          {/* farm cycle */}
                          <div className="flex flex-col items-start justify-start">
                            <h1 className="proj-return-heading !text-gray-500 flex items-center gap-x-1">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-gray-500">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m9 14.25 6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0c1.1.128 1.907 1.077 1.907 2.185ZM9.75 9h.008v.008H9.75V9Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm4.125 4.5h.008v.008h-.008V13.5Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                              </svg>
                              Farm Cycle
                              </h1>
                            <h1 className="proj-return-heading !text-black !w-full text-center">
                             {product.cycle ?? "N/A"}
                            </h1>
                          </div>
                        </div>
                      </div>
                    );
                  })
                }

              </div>
            </div>
          </>
        );
      })()
    }
    <div className="w-container">
      <p className="centered-paragraph">
        <strong className="featured-subheading black">
          Interested in exploring farmland investment opportunities?
          <br />
        </strong>
        Follow the button below to create a Free FarmFundr account. <br />
        You will also be updated as new opportunities become available. At
        FarmFundr, we pride ourselves on offering only the highest quality
        investments that we'd invest in ourselves. Be sure to join to be the
        first to know when these carefully sourced opportunities become
        available.
        <br />
      </p>
    </div>
    <a
      href="/portal/login"
      target="_blank"
      className="button long w-button"
    >
      login or sign up for full investment details
    </a>
    <div className="w-container">
      <div className="div-text _2">
        <p
          data-w-id="fce3d7bc-65fe-2619-527c-d130dac951bf"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
          className="paragraph center white"
        >
          With a nice modern and intuitive interface you can control contents to
          be contained and organised.
        </p>
      </div>
    </div>
  </div>
  <div className="blog-post business" />
  <div className="call-to-action">
    <div className="container-4 w-container">
      <a
        href="/custom-farmland-investments"
        data-ix="show-contact-wrapper-on-click"
        data-w-id="480af710-d713-5a8f-294f-ac533c1c6954"
        target="_blank"
        className="button outline white w-button"
      >
        InterEsted in a custom tailored INvestment?{" "}
      </a>
    </div>
  </div>
  <div className="footer dark-bootom" />
  <div id="m2mol4otqak1710278137374" className="widget-hidden">
    <iframe
      src="about:blank"
      frameBorder={0}
      scrolling="no"
      width="64px"
      height="60px"
      style={{
        outline: "none !important",
        visibility: "visible",
        resize: "none",
        boxShadow: "none !important",
        overflow: "visible !important",
        background: "none !important",
        opacity: "1 !important",
        filter: "alpha(opacity=100) !important",
        msFilter:
          "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
        MozOpacity: "1 !important",
        KhtmlOpacity: "1 !important",
        top: "auto !important",
        right: "auto !important",
        bottom: "20px !important",
        left: "20px !important",
        position: "fixed",
        border: "0 !important",
        minHeight: "60px !important",
        minWidth: "64px !important",
        maxHeight: "60px !important",
        maxWidth: "64px !important",
        padding: "0 !important",
        margin: "0 !important",
        MozTransitionProperty: "none !important",
        WebkitTransitionProperty: "none !important",
        OTransitionProperty: "none !important",
        transitionProperty: "none !important",
        transform: "none !important",
        WebkitTransform: "none !important",
        msTransform: "none !important",
        width: "64px !important",
        height: "60px !important",
        display: "block !important",
        zIndex: "1000001 !important",
        backgroundColor: "transparent !important",
        cursor: "none !important",
        float: "none",
        borderRadius: "unset !important",
        pointerEvents: "auto",
        clip: "auto !important",
        colorScheme: "light !important"
      }}
      id="j8gpplotv5n1710278137403"
      className=""
      title="chat widget"
    />
    <iframe
      src="about:blank"
      frameBorder={0}
      scrolling="no"
      width="350px"
      height="520px"
      style={{
        outline: "none !important",
        visibility: "visible",
        resize: "none",
        boxShadow: "none !important",
        overflow: "visible !important",
        background: "none !important",
        opacity: "1 !important",
        filter: "alpha(opacity=100) !important",
        msFilter:
          "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
        MozOpacity: "1 !important",
        KhtmlOpacity: "1 !important",
        top: "auto !important",
        right: "auto !important",
        bottom: "90px !important",
        left: "10px !important",
        position: "fixed",
        border: "0 !important",
        minHeight: "520px !important",
        minWidth: "350px !important",
        maxHeight: "520px !important",
        maxWidth: "350px !important",
        padding: "0 !important",
        margin: "0 !important",
        MozTransitionProperty: "none !important",
        WebkitTransitionProperty: "none !important",
        OTransitionProperty: "none !important",
        transitionProperty: "none !important",
        transform: "none !important",
        WebkitTransform: "none !important",
        msTransform: "none !important",
        width: "350px !important",
        height: "520px !important",
        display: "none !important",
        zIndex: "auto !important",
        backgroundColor: "transparent !important",
        cursor: "none !important",
        float: "none",
        borderRadius: "5px !important",
        pointerEvents: "auto",
        clip: "auto !important",
        colorScheme: "light !important"
      }}
      id="vo45hdailfv81710278137452"
      className=""
      title="chat widget"
    />
    <iframe
      src="about:blank"
      frameBorder={0}
      scrolling="no"
      width="360px"
      height="71px"
      style={{
        outline: "none !important",
        visibility: "visible",
        resize: "none",
        boxShadow: "none !important",
        overflow: "visible !important",
        background: "none !important",
        opacity: "1 !important",
        filter: "alpha(opacity=100) !important",
        msFilter:
          "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
        MozOpacity: "1 !important",
        KhtmlOpacity: "1 !important",
        top: "auto !important",
        right: "auto !important",
        bottom: 100,
        left: "20px !important",
        position: "fixed",
        border: "0 !important",
        minHeight: "71px !important",
        minWidth: "360px !important",
        maxHeight: "71px !important",
        maxWidth: "360px !important",
        padding: "0 !important",
        margin: "0 !important",
        MozTransitionProperty: "none !important",
        WebkitTransitionProperty: "none !important",
        OTransitionProperty: "none !important",
        transitionProperty: "none !important",
        transform: "none !important",
        WebkitTransform: "none !important",
        msTransform: "none !important",
        width: "360px !important",
        height: "71px !important",
        display: "none !important",
        zIndex: "auto !important",
        backgroundColor: "transparent !important",
        cursor: "none !important",
        float: "none",
        borderRadius: "unset !important",
        pointerEvents: "auto",
        clip: "auto !important",
        colorScheme: "light !important"
      }}
      id="hfqqm6vlumi1710278137429"
      className=""
      title="chat widget"
    />
    <iframe
      src="about:blank"
      frameBorder={0}
      scrolling="no"
      width="146px"
      height="85px"
      style={{
        outline: "none !important",
        visibility: "visible",
        resize: "none",
        boxShadow: "none !important",
        overflow: "visible !important",
        background: "none !important",
        opacity: "1 !important",
        filter: "alpha(opacity=100) !important",
        msFilter:
          "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
        MozOpacity: "1 !important",
        KhtmlOpacity: "1 !important",
        top: "auto !important",
        right: "auto !important",
        bottom: "82px !important",
        left: "20px !important",
        position: "fixed",
        border: "0 !important",
        minHeight: "85px !important",
        minWidth: "146px !important",
        maxHeight: "85px !important",
        maxWidth: "146px !important",
        padding: "0 !important",
        margin: "0px 0 0 0 !important",
        MozTransitionProperty: "none !important",
        WebkitTransitionProperty: "none !important",
        OTransitionProperty: "none !important",
        transitionProperty: "none !important",
        transform: "rotate(0deg) translateZ(0)",
        WebkitTransform: "rotate(0deg) translateZ(0)",
        msTransform: "rotate(0deg) translateZ(0)",
        width: "146px !important",
        height: "85px !important",
        display: "none !important",
        zIndex: "1000002 !important",
        backgroundColor: "transparent !important",
        cursor: "none !important",
        float: "none",
        borderRadius: "unset !important",
        pointerEvents: "auto",
        clip: "auto !important",
        colorScheme: "light !important",
        MozTransform: "rotate(0deg) translateZ(0)",
        OTransform: "rotate(0deg) translateZ(0)",
        transformOrigin: 0,
        MozTransformOrigin: 0,
        WebkitTransformOrigin: 0,
        OTransformOrigin: 0,
        msTransformOrigin: 0
      }}
      id="zk99a7olug981710278137416"
      className=""
      title="chat widget"
    />
  </div>
</>

      
      </MainTemplate>
    </>
  );
}
