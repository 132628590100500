/* eslint-disable jsx-a11y/alt-text */
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { Link } from "react-router-dom";
// import Multiselect from 'multiselect-react-dropdown';
import { useState } from "react";
import { Input } from "@material-tailwind/react";

type Props = {
  onDone: (stepName: string) => void;
  form: any;
  setForm: any;
  setStep?: (step: number) => void;
};
export default function BioDataForm({ onDone, form, setForm, setStep }: Props) {
  const [interests] = useState([
    {
      id: 1,
      name: "Looking for Options to Diversify my Portfolio",
      value: "looking_for_options_to_diversify_my_portfolio",
    },
    {
      id: 2,
      name: "Seeking Long Term Investments that are Inflation Resistant",
      value: "seeking_long_term_investments_that_are_inflation_resistant",
    },
    { id: 3, name: "Desire to Help Farmers", value: "desire_to_help_farmers" },
    {
      id: 4,
      name: "Looking for Farmland Investments with High Return Possibilities",
      value: "looking_for_farmland_investments_with_high_return_possibilities",
    },
    {
      id: 5,
      name: "I want to Diversify Outside of Traditional Asset Classes",
      value: "i_want_to_diversify_outside_of_traditional_asset_classes",
    },
    { id: 6, name: "Seeking Low Votility", value: "seeking_low_votility" },
    {
      id: 7,
      name: "Interested in Lower Volatility Investments",
      value: "interested_in_lower_volatility_investments",
    },
    { id: 8, name: "Other", value: "other" },
  ]);
  const [countries] = useState([
    "United States",
    "Canada",
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and/or Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Cape Verde",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo",
    "Cook Islands",
    "Costa Rica",
    "Croatia (Hrvatska)",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "East Timor",
    "Ecudaor",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands (Malvinas)",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "France, Metropolitan",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard and Mc Donald Islands",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran (Islamic Republic of)",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Ivory Coast",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, Democratic People's Republic of",
    "Korea, Republic of",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libyan Arab Jamahiriya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macau",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia, Federated States of",
    "Moldova, Republic of",
    "Monaco",
    "Mongolia",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "Netherlands Antilles",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfork Island",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Reunion",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia South Sandwich Islands",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "St. Helena",
    "St. Pierre and Miquelon",
    "Sudan",
    "Suriname",
    "Svalbarn and Jan Mayen Islands",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States minor outlying islands",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City State",
    "Venezuela",
    "Vietnam",
    "Virigan Islands (British)",
    "Virgin Islands (U.S.)",
    "Wallis and Futuna Islands",
    "Western Sahara",
    "Yemen",
    "Yugoslavia",
    "Zaire",
    "Zambia",
    "Zimbabwe",
  ]);

  // const [selectedValue, setSelectedValue] = useState([]);

  // const onSelect = (selectedList: any, selectedItem: any) => {
  //     setSelectedValue(selectedList);
  // }

  // const onRemove = (selectedList: any, removedItem: any) => {
  //     setSelectedValue(selectedList);
  // }

  const onSubmit = (e: any) => {
    e.preventDefault();
    onDone("bio_data");
  };

  const handleChange = (e: any) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  return (
    <>
      <h3 className="text-[#737f8b] text-center font-sans text-[20px] font-extralight my-10">
        Please share some basic information to continue.
      </h3>

      <div className="px-10 w-full">
        <div className="bg-white rounded-lg shadow-2xl py-5 px-10 w-full">
          {/**/}
          <div className="flex flex-col items-center w-full space-y-3">
            <div className="text-gray-600 text-2xl">
              <PeopleAltIcon
                fontSize="large"
                className="!text-primary-500 mr-2"
              />
              {form.account_type === "entity" ? "Entity" : "Individual"}
            </div>

            <Link
              to="/portal/setup?step=1"
              className="text-gray-600 hover:text-primary-500 text-xs"
            >
              Not the right account type? Click here to go back.
            </Link>

            <div className="text-sm w-full text-left flex flex-row space-x-3 items-center text-black">
              <div className="text-sm text-left flex flex-row items-center text-black whitespace-nowrap">
                <span className="flex items-center justify-center text-white bg-primary-500 rounded-full p-2 text-xs mr-2 h-6 w-6 text-center">
                  1
                </span>
                Basic Info
              </div>
              {
                form.accredited_investor && (
                  <>
                    <div className="py-[1px] bg-gray-500 w-full"></div>
                    <div className="text-sm text-left flex flex-row items-center text-black whitespace-nowrap">
                      <span className="flex items-center justify-center text-white bg-primary-500 rounded-full p-2 text-xs mr-2 h-6 w-6 text-center">
                        2
                      </span>
                      Accreditation
                    </div>
                  </>
                )
              }
            </div>

            <form
              className="ng-untouched ng-pristine ng-star-inserted ng-invalid w-full space-y-5"
              onSubmit={onSubmit}
            >
              <h1 className="text-xl !font-extralight mt-3">
                PERSONAL INFORMATION
              </h1>

              {/* first and last name */}
              <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-y-2 gap-x-3">
                <Input
                  crossOrigin={""}
                  label="First Name"
                  name="first_name"
                  type="text"
                  required
                  className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                  placeholder="First Name *"
                  value={form.first_name || ""}
                  onChange={handleChange}
                />

                <Input
                  crossOrigin={""}
                  label="Last Name"
                  name="last_name"
                  type="text"
                  required
                  className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                  placeholder="Last Name *"
                  value={form.last_name || ""}
                  onChange={handleChange}
                />
              </div>

              <h1 className="text-xl !font-extralight">CONTACT INFORMATION</h1>
              <h1 className="text-xl !font-extralight">Address</h1>
              <div className="flex flex-col space-y-5">
                <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-y-2 gap-x-3">
                  <Input
                  crossOrigin={""}
                  label="Street Address"
                    name="address"
                    type="text"
                    required
                    className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                    placeholder="Street Address *"
                    value={form.address || ""}
                    onChange={handleChange}
                  />

                  <Input
                  crossOrigin={""}
                  label="Suite / Unit"
                    name="suite"
                    type="text"
                    className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                    placeholder="Suite / Unit"
                    value={form.suite || ""}
                    onChange={handleChange}
                  />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-y-3 gap-x-3">
                  <Input
                  crossOrigin={""}
                  label="City"
                    name="city"
                    type="text"
                    required
                    className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                    placeholder="City *"
                    value={form.city || ""}
                    onChange={handleChange}
                  />

                  <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-y-3 gap-x-3">
                    <Input
                      crossOrigin={""}
                      label="State / Region"
                      name="state"
                      type="text"
                      required
                      className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                      placeholder="State / Region *"
                      value={form.state || ""}
                      onChange={handleChange}
                    />

                    <Input
                  crossOrigin={""}
                  label="Zip / Postal Code"
                      name="zip_code"
                      type="text"
                      required
                      className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                      placeholder="Zip / Postal Code *"
                      value={form.zip_code || ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-y-2 gap-x-3">
                  <div className="flex flex-col w-full">
                    <label className="font-normal">Country</label>
                    <select
                      name="country"
                      required
                      className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                      value={form.country || ""}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        Country *
                      </option>
                      {
                          countries.map((country) => (
                              <option value={country} key={country}>
                              {country}
                              </option>
                          ))
                      }
                    </select>
                  </div>
                </div>
              </div>

              <h1 className="text-xl !font-extralight">
                PLEASE TELL US MORE ABOUT YOU
              </h1>
              <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-y-2 gap-x-3">
                {/* <Multiselect
                name="interests"
                placeholder="What is your Primary Interest in Joining FarmFundr?"
                options={interests} // Options to display in the dropdown
                selectedValues={selectedValue} // Preselected value to persist in dropdown
                onSelect={onSelect} // Function will trigger on select event
                onRemove={onRemove} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
                className="!w-full py-0 [&>*]:!py-4"
                showCheckbox={true}
                showArrow={true}
                /> */}
                <div className="flex flex-col w-full">
                    <label className="font-normal">What is your Primary Interest in Joining FarmFundr?</label>
                <select
                  name="interest"
                  className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                  value={form.interest || ""}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    What is your Primary Interest in Joining FarmFundr?
                  </option>
                  {interests.map((interest) => (
                    <option value={interest.value} key={interest.id}>
                      {interest.name}
                    </option>
                  ))}
                </select>
                </div>

                <div className="flex flex-col w-full">
                    <label className="font-normal my-3"></label>
                  <Input
                  crossOrigin={""}
                  label="How did you find us?"
                    name="how_you_find_us"
                    type="text"
                    className="border border-gray-300 rounded-md p-3 text-sm placeholder:text-gray-600 w-full"
                    placeholder="How did you find out about FarmFundr?"
                    value={form.how_you_find_us || ""}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div></div>

              <button
                type="submit"
                className="bg-primary-500 hover:bg-primary text-white text-sm rounded-md p-3 px-20 w-full"
              >
                NEXT
              </button>
              <button
                type="button"
                className="bg-white text-gray-600 hover:text-primary text-sm rounded-md p-3 px-20 w-full"
                onClick={() => {
                  setStep && setStep(2);
                  window.history.pushState({}, "", `?step=2`);
                }}
              >
                BACK
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
