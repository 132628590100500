import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyATZYd7agTgOwAqAx8ye6BKmAp-K_iFApE",
  authDomain: "farmfundr-fc1cf.firebaseapp.com",
  projectId: "farmfundr-fc1cf",
  storageBucket: "farmfundr-fc1cf.appspot.com",
  messagingSenderId: "967208542766",
  appId: "1:967208542766:web:61505c7065727a93bff5bf",
  measurementId: "G-ZBYJV3LV05",
  fcmServerKey: "",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);
export const storage = getStorage(app);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();





  // Resolve permission issue
  // sudo chmod -R 777 /Users/seun/Documents/NodeJS/farmfundr
  // sudo chmod -R 777 /Users/seun/Documents/NodeJS/farmfundr/node_modules