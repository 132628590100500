/* eslint-disable eqeqeq */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import useDocumentTitle from "../../hooks/userDocumentTitle";
import PortalTemplate from "./PortalTemplate";
import { Helmet } from 'react-helmet';
import React from "react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
// import PendingTransactions from "./portfolio_tabs/PendingTransactions";
import TransactionHistory from "./portfolio_tabs/TransactionHistory";
import Investments from "./portfolio_tabs/Investments";



export default function PortfolioPage() {
  useDocumentTitle("Portfolio");

  const [activeTab, setActiveTab] = React.useState(new URLSearchParams(window.location.search).get("tab") || "investments");
  const data = [
    {
      label: "INVESTMENTS",
      value: "investments",
      body: <Investments />,
    },
    {
      label: "TRANSACTION HISTORY",
      value: "transaction-history",
      body: <TransactionHistory />
    },
    // {
    //   label: "PENDING TRANSACTIONS",
    //   value: "pending-transactions",
    //   body: <PendingTransactions />
    // },
  ];




  return (
   <PortalTemplate className="md:py-6 flex flex-col items-center justify-center">
    <Helmet>
                <style>{'body { background-color: #F2F3F6; }'}</style>
    </Helmet>
    <>
    <div className="w-full px-4 md:px-32">
    <h1 className="text-black text-xl md:text-2xl text-left w-full">My Portfolio</h1>
      <Tabs value={activeTab}>
        <TabsHeader
        placeholder={""}
          className="rounded-none border-b border-blue-gray-100 bg-transparent p-0 md:pr-[700px] overflow-x-auto z-0"
          indicatorProps={{
            className:
              "bg-transparent border-b-[5px] border-gray-700 shadow-none rounded-none w-auto z-0",
          }}
        >
          {data.map(({ label, value }) => (
            <Tab
            placeholder={""}
              key={value}
              value={value}
              onClick={() => {
                setActiveTab(value);
                window.history.pushState({}, "", `?tab=${value}`);
              }}
              className={`font-bold whitespace-nowrap ${activeTab === value ? "text-gray-900" : "text-gray-600"}`}
            >
              {label}
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody className="w-full" placeholder={""}>
          {data.map(({ value, body }) => (
            <TabPanel className="w-full !p-0" key={value} value={value}>
                {body}
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
    </div>
    </>

   </PortalTemplate>
  );
}


