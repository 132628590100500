import { useContext, useState } from "react";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { toast } from "react-toastify";
import { helpers } from "../../utilities/helpers";
import { db } from "../..";
import PortalFormModal from "./components/PortalFormModal";
import { Textarea } from "@material-tailwind/react";
import AppContext from "../../AppContext";

type Props = {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
}
const ContactModal = ({ showModal, setShowModal }: Props) => {

    const context: any = useContext(AppContext);

    const [formData, setOfferData] = useState({} as any);


    const onInputChanged = (e: any) => {
        setOfferData({ ...formData, [e.target.name]: e.target.value });
    }

    const onFormSubmit = async (e: any) => {
        e.preventDefault();

        const loading = toast.loading("Submitting message...");
    
        try {

            const user: any = context.user;
          //every possible search item
          const searchItems = [user.first_name + " " + user.last_name];
    
          //create order
          const orderDocNumber = await helpers.getNextDocumentNumber("orders");

    
          var order: any = {
            doc_number: orderDocNumber,
            product_id : null,
            product: null,
            message: formData.message || "",
            total: formData.price || 0,
            user_id: user.id || null,
            user: user,
            status: "pending",
            payment_status: "pending",
            type: "contact",
            search_keywords: helpers.generateSearchKeywords(
              searchItems.map((s) => s.toLowerCase()).join(" ")
            ),
            updated_at: serverTimestamp(),
            created_at: serverTimestamp(),
          };
          await addDoc(collection(db, "orders"), order);
     
        } catch (error: any) {
          toast.error(error.message || "An error occurred");
          toast.dismiss(loading);
          return;
        }
    
        toast.dismiss(loading);
    
        toast.success("Message sent successfully");

        //close modal
        setShowModal(false);
      };


    return (
        <>
            <PortalFormModal title={"Contact Us"} size="max-w-4xl" flex={true} showModal={showModal} setShowModal={setShowModal}>
                <form autoComplete="off" className="space-y-6" id="type-edit-form" onSubmit={onFormSubmit} method="POST" encType="multipart/form-data">
                    {/*body*/}
                    <div className="relative px-6 pt-6 pb-2 flex-auto">

                        <div>
                            {/* <label htmlFor="message" className="block text-sm font-medium text-gray-900 dark:text-gray-300">Message</label> */}
                            <Textarea
                                name="message"
                                value={formData.message || ""}
                                onChange={onInputChanged}
                                id="message" className="bg-gray-50 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                label="Type your message here. *"
                                 required />
                        </div>
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 pt-0 rounded-b">
                        <button type="submit" className="text-white bg-primary-500 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-3 text-center dark:bg-blue-600 dark:hover:bg-gray-700 dark:focus:ring-black">
                            <span id="submit-text">SUBMIT</span>
                        </button>
                    </div>
                </form>
            </PortalFormModal>
        </>
    );
}

export default ContactModal;