/* eslint-disable eqeqeq */
import { useContext, useEffect, useRef, useState } from "react";
import SkyeModal from "../../components/SkyeModal";
import Currency from "../../components/Currency";
import StatusLabel from "../../components/StatusLabel";
import { Timestamp, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../..";
import { toast } from "react-toastify";
import AppContext from "../../../../AppContext";
import { helpers } from "../../../../utilities/helpers";
// import { toBlob } from "html-to-image";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

type Props = {
  title?: string;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  payment?: any;
  setPayment: (payment: any) => void;
  onModalUpdated: (payment: any) => void;
};
const PaymentModal = ({
  showModal,
  setShowModal,
  payment,
  setPayment,
  onModalUpdated,
}: Props) => {
  const navigate = useNavigate();
  const context: any = useContext(AppContext);

  const [paymentData, setPaymentData] = useState(payment ?? {});
  // const [notes, setNotes] = useState(paymentData.notes ?? []);

  const [enableOutstadingEdit, setEnableOutstadingEdit] = useState(false);

  const imageRef = useRef(null);

  useEffect(() => {
    setPaymentData(payment);
    // setNotes(paymentData.notes ?? []);
  }, [payment, paymentData.notes]);

  const onInputChanged = (e: any) => {
    setPaymentData({ ...paymentData, [e.target.name]: e.target.value });
  };

  const shareInvoice = async () => {
    // const node = imageRef.current;
    // if (!node) {
    //   toast.error("Failed to share invoice");
    //   return;
    // }
    // const newFile = await toBlob(node);
    // if (!newFile) {
    //   toast.error("Failed to share invoice");
    //   return;
    // }

    // const data = {
    //   files: [
    //     new File([newFile], `invoice-${payment.doc_number}.png`, {
    //       type: newFile.type,
    //     }),
    //   ],
    //   title: `Invoice #${payment.doc_number}`,
    //   text: `Invoice #${payment.doc_number}`,
    // };
    // try {
    //   if (!navigator.canShare(data)) {
    //     toast.error("Sharing not supported");
    //   }
    //   await navigator.share(data);
    // } catch (err) {
    //   // console.log(err);
    //   // toast.error("Failed to share invoice");
    // }
  };

  const onFormSubmit = async (e: any) => {
    e.preventDefault();

    //warning for outstanding if status is paid
    if (
      paymentData.status === "paid" &&
      parseFloat(paymentData.total_paid ?? 0) < parseFloat(paymentData.amount)
    ) {
      if (
        !window.confirm("Outstanding not fully paid. Do you want to continue?")
      ) {
        return;
      }
    }

    const loading = toast.loading("Updating invoice...");

    //remove other fields except status
    let formData: any = {
      status: paymentData.status,
      total_paid:
        parseFloat(paymentData.total_paid) >= parseFloat(paymentData.amount)
          ? parseFloat(paymentData.total_paid)
          : paymentData.status === "paid"
          ? parseFloat(paymentData.amount)
          : parseFloat(paymentData.total_paid),
      //add who updated the invoice
      updated_by: {
        id: context.user.id,
        doc_number: context.user.doc_number ?? 0,
        full_name: context.user.full_name,
        email: context.user.email,
        phone: context.user.phone,
        role: context.user.role,
      },
    };

    //update updated_at
    formData.updated_at = Timestamp.now();

    //update payment
    await updateDoc(doc(db, "payments", paymentData.id), formData);

    //get new payment data
    const refreshedPayment = await getDoc(
      doc(db, "payments", paymentData.id)
    ).then((doc) => {
      if (doc.exists()) {
        return doc.data();
      }
    });
    

    //update order
    if (
      (paymentData.order_id ?? paymentData.order?.id) &&
      paymentData.payment_for === "order"
    ) {
      const orderRef = doc(
        db,
        "orders",
        paymentData.order_id ?? paymentData.order?.id
      );
      const order = await getDoc(orderRef);
      if (order.exists()) {
        await updateDoc(orderRef, {
          payment_status: paymentData.status,
          payment: refreshedPayment,
        });
      }
    }

    //close modal
    setShowModal(false);

    //remove loading
    toast.dismiss(loading);

    //show toast
    toast.success("Invoice updated successfully");

    setEnableOutstadingEdit(false);
    setPayment({});

    onModalUpdated(formData);
  };

  return (
    <>
      <SkyeModal
        title={
          paymentData.id ? `Invoice #${paymentData.doc_number}` : "New Invoice"
        }
        size="max-w-xl"
        flex={false}
        showModal={showModal}
        setShowModal={() => {
          setShowModal(false);
          setEnableOutstadingEdit(false);
          setPayment({});
        }}
        header={
          <div className="flex items-center justify-center w-full">
            <Button
              variant="contained"
              size="small"
              color="primary"
              className="!px-4 !rounded-md !normal-case !text-lg !h-[30px]"
              onClick={async () => {
                shareInvoice();
              }}
            >
              <span>Send</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5 ml-3"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                />
              </svg>
            </Button>
          </div>
        }
      >
        <form
          className="space-y-6"
          id="payment-edit-form"
          onSubmit={onFormSubmit}
          method="POST"
          encType="multipart/form-data"
        >
          {/*body*/}
          <div className="relative p-6 flex-auto">
            {/* body */}

            {/* action */}
            <div className="col-span-6 lg:col-span-3 mb-4">
              <label
                htmlFor="status"
                className="block text-sm font-medium text-gray-700"
              >
                Payment Status
              </label>
              <select
                name="status"
                value={paymentData.status || ""}
                onChange={onInputChanged}
                id="status"
                className="bg-gray-50 border-2 border-gray-300 text-gray-500 text-sm font-semibold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option value="pending">Pending</option>
                <option value="processing">Outstanding</option>
                <option value="paid">Paid</option>
                <option value="cancelled">Cancelled</option>
              </select>
            </div>

            <div id="share" ref={imageRef} className="bg-white">
              {/* receipt table */}
              <div className="flex flex-col mb-5">
                <div className="-my-2 overflow-x-auto lg:-mx-6">
                  <div className="py-2 align-middle inline-block w-full lg:px-6">
                    <div className="shadow overflow-hidden border-b border-gray-200 lg:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50 dark:bg-gray-700">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
                            >
                              #
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
                            >
                              Amount
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                          {/* order status */}
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100">
                                Order Status
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100 font-bold flex items-center">
                                <StatusLabel
                                  status={
                                    paymentData.order_status ??
                                    paymentData.order?.status
                                  }
                                />
                                {[
                                  "admin",
                                  "super_admin",
                                  "accounting_manager",
                                ].includes(context.user.role) && (
                                  <button
                                    type="button"
                                    onClick={() => {
                                      if (paymentData.order_id) {
                                        navigate(
                                          `/admin/orders?id=${paymentData.order_id}`
                                        );
                                      }
                                    }}
                                    id="row-edit"
                                    className="flex items-center underline rounded text-gray-700 hover:text-white p-0.5 hover:bg-gray-700 disabled:bg-gray-800 ml-1"
                                    data-modal-toggle="edit-modal"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="w-5 h-5 icon icon-tabler icon-tabler-pencil inline"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      strokeWidth="2"
                                      stroke="currentColor"
                                      fill="none"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <desc>
                                        Download more icon variants from
                                        https://tabler-icons.io/i/pencil
                                      </desc>
                                      <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      ></path>
                                      <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4"></path>
                                      <line
                                        x1="13.5"
                                        y1="6.5"
                                        x2="17.5"
                                        y2="10.5"
                                      ></line>
                                    </svg>
                                    <span className="hidden sm:inline">
                                      Go to Order
                                    </span>
                                  </button>
                                )}
                              </div>
                            </td>
                          </tr>
                          {/* amount */}
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100">
                                Total
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">
                                <Currency />
                                {helpers.money(paymentData.amount)}
                              </div>
                            </td>
                          </tr>
                          {/* paid */}
                          {(parseFloat(paymentData.total_paid ?? 0) <
                            parseFloat(paymentData.amount) ||
                            enableOutstadingEdit || paymentData.status == "processing") && (
                            <tr>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-900 dark:text-gray-100">
                                  Paid
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">
                                  {enableOutstadingEdit ? (
                                    <div className="flex">
                                      <input
                                        type="number"
                                        name="total_paid"
                                        value={paymentData.total_paid ?? 0}
                                        onChange={(e) => {
                                          setPaymentData({
                                            ...paymentData,
                                            total_paid: e.target.value,
                                          });
                                        }}
                                        className="bg-gray-50 border-2 border-gray-300 text-gray-500 text-sm font-semibold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                      />
                                      <button
                                        type="submit"
                                        id="row-edit"
                                        className="inline rounded bg-black text-white px-4 hover:bg-gray-700 disabled:bg-gray-800 ml-1"
                                        data-modal-toggle="edit-modal"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth={2}
                                          stroke="currentColor"
                                          className="w-4 h-4"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="m4.5 12.75 6 6 9-13.5"
                                          />
                                        </svg>
                                      </button>
                                    </div>
                                  ) : (
                                    <>
                                      <Currency />
                                      {helpers.money(
                                        paymentData.total_paid ?? 0
                                      )}
                                    </>
                                  )}
                                  {!enableOutstadingEdit && (
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setEnableOutstadingEdit(
                                          !enableOutstadingEdit
                                        );
                                      }}
                                      id="row-edit"
                                      className="inline rounded text-gray-700 hover:text-white p-0.5 hover:bg-gray-700 disabled:bg-gray-800 ml-1"
                                      data-modal-toggle="edit-modal"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-4 h-4 icon icon-tabler icon-tabler-pencil"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      >
                                        <desc>
                                          Download more icon variants from
                                          https://tabler-icons.io/i/pencil
                                        </desc>
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        ></path>
                                        <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4"></path>
                                        <line
                                          x1="13.5"
                                          y1="6.5"
                                          x2="17.5"
                                          y2="10.5"
                                        ></line>
                                      </svg>
                                    </button>
                                  )}
                                </div>
                              </td>
                            </tr>
                          )}
                          {/*  outstanding */}
                          {(parseFloat(paymentData.total_paid ?? 0) <
                            parseFloat(paymentData.amount) ||
                            enableOutstadingEdit || paymentData.status == "processing") && (
                            <tr>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-900 dark:text-gray-100">
                                  Outstanding
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div
                                  className={`text-sm text-gray-900 dark:text-gray-100 font-bold flex items-center  ${
                                    parseFloat(paymentData.total_paid ?? 0) >=
                                    parseFloat(paymentData.amount)
                                      ? "text-green-500"
                                      : "text-red-500"
                                  }`}
                                >
                                  {parseFloat(paymentData.total_paid ?? 0) >=
                                  parseFloat(paymentData.amount) ? (
                                    "Completed"
                                  ) : (
                                    <>
                                      -
                                      <Currency />
                                      {helpers.money(
                                        parseFloat(paymentData.amount) -
                                          parseFloat(
                                            paymentData.total_paid ?? 0
                                          )
                                      )}
                                    </>
                                  )}
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              {/* other info table */}
              <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto lg:-mx-8">
                  <div className="py-2 align-middle inline-block w-full lg:px-6">
                    <div className="shadow overflow-hidden border-b border-gray-200 lg:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50 dark:bg-gray-700">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
                            >
                              #
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
                            >
                              #
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                          {/* status */}
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100">
                                Status
                              </div>
                            </td>
                            <td className="px-6 py-4">
                              <StatusLabel status={paymentData.status} replacements={{
                                processing: 'Outstanding',
                              }} />
                            </td>
                          </tr>
                          {/* client_full_name */}
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100">
                                Client
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100 font-bold">
                                {paymentData.client_full_name}
                                {paymentData.is_new_client && (
                                  <span className="text-xs bg-green-600 text-white rounded-full px-2 py-0.5 ml-1">
                                    New
                                  </span>
                                )}
                              </div>
                            </td>
                          </tr>
                          {/* user.phone */}
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100">
                                Phone
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100">
                                {paymentData.user?.phone}
                              </div>
                            </td>
                          </tr>
                          {/* address */}
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100">
                                Address
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100">
                                {paymentData.user?.address}{" "}
                                {paymentData.user?.city}{" "}
                                {paymentData.user?.state}{" "}
                                {paymentData.user?.country}
                              </div>
                            </td>
                          </tr>
                          {/* date */}
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100">
                                Date
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100">
                                {paymentData.created_at
                                  ? new Date(
                                      paymentData.created_at.toDate()
                                    ).toLocaleDateString()
                                  : ""}
                              </div>
                            </td>
                          </tr>
                          {/* description */}
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100">
                                Description
                              </div>
                            </td>
                            <td className="px-6 py-4">
                              <div className="text-sm text-gray-900 dark:text-gray-100">
                                {paymentData.description}
                              </div>
                            </td>
                          </tr>
                          {/* order_id */}
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100">
                                Order ID
                              </div>
                            </td>
                            <td className="px-6 py-4">
                              <div className="text-sm text-gray-900 dark:text-gray-100">
                                #
                                {paymentData.order?.doc_number ??
                                  paymentData.order_id}
                              </div>
                            </td>
                          </tr>
                          {/* payment_for */}
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100">
                                Payment For
                              </div>
                            </td>
                            <td className="px-6 py-4">
                              <div className="text-sm text-gray-900 dark:text-gray-100">
                                {paymentData.payment_for}
                              </div>
                            </td>
                          </tr>
                          {/* sales_manager info */}
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900 dark:text-gray-100">
                                Sales Manager
                              </div>
                            </td>
                            <td className="px-6 py-4">
                              <div className="text-sm text-gray-900 dark:text-gray-100 my-2">
                                <b>Name:</b>{" "}
                                {paymentData.sales_manager?.full_name}
                              </div>
                              <div className="text-sm text-gray-900 dark:text-gray-100 my-2">
                                <b>Phone:</b> {paymentData.sales_manager?.phone}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* notes */}
            {/* <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 lg:col-span-3">
                                <label htmlFor="notes" className="block text-sm font-medium text-gray-700">
                                    Notes
                                </label>
                                <textarea
                                    name='notes'
                                    value={notes}
                                    onChange={(e: any) => setNotes(e.target.value)}
                                    id="notes" className="bg-gray-50 border-2 border-gray-300 text-gray-500 text-sm font-semibold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                            </div>
                        </div> */}

          
          </div>
          {/*footer*/}
          <div className="flex items-center justify-center p-6 pt-0 bpayment-t bpayment-solid bpayment-slate-200 rounded-b">
            <button
              type="submit"
              className="bg-black hover:bg-black text-white font-semibold py-2 px-20 text-xl rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2"
            >
              Update Invoice
            </button>
          </div>
        </form>
      </SkyeModal>
    </>
  );
};

export default PaymentModal;
