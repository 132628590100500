/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { doc, getDoc } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "..";
import Currency from "../components/Currency";
import { helpers } from "../utilities/helpers";
import OfferModal from "./portal/OfferModal";
import AppContext from "../AppContext";
import { toast } from "react-toastify";

function OfferContentPage() {
  const navigate = useNavigate();
  //get id from url
  const { id } = useParams();

  const context: any = useContext(AppContext);

  const [product, setProduct] = useState({} as any);
  const [productLoading, setProductLoading] = useState(true);
  const [error, setError] = useState(null as any);

  const [quantity, setQuantity] = useState(1);

  const [offerData, setOfferData] = useState({} as any);

    const [showModal, setShowModal] = useState(false);

  //fetch product from firebase
  const fetchProduct = async () => {
    setProductLoading(true);
    try {
      // fetch product from firebase
      const productRef = doc(db, "products", id!);
      const docSnap = await getDoc(productRef);
      if (docSnap.exists()) {
        setProduct({ ...docSnap.data(), id: docSnap.id });
      }
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      setProductLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchProduct();
    } else {
      setError("Product not found");
      setProductLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const investNow = () => {
    if (context.user && context.token){
      navigate(`/offer-pay/${id}?quantity=${quantity}`);
    } else {
      toast.error("Please login/register to continue");
      navigate("/portal/login?redirect=/offering/" + id);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const openOfferContactModal = (product: any) => {
    setOfferData(product);
    //open modal
    setShowModal(true);
  }

  const onModalUpdated = (product: any) => {
    // fetchProducts();
  }

  return (
    <>
      {(() => {

        if (productLoading) {
          return (
            <section className="py-8 bg-white md:py-16 dark:bg-gray-900 antialiased">
              <div className="max-w-screen-xl px-4 mx-auto 2xl:px-0">
                <div className="flex items-center justify-center h-96">
                  <svg
                    className="w-8 h-8 text-primary-600 animate-spin"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx={12}
                      cy={12}
                      r={10}
                      stroke="currentColor"
                      strokeWidth={4}
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V2.5"
                    />
                  </svg>
                </div>
              </div>
            </section>
          );
        } else if (error) {
          return (
            <section className="py-8 bg-white md:py-16 dark:bg-gray-900 antialiased">
              <div className="max-w-screen-xl px-4 mx-auto 2xl:px-0">
                <div className="flex items-center justify-center h-96">
                  <p className="text-gray-500 text-xl dark:text-gray-400">
                    {(typeof error === "object") ? (error?.message || "An error occurred") : error || "An error occurred"}
                  </p>
                </div>
              </div>
            </section>
          );
        } else if (!product || !product.id) {
          return (
            <section className="py-8 bg-white md:py-16 dark:bg-gray-900 antialiased">
              <div className="max-w-screen-xl px-4 mx-auto 2xl:px-0">
                <div className="flex items-center justify-center h-96">
                  <p className="text-gray-500 text-xl dark:text-gray-400">
                    Investment not found
                  </p>
                </div>
              </div>
            </section>
          );
        }

        return (
          <section className="py-8 bg-white md:py-16 dark:bg-gray-900 antialiased">
            <div className="max-w-screen-xl px-4 mx-auto 2xl:px-0">
              <div className="lg:grid lg:grid-cols-2 lg:gap-8 xl:gap-16">
                <div className="shrink-0 max-w-md lg:max-w-lg mx-auto">
                  <img
                    className="w-full h-[200px] md:h-[300px] object-cover rounded"
                    src={product.images ? product.images[0] : "https://via.placeholder.com/150"}
                    alt=""
                  />
                </div>
                <div className="mt-6 sm:mt-8 lg:mt-0">
                  <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
                    {product?.name}
                  </h1>
                  <div className="mt-4 sm:items-center sm:gap-4 sm:flex">
                    <p className="text-2xl font-extrabold text-gray-900 sm:text-3xl dark:text-white">
                      <Currency />{helpers.money(product?.price)}
                    </p>
                    <div className="flex items-center gap-2 mt-2 sm:mt-0">
                      <div className="flex items-center gap-1">
                        <svg
                          className="w-4 h-4 text-yellow-300"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                        </svg>
                        <svg
                          className="w-4 h-4 text-yellow-300"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                        </svg>
                        <svg
                          className="w-4 h-4 text-yellow-300"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                        </svg>
                        <svg
                          className="w-4 h-4 text-yellow-300"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                        </svg>
                        <svg
                          className="w-4 h-4 text-yellow-300"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                        </svg>
                      </div>
                      <p className="text-sm font-medium leading-none text-gray-500 dark:text-gray-400">
                        (5.0)
                      </p>
                      {/* <a
                        href="#"
                        className="text-sm font-medium leading-none text-gray-900 underline hover:no-underline dark:text-white"
                      >
                        345 Reviews
                      </a> */}
                    </div>
                  </div>

                      {/* location, roi, farm cycle */}
                  <div className="flex items-center justify-center gap-x-4 mt-2">
                    <div className="flex items-center gap-2">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                      </svg>

                        <div className="text-sm text-gray-500 dark:text-gray-400">
                          {product?.location}
                        </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m9 14.25 6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0c1.1.128 1.907 1.077 1.907 2.185ZM9.75 9h.008v.008H9.75V9Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm4.125 4.5h.008v.008h-.008V13.5Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                      </svg>

                        <div className="text-sm text-gray-500 dark:text-gray-400">
                          {product?.roi}% ROI
                        </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m9 14.25 6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0c1.1.128 1.907 1.077 1.907 2.185ZM9.75 9h.008v.008H9.75V9Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm4.125 4.5h.008v.008h-.008V13.5Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                      </svg>

                        <div className="text-sm text-gray-500 dark:text-gray-400">
                          {product?.cycle ?? "N/A"}
                        </div>
                    </div>
                  </div>

                  {/* quantity field (with decrease and increase button) */}
                  <div className="flex items-center justify-center gap-4 mt-4 font-montserrat">
                    <div className="flex flex-col items-center">
                      <label htmlFor="quantity" className="text-lg font-bold text-black dark:text-white">
                        Unit
                      </label>
                      <div className="flex items-center gap-2">
                        <button
                          onClick={() => setQuantity(Math.max(1, quantity - 1))}
                          className="p-2 text-gray-500 bg-gray-200 rounded-full focus:outline-none"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="w-5 h-5 text-black"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M20 12H4"
                            />
                          </svg>
                        </button>
                        <input
                          type="number"
                          value={quantity}
                          onChange={(e) =>
                            setQuantity(Math.max(1, parseInt(e.target.value)))
                          }
                          className="w-16 text-xl font-bold text-center rounded-lg border border-gray-200 focus:outline-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                        />
                        <button
                          onClick={() => setQuantity(quantity + 1)}
                          className="p-2 text-gray-500 font-bold bg-gray-200 rounded-full focus:outline-none"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="w-5 h-5 text-black"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    {/* <button
                      onClick={() => openOfferContactModal(product)}
                      className="text-white w-full bg-primary hover:bg-primary-500 focus:ring-4 focus:ring-primary-500 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-primary-500 dark:hover:bg-primary-500 focus:outline-none dark:focus:ring-primary-500"
                    >
                      Contact us
                    </button> */}
                  </div>
                  {/* total (show if quantity is greater than 1) */}
                  {quantity > 1 && (
                    <div className="mt-4 flex items-center gap-x-4 justify-center font-montserrat">
                      <div className="text-xl font-bold text-gray-600 dark:text-white">
                        Total:
                      </div>
                      <div className="text-2xl font-bold text-gray-900 dark:text-white">
                        <Currency />{helpers.money(product?.price * quantity)}
                      </div>
                    </div>
                  )}
                  {/* total roi amount */}
                  <div className="mt-4 flex items-center gap-x-4 justify-center font-montserrat">
                    <div className="text-sm font-bold text-gray-600 dark:text-white">
                      Payment each cycle:
                    </div>
                    <div className="text-md font-bold text-gray-900 dark:text-white">
                      <Currency />{helpers.money(product?.price * quantity * product?.roi / 100)}
                    </div>
                  </div>


                  <div className="mt-6 sm:gap-4 sm:items-center sm:flex sm:mt-8 w-full">
                    {/* <a
                      href="#"
                      title=""
                      className="flex items-center justify-center py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                      role="button"
                    >
                      <svg
                        className="w-5 h-5 -ms-2 me-2"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12.01 6.001C6.5 1 1 8 5.782 13.001L12.011 20l6.23-7C23 8 17.5 1 12.01 6.002Z"
                        />
                      </svg>
                      Add to favorites
                    </a> */}
                    <button
                      type="button"
                      onClick={investNow}
                      className="text-white w-full mt-4 sm:mt-0 bg-primary hover:bg-primary-500 focus:ring-4 focus:ring-primary-500 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-primary-500 dark:hover:bg-primary-500 focus:outline-none dark:focus:ring-primary-500 flex items-center justify-center"
                    >
                      <svg
                        className="w-5 h-5 -ms-2 me-2"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M4 4h1.5L8 16m0 0h8m-8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm.75-3H7.5M11 7H6.312M17 4v6m-3-3h6"
                        />
                      </svg>
                      Invest now
                    </button>
                  </div>
                  <hr className="my-6 md:my-8 border-gray-200 dark:border-gray-800" />
                  <p className="mb-6 text-gray-500 dark:text-gray-400">
                    {product?.description}
                  </p>
                </div>
              </div>
            </div>
          </section>
        );
      })()}


      <OfferModal offer={offerData} setOffer={setOfferData} showModal={showModal} setShowModal={setShowModal} onModalUpdated={onModalUpdated} />
    </>
  );
}

export default OfferContentPage;
