import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HelpIcon from '@mui/icons-material/Help';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

type Props = {
  onDone: (stepName: string) => void;
  form: any;
  setForm: any;
};
export default function AccreditedInvestor({ onDone, form, setForm }: Props) {



  return (
    <>
      <h3 className="text-[#737f8b] text-center font-sans text-[20px] font-extralight my-10">
      Are you an Accredited Investor? <HelpIcon className="!text-gray-600"/>
        <br />
      </h3>

      <div className="flex flex-col md:flex-row gap-y-16 md:gap-y-0 md:gap-x-5 items-center justify-center px-5 md:px-14 mt-12 w-full">


        <div className="group w-full">
          <div className="bg-white text-gray-600 h-full rounded-lg px-8 py-20 pb-10 flex flex-col gap-y-3 items-center justify-center hover:bg-gray-50 cursor-pointer hover:shadow-lg"
          onClick={() => {
            setForm({ ...form, accredited_investor: true });
            onDone("accredited_investor");
        }}
          >
            <h1 className="rounded-full p-[5px] bg-white shadow text-[#4D8B2B] text-xl text-center font-[600] text-[30px] font mt-[-120px] w-[100px] h-[100px] flex items-center justify-center">
              <PeopleAltIcon className="!text-[60px]" />
            </h1>
            <h2 className="text-[22px] font-medium mt-0">
            I am Accredited
            </h2>
          </div>
        </div>

        <div className="group w-full">
          <div className="bg-white text-gray-600 h-full rounded-lg px-8 py-20 pb-10 flex flex-col gap-y-3 items-center justify-center hover:bg-gray-50 cursor-pointer hover:shadow-lg"
            onClick={() => {
                window.open('https://www.investor.gov/introduction-investing/general-resources/news-alerts/alerts-bulletins/investor-bulletins/updated-3', '_blank');
            }}
          >
            <h1 className="rounded-full p-[5px] bg-white shadow text-[#4D8B2B] text-xl text-center font-[600] text-[30px] font mt-[-120px] w-[100px] h-[100px] flex items-center justify-center">
              <AccountBalanceIcon className="!text-[60px]" />
            </h1>
            <h2 className="text-[22px] font-medium mt-0">
            I am not sure <OpenInNewIcon className='!text-blue-500' />
            </h2>
          </div>
        </div>

        <div className="group w-full">
          <div className="bg-white text-gray-600 h-full rounded-lg px-8 py-20 pb-10 flex flex-col gap-y-3 items-center justify-center hover:bg-gray-50 cursor-pointer hover:shadow-lg"
          onClick={() => {
            setForm({ ...form, accredited_investor: false });
            onDone("accredited_investor");
        }}
          >
            <h1 className="rounded-full p-[5px] bg-white shadow text-[#4D8B2B] text-xl text-center font-[600] text-[30px] font mt-[-120px] w-[100px] h-[100px] flex items-center justify-center">
              <PeopleAltIcon className="!text-[60px]" />
            </h1>
            <h2 className="text-[22px] font-medium mt-0">
            I am NOT Accredited
            </h2>
          </div>
        </div>


      </div>
    </>
  );
}
