/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react/jsx-no-target-blank */
import useDocumentTitle from "../hooks/userDocumentTitle";
import MainTemplate from "./MainTemplate";

export default function CustomFarmlandInvestments() {
useDocumentTitle("Custom Farmland Investments");


  return (
    <>
      <MainTemplate pageType="others">
      

      <>
  <div
    data-collapse="medium"
    data-animation="default"
    data-duration={400}
    data-easing="ease"
    data-easing2="ease"
    role="banner"
    className="scroll-navbar navbar-2 w-hidden-medium w-hidden-small w-hidden-tiny w-nav"
    style={{
      willChange: "transform",
      transform:
        "translate3d(0px, -102%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
      transformStyle: "preserve-3d"
    }}
  >
    <div className="container-nav w-container">
      <a href="/" className="brand w-nav-brand" aria-label="home">
        <img
          src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e59a0c0a67bd6d170e34c37_FarmFundrLogo-01.png"
          width={150}
          alt="How to invest in farmland"
        />
      </a>
      <nav role="navigation" className="nav-menu w-nav-menu">
        <a
          href="/property"
          className="nav-link normal-nav black w-nav-link"
          style={{ maxWidth: 940 }}
        >
          Properties
        </a>
        <a
          href="/category/blog"
          className="nav-link normal-nav black w-nav-link"
          style={{ maxWidth: 940 }}
        >
          FARMLAND INVESTING BLOG
        </a>
        <a
          href="/about-us"
          className="nav-link normal-nav black w-nav-link"
          style={{ maxWidth: 940 }}
        >
          ABOUT US
        </a>
        <div
          data-delay={0}
          data-hover="false"
          className="nav-link w-dropdown"
          style={{ maxWidth: 940 }}
        >
          <div
            className="dropdown-toggle black w-dropdown-toggle"
            id="w-dropdown-toggle-0"
            aria-controls="w-dropdown-list-0"
            aria-haspopup="menu"
            aria-expanded="false"
            role="button"
            tabIndex={0}
          >
            <div className="text-block-22">resources</div>
            <div
              className="dropdown-arrow w-icon-dropdown-toggle"
              aria-hidden="true"
            />
          </div>
          <nav
            className="dropdown-list big w-dropdown-list"
            id="w-dropdown-list-0"
            aria-labelledby="w-dropdown-toggle-0"
          >
            <a
              href="/faqs"
              className="dropdown-link w-dropdown-link"
              tabIndex={0}
            >
              FAQ
            </a>
            <a
              href="/media"
              className="dropdown-link w-dropdown-link"
              tabIndex={0}
            >
              media
            </a>
            <a
              href="/helping-farmers-grow"
              className="dropdown-link w-dropdown-link"
              tabIndex={0}
            >
              HELPING&nbsp;FARMERS&nbsp;GROW
            </a>
            <a
              href="/sell-farmland"
              className="dropdown-link w-dropdown-link"
              tabIndex={0}
            >
              sell farmland
            </a>
            <a
              href="/custom-farmland-investments"
              aria-current="page"
              className="dropdown-link w-dropdown-link w--current"
              tabIndex={0}
            >
              farmfindr - Custom Investments
            </a>
          </nav>
        </div>
        <a
          href="/contact-us"
          className="nav-link normal-nav black w-nav-link"
          style={{ maxWidth: 940 }}
        >
          Contact
        </a>
        <a
          href="/portal/login"
          className="nav-link normal-nav login scroll w-nav-link"
          style={{ maxWidth: 940 }}
        >
          Login
        </a>
        <a
          href="/portal/register"
          className="nav-link normal-nav sign-up w-nav-link"
          style={{ maxWidth: 940 }}
        >
          Sign Up
        </a>
      </nav>
      <div
        className="menu-button gray w-nav-button"
        style={{ WebkitUserSelect: "text" }}
        aria-label="menu"
        role="button"
        tabIndex={0}
        aria-controls="w-nav-overlay-0"
        aria-haspopup="menu"
        aria-expanded="false"
      >
        <div className="icon w-icon-nav-menu" />
      </div>
    </div>
    <div className="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0" />
  </div>
  <div
    data-ix="hide-popup-on-initial"
    className="contact-full-wrapper wf-section"
  >
    <div className="popup-div">
      <div className="popup-content-div">
        <div className="contact-wrapper-div in-devices">
          <div className="algin-center">
            <div>
              <div className="sub-tittle top">EVERY DOLLAR COUNTS</div>
              <h2 className="heading-4">Make a Donation Today</h2>
            </div>
          </div>
          <div className="top-margin">
            <div className="w-form">
              <form
                id="email-form"
                name="email-form"
                data-name="Email Form"
                method="get"
                aria-label="Email Form"
              >
                <label htmlFor="Amount" className="field-label center">
                  Amount
                </label>
                <input
                  type="text"
                  className="text-field-popup w-input"
                  maxLength={256}
                  name="Amount"
                  data-name="Amount"
                  placeholder="$"
                  id="Amount"
                  required
                />
                <label htmlFor="Name-5" className="field-label center">
                  Your Details
                </label>
                <input
                  type="text"
                  className="text-field-popup w-input"
                  maxLength={256}
                  name="Name-4"
                  data-name="Name 4"
                  placeholder="Enter your name"
                  id="Name-5"
                />
                <input
                  type="email"
                  className="text-field-popup w-input"
                  maxLength={256}
                  name="Surname"
                  data-name="Surname"
                  placeholder="Enter your surname"
                  id="Surname"
                  required
                />
                <input
                  type="text"
                  className="text-field-popup w-input"
                  maxLength={256}
                  name="Enter-your-email-address"
                  data-name="Enter your email address"
                  placeholder="Enter your email address"
                  id="Enter-your-email-address"
                  required
                />
                <input
                  type="text"
                  className="text-field-popup w-input"
                  maxLength={256}
                  name="Additional-Information"
                  data-name="Additional Information"
                  placeholder="Additional Information"
                  id="Additional-Information"
                  required
                />
                <div>
                  <div className="algin-center">
                    <input
                      type="submit"
                      defaultValue="Place donation"
                      data-wait="Please wait..."
                      className="button w-button"
                    />
                  </div>
                </div>
              </form>
              <div
                className="w-form-done"
                tabIndex={-1}
                role="region"
                aria-label="Email Form success"
              >
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div
                className="w-form-fail"
                tabIndex={-1}
                role="region"
                aria-label="Email Form failure"
              >
                <div>Oops! Something went wrong while submitting the form</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      data-w-id="0aa23d50-42fe-dba2-e74c-5c7dbfbeb5e8"
      className="close-button"
    />
  </div>
  <div
    data-ix="slide-in-scroll-navigation"
    id="Top"
    className="page-header services wf-section"
  >
    <div className="w-container">
      <div className="div-text _2">
        <h1
          data-w-id="2c980ce5-42f8-fc4a-c9a9-2999079c37ec"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
          className="heading center white-font"
        >
          FarmFinder Program
        </h1>
        <p className="center-paragraph white">
          Let our expert team create a custom farmland investment for you!
          <br />
        </p>
        <div
          data-w-id="7e06734b-b7f5-22b6-0dd7-a0f543b014ea"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
          className="separated-div-wrapper"
        >
          <div className="separator header" />
          <div className="separator header center" />
          <div className="separator header" />
        </div>
        <p
          data-w-id="8518a68b-7374-e277-749e-8b04f39fb0c6"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
          className="paragraph center white"
        >
          With a nice modern and intuitive interface you can control contents to
          be contained and organised.
        </p>
      </div>
    </div>
  </div>
  <div
    data-autoplay="true"
    data-loop="true"
    data-wf-ignore="true"
    data-poster-url="https://uploads-ssl.webflow.com/57bab4d00c7bdb623822fa70/587217d2e5fbe3505cbedf2e_Currency App-SD-poster-00001.jpg"
    data-video-urls="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e5717f541472a42b458bfe6_Currency%20App-SD-transcode.mp4,https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e5717f541472a42b458bfe6_Currency%20App-SD-transcode.webm"
    className="background-video w-background-video w-background-video-atom"
  >
    <video
      id="92cdf720-883c-8310-e377-8988547daec3-video"
      autoPlay={true}
      loop={true}
      style={{
        backgroundImage:
          'url("https://uploads-ssl.webflow.com/57bab4d00c7bdb623822fa70/587217d2e5fbe3505cbedf2e_Currency App-SD-poster-00001.jpg")'
      }}
      muted={true}
      playsInline={true}
      data-wf-ignore="true"
      data-object-fit="cover"
    >
      <source
        src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e5717f541472a42b458bfe6_Currency%20App-SD-transcode.mp4"
        data-wf-ignore="true"
      />
      <source
        src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e5717f541472a42b458bfe6_Currency%20App-SD-transcode.webm"
        data-wf-ignore="true"
      />
    </video>
  </div>
  <div className="setion-services wf-section">
    <div className="w-container">
      <div data-ix="fade-in-on-load" className="services-div">
        <div className="row-motion services-page w-row">
          <div className="column w-col w-col-4">
            <div className="features-block icon gray">
              <div className="div-service">
                <img
                  src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e621041b81ddc2886130208_money.png"
                  alt=""
                  className="features-icon"
                />
              </div>
              <h1 className="heading features services">
                500k or more to invest?
              </h1>
              <p className="paragraph center">
                If you are looking to invest $500,000 or more into farmland and
                would like to be the sole owner, our FarmFindr program may be
                for you! Whether it's a 1031 exchange, a Solo 401K, an IRA
                account or another source - our experienced team and farm
                manager will help you structure a custom investment that has
                high profit potential, matches your risk tolerance and helps you
                reach your personal investment goals.
                <br />
              </p>
            </div>
          </div>
          <div className="column w-col w-col-4">
            <div className="features-block icon gray">
              <div className="div-service">
                <img
                  src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e621041452770fa11454f60_tree%20(1).png"
                  alt=""
                  className="features-icon center"
                />
              </div>
              <h1 className="heading features services">
                Choose your commodity
              </h1>
              <p className="paragraph center">
                We can create a truly customized farmland investment just for
                you. <br />
                Let us know what commodities you are interested in growing and
                we will advise you on which could be most profitable.
                <br />‍<br />
                This is a truly customized investment that will be designed for
                you and managed by an expert farm manager. You will even get to
                name name your farm!{" "}
              </p>
            </div>
          </div>
          <div className="column w-col w-col-4">
            <div className="features-block icon gray">
              <div className="div-service">
                <img
                  src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e62b3c71505d93ced19d91c_money.png"
                  alt=""
                  className="features-icon"
                />
              </div>
              <h1 className="heading features services">
                Target Returns 10-20%
              </h1>
              <p className="paragraph center">
                Depending on your personal risk tolerance, annual cash return
                goals and desired holding period, we will help you acquire a
                farm that fits your criteria and meets your desired return %.
                <br />
                <br />
                We will undergo a rigorous investment process and help you
                acquire and develop a farm that matches your criteria.
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="blog-post wf-section">
    <div className="container center w-container" />
    <div className="loan-container w-container">
      <h1 className="heading-3 center white mobile">
        Interested in a Custom Farmland Investment?
      </h1>
      <p className="center-paragraph white">
        Please tell us more about you and your goals &amp; we will schedule a
        call.
        <br />‍<br />
      </p>
      <a
        href="#"
        // href="https://tinyurl.com/farmfindrform"
        className="button findrpage w-button"
      >
        GET&nbsp;STARTED&nbsp;HERE
      </a>
    </div>
  </div>
  <div className="page-header newsletter subscribe green wf-section">
    <div className="containe trial w-container">
      <div className="div-text padding">
        <h1
          data-w-id="816f6681-f876-7f13-3fd8-e139897fddc8"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
          className="heading center white-font"
        >
          Subscribe To The FamrFundr Newsletter
        </h1>
        <div
          data-w-id="8450fdba-8efe-c343-7d65-4d70b79c0342"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
          className="separated-div-wrapper"
        >
          <div className="separator header" />
          <div className="separator header center white" />
          <div className="separator header" />
        </div>
        <p
          data-w-id="26037651-a298-1545-dcd1-dc0761f7fdf2"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
          className="paragraph center white"
        >
          A nation that destroys its soils destroys itself. Forests are the
          lungs of our land, purifying the air and giving fresh strength to our
          people.
        </p>
      </div>
      <div className="trial-wrapper w-form">
        <form
          id="email-form"
          name="email-form"
          data-name="Email Form"
          method="get"
          className="w-clearfix"
          aria-label="Email Form"
        >
          <input
            type="email"
            className="trial-field w-input"
            maxLength={256}
            name="email-5"
            data-name="Email 5"
            placeholder="Enter your email address"
            id="email-5"
            required
          />
          <input
            type="submit"
            defaultValue="Sign up now!"
            data-wait="Please wait..."
            className="trial-button sec w-button"
          />
        </form>
        <div
          className="success-message w-form-done"
          tabIndex={-1}
          role="region"
          aria-label="Email Form success"
        >
          <div className="text-block-7">
            Thank you! Your submission has been received!
          </div>
        </div>
        <div
          className="error-message w-form-fail"
          tabIndex={-1}
          role="region"
          aria-label="Email Form failure"
        >
          <div className="text-block-8">
            Oops! Something went wrong while submitting the form
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="footer dark-bootom wf-section" />
  {/*[if lte IE 9]><![endif]*/}
  <div id="lif1bjljdq3g1710278847551" className="widget-hidden">
    <iframe
      src="about:blank"
      frameBorder={0}
      scrolling="no"
      width="64px"
      height="60px"
      style={{
        outline: "none !important",
        visibility: "visible",
        resize: "none",
        boxShadow: "none !important",
        overflow: "visible !important",
        background: "none !important",
        opacity: "1 !important",
        filter: "alpha(opacity=100) !important",
        msFilter:
          "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
        MozOpacity: "1 !important",
        KhtmlOpacity: "1 !important",
        top: "auto !important",
        right: "auto !important",
        bottom: "20px !important",
        left: "20px !important",
        position: "fixed",
        border: "0 !important",
        minHeight: "60px !important",
        minWidth: "64px !important",
        maxHeight: "60px !important",
        maxWidth: "64px !important",
        padding: "0 !important",
        margin: "0 !important",
        MozTransitionProperty: "none !important",
        WebkitTransitionProperty: "none !important",
        OTransitionProperty: "none !important",
        transitionProperty: "none !important",
        transform: "none !important",
        WebkitTransform: "none !important",
        msTransform: "none !important",
        width: "64px !important",
        height: "60px !important",
        display: "block !important",
        zIndex: "1000001 !important",
        backgroundColor: "transparent !important",
        cursor: "none !important",
        float: "none",
        borderRadius: "unset !important",
        pointerEvents: "auto",
        clip: "auto !important",
        colorScheme: "light !important"
      }}
      id="y69pgdjro59g1710278847564"
      className=""
      title="chat widget"
    />
    <iframe
      src="about:blank"
      frameBorder={0}
      scrolling="no"
      width="350px"
      height="520px"
      style={{
        outline: "none !important",
        visibility: "visible",
        resize: "none",
        boxShadow: "none !important",
        overflow: "visible !important",
        background: "none !important",
        opacity: "1 !important",
        filter: "alpha(opacity=100) !important",
        msFilter:
          "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
        MozOpacity: "1 !important",
        KhtmlOpacity: "1 !important",
        top: "auto !important",
        right: "auto !important",
        bottom: "90px !important",
        left: "10px !important",
        position: "fixed",
        border: "0 !important",
        minHeight: "520px !important",
        minWidth: "350px !important",
        maxHeight: "520px !important",
        maxWidth: "350px !important",
        padding: "0 !important",
        margin: "0 !important",
        MozTransitionProperty: "none !important",
        WebkitTransitionProperty: "none !important",
        OTransitionProperty: "none !important",
        transitionProperty: "none !important",
        transform: "none !important",
        WebkitTransform: "none !important",
        msTransform: "none !important",
        width: "350px !important",
        height: "520px !important",
        display: "none !important",
        zIndex: "auto !important",
        backgroundColor: "transparent !important",
        cursor: "none !important",
        float: "none",
        borderRadius: "5px !important",
        pointerEvents: "auto",
        clip: "auto !important",
        colorScheme: "light !important"
      }}
      id="ek5pnouf643o1710278847600"
      className=""
      title="chat widget"
    />
    <iframe
      src="about:blank"
      frameBorder={0}
      scrolling="no"
      width="360px"
      height="55px"
      style={{
        outline: "none !important",
        visibility: "visible",
        resize: "none",
        boxShadow: "none !important",
        overflow: "visible !important",
        background: "none !important",
        opacity: "1 !important",
        filter: "alpha(opacity=100) !important",
        msFilter:
          "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
        MozOpacity: "1 !important",
        KhtmlOpacity: "1 !important",
        top: "auto !important",
        right: "auto !important",
        bottom: 100,
        left: "20px !important",
        position: "fixed",
        border: "0 !important",
        minHeight: "55px !important",
        minWidth: "360px !important",
        maxHeight: "55px !important",
        maxWidth: "360px !important",
        padding: "0 !important",
        margin: "0 !important",
        MozTransitionProperty: "none !important",
        WebkitTransitionProperty: "none !important",
        OTransitionProperty: "none !important",
        transitionProperty: "none !important",
        transform: "none !important",
        WebkitTransform: "none !important",
        msTransform: "none !important",
        width: "360px !important",
        height: "55px !important",
        display: "none !important",
        zIndex: "auto !important",
        backgroundColor: "transparent !important",
        cursor: "none !important",
        float: "none",
        borderRadius: "unset !important",
        pointerEvents: "auto",
        clip: "auto !important",
        colorScheme: "light !important"
      }}
      id="o9odk2t6ll2g1710278847592"
      className=""
      title="chat widget"
    />
    <iframe
      src="about:blank"
      frameBorder={0}
      scrolling="no"
      width="146px"
      height="85px"
      style={{
        outline: "none !important",
        visibility: "visible",
        resize: "none",
        boxShadow: "none !important",
        overflow: "visible !important",
        background: "none !important",
        opacity: "1 !important",
        filter: "alpha(opacity=100) !important",
        msFilter:
          "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
        MozOpacity: "1 !important",
        KhtmlOpacity: "1 !important",
        top: "auto !important",
        right: "auto !important",
        bottom: "82px !important",
        left: "20px !important",
        position: "fixed",
        border: "0 !important",
        minHeight: "85px !important",
        minWidth: "146px !important",
        maxHeight: "85px !important",
        maxWidth: "146px !important",
        padding: "0 !important",
        margin: "0px 0 0 0 !important",
        MozTransitionProperty: "none !important",
        WebkitTransitionProperty: "none !important",
        OTransitionProperty: "none !important",
        transitionProperty: "none !important",
        transform: "rotate(0deg) translateZ(0)",
        WebkitTransform: "rotate(0deg) translateZ(0)",
        msTransform: "rotate(0deg) translateZ(0)",
        width: "146px !important",
        height: "85px !important",
        display: "none !important",
        zIndex: "1000002 !important",
        backgroundColor: "transparent !important",
        cursor: "none !important",
        float: "none",
        borderRadius: "unset !important",
        pointerEvents: "auto",
        clip: "auto !important",
        colorScheme: "light !important",
        MozTransform: "rotate(0deg) translateZ(0)",
        OTransform: "rotate(0deg) translateZ(0)",
        transformOrigin: 0,
        MozTransformOrigin: 0,
        WebkitTransformOrigin: 0,
        OTransformOrigin: 0,
        msTransformOrigin: 0
      }}
      id="h1omnu8sse91710278847585"
      className=""
      title="chat widget"
    />
  </div>
</>

      
      </MainTemplate>
    </>
  );
}
