/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react/jsx-no-target-blank */
import { useState } from "react";
import MainTemplate from "./MainTemplate";

export default function HomePage() {

  const [whyInvestTabIndex, setWhyInvestTabIndex] = useState(0);

  return (
    <>
      <MainTemplate pageType="landing">
        {/* start page background video */}
        <div className="relative w-full h-screen" id="Top">
          <video
            id="49dd72d8-34ec-b75e-9caf-62be0ead7f45-video"
            autoPlay={true}
            loop={true}
            style={{
              backgroundImage:
                'url("https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e59a3ba2089e5a0bc82d59c_AlmondTreesFlyover-poster-00001.jpg")',
            }}
            muted={true}
            playsInline={true}
            data-wf-ignore="true"
            data-object-fit="cover"
            className="w-full h-full object-cover object-center"
          >
            <source
              src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e59a3ba2089e5a0bc82d59c_AlmondTreesFlyover-transcode.mp4"
              data-wf-ignore="true"
            />
            <source
              src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e59a3ba2089e5a0bc82d59c_AlmondTreesFlyover-transcode.webm"
              data-wf-ignore="true"
            />
          </video>

          <div className="bg-[#0000009c] md:bg-[#0000007b] absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center">
            <div className="flex flex-col items-center justify-center text-white md:px-0 md:mx-[250px] mt-28 md:mt-36 px-1">
              <img
                src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e59a0c0a67bd6d170e34c37_FarmFundrLogo-01.png"
                width={364}
                id="header-logo"
                alt="How to invest in farmland"
              />
              <h1 className="text-2xl md:text-3xl font-semibold text-center uppercase">
                HIGH&nbsp;PROFIT&nbsp;POTENTIAL FARMLAND&nbsp;INVESTMENTs
                managed by expert american farmers
              </h1>
              <div
                data-w-id="d9d3897f-670b-6fc4-5eed-0cf9947f27ad"
                style={{
                  opacity: 1,
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  transformStyle: "preserve-3d"
                }}
                className="div-text _2 left"
              >
                <a
                  href="/portal/register"
                  data-ix="show-contact-wrapper-on-click"
                  data-w-id="d9d3897f-670b-6fc4-5eed-0cf9947f27ae"
                  target="_blank"
                  className="button rounded padding left-side featured w-button"
                >
                  Click to Explore Available Investments{" "}
                </a>
              </div>

              <p className="hidden">
                We believe that everyone should have the opportunity to invest
                in the most important commodity in the world; food.
              </p>
            </div>

            <div className="mt-36">
              <a href="#Intro" className="w-inline-block w--current">
                <img
                  src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e5ff19d7e7d8f479e62adc3_down-arrow.png"
                  id="header-logo"
                  data-w-id="a735847a-f10e-36e4-72df-5d4ea62c0418"
                  alt=""
                  className="w-12 h-12"
                />
              </a>
            </div>
          </div>
        </div>

        {/* intro */}
        <div id="Intro"
          className="px-4 md:px-[250px] py-24 pb-32"
          style={{
            backgroundImage:
              "linear-gradient(180deg, rgba(255, 253, 253, 0.48), rgba(255, 253, 253, 0.48)), url(https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e5ea60a27f166629dfbf8e2_Yellow%20Field%20Blue%20Sky.png)",
            backgroundPosition: "0px 0px, 50% 100%",
            backgroundSize: "auto, cover",
            backgroundRepeat: "repeat, no-repeat",
          }}>
          <div className="mx-auto flex flex-col items-center justify-center">
            <div className="flex flex-col items-center justify-center gap-y-3">
              <h1 className="text-2xl text-black md:text-4xl font-semibold uppercase">How It works</h1>

              <div className="flex flex-row items-center justify-center gap-x-2 my-3">
                <div className="py-[0.5px] px-4 bg-gray-400" />
                <div className="py-[0.5px] px-4 bg-gray-800" />
                <div className="py-[0.5px] px-4 bg-gray-400" />
              </div>

              <p className="text-[#3e4543] text-[17px] leading-5 font-semibold text-center mb-10 md:px-[180px] block not-italic py-0">
                FarmFundr is a farmer-owned, equity crowdfunding platform
                focused on specialty crop operations in the United States. We
                offer fractional farmland ownership opportunities to our
                members.
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-4 gap-y-16 md:gap-y-0 md:gap-x-5 mt-16">
              <div className="group">
                <div className="bg-white h-full rounded-lg px-8 py-20 group-hover:animate-bounce-once flex flex-col gap-y-3 items-center justify-center">
                  <h1 className="rounded-full p-[30px] bg-primary text-white text-xl text-center font-[600] text-[30px] font mt-[-120px] w-[100px] h-[100px] flex items-center justify-center">
                    1
                  </h1>
                  <h2 className="text-[22px] font-medium mt-0">Sign Up</h2>
                  <p className="mx-auto my-auto leading-[15px] text-center block w-auto text-[#5e5e5e] text-sm font-medium">
                    <a
                      href="/portal"
                      target="_blank"
                      className="text-[#e16a2d]"
                    >
                      Sign up
                    </a>{" "}
                    for a complimentary FarmFundr Account to&nbsp;
                    <em>gain full access</em> to high profit farmland investment
                    opportunities in our easy-to-use portal.
                  </p>
                </div>
              </div>

              <div className="group">
                <div className="bg-white h-full rounded-lg px-8 py-20 group-hover:animate-bounce-once flex flex-col gap-y-3 items-center justify-center">
                  <h1 className="rounded-full p-[30px] bg-primary text-white text-xl text-center font-[600] text-[30px] font mt-[-120px] w-[100px] h-[100px] flex items-center justify-center">
                    2
                  </h1>
                  <h2 className="text-[22px] font-medium mt-0">Invest</h2>
                  <p className="mx-auto my-auto leading-[15px] text-center block w-auto text-[#5e5e5e] text-sm font-medium">
                    Determine what best fits your investment portfolio and
                    invest. All opportunities have been carefully selected and
                    offer high return possibilities. <br />
                  </p>
                </div>
              </div>

              <div className="group">
                <div className="bg-white h-full rounded-lg px-8 py-20 group-hover:animate-bounce-once flex flex-col gap-y-3 items-center justify-center">
                  <h1 className="rounded-full p-[30px] bg-primary text-white text-xl text-center font-[600] text-[30px] font mt-[-120px] w-[100px] h-[100px] flex items-center justify-center">
                    3
                  </h1>
                  <h2 className="text-[22px] font-medium mt-0">Monitor</h2>
                  <p className="mx-auto my-auto leading-[15px] text-center block w-auto text-[#5e5e5e] text-sm font-medium">
                    Congratulations, you’re a farmer! FarmFundr manages{" "}
                    <em>all</em> aspects of the farmland, from planting to
                    harvesting to selling while you simply monitor your farm’s
                    progress online.
                    <br />
                  </p>
                </div>
              </div>

              <div className="group">
                <div className="bg-white h-full rounded-lg px-8 py-20 group-hover:animate-bounce-once flex flex-col gap-y-3 items-center justify-center">
                  <h1 className="rounded-full p-[30px] bg-primary text-white text-xl text-center font-[600] text-[30px] font mt-[-120px] w-[100px] h-[100px] flex items-center justify-center">
                    4
                  </h1>
                  <h2 className="text-[22px] font-medium mt-0">Collect</h2>
                  <p className="mx-auto my-auto leading-[15px] text-center block w-auto text-[#5e5e5e] text-sm font-medium">
                    Profits from crop sales are distributed to investors after
                    crops are harvested and sold each year. At the end of the
                    holding period, they will receive their share from the sale
                    of the the land.
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* why investment in farmland */}
        <div className="why-farmland-section tab-alternative wf-section">
          <h1
            data-w-id="0ff6f19a-d9ee-b9f5-c58a-d9b5c8a07a8f"
            style={{
              opacity: 1,
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d",
            }}
            className="heading center"
          >
            Why invest in farmland
          </h1>
          <div
            data-w-id="1373bb7b-279c-79d3-26ca-72e13a039eb6"
            style={{
              opacity: 1,
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d",
            }}
            className="separated-div-wrapper"
          >
            <div className="separator header" />
            <div className="separator header center" />
            <div className="separator header" />
            <p
              data-w-id="2d4b1930-9c8c-92ae-b072-f175984ba6f2"
              style={{
                opacity: 1,
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d",
              }}
              className="paragraph center black"
            >
              Explore the many reasons that we believe United States farmland is
              an attractive investment.
            </p>
          </div>
          <div className="whyfarmlandtabs w-container">
            <div
              data-duration-in={300}
              data-duration-out={100}
              data-current="Tab 1"
              data-easing="ease"
              className="tabs w-tabs"
            >
              <div className="tab-menu alternative w-tab-menu" role="tablist">
                <a
                  data-w-tab="Tab 1"
                  className={`tab-link w-inline-block w-tab-link ${whyInvestTabIndex === 0 ? "w--current" : ""}`}
                  id="w-tabs-0-data-w-tab-0"
                  href="#w-tabs-0-data-w-pane-0-xx"
                  role="tab"
                  aria-controls="w-tabs-0-data-w-pane-0"
                  aria-selected="true"
                  onClick={() => setWhyInvestTabIndex(0)}
                >
                  <div className="text-block-12">1</div>
                </a>
                <a
                  data-w-tab="Tab 2"
                  className={`tab-link w-inline-block w-tab-link ${whyInvestTabIndex === 1 ? "w--current" : ""}`}
                  tabIndex={-1}
                  id="w-tabs-0-data-w-tab-1"
                  href="#w-tabs-0-data-w-pane-1-xx"
                  role="tab"
                  aria-controls="w-tabs-0-data-w-pane-1"
                  aria-selected="false"
                  onClick={() => setWhyInvestTabIndex(1)}
                >
                  <div className="text-block-13">2</div>
                </a>
                <a
                  data-w-tab="Tab 3"
                  className={`tab-link w-inline-block w-tab-link ${whyInvestTabIndex === 2 ? "w--current" : ""}`}
                  tabIndex={-1}
                  id="w-tabs-0-data-w-tab-2"
                  href="#w-tabs-0-data-w-pane-2-xx"
                  role="tab"
                  aria-controls="w-tabs-0-data-w-pane-2"
                  aria-selected="false"
                  onClick={() => setWhyInvestTabIndex(2)}
                >
                  <div className="text-block-14">3</div>
                </a>
              </div>
              <div className="w-tab-content">
                {/* tab 1 */}
                <div
                  data-w-tab="Tab 1"
                  className={`tab-pane ${whyInvestTabIndex === 0 ? "w--tab-active !block" : "!hidden"}`}
                  id="w-tabs-0-data-w-pane-0"
                  role="tabpanel"
                  aria-labelledby="w-tabs-0-data-w-tab-0"
                >
                  <div className="w-row">
                    <div
                      data-w-id="c8802b5c-18d5-4fc5-6784-9347bf8d95bd"
                      style={{
                        opacity: 1,
                        transform:
                          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        transformStyle: "preserve-3d",
                      }}
                      className="w-clearfix w-col w-col-6 w-col-stack"
                    >
                      <div className="features-block icon alternative w-clearfix">
                        <div className="icon-block-features">
                          <img
                            src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e62b3c758df44bfab6c7066_planet-earth.png"
                            alt="Earth Farmland Investment Icon"
                            className="features-icon small"
                          />
                        </div>
                        <h1 className="heading features left-side">
                          An industry that matters
                        </h1>
                        <p className="paragraph left">
                          Ownership in a farm operation allows investors to
                          learn about the many aspects that go into a real
                          working farm and the hard work that goes into feeding
                          the world.
                          <br />
                        </p>
                      </div>
                      <div className="features-block icon alternative w-clearfix">
                        <div className="icon-block-features">
                          <img
                            src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e62b3c76f97347912858a5b_fruit%20(1).png"
                            alt="Fruit Icon"
                            className="features-icon small"
                          />
                        </div>
                        <h1 className="heading features left-side">
                          Demand for food
                        </h1>
                        <p className="paragraph left">
                          By 2050 the world population will grow to over 10
                          billion people. An additional 3 billion people will
                          enter the middle class causing food demand to sky
                          rocket.
                          <br />
                        </p>
                      </div>
                      <div className="features-block icon alternative w-clearfix">
                        <div className="icon-block-features">
                          <img
                            src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e62b3c858df44d64f6c7067_stock.png"
                            alt="Stock Icon"
                            className="features-icon small"
                          />
                        </div>
                        <h1 className="heading features left-side">
                          More STability than the stock market
                        </h1>
                        <p className="paragraph left">
                          Since farmland is uncorrelated with most other major
                          asset classes, it has proven to perform well during
                          challenging economic conditions.
                          <br />
                        </p>
                      </div>
                    </div>
                    <div
                      data-w-id="c8802b5c-18d5-4fc5-6784-9347bf8d95d3"
                      style={{
                        opacity: 1,
                        transform:
                          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        transformStyle: "preserve-3d",
                      }}
                      className="w-clearfix w-col w-col-6 w-col-stack"
                    >
                      <div className="features-block icon alternative w-clearfix">
                        <div className="icon-block-features">
                          <img
                            src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e62103f9ca437857c079a1b_land.png"
                            alt=""
                            className="features-icon small"
                          />
                        </div>
                        <h1 className="heading features left-side">
                          They're not making any more land
                        </h1>
                        <p className="paragraph left">
                          The U.S. alone loses almost 500,000 acres of farmland
                          a year. That’s a loss of nearly 15,000,000 acres of
                          farmland by 2050. <br />
                        </p>
                      </div>
                      <div className="features-block icon alternative w-clearfix">
                        <div className="icon-block-features">
                          <img
                            src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e62b3c71505d93ced19d91c_money.png"
                            alt=""
                            className="features-icon small"
                          />
                        </div>
                        <h1 className="heading features left-side">
                          Long Term Investment Strategy
                        </h1>
                        <p className="paragraph left">
                          Farmland is an attractive, long-term investment that
                          has the potential to provide &nbsp;relative resiliency
                          to challenging economic conditions.{" "}
                        </p>
                      </div>
                      <div className="features-block icon alternative w-clearfix">
                        <div className="icon-block-features">
                          <img
                            src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e62b469de4a9fceb1cb6bac_lock.png"
                            alt=""
                            className="features-icon small"
                          />
                        </div>
                        <h1 className="heading features left-side">
                          Hedge Against inflation
                        </h1>
                        <p className="paragraph left">
                          Over time, farmland has proven to have a positive
                          correlation with inflation. More so than bonds, the
                          stock market, and even gold. On average, no investment
                          offers a hedge against inflation like farmland.
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* tab 2 */}
                <div
                  data-w-tab="Tab 2"
                  className={`w-tab-pane ${whyInvestTabIndex === 1 ? "w--tab-active !block" : "!hidden"}`}
                  id="w-tabs-0-data-w-pane-1"
                  role="tabpanel"
                  aria-labelledby="w-tabs-0-data-w-tab-1"
                >
                  <div className="div-text _2">
                    <div className="div-text _2">
                      <h1 className="heading center">
                        Historical resilience &amp; positive returns
                      </h1>
                      <div className="separated-div-wrapper">
                        <div className="separator header" />
                        <div className="separator header center" />
                        <div className="separator header" />
                      </div>
                      <p className="paragraph center black">
                        Historical data reveals that farmland has been an
                        extremely resilient asset class. The industry has seen
                        positive returns generated every year since 1990,
                        proving it to be one of the most stable asset classes
                        over the past few decades.
                        <br />
                      </p>
                      <img
                        src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e62a14858df44920c6c01c7_US%20FARMLAND%20NCREIF%20Total%20Return%20Index.png"
                        width={762}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                {/* tab 3 */}
                <div
                  data-w-tab="Tab 3"
                  className={`w-tab-pane ${whyInvestTabIndex === 2 ? "w--tab-active !block" : "!hidden"}`}
                  id="w-tabs-0-data-w-pane-2"
                  role="tabpanel"
                  aria-labelledby="w-tabs-0-data-w-tab-2"
                >
                  <h1 className="heading center">
                    More reasons to invest in farmland
                  </h1>
                  <div className="separated-div-wrapper">
                    <div className="separator header" />
                    <div className="separator header center" />
                    <div className="separator header" />
                  </div>
                  <div className="pricing-row w-row">
                    <div className="pricing-column w-col w-col-4">
                      <div className="pricing-block">
                        <h1 className="heading features _2">
                          Supply &amp; Demand in Favor of Farmland Investing
                        </h1>
                        <ul
                          role="list"
                          className="pricing-list w-list-unstyled"
                        >
                          <li className="list-item-3" />
                          <li className="list-item-4">
                            <p className="paragraph-list">
                              With a growing global population and shrinking
                              U.S. farmland acreage, the laws of supply and
                              demand are clearly in favor of farmland investing.
                              As a result, farmland has consistently beat other
                              asset classes over time.
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="pricing-column _2 w-col w-col-4">
                      <div className="pricing-block">
                        <h1 className="heading features _2">
                          Social &amp; Economic Benefits
                        </h1>
                        <ul
                          role="list"
                          className="pricing-list w-list-unstyled"
                        >
                          <li className="list-item-3">
                            <p className="paragraph-list">
                              Investing in farmland benefits the investor more
                              than just in financial gains. An investment in
                              agriculture has social and economical benefits
                              that include keeping farmers in the business of
                              farming, keeping the world fed and clothed,
                              bringing jobs and success to a community and
                              supporting family businesses.
                              <br />
                              <br />
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="pricing-column _3 w-col w-col-4">
                      <div className="pricing-block">
                        <h1 className="heading features _2">
                          Connection to the Industry that Feeds
                          &amp;&nbsp;Clothes the World
                        </h1>
                        <ul
                          role="list"
                          className="pricing-list w-list-unstyled"
                        >
                          <li className="list-item-3">
                            <p className="paragraph-list">
                              We believe that farmland is a different kind of
                              asset. It's one that has so many interesting
                              processes and produces a commodity that really
                              matters; food. Visiting your piece of farmland and
                              seeing it for yourself is a unique opportunity
                              that we invite our investors to experience.{" "}
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* blog post - youtube video */}
        <div className="blog-post without-paddings second wf-section">
          <div className="container-2 w-container">
            <div className="div-device">
              <div
                style={{ paddingTop: "56.17021276595745%" }}
                className="video-pop-up w-video w-embed"
              >
                <iframe
                  className="embedly-embed"
                  src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FsDV84dkkAZ4%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DsDV84dkkAZ4&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FsDV84dkkAZ4%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube"
                  scrolling="no"
                  title="YouTube embed"
                  frameBorder={0}
                  allow="autoplay; fullscreen"
                  allowFullScreen={true}
                />
              </div>
            </div>
          </div>
        </div>

        {/* make money two ways */}
        <div className="make-money-section full wf-section">
          <div className="w-row">
            <div className="w-col w-col-6">
              <div data-ix="fade-in-on-scroll-right" className="left-side-div">
                <div
                  data-w-id="40485c9b-84ad-89d4-5c0c-dbd40235302f"
                  style={{
                    opacity: 1,
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    transformStyle: "preserve-3d",
                  }}
                  className="div-text _2 left"
                >
                  <h2 className="heading center">
                    MAKE&nbsp;MONEY&nbsp;TWO&nbsp;WAYS
                  </h2>
                  <div className="separated-div-wrapper left">
                    <div className="separator header" />
                    <div className="separator header center" />
                    <div className="separator header" />
                  </div>
                  <p className="center-paragraph">
                    At FarmFundr, we do things a little
                    <br />
                    differently so that our investors can realize as much profit
                    from their farm operation as possible. As part owner in the
                    farmland, you will receive annual returns from the crop
                    sales, in addition to the appreciation from ownership of the
                    land.
                    <br />
                  </p>
                  <a
                    href="/portal"
                    data-ix="show-contact-wrapper-on-click"
                    data-w-id="b1659b07-3c2f-7051-2546-8ff71f1c7dec"
                    target="_blank"
                    className="button rounded padding left-side w-button"
                  >
                    Start Exploring Opportunities
                  </a>
                </div>
              </div>
            </div>
            <div className="w-col w-col-6">
              <div
                data-ix="fade-in-on-scroll-left"
                data-w-id="60e76a75-30e6-49bb-8f7b-b2e102a3444e"
                style={{
                  opacity: 1,
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  transformStyle: "preserve-3d",
                }}
                className="w-row"
              >
                <div className="w-col w-col-6">
                  <div className="box-service-image full">
                    <h3 className="heading3 white small">1. Crop Profits</h3>
                    <p className="paragraph white">
                      Receive profits when crops are harvested and sold, in most
                      cases, annually.
                    </p>
                  </div>
                </div>
                <div className="w-col w-col-6">
                  <div className="box-service-image _3 full">
                    <h3 className="heading3 white small">2. own the land</h3>
                    <p className="paragraph white">
                      At the end of the investment's holding period , investors
                      will realize profits from the appreciation of the land.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* what makes farmer funders different  */}
        <div className="different-section second wf-section">
          <h1 className="heading center rise white">
            What makes farmfundr different
          </h1>
          <div className="w-container">

          <div className="grid grid-cols-1 md:grid-cols-3 gap-y-16 md:gap-y-0 md:gap-x-5 mt-16">
              <div className="group">
                <div className="bg-primary text-white h-full rounded-lg px-8 py-20 flex flex-col gap-y-3 items-center justify-center">
                  <h1 className="rounded-full p-[20px] bg-[#4D8B2B] text-white text-xl text-center font-[600] text-[30px] font mt-[-120px] w-[80px] h-[80px] flex items-center justify-center">
                  <img
                      src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e5ef7488833e301a7465aba_WhiteLeaf-01.png"
                      alt=""
                      className="features-icon"
                    />
                  </h1>
                  <h2 className="text-[22px] font-medium mt-0">Farmer Owned</h2>
                  <p className="mx-auto my-auto leading-[15px] text-center text-white block w-auto text-sm font-normal">
                  Founded by a fourth generation farmer with over 21 years of
                    successful farming, FarmFundr’s managers have a proven track
                    record in purchasing, developing and managing the right
                    properties to maximize return on investment. <br />
                    <br />
                  </p>
                </div>
              </div>

              <div className="group">
                <div className="bg-white text-black h-full rounded-lg px-8 py-20 flex flex-col gap-y-3 items-center justify-center">
                  <h1 className="rounded-full p-[20px] bg-[#4D8B2B] text-white text-xl text-center font-[600] text-[30px] font mt-[-120px] w-[80px] h-[80px] flex items-center justify-center">
                  <img
                      src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e5ef7488833e301a7465aba_WhiteLeaf-01.png"
                      alt=""
                      className="features-icon"
                    />
                  </h1>
                  <h2 className="text-[22px] font-medium mt-0">Asset Class Experts</h2>
                  <p className="mx-auto my-auto leading-[15px] text-center text-gray-600 block w-auto text-sm font-normal">
                  FarmFundr's management is often consulted by other
                    investment portals and companies for investment structure
                    advice. Our experience in successful farmland investing
                    along with farmland management is an asset to our valued
                    investors.
                    <span className="little text-span" />
                    <br />
                  </p>
                </div>
              </div>

              <div className="group">
                <div className="bg-primary text-white h-full rounded-lg px-8 py-20 flex flex-col gap-y-3 items-center justify-center">
                  <h1 className="rounded-full p-[20px] bg-[#4D8B2B] text-white text-xl text-center font-[600] text-[30px] font mt-[-120px] w-[80px] h-[80px] flex items-center justify-center">
                  <img
                      src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e5ef7488833e301a7465aba_WhiteLeaf-01.png"
                      alt=""
                      className="features-icon"
                    />
                  </h1>
                  <h2 className="text-[22px] font-medium mt-0">Diversity</h2>
                  <p className="mx-auto my-auto leading-[15px] text-center text-white block w-auto text-sm font-normal">
                  Traditionally, it would take millions of dollars to invest
                    in a variety of farms and crops. This can now be
                    accomplished for as little as $10,000 per investment with
                    FarmFundr's easy-to-use investment portal.
                  </p>
                </div>
              </div>
              

            </div>

         
          </div>
        </div>

        {/* what our investors says */}
        <div className="section wf-section">
          <div className="w-container">
            <h1 className="heading center rise">
              What OUR&nbsp;INVESTORS&nbsp;SAY
            </h1>
            <div
              data-delay={4000}
              data-animation="slide"
              className="testimonial-slider w-slider"
              data-autoplay="false"
              data-easing="ease"
              data-hide-arrows="false"
              data-disable-swipe="false"
              data-autoplay-limit={0}
              data-nav-spacing={3}
              data-duration={500}
              data-infinite="true"
              role="region"
              aria-label="carousel"
            >
              <div className="w-slider-mask" id="w-slider-mask-0">
                <div
                  className="w-slide"
                  aria-label="1 of 1"
                  role="group"
                  style={{ transform: "translateX(0px)", opacity: 1 }}
                >
                  <div>
                    <div>
                      <div className="w-row">
                        <div className="w-col w-col-4">
                          <div className="div-block">
                            <div className="team-testimonial-block">
                              <div className="team-image-2">
                                <p className="name-customer">Chris W.</p>
                                <p className="paragraph-35 center name">
                                  FarmFundr Investor
                                </p>
                                <img
                                  src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5f987a4f684f157c66e33ce4_unnamed.png"
                                  loading="lazy"
                                  width={111}
                                  id="5-star"
                                  alt="Farmland Investment Review"
                                  className="_5-star-review"
                                />
                              </div>
                              <p className="paragraph-35 testimonials">
                                FarmFundr provides great details on each
                                investment, with the data presented in a clear
                                format. I am sure they will continue to grow and
                                be a great investment platform for farmland. I
                                am eager to see more of the opportunities they
                                are always making available.{" "}
                                <a
                                  href="https://businessapp.b2b.trustpilot.com/#/reviews/5f977dc45e693f06f8725a6d"
                                  target="_blank"
                                />
                                <a href="https://www.trustpilot.com/users/5f9763e95671f2001b9ee3a4">
                                  <br />
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="w-col w-col-4">
                          <div className="div-block">
                            <div className="team-testimonial-block">
                              <div className="team-image-2">
                                <p className="name-customer">John M.</p>
                                <p className="paragraph-35 center name">
                                  FarmFundr Investor
                                </p>
                                <img
                                  src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5f987a4f684f157c66e33ce4_unnamed.png"
                                  loading="lazy"
                                  width={111}
                                  id="5-star"
                                  alt="Farmland Investment Review"
                                  className="_5-star-review"
                                />
                              </div>
                              <p className="paragraph-35 testimonials">
                                Great Company! I have made multiple investments
                                with Farmfundr. My experience has been
                                excellent. Best of all, I get prompt responses
                                regarding questions whether i call or email.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="w-col w-col-4">
                          <div className="div-block">
                            <div className="team-testimonial-block">
                              <div className="team-image-2">
                                <p className="name-customer">Alex M.</p>
                                <p className="paragraph-35 center name">
                                  FarmFundr Investor
                                </p>
                                <img
                                  src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5f987a4f684f157c66e33ce4_unnamed.png"
                                  loading="lazy"
                                  width={111}
                                  id="5-star"
                                  alt="Farmland Investment Review"
                                  className="_5-star-review"
                                />
                              </div>
                              <p className="paragraph-35 testimonials">
                                I’ve reviewed other opportunities in farmland
                                investment and have discovered that FarmFundr
                                has the highest quality properties with the
                                highest return potential.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  aria-live="off"
                  aria-atomic="true"
                  className="w-slider-aria-label"
                  data-wf-ignore=""
                />
              </div>
              <div
                className="testimonial-arrow-icon w-slider-arrow-left"
                role="button"
                tabIndex={0}
                aria-controls="w-slider-mask-0"
                aria-label="previous slide"
              >
                <div className="w-icon-slider-left" />
              </div>
              <div
                className="testimonial-arrow-icon w-slider-arrow-right"
                role="button"
                tabIndex={0}
                aria-controls="w-slider-mask-0"
                aria-label="next slide"
                style={{}}
              >
                <div className="w-icon-slider-right" />
              </div>
              <div className="slide-nav w-slider-nav w-slider-nav-invert w-round">
                <div
                  className="w-slider-dot w-active"
                  data-wf-ignore=""
                  aria-label="Show slide 1 of 1"
                  aria-pressed="true"
                  role="button"
                  tabIndex={0}
                  style={{ marginLeft: 3, marginRight: 3 }}
                />
              </div>
            </div>
          </div>
        </div>

        {/* get access now */}
        <div className="call-to-action">
          <div className="w-container">
            <div className="w-row">
              <div className="w-col w-col-9">
                <h2 className="cta-text">
                  Start Exploring High Profit Potential Farmland Investments
                </h2>
              </div>
              <div className="w-col w-col-3">
                <a
                  href="/portal"
                  data-ix="show-contact-wrapper-on-click"
                  data-w-id="9d9f4086-7586-69f8-c80b-ecaa8fc12354"
                  target="_blank"
                  className="button outline w-button"
                >
                  GET&nbsp;ACCESS Now
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* stats */}
        <div className="image-section-overlay dark">
          <div className="w-container">
            <div className="w-row">
              <div className="w-col w-col-3 w-col-small-6 w-col-medium-6">
                <div className="features-block fact-box new">
                  <img
                    src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e62103f7b1e8370a8d33030_population%20(2).png"
                    width={64}
                    alt=""
                    className="image-fact"
                  />
                  <h1 className="font-icon number">10 BILLION</h1>
                  <h1 className="features-tittle fact title-fact">
                    THE WORLD POPULATION&nbsp;
                    <br />
                    BY&nbsp;2050
                  </h1>
                </div>
              </div>
              <div className="w-col w-col-3 w-col-small-6 w-col-medium-6">
                <div className="features-block fact-box new">
                  <img
                    src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e621041452770fa11454f60_tree%20(1).png"
                    width={64}
                    alt=""
                    className="image-fact"
                  />
                  <h1 className="font-icon number">70%</h1>
                  <h1 className="features-tittle fact title-fact">
                    MORE FOOD WILL NEED TO BE PRODUCED
                  </h1>
                </div>
              </div>
              <div className="w-col w-col-3 w-col-small-6 w-col-medium-6">
                <div className="features-block fact-box new">
                  <img
                    src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e62103f9ca437857c079a1b_land.png"
                    width={64}
                    alt=""
                    className="image-fact"
                  />
                  <h1 className="font-icon number">500,000</h1>
                  <h1 className="features-tittle fact title-fact">
                    THE AMOUNT OF U.S. FARMLAND LOST ANNUALLY{" "}
                  </h1>
                </div>
              </div>
              <div className="w-col w-col-3 w-col-small-6 w-col-medium-6">
                <div className="features-block fact-box new">
                  <img
                    src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e621041b81ddc2886130208_money.png"
                    width={64}
                    alt=""
                    className="image-fact"
                  />
                  <h1 className="font-icon number">11.5%</h1>
                  <h1 className="features-tittle fact title-fact">
                    THE AVERAGE ANNUAL RETURN OF FARMLAND
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainTemplate>
    </>
  );
}
