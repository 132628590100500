/* eslint-disable eqeqeq */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useState } from "react";
import useDocumentTitle from "../../hooks/userDocumentTitle";
import PortalTemplate from "./PortalTemplate";
import { Helmet } from 'react-helmet';
import AppContext from "../../AppContext";
import { toast } from "react-toastify";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../..";



export default function ChangePasswordPage() {
  useDocumentTitle("Change Password");

  const context: any = useContext(AppContext);

  const [passwordData, setPasswordData] = useState({} as any);

    const passwordInputChange = (e: any) => {
        setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
    }

    const submitPassword = async (e: any) => {
        e.preventDefault();

        if (passwordData.password !== passwordData.password_confirmation) {
            toast.error("Password and Confirm Password does not match!");
            return;
        }

        const loading = toast.loading("Updating Password...");

        try {
            const docRef = doc(db, "users", context.user.id);

            //get current password
            const docSnap = await getDoc(docRef);
            const currentPassword = docSnap.data()?.password;

            if (currentPassword !== passwordData.current_password) {
                toast.error("Current Password is incorrect!");
                return;
            }


            await updateDoc(docRef, {password: passwordData.password});
            toast.success("Password Updated!");
        }
        catch (error) {
            toast.error("Error Updating Password!");
        }
        finally {
            toast.dismiss(loading);
        }

    }


  return (
   <PortalTemplate className="md:py-6 flex flex-col items-center justify-center">
    <Helmet>
                <style>{'body { background-color: #F2F3F6; }'}</style>
    </Helmet>
    <>
    <div className="w-full px-4 md:px-32">
    <h1 className="text-black text-xl md:text-2xl text-left w-full">Change Password</h1>


    <form onSubmit={submitPassword} method="POST">
                                <div className="overflow-hidden shadow lg:rounded-md">
                                    <div className="space-y-6 bg-white px-4 py-5 lg:p-6">
                                        <div className="col-span-6 lg:col-span-3">
                                            <label htmlFor="current_password" className="block text-sm font-medium text-gray-700">
                                                Current Password
                                            </label>
                                            <input
                                                name='current_password'
                                                value={passwordData.current_password || ""}
                                                onChange={passwordInputChange}
                                                type="password" id="current_password" style={{ fontFamily: 'Verdana' }} className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black" placeholder="Password" required />
                                        </div>

                                        <div className="col-span-6 lg:col-span-3">
                                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                                New Password
                                            </label>
                                            <input
                                                name='password'
                                                value={passwordData.password || ""}
                                                onChange={passwordInputChange}
                                                type="password" id="password" style={{ fontFamily: 'Verdana' }} className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black" placeholder="Password" required />

                                        </div>
                                        <div className="col-span-6 lg:col-span-3">
                                            <label htmlFor="password_confirmation" className="block text-sm font-medium text-gray-700">
                                                Confirm Password
                                            </label>
                                            <input
                                                name='password_confirmation'
                                                value={passwordData.password_confirmation || ""}
                                                onChange={passwordInputChange}
                                                type="password" id="password_confirmation" style={{ fontFamily: 'Verdana' }} className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black" placeholder="Password" required />

                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 text-right lg:px-6">
                                        <button
                                            type="submit"
                                            className="w-full justify-center rounded-md border border-transparent bg-primary-500 py-3 px-4 text-lg font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-700 focus:ring-offset-2"
                                        >
                                            Change Password
                                        </button>
                                    </div>
                                </div>
                            </form>
    </div>
    <br/>
    <br/>
    <br/>
    <br/>
    </>

   </PortalTemplate>
  );
}


