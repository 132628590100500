/* eslint-disable eqeqeq */

import { useState } from "react";

type Props = {
  navLinks: Array<any>;
  setNavLinks: Function;
  className?: string;
};
export default function LandingHeader({
  navLinks,
  setNavLinks,
  className,
}: Props) {

  const [showNav, setShowNav] = useState(false);


  //hide all sublinks when scrolling
  const handleScroll = () => {
    const newLinks = [...navLinks];
    newLinks.forEach((link: any) => {
      link.showSubLinks = false;
    });
    setNavLinks(newLinks);
    setShowNav(false);
  };

  //add scroll event listener
  if (typeof window !== "undefined") {
    window.addEventListener("scroll", handleScroll);
  }


  return (
    <>
      <header
        className={`fixed z-40 w-full bg-transparent text-white flex flex-row md:items-center justify-end md:justify-center gap-x-2 px-2 md:px-5 py-2 md:py-5 text-[12px] font-[500] border-b border-b-[#ffffff31] ${className}`}
      >
        {/* nav */}
        <nav
          role="navigation"
          className={`${showNav ? "flex" : "hidden md:flex"} flex-col md:flex-row md:items-center md:justify-center gap-x-3 mt-2 w-full md:w-auto absolute left-0 md:left-auto top-[70px] md:top-auto md:relative bg-[#000000d4] md:bg-transparent py-5 md:py-0`}
        >
          {navLinks.map((link, index) => {
            if (link.name == "sign-up") {
              return (
                <a
                  key={index}
                  href={link.href}
                  className="py-3 px-4 text-white bg-[#678c805c] md:bg-[#678C80] hover:bg-primary md:rounded-full"
                >
                  {(() => {
                    if (link.title == "SIGN UP") {
                      return "GAIN ACCESS";
                    }
                    return link.title;
                  })()}
                </a>
              );
            } else if (link.name == "login") {
              return (
                <a
                  key={index}
                  href={link.href}
                  className="py-3 px-4 text-white hover:bg-[#4D8B2B] md:border md:border-[#77bc1f] md:rounded-full"
                >
                  {link.title}
                </a>
              );
            } else if (link.subLinks) {
              return (
                <div key={index} className="relative group">
                  <div
                    className="py-4 md:py-3 px-4 hover:bg-[#ffffff16] md:hover:bg-transparent md:hover:border md:hover:border-white md:rounded-full cursor-pointer flex gap-x-2 items-center "
                    onClick={() => {
                      const newLinks = [...navLinks];
                      newLinks[index].showSubLinks =
                        !newLinks[index].showSubLinks;
                      setNavLinks(newLinks);
                    }}
                  >
                    <div className="">{link.title}</div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="w-3 h-3"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m19.5 8.25-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </div>
                  <nav
                    className={`absolute z-30 top-14 md:top-16 left-0 bg-[#235A40] md:bg-[#000000ce] text-gray-400 shadow-lg py-5 w-full md:w-auto ${
                      link.showSubLinks ? "block" : "hidden"
                    }`}
                  >
                    {link.subLinks.map((subLink: any, index: any) => {
                      return (
                        <a
                          key={`sublink-${index}`}
                          href={subLink.href}
                          className="block pl-6 pr-12 py-4 hover:bg-[#ffffff24] hover:text-white whitespace-nowrap"
                        >
                          {subLink.title}
                        </a>
                      );
                    })}
                  </nav>
                </div>
              );
            }
            return (
              <a
                key={index}
                href={link.href}
                className="py-4 md:py-3 px-4 hover:bg-[#ffffff16] md:hover:bg-transparent md:hover:border md:hover:border-white md:rounded-full"
              >
                {(() => {
                  if (link.title == "PROPERTIES") {
                    return "INVESTMENTS";
                  } else if (link.title == "ABOUT US") {
                    return "ABOUT FARMFUNDR";
                  } else if (link.title == "SIGN UP") {
                    return "GAIN ACCESS";
                  }
                  return link.title;
                })()}
              </a>
            );
          })}
        </nav>
        {/* mobile nav button */}
        <div
          className="md:hidden bg-[#ffffff27] p-4 hover:bg-[#ffffff43]"
          aria-label="menu"
          role="button"
          tabIndex={0}
          aria-controls="w-nav-overlay-0"
          aria-haspopup="menu"
          aria-expanded="false"
          onClick={() => setShowNav(!showNav)}
        >
          <div className="icon w-icon-nav-menu text-3xl" />
        </div>
      </header>
    </>
  );
}
