/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react/jsx-no-target-blank */
import useDocumentTitle from "../hooks/userDocumentTitle";
import MainTemplate from "./MainTemplate";

export default function ContactPage() {
  useDocumentTitle("Contact");

  const onSubmit = (e: any) => {
    e.preventDefault();
  }

  return (
    <>
      <MainTemplate pageType="others">
        <>
          <div
            data-collapse="medium"
            data-animation="default"
            data-duration={400}
            data-easing="ease"
            data-easing2="ease"
            role="banner"
            className="scroll-navbar navbar-2 w-hidden-medium w-hidden-small w-hidden-tiny w-nav"
            style={{
              willChange: "transform",
              transform:
                "translate3d(0px, -102%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d",
            }}
          >
            <div className="container-nav w-container">
              <a href="/" className="brand w-nav-brand" aria-label="home">
                <img
                  src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e59a0c0a67bd6d170e34c37_FarmFundrLogo-01.png"
                  width={150}
                  alt="How to invest in farmland"
                />
              </a>
              <nav role="navigation" className="nav-menu w-nav-menu">
                <a
                  href="/property"
                  className="nav-link normal-nav black w-nav-link"
                  style={{ maxWidth: 940 }}
                >
                  Properties
                </a>
                <a
                  href="/category/blog"
                  className="nav-link normal-nav black w-nav-link"
                  style={{ maxWidth: 940 }}
                >
                  FARMLAND INVESTING BLOG
                </a>
                <a
                  href="/about-us"
                  className="nav-link normal-nav black w-nav-link"
                  style={{ maxWidth: 940 }}
                >
                  ABOUT US
                </a>
                <div
                  data-delay={0}
                  data-hover="false"
                  className="nav-link w-dropdown"
                  style={{ maxWidth: 940 }}
                >
                  <div
                    className="dropdown-toggle black w-dropdown-toggle"
                    id="w-dropdown-toggle-0"
                    aria-controls="w-dropdown-list-0"
                    aria-haspopup="menu"
                    aria-expanded="false"
                    role="button"
                    tabIndex={0}
                  >
                    <div className="text-block-22">resources</div>
                    <div
                      className="dropdown-arrow w-icon-dropdown-toggle"
                      aria-hidden="true"
                    />
                  </div>
                  <nav
                    className="dropdown-list big w-dropdown-list"
                    id="w-dropdown-list-0"
                    aria-labelledby="w-dropdown-toggle-0"
                  >
                    <a
                      href="/faqs"
                      className="dropdown-link w-dropdown-link"
                      tabIndex={0}
                    >
                      FAQ
                    </a>
                    <a
                      href="/media"
                      className="dropdown-link w-dropdown-link"
                      tabIndex={0}
                    >
                      media
                    </a>
                    <a
                      href="/helping-farmers-grow"
                      className="dropdown-link w-dropdown-link"
                      tabIndex={0}
                    >
                      HELPING&nbsp;FARMERS&nbsp;GROW
                    </a>
                    <a
                      href="/sell-farmland"
                      className="dropdown-link w-dropdown-link"
                      tabIndex={0}
                    >
                      sell farmland
                    </a>
                    <a
                      href="/custom-farmland-investments"
                      className="dropdown-link w-dropdown-link"
                      tabIndex={0}
                    >
                      farmfindr - Custom Investments
                    </a>
                  </nav>
                </div>
                <a
                  href="/contact-us"
                  aria-current="page"
                  className="nav-link normal-nav black w-nav-link w--current"
                  style={{ maxWidth: 940 }}
                >
                  Contact
                </a>
                <a
                  href="http://portal.farmfundr.com"
                  className="nav-link normal-nav login scroll w-nav-link"
                  style={{ maxWidth: 940 }}
                >
                  Login
                </a>
                <a
                  href="https://portal.farmfundr.com/?register=1"
                  className="nav-link normal-nav sign-up w-nav-link"
                  style={{ maxWidth: 940 }}
                >
                  Sign Up
                </a>
              </nav>
              <div
                className="menu-button gray w-nav-button"
                style={{ WebkitUserSelect: "text" }}
                aria-label="menu"
                role="button"
                tabIndex={0}
                aria-controls="w-nav-overlay-0"
                aria-haspopup="menu"
                aria-expanded="false"
              >
                <div className="icon w-icon-nav-menu" />
              </div>
            </div>
            <div
              className="w-nav-overlay"
              data-wf-ignore=""
              id="w-nav-overlay-0"
            />
          </div>
          <div
            data-ix="slide-in-scroll-navigation"
            className="page-header contact second wf-section"
          >
            <div className="w-container">
              <div className="div-text padding">
                <h1
                  data-w-id="0783b97b-7404-1b0f-a79a-9bff07916b4e"
                  style={{
                    opacity: 1,
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    transformStyle: "preserve-3d",
                  }}
                  className="heading center white-font"
                >
                  Get In touch
                  <span className="different-font" />
                </h1>
                <div
                  data-w-id="5bc84ca5-6e33-f43c-168e-cb14e4b0120a"
                  style={{
                    opacity: 1,
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    transformStyle: "preserve-3d",
                  }}
                  className="separated-div-wrapper"
                >
                  <div className="separator header" />
                  <div className="separator header center" />
                  <div className="separator header" />
                </div>
                <p
                  data-w-id="e96bf5f4-52b6-45f4-1625-5f8bad375dfb"
                  style={{
                    opacity: 1,
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    transformStyle: "preserve-3d",
                  }}
                  className="paragraph center white"
                >
                  We are happy to answer any questions you may have!
                </p>
              </div>
              <div
                data-w-id="f56afff7-aee6-48a4-1f9f-e2ee700d3a48"
                style={{
                  opacity: 1,
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  transformStyle: "preserve-3d",
                }}
                className="popup-div contact"
              >
                <div className="popup-content-div">
                  <div className="contact-wrapper-div in-devices">
                    <div className="top-margin">
                      <div className="w-form">
                        <form
                          id="wf-form-Contact-Us-Form"
                          name="wf-form-Contact-Us-Form"
                          data-name="Contact Us Form"
                          method="get"
                          aria-label="Contact Us Form"
                          onSubmit={onSubmit}
                        >
                          <input
                            type="text"
                            className="text-field-popup w-input"
                            maxLength={256}
                            name="First-Name"
                            data-name="First Name"
                            placeholder="First Name"
                            id="First-Name"
                            required
                          />
                          <input
                            type="text"
                            className="text-field-popup w-input"
                            maxLength={256}
                            name="Last-Name"
                            data-name="Last Name"
                            placeholder="Last Name"
                            id="Last-Name"
                            required
                          />
                          <input
                            type="tel"
                            className="text-field-popup w-input"
                            maxLength={256}
                            name="Phone"
                            data-name="Phone"
                            placeholder="Phone Number"
                            id="Phone"
                            required
                          />
                          <input
                            type="email"
                            className="text-field-popup w-input"
                            maxLength={256}
                            name="Email"
                            data-name="Email"
                            placeholder="Email address"
                            id="Email-7"
                            required
                          />
                          <textarea
                            data-name="Comment"
                            maxLength={5000}
                            id="Comment"
                            name="Comment"
                            placeholder="Message"
                            required
                            className="text-field-popup w-input"
                            defaultValue={""}
                          />
                          <div
                            data-sitekey="6Ld9l48kAAAAAPISYyGKZI7kCBsPkS_ktqLAbMf2"
                            className="w-form-formrecaptcha g-recaptcha g-recaptcha-error g-recaptcha-disabled"
                          >
                            <div style={{ width: 304, height: 78 }}>
                              <div>
                                <iframe
                                  title="reCAPTCHA"
                                  width={304}
                                  height={78}
                                  role="presentation"
                                  name="a-ufs2vqch2zfm"
                                  frameBorder={0}
                                  scrolling="no"
                                  sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation"
                                  src="https://www.google.com/recaptcha/api2/anchor?ar=1&k=6Ld9l48kAAAAAPISYyGKZI7kCBsPkS_ktqLAbMf2&co=aHR0cHM6Ly93d3cuZmFybWZ1bmRyLmNvbTo0NDM.&hl=en&v=07g0mpPGukTo20VqKa8GbTSw&size=normal&cb=eps1a9rz76ej"
                                />
                              </div>
                              <textarea
                                id="g-recaptcha-response"
                                name="g-recaptcha-response"
                                className="g-recaptcha-response"
                                style={{
                                  width: 250,
                                  height: 40,
                                  border: "1px solid rgb(193, 193, 193)",
                                  margin: "10px 25px",
                                  padding: 0,
                                  resize: "none",
                                  display: "none",
                                }}
                                defaultValue={""}
                              />
                            </div>
                            <iframe style={{ display: "none" }} />
                          </div>
                          <div>
                            <div className="algin-center">
                              <input
                                type="submit"
                                defaultValue="Send Message"
                                data-wait="Please wait..."
                                className="button full w-button"
                              />
                            </div>
                          </div>
                        </form>
                        <div
                          className="success-message w-form-done"
                          tabIndex={-1}
                          role="region"
                          aria-label="Contact Us Form success"
                        >
                          <div>
                            Thank you for contacting FarmFundr! <br />
                            Someone will be in touch as soon as possible.
                          </div>
                        </div>
                        <div
                          className="error-message error-message-3 error-message-4 error-message-5 error-message-6 w-form-fail"
                          tabIndex={-1}
                          role="region"
                          aria-label="Contact Us Form failure"
                        >
                          <div>
                            Oops! Something went wrong while submitting the form
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="blog-post white services wf-section">
            <div className="w-row">
              <div className="column w-col w-col-6">
                <div className="features-block icon no-color contact">
                  <div className="icon-block-features new">
                    <img
                      src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e5eee59df814c507d673ddc_call.png"
                      alt="Call FarmFundr"
                      className="features-icon small"
                    />
                  </div>
                  <h1 className="heading features left-side">
                    Call Us
                    <span className="different-font" />
                  </h1>
                  <p className="paragraph center">912 229 0376</p>
                  <p className="paragraph center">210 239 2384</p>
                </div>
              </div>
              <div className="column w-col w-col-6">
                <div className="features-block icon no-color contact">
                  <div className="icon-block-features new">
                    <img
                      src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e5717f541472aa49e58bf77_earth-globe.svg"
                      alt="Farmland Investment Platform Location"
                      className="features-icon small"
                    />
                  </div>
                  <h1 className="heading features left-side">
                    <span className="different-font" />
                    mailing address
                  </h1>
                  <p className="paragraph left">
                    14345 Houston Ave. <br />
                    Hanford, CA 93230
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="footer dark-bootom wf-section" />
          {/*[if lte IE 9]><![endif]*/}
          <div
            style={{
              backgroundColor: "rgb(255, 255, 255)",
              border: "1px solid rgb(204, 204, 204)",
              boxShadow: "rgba(0, 0, 0, 0.2) 2px 2px 3px",
              position: "absolute",
              transition: "visibility 0s linear 0.3s, opacity 0.3s linear 0s",
              opacity: 0,
              visibility: "hidden",
              zIndex: 2000000000,
              left: 0,
              top: "-10000px",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "fixed",
                top: 0,
                left: 0,
                zIndex: 2000000000,
                backgroundColor: "rgb(255, 255, 255)",
                opacity: "0.05",
              }}
            />
            <div
              className="g-recaptcha-bubble-arrow"
              style={{
                border: "11px solid transparent",
                width: 0,
                height: 0,
                position: "absolute",
                pointerEvents: "none",
                marginTop: "-11px",
                zIndex: 2000000000,
              }}
            />
            <div
              className="g-recaptcha-bubble-arrow"
              style={{
                border: "10px solid transparent",
                width: 0,
                height: 0,
                position: "absolute",
                pointerEvents: "none",
                marginTop: "-10px",
                zIndex: 2000000000,
              }}
            />
            <div style={{ zIndex: 2000000000, position: "relative" }}>
              <iframe
                title="recaptcha challenge expires in two minutes"
                name="c-ufs2vqch2zfm"
                frameBorder={0}
                scrolling="no"
                sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation"
                src="https://www.google.com/recaptcha/api2/bframe?hl=en&v=07g0mpPGukTo20VqKa8GbTSw&k=6Ld9l48kAAAAAPISYyGKZI7kCBsPkS_ktqLAbMf2"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
          {/* <div id="cg7ea1v3lfcg1710282689937" className="widget-hidden">
            <iframe
              src="about:blank"
              frameBorder={0}
              scrolling="no"
              width="64px"
              height="60px"
              style={{
                outline: "none !important",
                visibility: "visible !important",
                resize: "none !important",
                boxShadow: "none !important",
                overflow: "visible !important",
                background: "none !important",
                opacity: "1 !important",
                filter: "alpha(opacity=100) !important",
                msFilter:
                  "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
                MzOpacity: "1 !important",
                KhtmlOpacity: "1 !important",
                top: "auto !important",
                right: "auto !important",
                bottom: "20px !important",
                left: "20px !important",
                position: "fixed !important",
                border: "0 !important",
                minHeight: "60px !important",
                minWidth: "64px !important",
                maxHeight: "60px !important",
                maxWidth: "64px !important",
                padding: "0 !important",
                margin: "0 !important",
                MozTransitionProperty: "none !important",
                WebkitTransitionProperty: "none !important",
                OTransitionProperty: "none !important",
                transitionProperty: "none !important",
                transform: "none !important",
                WebkitTransform: "none !important",
                msTransform: "none !important",
                width: "64px !important",
                height: "60px !important",
                display: "block !important",
                zIndex: "1000001 !important",
                backgroundColor: "transparent !important",
                cursor: "none !important",
                float: "none !important",
                borderRadius: "unset !important",
                pointerEvents: "auto !important",
                clip: "auto !important",
                colorScheme: "light !important",
              }}
              id="j2hnp38ugeko1710282689975"
              className=""
              title="chat widget"
            />
            <iframe
              src="about:blank"
              frameBorder={0}
              scrolling="no"
              width="350px"
              height="520px"
              style={{
                outline: "none !important",
                visibility: "visible !important",
                resize: "none !important",
                boxShadow: "none !important",
                overflow: "visible !important",
                background: "none !important",
                opacity: "1 !important",
                filter: "alpha(opacity=100) !important",
                msFilter:
                  "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
                MzOpacity: "1 !important",
                KhtmlOpacity: "1 !important",
                top: "auto !important",
                right: "auto !important",
                bottom: "90px !important",
                left: "10px !important",
                position: "fixed !important",
                border: "0 !important",
                minHeight: "520px !important",
                minWidth: "350px !important",
                maxHeight: "520px !important",
                maxWidth: "350px !important",
                padding: "0 !important",
                margin: "0 !important",
                MozTransitionProperty: "none !important",
                WebkitTransitionProperty: "none !important",
                OTransitionProperty: "none !important",
                transitionProperty: "none !important",
                transform: "none !important",
                WebkitTransform: "none !important",
                msTransform: "none !important",
                width: "350px !important",
                height: "520px !important",
                display: "none !important",
                zIndex: "auto !important",
                backgroundColor: "transparent !important",
                cursor: "none !important",
                float: "none !important",
                borderRadius: "5px !important",
                pointerEvents: "auto !important",
                clip: "auto !important",
                colorScheme: "light !important",
              }}
              id="hbkej3eld8bo1710282690156"
              className=""
              title="chat widget"
            />
            <iframe
              src="about:blank"
              frameBorder={0}
              scrolling="no"
              width="360px"
              height="145px"
              style={{
                outline: "none !important",
                visibility: "visible !important",
                resize: "none !important",
                boxShadow: "none !important",
                overflow: "visible !important",
                background: "none !important",
                opacity: "1 !important",
                filter: "alpha(opacity=100) !important",
                msFilter:
                  "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
                MzOpacity: "1 !important",
                KhtmlOpacity: "1 !important",
                top: "auto !important",
                right: "auto !important",
                bottom: 100,
                left: "20px !important",
                position: "fixed !important",
                border: "0 !important",
                minHeight: "145px !important",
                minWidth: "360px !important",
                maxHeight: "145px !important",
                maxWidth: "360px !important",
                padding: "0 !important",
                margin: "0 !important",
                MozTransitionProperty: "none !important",
                WebkitTransitionProperty: "none !important",
                OTransitionProperty: "none !important",
                transitionProperty: "none !important",
                transform: "none !important",
                WebkitTransform: "none !important",
                msTransform: "none !important",
                width: "360px !important",
                height: "145px !important",
                display: "none !important",
                zIndex: "auto !important",
                backgroundColor: "transparent !important",
                cursor: "none !important",
                float: "none !important",
                borderRadius: "unset !important",
                pointerEvents: "auto !important",
                clip: "auto !important",
                colorScheme: "light !important",
              }}
              id="nm703mks1u1g1710282690074"
              className=""
              title="chat widget"
            />
            <iframe
              src="about:blank"
              frameBorder={0}
              scrolling="no"
              width="146px"
              height="85px"
              style={{
                outline: "none !important",
                visibility: "visible !important",
                resize: "none !important",
                boxShadow: "none !important",
                overflow: "visible !important",
                background: "none !important",
                opacity: "1 !important",
                filter: "alpha(opacity=100) !important",
                msFilter:
                  "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
                MzOpacity: "1 !important",
                KhtmlOpacity: "1 !important",
                top: "auto !important",
                right: "auto !important",
                bottom: "82px !important",
                left: "20px !important",
                position: "fixed !important",
                border: "0 !important",
                minHeight: "85px !important",
                minWidth: "146px !important",
                maxHeight: "85px !important",
                maxWidth: "146px !important",
                padding: "0 !important",
                margin: "0px 0 0 0 !important",
                MozTransitionProperty: "none !important",
                WebkitTransitionProperty: "none !important",
                OTransitionProperty: "none !important",
                transitionProperty: "none !important",
                transform: "rotate(0deg) translateZ(0)",
                WebkitTransform: "rotate(0deg) translateZ(0)",
                msTransform: "rotate(0deg) translateZ(0)",
                width: "146px !important",
                height: "85px !important",
                display: "none !important",
                zIndex: "1000002 !important",
                backgroundColor: "transparent !important",
                cursor: "none !important",
                float: "none !important",
                borderRadius: "unset !important",
                pointerEvents: "auto !important",
                clip: "auto !important",
                colorScheme: "light !important",
                MozTransform: "rotate(0deg) translateZ(0)",
                OTransform: "rotate(0deg) translateZ(0)",
                transformOrigin: 0,
                MozTransformOrigin: 0,
                WebkitTransformOrigin: 0,
                OTransformOrigin: 0,
                msTransformOrigin: 0,
              }}
              id="w41jf4hduh5o1710282690027"
              className=""
              title="chat widget"
            />
          </div> */}
        </>
      </MainTemplate>
    </>
  );
}
