/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react/jsx-no-target-blank */
import useDocumentTitle from "../hooks/userDocumentTitle";
import MainTemplate from "./MainTemplate";

export default function AboutPage() {
  useDocumentTitle("About");

  return (
    <>
      <MainTemplate pageType="others">
        <>
          <div className="w-container" />
          <div
            data-collapse="medium"
            data-animation="default"
            data-duration={400}
            data-easing="ease"
            data-easing2="ease"
            role="banner"
            className="scroll-navbar navbar-2 w-hidden-medium w-hidden-small w-hidden-tiny w-nav"
            style={{
              willChange: "transform",
              transform:
                "translate3d(0px, -102%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d",
            }}
          >
            <div className="container-nav w-container">
              <a href="/" className="brand w-nav-brand" aria-label="home">
                <img
                  src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e59a0c0a67bd6d170e34c37_FarmFundrLogo-01.png"
                  width={150}
                  alt="How to invest in farmland"
                />
              </a>
              <nav role="navigation" className="nav-menu w-nav-menu">
                <a
                  href="/property"
                  className="nav-link normal-nav black w-nav-link"
                  style={{ maxWidth: 940 }}
                >
                  Properties
                </a>
                <a
                  href="/category/blog"
                  className="nav-link normal-nav black w-nav-link"
                  style={{ maxWidth: 940 }}
                >
                  FARMLAND INVESTING BLOG
                </a>
                <a
                  href="/about-us"
                  aria-current="page"
                  className="nav-link normal-nav black w-nav-link w--current"
                  style={{ maxWidth: 940 }}
                >
                  ABOUT US
                </a>
                <div
                  data-delay={0}
                  data-hover="false"
                  className="nav-link w-dropdown"
                  style={{ maxWidth: 940 }}
                >
                  <div
                    className="dropdown-toggle black w-dropdown-toggle"
                    id="w-dropdown-toggle-0"
                    aria-controls="w-dropdown-list-0"
                    aria-haspopup="menu"
                    aria-expanded="false"
                    role="button"
                    tabIndex={0}
                  >
                    <div className="text-block-22">resources</div>
                    <div
                      className="dropdown-arrow w-icon-dropdown-toggle"
                      aria-hidden="true"
                    />
                  </div>
                  <nav
                    className="dropdown-list big w-dropdown-list"
                    id="w-dropdown-list-0"
                    aria-labelledby="w-dropdown-toggle-0"
                  >
                    <a
                      href="/faqs"
                      className="dropdown-link w-dropdown-link"
                      tabIndex={0}
                    >
                      FAQ
                    </a>
                    <a
                      href="/media"
                      className="dropdown-link w-dropdown-link"
                      tabIndex={0}
                    >
                      media
                    </a>
                    <a
                      href="/helping-farmers-grow"
                      className="dropdown-link w-dropdown-link"
                      tabIndex={0}
                    >
                      HELPING&nbsp;FARMERS&nbsp;GROW
                    </a>
                    <a
                      href="/sell-farmland"
                      className="dropdown-link w-dropdown-link"
                      tabIndex={0}
                    >
                      sell farmland
                    </a>
                    <a
                      href="/custom-farmland-investments"
                      className="dropdown-link w-dropdown-link"
                      tabIndex={0}
                    >
                      farmfindr - Custom Investments
                    </a>
                  </nav>
                </div>
                <a
                  href="/contact-us"
                  className="nav-link normal-nav black w-nav-link"
                  style={{ maxWidth: 940 }}
                >
                  Contact
                </a>
                <a
                  href="/portal/login"
                  className="nav-link normal-nav login scroll w-nav-link"
                  style={{ maxWidth: 940 }}
                >
                  Login
                </a>
                <a
                  href="/portal/register"
                  className="nav-link normal-nav sign-up w-nav-link"
                  style={{ maxWidth: 940 }}
                >
                  Sign Up
                </a>
              </nav>
              <div
                className="menu-button gray w-nav-button"
                style={{ WebkitUserSelect: "text" }}
                aria-label="menu"
                role="button"
                tabIndex={0}
                aria-controls="w-nav-overlay-0"
                aria-haspopup="menu"
                aria-expanded="false"
              >
                <div className="icon w-icon-nav-menu" />
              </div>
            </div>
            <div
              className="w-nav-overlay"
              data-wf-ignore=""
              id="w-nav-overlay-0"
            />
          </div>
          <div data-ix="hide-popup-on-initial" className="contact-full-wrapper">
            <div className="popup-div">
              <div className="popup-content-div">
                <div className="contact-wrapper-div in-devices">
                  <div className="algin-center">
                    <div>
                      <div className="sub-tittle top">EVERY DOLLAR COUNTS</div>
                      <h2 className="heading-4">Make a Donation Today</h2>
                    </div>
                  </div>
                  <div className="top-margin">
                    <div className="w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        data-wf-page-id="60663b672c7236dbbd4694c6"
                        data-wf-element-id="4a8d00a1-f448-4f36-5c63-33359a84d817"
                        aria-label="Email Form"
                      >
                        <label htmlFor="Amount" className="field-label center">
                          Amount
                        </label>
                        <input
                          className="text-field-popup w-input"
                          maxLength={256}
                          name="Amount"
                          data-name="Amount"
                          placeholder="$"
                          type="text"
                          id="Amount"
                          required
                        />
                        <label htmlFor="Name-5" className="field-label center">
                          Your Details
                        </label>
                        <input
                          className="text-field-popup w-input"
                          maxLength={256}
                          name="Name-4"
                          data-name="Name 4"
                          placeholder="Enter your name"
                          type="text"
                          id="Name-5"
                        />
                        <input
                          className="text-field-popup w-input"
                          maxLength={256}
                          name="Surname"
                          data-name="Surname"
                          placeholder="Enter your surname"
                          type="email"
                          id="Surname"
                          required
                        />
                        <input
                          className="text-field-popup w-input"
                          maxLength={256}
                          name="Enter-your-email-address"
                          data-name="Enter your email address"
                          placeholder="Enter your email address"
                          type="text"
                          id="Enter-your-email-address"
                          required
                        />
                        <input
                          className="text-field-popup w-input"
                          maxLength={256}
                          name="Additional-Information"
                          data-name="Additional Information"
                          placeholder="Additional Information"
                          type="text"
                          id="Additional-Information"
                          required
                        />
                        <div>
                          <div className="algin-center">
                            <input
                              type="submit"
                              data-wait="Please wait..."
                              className="button w-button"
                              defaultValue="Place donation"
                            />
                          </div>
                        </div>
                      </form>
                      <div
                        className="w-form-done"
                        tabIndex={-1}
                        role="region"
                        aria-label="Email Form success"
                      >
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div
                        className="w-form-fail"
                        tabIndex={-1}
                        role="region"
                        aria-label="Email Form failure"
                      >
                        <div>
                          Oops! Something went wrong while submitting the form
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              data-w-id="0aa23d50-42fe-dba2-e74c-5c7dbfbeb5e8"
              className="close-button"
            />
          </div>
          <div
            data-autoplay="true"
            data-loop="true"
            data-wf-ignore="true"
            data-poster-url="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e59aa6b3fb2cc54b16ac082_Plowing Dirt Field-poster-00001.jpg"
            data-video-urls="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e59aa6b3fb2cc54b16ac082_Plowing Dirt Field-transcode.mp4,https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e59aa6b3fb2cc54b16ac082_Plowing Dirt Field-transcode.webm"
            className="background-video w-background-video w-background-video-atom"
          >
            <video
              id="92cdf720-883c-8310-e377-8988547daec3-video"
              autoPlay={true}
              loop={true}
              style={{
                backgroundImage:
                  'url("https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e59aa6b3fb2cc54b16ac082_Plowing Dirt Field-poster-00001.jpg")',
              }}
              muted={true}
              playsInline={true}
              data-wf-ignore="true"
              data-object-fit="cover"
            >
              <source
                src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e59aa6b3fb2cc54b16ac082_Plowing Dirt Field-transcode.mp4"
                data-wf-ignore="true"
              />
              <source
                src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e59aa6b3fb2cc54b16ac082_Plowing Dirt Field-transcode.webm"
                data-wf-ignore="true"
              />
            </video>
            <div
              id="Top"
              data-ix="slide-in-scroll-navigation"
              className="hero-div-video"
            >
              <div className="w-container">
                <div className="div-text _2">
                  <h1
                    data-w-id="219a3cdd-15ce-3b9a-d974-07fe5c78950d"
                    style={{
                      opacity: 1,
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                    className="heading center white-font"
                  >
                    ABOUT&nbsp;FarmFundr
                  </h1>
                  <div
                    data-w-id="7e06734b-b7f5-22b6-0dd7-a0f543b014ea"
                    style={{
                      opacity: 1,
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                    className="separated-div-wrapper"
                  >
                    <div className="separator header" />
                    <div className="separator header center" />
                    <div className="separator header" />
                  </div>
                  <p
                    data-w-id="8518a68b-7374-e277-749e-8b04f39fb0c6"
                    style={{
                      opacity: 1,
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                    className="paragraph center white"
                  >
                    As the only farmer-owned farmland
                    <br />
                    crowdfunding company, we have a full understanding of what a
                    good farmland investment looks like and what it takes to
                    make it profitable.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            data-ix="slide-in-scroll-navigation"
            className="page-header about-me"
          >
            <div className="w-container">
              <div className="w-row">
                <div className="w-col w-col-6">
                  <div
                    data-w-id="73b10fbe-5be4-3a6b-8a64-1dda36683052"
                    style={{
                      opacity: 1,
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                    className="div-text _2 w-clearfix"
                  >
                    <h1 className="heading white left">
                      farmer <br />
                      <br />
                      owned.
                    </h1>
                    <div className="separated-div-wrapper left">
                      <div className="separator header" />
                      <div className="separator header center" />
                      <div className="separator header" />
                    </div>
                    <p className="paragraph white left">
                      As a fourth-generation farmer, real estate investor and
                      expert in farm management, our CEO, Brandon, takes extreme
                      pride and care when it comes to selecting offerings for
                      our investors.{" "}
                    </p>
                  </div>
                </div>
                <div className="w-col w-col-6">
                  <div className="my-image-div ceo" />
                </div>
              </div>
            </div>
          </div>
          <div className="blog-post">
            <div className="container about w-container">
              <div className="w-row">
                <div className="w-col w-col-6">
                  <div className="div-text _2 left">
                    <h1 className="heading-4">
                      Meet our CEO, Brandon Silveira
                    </h1>
                    <img
                      src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e61f3ee45277003d044b043_BrandonAndInvestor.jpg"
                      alt="Brandon Silveira Meets with Farmland Investor at a Farmland Investment Property"
                      className="image-20 investor"
                    />
                  </div>
                </div>
                <div className="w-col w-col-6">
                  <p className="paragraph-32">
                    Brandon graduated from California Polytechnic University San
                    Luis Obispo with an agricultural degree. After graduating,
                    Brandon started his career in the agricultural industry, and
                    has experience managing and farming a large variety of
                    crops.
                    <br />
                    <br />
                    Brandon has bought and sold millions in real estate and
                    currently manages over $100 million in assets. His farm
                    management company was recognizing in 2012 for achieving
                    over 900% growth, and was listed on the “INC. Magazine” list
                    of fastest growing companies at number 701. It was the only
                    agricultural company on the list.
                    <br />
                    <br />
                    Brandon’s specialty is in farm management, land acquisition
                    and a variety of farm and land financing and strategies. His
                    passion is to help bridge the gap between the agricultural
                    industry and the consumer. <br />
                    <br />
                    FarmFundr investors can rest assured they are gaining access
                    to deals selected by a professional that has a demonstrated
                    history of thriving in the farming industry.
                  </p>
                  <div className="podcast-div">
                    <a
                      href="https://joefairless.com/podcast/jf1977-how-to-invest-in-farmland-without-huge-amounts-of-capital-with-brandon-silveira/"
                      target="_blank"
                      className="link-block-3 w-inline-block"
                    >
                      <p
                        data-w-id="5a951c61-089c-e893-5665-fbd119a5937c"
                        className="podcast-paragraph"
                      >
                        Click here to hear Brandon explain why he created
                        FarmFundr, what variables to look out for when selecting
                        investments &amp; what the benefits of investing in
                        farmland are.
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-container">
              <a
                href="https://profiles.forbes.com/members/business/profile/Brandon-Silveira-Founder-CEO-FarmFundr-LLC/c1071ba5-1cf1-48f1-a395-ed834c2f9672"
                target="_blank"
                className="w-inline-block"
              >
                <img
                  src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/6036ae9a1f2d545f9a4256eb_FBC-Badge-Circle-Blue-2021.png"
                  loading="lazy"
                  width={199}
                  alt="FarmFundr Forbes Council Member"
                />
              </a>
            </div>
          </div>
          <div className="call-to-action">
            <div className="quote-div">
              <div className="quote">
                "I saw a need to offer people the opportunity to <br />
                invest in farmland without putting up huge amounts of capital."
              </div>
              <div className="sub-tittle-quote">Brandon Silveira</div>
            </div>
          </div>
          <section id="cards-section" className="cards-section">
            <h1 className="heading center">Our Team</h1>
            <div
              data-w-id="e0ec2498-bd0f-eba1-0ec6-d6d7d1250ca4"
              style={{
                opacity: 1,
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d",
              }}
              className="separated-div-wrapper"
            >
              <div className="separator header" />
              <div className="separator header center" />
              <div className="separator header" />
            </div>
            <div className="centered-container w-container">
              <div className="team-column w-row">
                <div className="w-col w-col-3">
                  <img
                    src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e62e211b6815ff52c1e8390_Hil2.png"
                    width={197}
                    alt="Hilary Mathias"
                    className="team-image"
                  />
                </div>
                <div className="w-col w-col-9">
                  <h3>Hilary Mathias</h3>
                  <h3 className="subtext-field title">
                    Chief Operating Officer
                  </h3>
                  <p>
                    As someone who has been raised in an agricultural community
                    and farming family, Hilary is passionate about the industry.
                    She is thrilled to play a role in helping farmers find a
                    solution to a problem that has challenged them for years,
                    finding funding and introducing investors to this exciting
                    asset.
                    <br />
                  </p>
                  <div className="accordion-item">
                    <div
                      data-w-id="e0eb7f5d-77d2-aa5c-5845-38a70bc2afaa"
                      className="accordion__title-2"
                    >
                      <div>Read More</div>
                      <div className="accordion__plus-wrapper-2">
                        <div
                          className="accordion__bar-vert-2"
                          style={{
                            transform:
                              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                            transformStyle: "preserve-3d",
                          }}
                        />
                        <div className="accordion__bar-hor-2" />
                      </div>
                    </div>
                    <div
                      style={{ width: 700, height: 0 }}
                      className="accordion__content-wrap-2"
                    >
                      <div className="accordion__content-2">
                        <p className="bio">
                          In the past 10 years, Hilary has helped a variety of
                          agricultural companies market their products and
                          services through a variety of projects, everything
                          from social media management to full brand development
                          and market launch. Her experience has given Hilary
                          insight on the entire process of agricultural goods
                          going from farm to consumer.
                          <br />
                          <br />
                          In her role as Director of Marketing and Operations,
                          Hilary will lead the company’s initiatives to build
                          the FarmFundr brand and increase awareness about
                          farmland as a valuable asset class.
                          <br />
                          <br />
                          Hilary is also responsible for researching and
                          implementing new directives for growth and prosperity
                          of FarmFundr and it's valued investors. Creating a
                          positive and seamless investor experience is a top
                          priority for Hilary.
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="team-column w-row">
                <div className="w-col w-col-3">
                  <img
                    src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5f08a5de97aa1c37234f7faa_unnamed.jpg"
                    width={218}
                    alt="AJ Mildenberg"
                    className="team-image"
                  />
                </div>
                <div className="w-col w-col-9">
                  <h3>AJ Mildenberg</h3>
                  <h3 className="subtext-field title">Investment Analyst</h3>
                  <p>
                    <em className="italic-text-2">
                      Financial Analyst) and CAIA (Chartered Alternative
                      Investment Analyst), AJ brings a wealth of knowledge to
                      the team, including prior experience in the private
                      equity-crowdfunding space, institutional investment
                      consulting, early/late stage finance, and financial
                      technology over 8 years.{" "}
                    </em>
                  </p>
                  <div className="accordion-item">
                    <div
                      data-w-id="84c2a4a6-e333-31e2-ff8d-42fbd67f17a9"
                      className="accordion__title-2"
                    >
                      <div>Read More</div>
                      <div className="accordion__plus-wrapper-2">
                        <div className="accordion__bar-vert-2" />
                        <div className="accordion__bar-hor-2" />
                      </div>
                    </div>
                    <div
                      style={{ height: 0 }}
                      className="accordion__content-wrap-2"
                    >
                      <div className="accordion__content-2">
                        <p className="bio">
                          <em className="italic-text-2">
                            As an experienced financial professional with
                            accreditations including &nbsp;the CFA (Chartered
                            Graduating from the University of Southern
                            California with a degree in Economics, AJ previously
                            helped USC based start-ups raise over $2.6M in the
                            consumer products, technology, and other industries.{" "}
                            <br />‍<br />
                            Similar to the FarmFundr team, AJ was also born and
                            raised in a farming community in Boone County
                            Indiana, outside of Indianapolis, and has a passion
                            for helping his community succeed. Given his
                            experience in investment analysis and financing at
                            the institutional and individual level, knowledge of
                            alternative assets, and interest in California
                            Farmland investing AJ provides investors with a
                            solid understanding and ability to teach FarmFundr's
                            investment strategies. <br />
                          </em>
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="team-column w-row">
                <div className="w-col w-col-3">
                  <img
                    src="https://uploads-ssl.webflow.com/5e5717f4fa858f1cafb8c9cc/5e62e00851beefff11bdce35_richardcookweb.jpg"
                    width={218}
                    alt="Richard Cook "
                    className="team-image"
                  />
                </div>
                <div className="w-col w-col-9">
                  <h3>Richard Cook</h3>
                  <h3 className="subtext-field title">Advisor</h3>
                  <p>
                    <em className="italic-text-3">
                      With decades of experience in agricultural finance and
                      management, Richard Cook brings a special level of
                      expertise to our team. Having served in CFO &amp; CEO
                      leadership roles in primary production and fully
                      integrated{" "}
                    </em>
                    <a href="http://www.AgLendingGroup.com" target="_blank">
                      <em className="italic-text-3">Agribusiness </em>
                    </a>
                    <em className="italic-text-3">
                      enterprises, he has passed the test of time as a veteran
                      Agribusiness leader.
                      <br />
                    </em>
                  </p>
                  <div className="accordion-item">
                    <div
                      data-w-id="f917968e-2251-a220-2127-b0f5e83933e2"
                      className="accordion__title-2"
                    >
                      <div>Read More</div>
                      <div className="accordion__plus-wrapper-2">
                        <div
                          className="accordion__bar-vert-2"
                          style={{
                            transform:
                              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                            transformStyle: "preserve-3d",
                          }}
                        />
                        <div className="accordion__bar-hor-2" />
                      </div>
                    </div>
                    <div
                      style={{ width: 700, height: 0 }}
                      className="accordion__content-wrap-2"
                    >
                      <div className="accordion__content-2">
                        <p className="bio">
                          <em className="italic-text-2">
                            {" "}
                            During his ten-years as a partner and CFO for
                            McCarthy Farming, Richard oversaw the organization
                            becoming the largest agricultural organization in
                            the United States. His expertise helped the
                            organization develop over 30,000 acres of permanent
                            crops including vineyards in California, and large
                            cotton and soybean farms in West Texas and the
                            Mississippi Delta. This growth was made possible by
                            equity and debt funding from Life Insurance
                            Companies with significant funding from a California
                            bank. These arrangements were negotiated by Richard
                            and his team.
                            <br />‍<br />
                            The holdings of the McCarthy agricultural investment
                            companies also included 2.5 Million acres of cattle
                            grazing properties located in Queensland, Australia
                            and approximately 10,000 acres of irrigated farm
                            land in New South Wales, Australia.
                            <br /> <br />
                            Richard's leadership was also instrumental in the
                            development of the new and expanding Pistachio
                            Industry in California and a variety of other
                            plantings including cotton, tomatoes (fresh and
                            canning), melons, and lettuce. <br />
                            In addition to his work in the agricultural
                            business, in 1997 Richard lead an investment group
                            in the acquisition of Silicon Valley-based company,
                            Precision Components Group, via a leveraged
                            recapitalization deal.
                            <br /> <br />
                            Throughout his career in finance and agribusiness,
                            Richard has earned a reputation for optimizing
                            results and successfully negotiating difficult
                            territory.
                            <br />
                          </em>
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="featured-blog-container w-container">
            <h1 className="heading gray center">
              FEATURED&nbsp;BLOG&nbsp;POSTS
            </h1>
            <div className="w-dyn-list">
              <div role="list" className="w-clearfix w-dyn-items w-row">
                <div
                  role="listitem"
                  className="dynamic-item w-dyn-item w-col w-col-4"
                >
                  <a
                    href="#"
                    // href="/blog/useful-tips-for-becoming-a-successful-agriculture-investor"
                    className="featured-wrapper-3 w-inline-block"
                  >
                    <div className="div-block-5">
                      <div
                        style={{
                          backgroundImage:
                            'url("https://assets-global.website-files.com/5e5717f541472a2c2b58bd84/63efe7c6cb3df45d8d1573d4_Blog%20Image%20-%202022%20(1).png")',
                        }}
                        className="featured-image"
                      />
                    </div>
                    <div className="feafured-text">
                      <div className="featured-title">
                        Useful Tips for Becoming a Successful Agriculture
                        Investor
                      </div>
                      <div className="featured-description">
                        Investing in agriculture can provide long-term profits,
                        and with the right approach, you can become a successful
                        agriculture investor.{" "}
                      </div>
                      <div className="featured-details">
                        <div className="w-clearfix">
                          <img
                            alt=""
                            src=""
                            className="author-image w-dyn-bind-empty"
                          />
                          <div className="author lite w-dyn-bind-empty" />
                          <div className="thumbnail date">February 6, 2023</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div
                  role="listitem"
                  className="dynamic-item w-dyn-item w-col w-col-4"
                >
                  <a
                    href="#"
                    // href="/blog/questions-you-should-ask-yourself-before-purchasing-farmland"
                    className="featured-wrapper-3 w-inline-block"
                  >
                    <div className="div-block-5">
                      <div
                        style={{
                          backgroundImage:
                            'url("https://assets-global.website-files.com/5e5717f541472a2c2b58bd84/63efd44026ac7b0d241a099d_Blog%20Image%20-%202022.png")',
                        }}
                        className="featured-image"
                      />
                    </div>
                    <div className="feafured-text">
                      <div className="featured-title">
                        Questions You Should Ask Yourself Before Purchasing
                        Farmland
                      </div>
                      <div className="featured-description">
                        The idea of owning farmland appeals to many investors,
                        however, those who lack experience in farmland will want
                        to ask themselves some key questions before purchasing a
                        farm.
                      </div>
                      <div className="featured-details">
                        <div className="w-clearfix">
                          <img
                            alt=""
                            src=""
                            className="author-image w-dyn-bind-empty"
                          />
                          <div className="author lite w-dyn-bind-empty" />
                          <div className="thumbnail date">January 9, 2023</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div
                  role="listitem"
                  className="dynamic-item w-dyn-item w-col w-col-4"
                >
                  <a
                    href="#"
                    // href="/blog/why-invest-in-farmland-instead-of-stocks"
                    className="featured-wrapper-3 w-inline-block"
                  >
                    <div className="div-block-5">
                      <div
                        style={{
                          backgroundImage:
                            'url("https://assets-global.website-files.com/5e5717f541472a2c2b58bd84/632b6388f89abb5591f367ec_Blog%20Image%20-%202022%20(6).png")',
                        }}
                        className="featured-image"
                      />
                    </div>
                    <div className="feafured-text">
                      <div className="featured-title">
                        Why Invest in Farmland Instead of Stocks?
                      </div>
                      <div className="featured-description">
                        While many investors choose to focus their money on the
                        stock market, there’s much to be said for considering
                        farmland as a dependable way to build up your portfolio.
                      </div>
                      <div className="featured-details">
                        <div className="w-clearfix">
                          <img
                            alt=""
                            src=""
                            className="author-image w-dyn-bind-empty"
                          />
                          <div className="author lite w-dyn-bind-empty" />
                          <div className="thumbnail date">
                            September 19, 2022
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="footer dark-bootom" />
          <a
            href="#Top"
            data-ix="go-top-on-load"
            className="go-to w-inline-block w--current"
          />
          <div id="c0hqr6otlgao1710282332213" className="widget-hidden">
            {/* <iframe
              src="about:blank"
              frameBorder={0}
              scrolling="no"
              width="64px"
              height="60px"
              style={{
                outline: "none !important",
                visibility: "visible !important",
                resize: "none !important",
                boxShadow: "none !important",
                overflow: "visible !important",
                background: "none !important",
                opacity: "1 !important",
                filter: "alpha(opacity=100) !important",
                msFilter:
                  "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
                MzOpacity: "1 !important",
                KhtmlOpacity: "1 !important",
                top: "auto !important",
                right: "auto !important",
                bottom: "20px !important",
                left: "20px !important",
                position: "fixed !important",
                border: "0 !important",
                minHeight: "60px !important",
                minWidth: "64px !important",
                maxHeight: "60px !important",
                maxWidth: "64px !important",
                padding: "0 !important",
                margin: "0 !important",
                MozTransitionProperty: "none !important",
                WebkitTransitionProperty: "none !important",
                OTransitionProperty: "none !important",
                transitionProperty: "none !important",
                transform: "none !important",
                WebkitTransform: "none !important",
                msTransform: "none !important",
                width: "64px !important",
                height: "60px !important",
                display: "block !important",
                zIndex: "1000001 !important",
                backgroundColor: "transparent !important",
                cursor: "none !important",
                float: "none !important",
                borderRadius: "unset !important",
                pointerEvents: "auto !important",
                clip: "auto !important",
                colorScheme: "light !important",
              }}
              id="f4jokdk8e37o1710282332243"
              className=""
              title="chat widget"
            />
            <iframe
              src="about:blank"
              frameBorder={0}
              scrolling="no"
              width="350px"
              height="520px"
              style={{
                outline: "none !important",
                visibility: "visible !important",
                resize: "none !important",
                boxShadow: "none !important",
                overflow: "visible !important",
                background: "none !important",
                opacity: "1 !important",
                filter: "alpha(opacity=100) !important",
                msFilter:
                  "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
                MzOpacity: "1 !important",
                KhtmlOpacity: "1 !important",
                top: "auto !important",
                right: "auto !important",
                bottom: "90px !important",
                left: "10px !important",
                position: "fixed !important",
                border: "0 !important",
                minHeight: "520px !important",
                minWidth: "350px !important",
                maxHeight: "520px !important",
                maxWidth: "350px !important",
                padding: "0 !important",
                margin: "0 !important",
                MozTransitionProperty: "none !important",
                WebkitTransitionProperty: "none !important",
                OTransitionProperty: "none !important",
                transitionProperty: "none !important",
                transform: "none !important",
                WebkitTransform: "none !important",
                msTransform: "none !important",
                width: "350px !important",
                height: "520px !important",
                display: "none !important",
                zIndex: "auto !important",
                backgroundColor: "transparent !important",
                cursor: "none !important",
                float: "none !important",
                borderRadius: "5px !important",
                pointerEvents: "auto !important",
                clip: "auto !important",
                colorScheme: "light !important",
              }}
              id="z0rn2lhu6fqo1710282332325"
              className=""
              title="chat widget"
            />
            <iframe
              src="about:blank"
              frameBorder={0}
              scrolling="no"
              width="360px"
              height="145px"
              style={{
                outline: "none !important",
                visibility: "visible !important",
                resize: "none !important",
                boxShadow: "none !important",
                overflow: "visible !important",
                background: "none !important",
                opacity: "1 !important",
                filter: "alpha(opacity=100) !important",
                msFilter:
                  "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
                MzOpacity: "1 !important",
                KhtmlOpacity: "1 !important",
                top: "auto !important",
                right: "auto !important",
                bottom: 100,
                left: "20px !important",
                position: "fixed !important",
                border: "0 !important",
                minHeight: "145px !important",
                minWidth: "360px !important",
                maxHeight: "145px !important",
                maxWidth: "360px !important",
                padding: "0 !important",
                margin: "0 !important",
                MozTransitionProperty: "none !important",
                WebkitTransitionProperty: "none !important",
                OTransitionProperty: "none !important",
                transitionProperty: "none !important",
                transform: "none !important",
                WebkitTransform: "none !important",
                msTransform: "none !important",
                width: "360px !important",
                height: "145px !important",
                display: "none !important",
                zIndex: "auto !important",
                backgroundColor: "transparent !important",
                cursor: "none !important",
                float: "none !important",
                borderRadius: "unset !important",
                pointerEvents: "auto !important",
                clip: "auto !important",
                colorScheme: "light !important",
              }}
              id="l3prfuvofs1710282332294"
              className=""
              title="chat widget"
            />
            <iframe
              src="about:blank"
              frameBorder={0}
              scrolling="no"
              width="146px"
              height="85px"
              style={{
                outline: "none !important",
                visibility: "visible !important",
                resize: "none !important",
                boxShadow: "none !important",
                overflow: "visible !important",
                background: "none !important",
                opacity: "1 !important",
                filter: "alpha(opacity=100) !important",
                msFilter:
                  "progid:DXImageTransform.Microsoft.Alpha(Opacity 1}) !important",
                MzOpacity: "1 !important",
                KhtmlOpacity: "1 !important",
                top: "auto !important",
                right: "auto !important",
                bottom: "82px !important",
                left: "20px !important",
                position: "fixed !important",
                border: "0 !important",
                minHeight: "85px !important",
                minWidth: "146px !important",
                maxHeight: "85px !important",
                maxWidth: "146px !important",
                padding: "0 !important",
                margin: "0px 0 0 0 !important",
                MozTransitionProperty: "none !important",
                WebkitTransitionProperty: "none !important",
                OTransitionProperty: "none !important",
                transitionProperty: "none !important",
                transform: "rotate(0deg) translateZ(0)",
                WebkitTransform: "rotate(0deg) translateZ(0)",
                msTransform: "rotate(0deg) translateZ(0)",
                width: "146px !important",
                height: "85px !important",
                display: "none !important",
                zIndex: "1000002 !important",
                backgroundColor: "transparent !important",
                cursor: "none !important",
                float: "none !important",
                borderRadius: "unset !important",
                pointerEvents: "auto !important",
                clip: "auto !important",
                colorScheme: "light !important",
                MozTransform: "rotate(0deg) translateZ(0)",
                OTransform: "rotate(0deg) translateZ(0)",
                transformOrigin: 0,
                MozTransformOrigin: 0,
                WebkitTransformOrigin: 0,
                OTransformOrigin: 0,
                msTransformOrigin: 0,
              }}
              id="zq5348ib4mug1710282332270"
              className=""
              title="chat widget"
            /> */}
          </div>
        </>
      </MainTemplate>
    </>
  );
}
